import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Form, Field } from 'react-final-form'

// Components
import TextField from '../../components/fields/TextField';
import AlertModal from '../../components/AlertModal';
import MapField from '../../components/fields/MapField';
import ToggleField from '../../components/fields/ToggleField';
import CompanySingleselectField from '../../components/fields/CompanySingleselectField';
import TimezoneSelectField from '../../components/fields/TimezoneSelectField';
import NumberField from '../../components/fields/NumberField';
import RangeSliderField from '../../components/fields/RangeSliderField';
import CreateableMultiSelect from '../../components/fields/CreateableMultiSelect';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

// Form Multiple Validators
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
const required = value => (value ? undefined : 'Required')
const arrayRequired = value => (value.length ? undefined : 'Required')


export default function CreateCrud(props) {
  const { user } = props
  let params = useParams()
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [sendingData, setSendingData] = useState(false)

  // Set page title
  useEffect(() => {
    document.title = `Create Office`;
  }, [])

  function closeForm() {
    setAlertModalOpen(false)
    navigate("/offices");
  }

  // Send data
  const sendData = values => new Promise(resolve => {
    setSendingData(true)
    const formData = new FormData();
    formData.append('company_id', values.company_id);
    formData.append('name', values.name);
    if(values.address) { formData.append('address', values.address); }
    if(values.phone) { formData.append('phone', values.phone); }
    formData.append('timezone', values.timezone);
    formData.append('close_payroll_day', values.close_payroll_day);
    formData.append('is_notify_hr', values.is_notify_hr);
    formData.append('is_notify_line_manager', values.is_notify_line_manager);
    formData.append('is_notify_ceo', values.is_notify_ceo);
    formData.append('lat', values.location.lat);
    formData.append('lng', values.location.lng);
    formData.append('is_allow_buffer', values.is_allow_buffer);
    formData.append('allowed_range_radius', values.allowed_range_radius);
    formData.append('attendance_notes', JSON.stringify(values.attendance_notes));
    
    axios.post('offices', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    .then(function (response) {
        setSendingData(false)
        resolve()
        if(response.data.data.id) {
          navigate('/offices')
        }
      })
      .catch(function (error) {
        setSendingData(false)
        resolve()
      });
  })

  // Submit form
  const onSubmit = async values => {
    await sendData(values)
  }

  if(!isLoaded) {
    return <div className="fullscreen-loading flex items-center justify-around">Loading..</div>
  } else {
    return (
      <div className="h-full">
        <Form
          keepDirtyOnReinitialize
          onSubmit={onSubmit}
          initialValues={{ timezone: 'Asia/Kuwait', is_notify_hr: false, is_notify_line_manager: false, is_notify_ceo: false, close_payroll_day: 19, is_allow_buffer: false, allowed_range_radius: 200, }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              {/* Page title & actions */}
              <div className="grandizer-app-header bg-white border-b border-gray-200 px-4 py-4 flex items-center justify-between sm:px-6 lg:px-8 fixed top-14 lg:top-0 right-0 left-0 lg:left-56  lg:fixed lg:top-0 lg:left-56 lg:right-0 lg:z-10 bg-white">
                <div className="flex-1 min-w-0">
                  <h1 className="text-lg font-medium leading-6 text-gray-900 truncate">Create Office</h1>
                </div>
                <div className="mt-4 flex mt-0 ml-4"> 
                  <a href="#" onClick={() => setAlertModalOpen(true)} disabled={sendingData} className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-2 disabled:opacity-75">
                    Cancel
                  </a>
                  <button
                    disabled={sendingData}
                    type="submit"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
                  >
                    {sendingData ? 'Submitting..' : 'Submit'}
                  </button>
                </div>
              </div>
              <main className="py-10 pt-20">
                <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8 mt-6">
                  <div className="shadow sm:rounded-md sm:overflow-hidden w-full">
                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6 relative">
                      {sendingData ? (<div className="bg-white absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center z-50">
                        Please wait..
                      </div>) : ''}
                      <div>
                        <div> 
                          <div className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200">
                              <div>
                                <div>
                                  <h3 className="text-lg leading-6 font-medium text-gray-900">Office information</h3>
                                  <p className="mt-1 text-sm text-gray-500">
                                  
                                  </p>
                                </div>
                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                  <Field name="company_id" validate={required}>
                                    {({ input, meta }) => (            
                                      <CompanySingleselectField required={true} label="Belongs to company" col={6} input={input} meta={meta} />
                                    )}
                                  </Field>

                                  <Field name="name" validate={required}>
                                    {({ input, meta }) => (            
                                      <TextField required={true} label="Office Name" input={input} meta={meta} col={6} />
                                    )}
                                  </Field>

                                  <Field name="timezone" validate={required}>
                                    {({ input, meta }) => (            
                                      <TimezoneSelectField required={true} label="Timezone" input={input} meta={meta} col={6} />
                                    )}
                                  </Field>

                                  <Field name="close_payroll_day" validate={required}>
                                    {({ input, meta }) => (            
                                      <NumberField helpText="min: 1, max: 28" min={1} max={28} required={true} label="Close Payroll Day" input={input} meta={meta} col={6} />
                                    )}
                                  </Field>

                                  <Field name="address">
                                    {({ input, meta }) => (            
                                      <TextField label="Address" input={input} meta={meta} col={3} />
                                    )}
                                  </Field>

                                  <Field name="phone">
                                    {({ input, meta }) => (            
                                      <TextField label="Phone" input={input} meta={meta} col={3} />
                                    )}
                                  </Field>

                                  {values.company_id && <Field name="attendance_notes">
                                    {({ input, meta }) => (            
                                      <CreateableMultiSelect 
                                        createParams={{ company_id: values.company_id }} 
                                        createPath="attendance-notes"
                                        path={"attendance-notes?company_id="+values.company_id} 
                                        label="Predefined Notes" 
                                        col={6} 
                                        input={input} 
                                        meta={meta} 
                                        description={`Predefined notes which emp going choose from when invalid check-in/out happen either location or timing, by default "No Reason Specified" already attached to each office so no need to add here.`}
                                      />
                                    )}
                                  </Field>}

                                  <Field name="is_allow_buffer">
                                      {({ input, meta }) => (            
                                        <ToggleField label="Allow 15 mins buffer for check-in & check-out timings to be considered as valid?" input={input} meta={meta} col={6} />
                                      )}
                                  </Field>

                                  <Field name="is_notify_hr">
                                      {({ input, meta }) => (            
                                        <ToggleField label="Notify HR if attendance is invalid?" input={input} meta={meta} col={2} />
                                      )}
                                  </Field>

                                  <Field name="is_notify_line_manager">
                                      {({ input, meta }) => (            
                                        <ToggleField label="Notify Line Manager if attendance is invalid?" input={input} meta={meta} col={2} />
                                      )}
                                  </Field>

                                  <Field name="is_notify_ceo">
                                      {({ input, meta }) => (            
                                        <ToggleField label="Notify CEO if attendance is invalid?" input={input} meta={meta} col={2} />
                                      )}
                                  </Field>

                                  <Field name="allowed_range_radius">
                                      {({ input, meta }) => (            
                                        <RangeSliderField label="Allowed range radius" unit="meters" input={input} meta={meta} col={6} min={10} max={2000} />
                                      )}
                                  </Field>
                  
                                  <Field name="location" validate={required}>
                                    {({ input, meta }) => (            
                                      <MapField required={true} label="Location" input={input} meta={meta} col="6" />
                                    )}
                                  </Field>
                                  
                                  
                                  {process.env.NODE_ENV === 'development' && <div>
                                    <pre>{JSON.stringify(values, 0, 2)}</pre>
                                  </div>}
                                </div>

                        
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </form>
            
          )}  
        />
        
        <AlertModal title="Close Form" confirm={closeForm} close={() => setAlertModalOpen(false)} open={alertModalOpen} />
      </div>
    )
  }
}
