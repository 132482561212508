import { useState, useEffect } from 'react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import axios from 'axios';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CompanySelectField(props) {
    const { label, input, meta, required = false } = props
    const [isLoaded, setIsLoaded] = useState(false);
    const [query, setQuery] = useState('')
    const [selectedItem, setSelectedItem] = useState(input.value)
    const [items, setItems] = useState([])

    useEffect(() => {
        // Get user detail
        axios.get('/companies-with-avgminprofit')
            .then(function (response) {
                //console.log(response.data)
                setItems(response.data)
                setIsLoaded(true);
            })
            .catch(function (error) {
            // handle error
            //setIsLoaded(true);
            //setError(error);
            })
            .then(function () {
            //setIsLoaded(true);
            });    
    }, [])

    const filteredItems =
        query === ''
        ? items
        : items.filter((item) => {
            return item.name.toLowerCase().includes(query.toLowerCase())
            })

    function handleChange(value)
    {
        setSelectedItem(value)
        input.onChange(value)
    }

    if(isLoaded) {
        return (
            <Combobox as="div" value={selectedItem} onChange={handleChange}>
            <Combobox.Label className="block text-sm font-medium text-gray-700">{label} {required && <span className="text-red-500">*</span>}</Combobox.Label>
            <div className="relative mt-1">
                <Combobox.Input
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(item) => item?.name +` (${item?.min_avg_project_profit}%)`}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>

                {filteredItems.length > 0 && (
                <Combobox.Options className="absolute z-20 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredItems.map((item) => (
                    <Combobox.Option
                        key={item.id}
                        value={item}
                        className={({ active }) =>
                        classNames(
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                        )
                        }
                    >
                        {({ active, selected }) => (
                        <>
                            <div className="flex items-center">
                            <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>
                                <div>{item.name}</div>
                                <div className="font-normal text-xs">{item.min_avg_project_profit}%</div>
                            </span>
                            </div>

                            {selected && (
                            <span
                                className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-indigo-600'
                                )}
                            >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                            )}
                        </>
                        )}
                    </Combobox.Option>
                    ))}
                </Combobox.Options>
                )}
            </div>
            </Combobox>
        )
    }
}
