import React, { useState, useRef, useEffect } from "react";

const SelectedAreaInputField = ({ containerRef, onUpdate, parentState }) => {
  const handleRef = useRef(null);
  const dragRef = useRef(null);
  const resizeRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    const handle = handleRef.current;
    const drag = dragRef.current;
    const resize = resizeRef.current;
    const container = containerRef.current;

    const handleMouseDown = (event) => {
      event.stopPropagation();
      setIsResizing(true);
    };

    const dragMouseDown = (event) => {
      event.stopPropagation();
      setIsDragging(true);

      const startX = event.clientX;
      const startY = event.clientY;
      const startRect = { ...parentState };

      const handleMouseMove = (event) => {
        const deltaX = event.clientX - startX;
        const deltaY = event.clientY - startY;

        const newRect = {
          ...startRect,
          x: Math.min(
            Math.max(startRect.x + deltaX, 0),
            container.clientWidth - startRect.width
          ),
          y: Math.min(
            Math.max(startRect.y + deltaY, 0),
            container.clientHeight - startRect.height
          ),
        };

        onUpdate(newRect);
      };

      const handleMouseUp = () => {
        setIsDragging(false);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    const handleResizeMouseDown = (event) => {
      event.stopPropagation();
      setIsResizing(true);

      const startX = event.clientX;
      const startY = event.clientY;
      const startRect = { ...parentState };

      const handleMouseMove = (event) => {
        const newRect = {
          ...startRect,
          width: Math.min(
            Math.max(startRect.width + (event.clientX - startX), 50),
            container.clientWidth - startRect.x
          ),
          height: Math.min(
            Math.max(startRect.height + (event.clientY - startY), 50),
            container.clientHeight - startRect.y
          ),
        };

        onUpdate(newRect);
      };

      const handleMouseUp = () => {
        setIsResizing(false);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    handle.addEventListener("mousedown", handleMouseDown);
    drag.addEventListener("mousedown", dragMouseDown);
    resize.addEventListener("mousedown", handleResizeMouseDown);

    return () => {
      handle.removeEventListener("mousedown", handleMouseDown);
      drag.removeEventListener("mousedown", dragMouseDown);
      resize.removeEventListener("mousedown", handleResizeMouseDown);
    };
  }, [parentState, containerRef]);

  return (
    <div
      style={{
        position: "absolute",
        left: parentState?.x,
        top: parentState?.y,
        width: parentState?.width,
        height: parentState?.height,
        border: "5px solid red",
        borderStyle: "dashed",
        cursor: isResizing ? "se-resize" : isDragging ? "grabbing" : "grab",
      }}
      ref={dragRef}
    >
      <div
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          width: 10,
          height: 10,
          background: "gray",
          cursor: "se-resize",
        }}
        ref={resizeRef}
      ></div>
      <div
        style={{
          position: "absolute",
          background: "gray",
          cursor: "grab",
        }}
        ref={handleRef}
      ></div>
      <textarea
        type="text"
        readOnly
        style={{
          width: "100%",
          height: "100%",
          outline: "none",
          border: "none",
          background: "transparent",
        }}
      />
    </div>
  );
};

export default SelectedAreaInputField;
