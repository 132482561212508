import { Fragment, useState, useEffect } from 'react'
import { Link  } from 'react-router-dom';
import axios from 'axios';
import { Dialog, Menu, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { ArchiveIcon, DotsVerticalIcon, MailIcon, PhoneIcon, TrashIcon } from '@heroicons/react/solid'
import Comments from '../../components/Comments';
import RatingStars from '../../components/RatingStars';
import { Form, Field } from 'react-final-form'

// Components
import AlertModal from '../../components/AlertModal';
import SelectField from '../../components/fields/SelectField';
import AutoSave from '../../components/fields/AutoSave';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Show(props) {
    const { user, open, close, modelId, reloadData } = props
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [model, setModel] = useState();
    const [archiveModalOpen, setArchiveModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [statuses, setStatuses] = useState([])

    function reloadFormMeta() {
        // Creation form data
        axios.get('/recruit-job-applications/create')
          .then(function (response) {
            // handle success
            setStatuses(response.data.statuses)
            loadData()
          })
          .catch(function (error) {
            // handle error
          })
          .then(function () {
            //
          });
      }

    function loadData() {
        setIsLoaded(false)
        axios.get('/recruit-job-applications/'+modelId)
        .then(function (response) {
            // handle success
            setIsLoaded(true);
            setModel(response.data)
        })
        .catch(function (error) {
            // handle error
            setIsLoaded(true);
            setError(error);
        })
        .then(function () {
            setIsLoaded(true);
        });
    }

    useEffect(() => {
        if(open && modelId) {
            reloadFormMeta() 
        }
    }, [open])

    function archiveModel() {
        setIsDeleting(true)
        axios.post('recruit-job-applications/'+model.id, {_method: 'delete'})
          .then(function (response) {
                if(response.data.status == 'deleted') {
                    window.location.reload()
                }
                setIsDeleting(false)
            })
            .catch(function (error) {
                setIsDeleting(false)
            });
    }

    function deleteModel() {
        setIsDeleting(true)
        axios.post('recruit-job-applications/'+model.id+'/force', {_method: 'delete'})
          .then(function (response) {
                if(response.data.status == 'deleted') {
                    window.location.reload()
                }
                setIsDeleting(false)
            })
            .catch(function (error) {
                setIsDeleting(false)
            });
    }

    // Send data
  const sendData = values => new Promise(resolve => {
    //setSendingData(true)
    const formData = new FormData();

    formData.append('_method', 'put');
    formData.append('status_id', values.status.id);

    axios.post('recruit-job-applications-update-status/'+model.id, formData)
    .then(function (response) {
        //setSendingData(false)
        resolve()
        if(response.data.data.id) {
          //setSavedNotificationShow(true)
        }
      })
      .catch(function (error) {
        //setSendingData(false)
        resolve()
      });
  })

    // Submit form
    const onSubmit = async values => {
        await sendData(values)
        reloadData()
    }

    return (
        <div>
        <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="px-4 py-6 sm:px-6">
                            <div className="flex items-start justify-between">
                                <Dialog.Title className="text-gray-900">
                                    { isLoaded && <Form
                                        keepDirtyOnReinitialize
                                        onSubmit={onSubmit}
                                        initialValues={{ ...model }}
                                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                                            <form onSubmit={handleSubmit} encType="multipart/form-data">
                                                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 w-full">
                                                    <Field name="status">
                                                    {({ input, meta }) => (            
                                                        <SelectField label="" col="3" input={input} meta={meta} options={statuses} />
                                                    )}
                                                    </Field>

                                                    <div className="col-span-3">
                                                        <AutoSave title={false} debounce={500} save={onSubmit} />
                                                    </div>
                                                </div>
                                            </form>)}  
                                        />}
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-0"
                                    onClick={() => close()}
                                >
                                    <span className="sr-only">Close panel</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                            </div>
                            </div>
                            {/* Main */}
                            { isLoaded && <div className="divide-y divide-gray-200">
                            <div className="pb-6">
                                <div className="h-24 bg-indigo-700 sm:h-20 lg:h-28" />
                                <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                                <div>
                                    <div className="-m-1 flex">
                                    <div className=" overflow-hidden rounded-lg border-4 border-white bg-white aspect-square">
                                        <a href="" href={model.photo_url}
                                        rel="noreferrer"
                                        target="_blank"><img
                                        className="w-24 sm:w-40 first-letter:lg:w-48 rounded-lg"
                                        src={model.photo_url}
                                        alt=""
                                        /></a>
                                    </div>
                                    </div>
                                </div>
                                <div className="mt-6 sm:ml-6 sm:flex-1">
                                    <div>
                                    <div className="flex items-center">
                                        <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">{model.full_name}</h3>
                                    </div>
                                    
                                    <p className="text-sm text-gray-500 flex items-center">
                                        <a href={'mailto:'+model.email} className="flex items-center mr-3"><MailIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" /> {model.email}</a>
                                        <a href={'tel:'+model.phone} className="flex items-center "><PhoneIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" /> {model.phone}</a>
                                    </p>
                                    </div>
                                    <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                                    <a 
                                        href={model.resume_url}
                                        rel="noreferrer"
                                        target="_blank"
                                        type="button"
                                        className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:flex-1"
                                    >
                                        View Resume
                                    </a>
                                    <Link to={'/recruit-job-applications/'+model.id+'/edit'} 
                                        className="inline-flex w-full flex-1 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Edit Data
                                    </Link>
                                    <div className="ml-3 inline-flex sm:ml-0">
                                        <Menu as="div" className="relative inline-block text-left">
                                        <Menu.Button className="inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                            <span className="sr-only">Open options menu</span>
                                            <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                        </Menu.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                    onClick={() => setArchiveModalOpen(true)}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm w-full text-left group flex items-center '
                                                    )}
                                                    > 
                                                        <ArchiveIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                        Archive Application
                                                    </button>
                                                )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                    onClick={() => setDeleteModalOpen(true)}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm w-full text-left group flex items-center '
                                                    )}
                                                    >
                                                        <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                        Delete Application
                                                    </button>
                                                )}
                                                </Menu.Item>
                                            </div>
                                            </Menu.Items>
                                        </Transition>
                                        </Menu>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 sm:px-0 sm:py-0">
                                <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                                    {model.rating && <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Rating
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                            <RatingStars stars={model.rating} />
                                        </dd>
                                    </div>}
                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Company
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                            {model.company.company_name}
                                        </dd>
                                    </div>
                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Applied For
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                            {model.job.title}
                                        </dd>
                                    </div>
                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Gender
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 capitalize">
                                            {model.gender}
                                        </dd>
                                    </div>
                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Nationality
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                            {model.nationality}
                                        </dd>
                                    </div>
                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Resident Country
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                            {model.country}
                                        </dd>
                                    </div>
                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        State
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                            {model.state}
                                        </dd>
                                    </div>
                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        City
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                            {model.city}
                                        </dd>
                                    </div>
                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Created By
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                            {(model.creator) ? <span>{model.creator.name}</span> : <span>Portal</span>}
                                        </dd>
                                    </div>
                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Created At
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                        <time dateTime={model.created_at}>{model.created_at}</time>
                                        </dd>
                                    </div>
                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">Cover Letter</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                        <p>{model.cover_letter}</p>
                                        </dd>
                                    </div>

                                    {model.additional_fields.length > 0 && <h2 id="notes-title" className="text-lg font-medium text-gray-900 px-6 py-2 pt-5">
                                            Additional Details
                                        </h2>}
                                        
                                    {model.additional_fields.map((additionalField) => (
                                        <div key={additionalField.label} className="sm:flex sm:px-6 sm:py-2">
                                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">{additionalField.label}</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                                {additionalField.value}
                                            </dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>

                            <Comments user={user} modelId={model.id} />                            

                        </div>}
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
        
        <AlertModal title="Archive Application" note="Are you sure you want to archive this application?" confirm={archiveModel} close={() => setArchiveModalOpen(false)} open={archiveModalOpen} />
        <AlertModal title="Delete Application" note="Are you sure you want to delete this application?" confirm={deleteModel} close={() => setDeleteModalOpen(false)} open={deleteModalOpen} />
        </div>
    )
}
