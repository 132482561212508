import { useState, useEffect } from "react";
import {
  ExclamationCircleIcon,
  XIcon,
  TrashIcon,
} from "@heroicons/react/solid";

export default function ArrayField(props) {
  const {
    label,
    input,
    meta,
    columns,
    required = false,
    keyValue,
    setNonRegisterederror,
  } = props;
  const [dataRows, setDataRows] = useState(input.value ? input.value : []);

  // // this is extra code for update case
  useEffect(() => {
    if (input.value !== "") {
      setDataRows(input?.value);
    }
  }, []);

  //

  function addDataRow() {
    let newRowData = {};
    columns.map((column) => {
      newRowData[column.key] = "";
    });
    setDataRows([...dataRows, newRowData]);
  }

  const [errors, setErrors] = useState({});

  function handleChange(event, dataRowIndex, columnKey) {
    const value = event.target.value;
    let newDataRows = [...dataRows];
    newDataRows[dataRowIndex][columnKey] = value;
    setDataRows(newDataRows);

    let newErrors = { ...errors };
    let rowErrors = { ...newErrors[dataRowIndex] };

    if (!value) {
      if (columnKey === "name") {
        rowErrors[columnKey] = `Name is required.`;
        setNonRegisterederror((prev) => ({
          ...prev,
          name: "Name is required.",
        }));
      } else if (columnKey === "number") {
        rowErrors[columnKey] = `Credit Number is required.`;
        setNonRegisterederror((prev) => ({
          ...prev,
          number: "Credit Number is required.",
        }));
      }
    } else if (
      (columnKey === "name" && !/^(?!\s)(?!.*\s$).{1,40}$/.test(value)) ||
      (columnKey === "number" && !/^\d{4}(?:\s?\d{4}){0,3}$/.test(value))
    ) {
      if (columnKey === "name") {
        rowErrors[columnKey] = "Name: Maximum 40 chars, no leading spaces.";
        setNonRegisterederror((prev) => ({
          ...prev,
          name: "Name must be 40 characters or less.",
        }));
      } else if (columnKey === "number") {
        rowErrors[columnKey] =
          "Credit Number: Only digits, pattern '4444 4444 4444 4444', no leading spaces.";

        setNonRegisterederror((prev) => ({
          ...prev,
          number:
            "Credit Number must contain only digits, with optional spaces, and must have the pattern '4444 4444 4444 4444'.",
        }));
      }
    } else {
      delete rowErrors[columnKey];
      if (columnKey === "name") {
        setNonRegisterederror((prev) => {
          const { name, ...rest } = prev;
          return { ...rest };
        });
      } else if (columnKey === "number") {
        setNonRegisterederror((prev) => {
          const { number, ...rest } = prev;
          return { ...rest };
        });
      }
    }

    newErrors[dataRowIndex] = rowErrors;
    setErrors(newErrors);
    input.onChange(newDataRows);
  }

  function removeDataRow(dataRowIndex) {
    let newDataRows = dataRows.slice();
    newDataRows.splice(dataRowIndex, 1);
    setDataRows(newDataRows);
    input.onChange(newDataRows);
  }

  return (
    <div className="col-span-12 sm:col-span-6">
      <label
        htmlFor={input.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label} {required && <span className="text-red-500">*</span>}
      </label>

      <div className="mt-1 relative">
        {dataRows?.map((dataRow, dataRowIndex) => (
          <div className="relative pr-10" key={dataRowIndex}>
            <div
              className={
                "mb-2 grid gap-4 grid-cols-1 sm:grid-cols-" + columns.length
              }
            >
              {columns.map((column, ind) => (
                <div key={ind}>
                  <input
                    type="text"
                    onChange={(event) =>
                      handleChange(event, dataRowIndex, column.key)
                    }
                    placeholder={column.label}
                    value={dataRow[column.key]}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                  />
                  {errors[dataRowIndex]?.[column.key] && (
                    <p className="mt-2 text-sm text-red-600">
                      {errors[dataRowIndex][column.key]}
                    </p>
                  )}
                </div>
              ))}
            </div>

            <button
              onClick={() => removeDataRow(dataRowIndex)}
              type="button"
              className="absolute right-0 top-1 inline-flex items-center p-1 border border-transparent rounded-full text-white"
            >
              <TrashIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
            </button>
          </div>
        ))}

        <button
          onClick={() => addDataRow()}
          type="button"
          className="w-28 text-center relative inline-flex items-center py-2 my-2 border border-transparent shadow-sm text-sm font-bold rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75 justify-center"
        >
          Add
        </button>
      </div>
      {meta.error && meta.touched && (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      )}
      <div className="grid-cols-1 sr-only"></div>
      <div className="grid-cols-2 sr-only"></div>
      <div className="grid-cols-3 sr-only"></div>
      <div className="grid-cols-4 sr-only"></div>
      <div className="grid-cols-5 sr-only"></div>
      <div className="grid-cols-6 sr-only"></div>
      <div className="grid-cols-7 sr-only"></div>
      <div className="grid-cols-8 sr-only"></div>
      <div className="grid-cols-9 sr-only"></div>
      <div className="grid-cols-10 sr-only"></div>
      <div className="grid-cols-11 sr-only"></div>
      <div className="grid-cols-12 sr-only"></div>
    </div>
  );
}
