import { useState, useEffect } from 'react'
import Select from 'react-select'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import axios from 'axios';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CompanySingleselectField(props) {
    const { label, input, meta, col = 3, required = false } = props
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([])
    const [selectedCompany, setSelectedCompany] = useState()

    useEffect(() => {
        // Get user detail
        axios.get('/companies-multiselect')
            .then(function (response) {
                //console.log(response.data)
                setItems(response.data.data)
                if(input.value) {
                    setSelectedCompany(response.data.data.find(item => parseInt(item.id) === parseInt(input.value)))
                }
                if(response.data.data.length === 1) {
                    input.onChange(response.data.data[0].id)
                }
                setIsLoaded(true);
            })
            .catch(function (error) {
            // handle error
            //setIsLoaded(true);
            //setError(error);
            })
            .then(function () {
            //setIsLoaded(true);
            });    
    }, [])


    function handleChange(value)
    {
        input.onChange(value.value)
    }

    if(isLoaded) {
        return (
            <div className={'sm:col-span-'+col}>
                <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                    {label} {required && <span className="text-red-500">*</span>}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    {(items.length === 1) ? (<p className="py-2 mt-1 max-w-2xl text-sm text-gray-500">
                        {items[0].label}
                    </p>) :(<Select
                        classNamePrefix="react-select"
                        defaultValue={selectedCompany} 
                        isMulti={false}
                        options={items} 
                        className="basic-multi-select"
                        onChange={handleChange}
                    />)}
                    {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>}
                </div>
                {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
            </div>
        )
    }
}
