import { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XIcon } from '@heroicons/react/outline'
import { Form, Field } from 'react-final-form'
import createDecorator from 'final-form-calculate'
import axios from 'axios';
import { ExclamationCircleIcon, SelectorIcon } from '@heroicons/react/solid'
import LoadingIcon from './buttons/LoadingIcon';

// Components
import TextareaField from './fields/TextareaField';
import TimePickerField from './fields/TimePickerField';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

// Form Multiple Validators
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
const required = value => (value ? undefined : 'Required')

export default function MarkAttendanceValidModal(props) {
  let navigate = useNavigate();
  const { open, confirm, close, confirmButtonText, title, selectedItems, onDone } = props
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [note, setNote] = useState(null)
  const [sendingData, setSendingData] = useState(false)

  useEffect(() => {
    setNote(null)
  }, [open])

  // Send data
  const sendData = values => new Promise(resolve => {
    setSendingData(true)
    const formData = new FormData();
    formData.append('_method', 'put');
    formData.append('selected_items', JSON.stringify(selectedItems));
    if(values.checkout_at) { formData.append('checkout_at', values.checkout_at); }
    if(values.checkin_at) { formData.append('checkin_at', values.checkin_at); }
    formData.append('note', values.note);

    axios.post('attendance-reports-mark-valid', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    .then(function (response) {
        setSendingData(false)
        resolve()
      })
      .catch(function (error) {
        setSendingData(false)
        resolve()
      });
  })

  // Submit form
  const onSubmit = async values => {
    await sendData(values)
    onDone();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => close()}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <SelectorIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mr-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className='w-full'>
                      <Form
                        onSubmit={onSubmit}
                        initialValues={{  }}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                          <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            
                              <Field name="note" validate={required}>
                                {({ input, meta }) => (            
                                  <TextareaField required={true} label="Note" col={6} input={input} meta={meta} />
                                )}
                              </Field>

                              <Field name="checkin_at">
                                {({ input, meta }) => (            
                                  <TimePickerField label="Override Check-in Time" col={6} input={input} meta={meta} />
                                )}
                              </Field>

                              <Field name="checkout_at">
                                {({ input, meta }) => (            
                                  <TimePickerField label="Override Check-out Time" col={6} input={input} meta={meta} />
                                )}
                              </Field>

                            </div>


                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                              <button
                                type="submit"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-0 sm:ml-3 sm:w-auto sm:text-sm"                          
                              >
                                <LoadingIcon loading={submitting} />
                                {submitting ? 'Processing..' : 'Confirm'}
                              </button>
                              <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-0sm:mt-0 sm:w-auto sm:text-sm"
                                onClick={() => close()}
                              >
                                Cancel
                              </button>
                            </div>
                
                          </form>
                          
                        )}  
                      />

                      </div>
                    </div>
                  </div>
                </div>
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
