import { useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { Range } from 'react-range';

export default function RangeSliderField(props) {
    const { label, input, meta, col = 3, required = false, min = 0, max = 100, unit = ''} = props
    const [values, setValues] = useState(input.value ? [input.value]: [min])
    //console.log(input)

    function handleChange(values) {
        setValues(values)
        input.onChange(values[0])
    }

    return (
        <div className={'sm:col-span-'+col}>
            <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                {label} ({values[0]} {unit}) {required && <span className="text-red-500">*</span>}
            </label>
            <div className="mt-1 py-5 px-2 relative">
                <Range step={1}
                    min={min}
                    max={max}
                    values={values}
                    onChange={(values) => handleChange(values)}
                    renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        style={{
                        ...props.style,
                        height: '6px',
                        width: '100%',
                        backgroundColor: '#ccc'
                        }}
                    >
                        {children}
                    </div>
                    )}
                    renderThumb={({ props }) => (
                    <div
                        className="w-4 h4 bg-indigo-600 rounded-full"
                        {...props}
                        style={{
                        ...props.style,
                        height: '20px',
                        width: '20px',
                        backgroundColor: '#4f46e5'
                        }}
                    />
                    )}
                />
                {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>}
            </div>
            {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
        </div>
    )
}