import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useEffect } from "react";

export default function Numberfield(props) {
  const {
    label,
    input,
    meta,
    placeholder = null,
    col = 3,
    value,
    required = false,
  } = props;


  useEffect(() => {
     // do something
  }, [value])
  
  return (
    <div className={"col-span-12 sm:col-span-" + col}>
      <label
        htmlFor={input?.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          {...input}
          type="number"
          name="number"
          placeholder={placeholder}
          className={
            meta.error && meta.touched && value
              ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
              : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
          }
        />
        {meta.error && meta.touched && value && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {meta.error && meta.touched && value && (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      )}
    </div>
  );
}
