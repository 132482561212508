import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-calculate";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { Switch } from "@headlessui/react";
import Select from "react-select";

import { choices } from "./objects/vendorObjects";

// Components
import DropzoneField from "../../components/fields/DropzoneField";
import PrioritySelectField from "../../components/fields/PrioritySelectField";
import TextField from "../../components/fields/TextField";
import EmailField from "../../components/fields/EmailField";
import TextareaField from "../../components/fields/TextareaField";
import AlertModal from "../../components/AlertModal";
import SwitchField from "../../components/fields/SwitchField";
import CompanyMultiselectField from "../../components/fields/CompanyMultiselectField";
import CompanySingleselectField from "../../components/fields/CompanySingleselectField";
import ArrayField from "../../components/fields/ArrayField";
import DatePickerField from "../../components/fields/DatePickerField";
import CountrySelectField from "../../components/fields/CountrySelectField";
import PhoneNumberField from "../../components/fields/PhoneNumberField";
import ToggleField from "../../components/fields/ToggleField";
import RadioGroupField from "../../components/fields/RadioGroupField";
import AddressField from "../../components/fields/AddressField";
import NumberField from "../../components/fields/NumberField";
import TagsField from "../../components/fields/TagsField";
import { current } from "@reduxjs/toolkit";
import CheckboxesField from "../../components/fields/CheckboxesField";
import CreateableSingleSelect from "../../components/fields/CreateableSingleSelect";

import { validateAllFields } from "helpers/validationForVendors";

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

const tabs = [
  { name: "Information", key: "information" },
  { name: "Attributes", key: "attributes" },
  { name: "Media", key: "media" },
];

// Form Multiple Validators
const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
const required = (value) => (value ? undefined : "Required");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CreateCrud(props) {
  const { user } = props;
  let params = useParams();
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [vendorAgents, setVendorAgents] = useState([]);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState("information");

  // Set page title
  useEffect(() => {
    document.title = `Create Vendor`;
  }, []);

  useEffect(() => {
    // Creation form data
    axios
      .get("vendors/create?type=")
      // .get("vendors/create?type=" + type())
      .then(function (response) {
        if (response.data.vendor_categories) {
          setVendorCategories(response.data.vendor_categories);
          setVendorAgents(response.data.vendor_agents);
          setIsLoaded(true);
        }
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
        setIsLoaded(true);
      })
      .then(function () {
        setIsLoaded(true);
      });
  }, []);

  function closeForm() {
    setAlertModalOpen(false);
    navigate("/vendors");
  }

  // Send data
  const sendData = async (values) =>
    new Promise(async (resolve) => {
      console.log(values, "valuesvalues");
      setSendingData(true);
      const formData = new FormData();

      if (values?.name) {
        formData.append("name", values.name);
      }
      if (values?.email) {
        formData.append("email", values.email);
      }
      if (values?.vat_number) {
        formData.append("vat_number", values.vat_number);
      }
      if (values?.cr_number) {
        formData.append("cr_number", values.cr_number);
      }
      console.log(values, "VAAL");
      if (values?.companies) {
        formData.append("companies", values.companies.id);
      }
      if (values?.catagories) {
        formData.append("catagories", values.catagories.label);
      }
      if (values.address?.length > 0) {
        const addresses = values.address.map((e) => {
          return {
            name: e.name,
            address: e.address,
          };
        });
        formData.append("address", JSON.stringify(addresses));
      }

      if (values.phones?.length > 0) {
        const phones = values.phones.map((e) => {
          return {
            name: e.name,
            number: e.number,
          };
        });
        formData.append("phones", JSON.stringify(phones));
      }

      if (values.bank_acc?.length > 0) {
        const bank_accounts = values.bank_acc.map((e) => {
          return {
            bank_name: e.bank_name,
            account_number: e.account_number,
            swift: e.swift,
          };
        });
        formData.append("bank_acc", JSON.stringify(bank_accounts));
      }

      // } else {
      //   if (values.last_name) {
      //     formData.append("last_name", values.last_name);
      //   }
      //   if (values.dob) {
      //     formData.append("dob", values.dob);
      //   }
      //   if (values.id_iqama) {
      //     formData.append("id_iqama", values.id_iqama);
      //   }
      //   if (values.education_level) {
      //     formData.append("education_level", values.education_level);
      //   }
      //   if (values.nationality) {
      //     formData.append("nationality", values.nationality);
      //   }
      //   if (values.job_level) {
      //     formData.append("job_level", values.job_level);
      //   }
      //   if (values.phone_number) {
      //     formData.append("phone_number", values.phone_number);
      //   }
      //   if (values.second_phone_number) {
      //     formData.append("second_phone_number", values.second_phone_number);
      //   }
      //   if (values.can_relocate) {
      //     formData.append("can_relocate", values.can_relocate);
      //   }
      //   if (values.gender) {
      //     formData.append("gender", values.gender);
      //   }
      //   if (values.address) {
      //     formData.append("address", values.address.address);
      //   }
      //   if (values.address) {
      //     formData.append("address_city", values.address.city);
      //   }
      //   if (values.address) {
      //     formData.append("address_country", values.address.country);
      //   }
      //   if (values.cost) {
      //     formData.append("cost", values.cost);
      //   }
      //   if (values.hobbies) {
      //     formData.append("hobbies", JSON.stringify(values.hobbies));
      //   }
      //   if (values.available_from) {
      //     formData.append("available_from", values.available_from);
      //   }
      //   if (values.available_to) {
      //     formData.append("available_to", values.available_to);
      //   }
      //   if (values.description) {
      //     formData.append("description", values.description);
      //   }
      //   if (values.social_youtube) {
      //     formData.append("social_youtube", values.social_youtube);
      //   }
      //   if (values.social_twitter) {
      //     formData.append("social_twitter", values.social_twitter);
      //   }
      //   if (values.social_facebook) {
      //     formData.append("social_facebook", values.social_facebook);
      //   }
      //   if (values.social_instagram) {
      //     formData.append("social_instagram", values.social_instagram);
      //   }
      //   if (values.social_snapchat) {
      //     formData.append("social_snapchat", values.social_snapchat);
      //   }
      //   vendorCategories.forEach((vendorCategory) => {
      //     if (values["vendor_category_" + vendorCategory.id]) {
      //       if (values["vendor_category_" + vendorCategory.id].length) {
      //         values["vendor_category_" + vendorCategory.id].forEach((item) => {
      //           formData.append("vendor_category_item_ids[]", item);
      //         });
      //       }
      //     }
      //   });
      //   if (values.attachments) {
      //     values.attachments.forEach((attachment) => {
      //       formData.append("attachments[]", attachment);
      //     });
      //   }
      // }
      console.log(formData, "valuesvalues");
      await axios
        .post("v2vendor/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          setSendingData(false);
          resolve();
          if (response.data.data.id) {
            navigate("/vendors/" + response.data.data.id);
          }
        })
        .catch(function (error) {
          setSendingData(false);
          resolve();
        });
    });

  // const sendData = async (values) => {
  //   try {
  //     console.log(values, "valuesvalues");
  //     setSendingData(true);
  //     const formData = new FormData();

  //     // Append form data fields as before
  //     const queryParamsObject = {
  //       name: values.name,
  //       email: values.email,
  //       vat_number: values.general_vat_number,
  //       cr_number: values.general_cr_number,
  //       companies: values.Companies.label,
  //       catagories: values.Category.label,
  //       address: values.general_addresses,
  //       phones: JSON.stringify(values.general_phones),
  //       bank_acc: JSON.stringify(values.general_bank_accounts),
  //     };
  //     // Construct the query parameters
  //     const queryParams = new URLSearchParams(queryParamsObject);
  //     console.log(queryParamsObject, "queryParams");
  //     // Construct the complete URL
  //     const url = `http://127.0.0.1:8000/v2/v2vendor/create?${queryParams.toString()}`;

  //     // console.log(formData, "valuesvalues");
  //     await axios.post("v2vendor/create", {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });

  //     // Perform navigation or other actions
  //     setSendingData(false);
  //     // Handle success here
  //   } catch (error) {
  //     setSendingData(false);
  //     // Handle error here
  //   }
  // };

  // Submit form
  const onSubmit = async (values) => {
    console.log(values, "valuesvalues");
    await sendData(values);
  };

  const [selectCategory, setSelectCategory] = useState("");
  const handleCategoryChange = (e, input) => {
    input.onChange(e);
    setSelectCategory(e.value);
  };

  // function type() {
  //   if (searchParams.get("type")) {
  //     return searchParams.get("type");
  //   }
  //   return "general";
  // }

  // function isGeneral() {
  //   return type() === "general";
  // }

  // function currentTabClass(tab) {
  //   return currentTab === tab ? "" : "hidden";
  // }

  if (!isLoaded) {
    return (
      <div className="fullscreen-loading flex items-center justify-around">
        Loading..
      </div>
    );
  } else {
    return (
      <div className="h-full">
        <Form
          keepDirtyOnReinitialize
          validate={validateAllFields}
          onSubmit={onSubmit}
          // initialValues={{
          //   attachments: [],
          //   address: { country: "Saudi Arabia", city: null, address: null },
          //   companies: [],
          // }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              {/* Page title & actions */}
              <div className="bg-white border-b border-gray-200 px-4 py-4 flex items-center justify-between sm:px-6 lg:px-8 fixed top-14 right-0 left-0 lg:left-56  lg:fixed lg:top-0  lg:right-0 z-50">
                <div className="flex-1 min-w-0">
                  <h1 className="text-lg font-medium leading-6 text-gray-900 truncate">
                    Create Vendor:
                    {/* {type()} */}
                  </h1>
                </div>
                <div className="mt-4 flex ml-4">
                  <a
                    href="#"
                    onClick={() => setAlertModalOpen(true)}
                    disabled={sendingData}
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-2 disabled:opacity-75"
                  >
                    Cancel
                  </a>
                  <button
                    disabled={sendingData}
                    type="submit"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
                  >
                    {sendingData ? "Submitting.." : "Submit"}
                  </button>
                </div>
              </div>
              <main className="py-10 pt-20">
                {/* Tabs */}
                {/* {!isGeneral() && (
                  <div className="max-w-3xl mx-auto md:flex md:items-center md:justify-between lg:max-w-7xl lg:px-8 mt-6">
                    <div className="hidden sm:block">
                      <nav className="flex space-x-4" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <a
                            onClick={() => setCurrentTab(tab.key)}
                            href={"#" + tab.key}
                            key={tab.name}
                            className={classNames(
                              tab.key === currentTab
                                ? "bg-gray-200 text-gray-800"
                                : "text-gray-600 hover:text-gray-800",
                              "px-3 py-2 font-medium text-sm rounded-md"
                            )}
                            aria-current={
                              tab.key === currentTab ? "page" : undefined
                            }
                          >
                            {tab.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                )} */}
                {/* /Tabs */}

                <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8 mt-4">
                  <div className="shadow sm:rounded-md sm:overflow-hidden w-full">
                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6 relative">
                      {sendingData ? (
                        <div className="bg-white absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center z-50">
                          Please wait..
                        </div>
                      ) : (
                        ""
                      )}
                      <div>
                        <div>
                          <div className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200">
                              <div>
                                {/* <div
                                  className={
                                    "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 "
                                    //  +
                                    // currentTabClass("media")
                                  }
                                >
                                  {!isGeneral() && (
                                    <Field name="attachments">
                                      {({ input, meta }) => (
                                        <DropzoneField
                                          {...input}
                                          label="Attachments"
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}
                                </div> */}

                                <div
                                  className={
                                    "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 "
                                    // +
                                    // currentTabClass("attributes")
                                  }
                                >
                                  {vendorCategories.map((vendorCategory) => (
                                    <div
                                      key={vendorCategory.id}
                                      className="sm:col-span-6"
                                    >
                                      {vendorCategory.items.length > 0 && (
                                        <div className="mb-5">
                                          <div className="border-t border-gray-200 col-span-6 pt-5 first:border-0 first:pt-0 first:-mt-5">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                              {vendorCategory.name}
                                            </h3>
                                            <p className="mt-1 text-sm text-gray-500"></p>
                                          </div>
                                          <Field
                                            name={
                                              "vendor_category_" +
                                              vendorCategory.id
                                            }
                                          >
                                            {({ input, meta }) => (
                                              <CheckboxesField
                                                label="Select items"
                                                col="6"
                                                input={input}
                                                meta={meta}
                                                items={vendorCategory.items}
                                                hasLabel={false}
                                              />
                                            )}
                                          </Field>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>

                                <div
                                  className={
                                    "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 "
                                    // +
                                    // currentTabClass("information")
                                  }
                                >
                                  <Field name="company_id">
                                    {({ input, meta }) => (
                                      <CompanySingleselectField
                                        label="Belongs to company"
                                        col={6}
                                        input={input}
                                        meta={meta}
                                      />
                                    )}
                                  </Field>

                                  {/* <Field name="companies" validate={required}>
                                    {({ input, meta }) => (
                                      <CompanyMultiselectField
                                        required={true}
                                        label="Shared with companies"
                                        col={3}
                                        // col={isGeneral() ? 3 : 6}
                                        input={input}
                                        meta={meta}
                                      />
                                    )}
                                  </Field> */}

                                  {/* {values.company_id && isGeneral() && ( */}
                                  {/* <Field name="vendor_category_id">
                                    {({ input, meta }) => (
                                      <CreateableSingleSelect
                                        createParams={{
                                          type: "general",
                                          company_id: values.company_id,
                                        }}
                                        createPath="vendor-categories"
                                        path={
                                          "vendor-categories?type=general&company_id=" +
                                          values.company_id
                                        }
                                        label="Category"
                                        col={3}
                                        input={input}
                                        meta={meta}
                                      />
                                    )}
                                  </Field> */}
                                  {/* )} */}

                                  <Field name="name" validate={required}>
                                    {({ input, meta }) => (
                                      <TextField
                                        required={true}
                                        label={
                                          " # Name"
                                          // isGeneral()
                                          //   ? "# Name"
                                          //   : "# First Name"
                                        }
                                        input={input}
                                        meta={meta}
                                      />
                                    )}
                                  </Field>

                                  {/* {!isGeneral() && (
                                    <Field name="last_name">
                                      {({ input, meta }) => (
                                        <TextField
                                          label="# Last Name"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="dob">
                                      {({ input, meta }) => (
                                        <DatePickerField
                                          label="Birthday Date"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="id_iqama">
                                      {({ input, meta }) => (
                                        <TextField
                                          label="ID/Iqama"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="vendor_agent_id">
                                      {({ input, meta }) => (
                                        <div className="sm:col-span-3">
                                          <label
                                            htmlFor={input.name}
                                            className="block text-sm font-medium text-gray-700"
                                          >
                                            Agent
                                          </label>
                                          <div className="mt-1 relative rounded-md shadow-sm">
                                            <select
                                              {...input}
                                              className={
                                                meta.error && meta.touched
                                                  ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                                                  : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                              }
                                            >
                                              <option value="">Select</option>
                                              {vendorAgents.map(
                                                (vendorAgent) => (
                                                  <option
                                                    value={vendorAgent.id}
                                                    key={vendorAgent.id}
                                                  >
                                                    {vendorAgent.name}
                                                  </option>
                                                )
                                              )}
                                            </select>

                                            {meta.error && meta.touched && (
                                              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                <ExclamationCircleIcon
                                                  className="h-5 w-5 text-red-500"
                                                  aria-hidden="true"
                                                />
                                              </div>
                                            )}
                                          </div>
                                          {meta.error && meta.touched && (
                                            <p className="mt-2 text-sm text-red-600">
                                              {meta.error}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="education_level">
                                      {({ input, meta }) => (
                                        <TextField
                                          label="Education Level"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="nationality">
                                      {({ input, meta }) => (
                                        <CountrySelectField
                                          label="Nationality"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="job_level">
                                      {({ input, meta }) => (
                                        <TextField
                                          label="Job Level"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="phone_number">
                                      {({ input, meta }) => (
                                        <PhoneNumberField
                                          label="Phone Number"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="second_phone_number">
                                      {({ input, meta }) => (
                                        <PhoneNumberField
                                          label="Second Phone Number"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )} */}

                                  <Field name="email">
                                    {({ input, meta }) => (
                                      <EmailField
                                        label="# Email"
                                        input={input}
                                        meta={meta}
                                      />
                                    )}
                                  </Field>

                                  {/* {!isGeneral() && (
                                    <Field name="can_relocate">
                                      {({ input, meta }) => (
                                        <ToggleField
                                          label="Can re-alocate to another city/country"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="gender">
                                      {({ input, meta }) => (
                                        <RadioGroupField
                                          label="Gender"
                                          input={input}
                                          meta={meta}
                                          col="6"
                                          options={[
                                            { label: "Male", value: 1 },
                                            { label: "Female", value: 2 },
                                          ]}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="address">
                                      {({ input, meta }) => (
                                        <AddressField
                                          label="Residancy Address"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <div className="border-t border-gray-200 col-span-6 pt-5 mt-5">
                                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Extras
                                      </h3>
                                      <p className="mt-1 text-sm text-gray-500"></p>
                                    </div>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="cost">
                                      {({ input, meta }) => (
                                        <NumberField
                                          label="Cost"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="hobbies">
                                      {({ input, meta }) => (
                                        <TagsField
                                          label="Hobbies"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="available_from">
                                      {({ input, meta }) => (
                                        <DatePickerField
                                          label="Available From"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="available_to">
                                      {({ input, meta }) => (
                                        <DatePickerField
                                          label="Available To"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="description">
                                      {({ input, meta }) => (
                                        <TextareaField
                                          label="Description"
                                          input={input}
                                          meta={meta}
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <div className="border-t border-gray-200 col-span-6 pt-5 mt-5">
                                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Social Media
                                      </h3>
                                      <p className="mt-1 text-sm text-gray-500"></p>
                                    </div>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="social_youtube">
                                      {({ input, meta }) => (
                                        <TextField
                                          label="YouTube"
                                          input={input}
                                          meta={meta}
                                          placeholder="https://"
                                          col="6"
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="social_twitter">
                                      {({ input, meta }) => (
                                        <TextField
                                          label="Twitter"
                                          input={input}
                                          meta={meta}
                                          placeholder="https://"
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="social_facebook">
                                      {({ input, meta }) => (
                                        <TextField
                                          label="Facebook"
                                          input={input}
                                          meta={meta}
                                          placeholder="https://"
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="social_instagram">
                                      {({ input, meta }) => (
                                        <TextField
                                          label="Instagram"
                                          input={input}
                                          meta={meta}
                                          placeholder="https://"
                                        />
                                      )}
                                    </Field>
                                  )}

                                  {!isGeneral() && (
                                    <Field name="social_snapchat">
                                      {({ input, meta }) => (
                                        <TextField
                                          label="Snapchat"
                                          input={input}
                                          meta={meta}
                                          placeholder="https://"
                                        />
                                      )}
                                    </Field>
                                  )} */}

                                  {/* {isGeneral() && ( */}
                                  <Field name="vat_number">
                                    {({ input, meta }) => (
                                      <TextField
                                        label="# VAT Number"
                                        input={input}
                                        meta={meta}
                                      />
                                    )}
                                  </Field>
                                  {/* )} */}

                                  {/* {isGeneral() && ( */}
                                  <Field name="cr_number">
                                    {({ input, meta }) => (
                                      <TextField
                                        label="# CR Number"
                                        input={input}
                                        meta={meta}
                                      />
                                    )}
                                  </Field>
                                  {/* )} */}
                                  {/* {isGeneral() && ( */}
                                  <>
                                    <Field name="companies">
                                      {({ input, meta }) => (
                                        <div className="col-span-12 sm:col-span-3">
                                          <label
                                            htmlFor={input.name}
                                            className="block text-sm font-medium text-gray-700"
                                          >
                                            # Companies{" "}
                                            <span className="text-red-400">
                                              *
                                            </span>
                                          </label>
                                          <Select
                                            {...input}
                                            options={user?.managed_companies.map(
                                              (company) => ({
                                                value: company.name,
                                                label: company.name,
                                                id: company.id,
                                              })
                                            )}
                                            menuPortalTarget={document.body}
                                            styles={{
                                              menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                          />
                                          {meta.error && meta.touched && (
                                            <p className="mt-2 text-sm text-red-600">
                                              {meta.error}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </Field>

                                    <Field name="catagories">
                                      {({ input, meta }) => (
                                        <div className="col-span-12 sm:col-span-3">
                                          <label
                                            htmlFor={input.name}
                                            className="block text-sm font-medium text-gray-700"
                                          >
                                            # Category{" "}
                                            <span className="text-red-400">
                                              *
                                            </span>
                                          </label>
                                          {/* {setSelectCategory(input.value.value)}
                                          {console.log(selectCategory, "input")} */}
                                          <Select
                                            {...input}
                                            options={choices}
                                            onChange={(e) =>
                                              handleCategoryChange(e, input)
                                            }
                                            menuPortalTarget={document.body}
                                            styles={{
                                              menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                          />
                                          {meta.error && meta.touched && (
                                            <p className="mt-2 text-sm text-red-600">
                                              {meta.error}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </>
                                  {/* {(selectCategory === "Afghan" ||
                                    selectCategory === "Albanian") && ( */}
                                  <Field name="address" validate={required}>
                                    {({ input, meta }) => (
                                      <ArrayField
                                        required={true}
                                        label="# Addresses"
                                        input={input}
                                        meta={meta}
                                        columns={[
                                          { key: "name", label: "# Name" },
                                          {
                                            key: "address",
                                            label: "# Address",
                                          },
                                        ]}
                                      />
                                    )}
                                  </Field>
                                  {/* )} */}

                                  {/* {selectCategory === "Albanian" && ( */}
                                  <Field name="phones" validate={required}>
                                    {({ input, meta }) => (
                                      <ArrayField
                                        required={true}
                                        label="# Phones"
                                        input={input}
                                        meta={meta}
                                        columns={[
                                          { key: "name", label: "# Name" },
                                          {
                                            key: "number",
                                            label: "# Number",
                                          },
                                        ]}
                                      />
                                    )}
                                  </Field>
                                  {/* )} */}

                                  {/* {selectCategory === "Algerian" && ( */}
                                  <Field name="bank_acc" validate={required}>
                                    {({ input, meta }) => (
                                      <ArrayField
                                        required={true}
                                        label="# Bank Accounts"
                                        input={input}
                                        meta={meta}
                                        columns={[
                                          {
                                            key: "bank_name",
                                            label: "# Bank Name",
                                          },
                                          {
                                            key: "account_number",
                                            label: "# Account Number",
                                          },
                                          { key: "swift", label: "SWIFT" },
                                        ]}
                                      />
                                    )}
                                  </Field>
                                  {/* )} */}
                                </div>

                                {/* <div>
                                  <pre>{JSON.stringify(values, 0, 2)}</pre>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </form>
          )}
        />

        <AlertModal
          title="Close Form"
          confirm={closeForm}
          close={() => setAlertModalOpen(false)}
          open={alertModalOpen}
        />
      </div>
    );
  }
}
