import { Fragment, useState, useEffect, useLayoutEffect } from 'react';
import axios from 'axios';
import { Link, useSearchParams, useNavigate, useParams  } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon, SearchIcon, XIcon } from '@heroicons/react/solid'
import { TableIcon, ViewBoardsIcon, } from '@heroicons/react/outline'
import { FilterIcon } from '@heroicons/react/outline'

// Components
import Pagination from '../../components/Pagination';
import EmptyStates from '../../components/EmptyStates';
import Show from './Show';
import SkeletonTable from '../../components/SkeletonTable';
import KanbanBoardMultipleContainers from '../../components/KanbanBoardMultipleContainers';
import DateRangePickerFilterField from '../../components/filterFields/DateRangePickerFilterField';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

// Helpers
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// Search params as a object
const useCustomSearchParams = () => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = Object.fromEntries(
    new URLSearchParams(search)
  );

  return [searchAsObject, setSearch];
};


export default function List(props) {
  const { user } = props
  let urlParams = useParams()
  const navigate = useNavigate()
  //const [search, setSearch] = useCustomSearchParams();
  let [searchParams, setSearchParams] = useSearchParams();

  // Set page title
  useEffect(() => {
    document.title = `Recruit Job Applications`;
  }, [])

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState([]);
  const [dataUrl, setDataUrl] = useState(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [tabs, setTabs] = useState([
    { status: 'Applied', id: 1, count: 0, current: true, color: '#333' },
    { status: 'Phone Screen', id: 2, count: 0, current: false, color: '#333' },
    { status: 'Interview', id: 3, count: 0, current: false, color: '#333' },
    { status: 'Hired', id: 4, count: 0, current: false, color: '#333' },
    { status: 'Rejected', id: 5, count: 0, current: false, color: '#333' },
    { status: 'Future Reference', id: 6, count: 0, current: false, color: '#333' },
  ])
  const [jobs, setJobs] = useState([])
  const [locations, setLocations] = useState([])
  const [skills, setSkills] = useState([])
  const [filterCount, setFilterCount] = useState(0)
  const [flatStatuses, setFlatStatuses] = useState([]); 
  
  const [kanbanMaxHeight, setKanbanMaxHeight] = useState(window.innerHeight - 227)

  useLayoutEffect(() => {
    function updateSize() {
      setKanbanMaxHeight(window.innerHeight - 227);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  // View type
  const [viewType, setViewType] = useState('table')  
  
  // Order By
  let curretnOrderByValue = {
    key: 'created_at',
    order: 'desc',
  }
  if(searchParams.get('order_by')) {
    if(searchParams.get('order_by').key !== 'created_at' && searchParams.get('order_by').order !== 'desc') {
      const currentOrderByArray = searchParams.get('order_by').split("-");
      curretnOrderByValue = {
        key: currentOrderByArray[0],
        order: currentOrderByArray[1],
      }
    }
  }
  let [currentOrderBy, setCurrentOrderBy] = useState(curretnOrderByValue)
  
  const loadFilterData = () => new Promise(resolve => {
    axios.get('/recruit-job-application-filterdata?company_id')
    .then(function (response) {
      setTabs(response.data.statuses)

      if(response.data.statuses) {
        let newFlatStatuses = [];
        response.data.statuses.forEach(status => {
          newFlatStatuses[status.id] = {
            name: status.status,
            color: status.color,
          }
        });
        setFlatStatuses(newFlatStatuses);
      }
      
      setJobs(response.data.jobs)
      setLocations(response.data.locations)
      setSkills(response.data.skills)
      resolve()
    })
    .catch(function (error) {
      // handle error
    })
    .then(function () {
    });
  })
  
  const apiUrl = '/recruit-job-applications'
  const loadData = (params = {}) => {
    setIsDataLoading(true)
      // if(params === {} || !params) {
      //   //params['status'] = 'applied'
      // }
    //console.log(params)
    setSearchParams(params)

    //console.log('Params', params)
    
    axios.get(apiUrl, {
      params: params
    })
    .then(function (response) {
      // handle success
      setIsLoaded(true);
      setItems(response.data.data);
      setPaginationMeta(response.data.meta)
      setTimeout(function() {
        setIsDataLoading(false)
      }, 300)
    })
    .catch(function (error) {
      // handle error
      setIsLoaded(true);
      setError(error);
      setIsDataLoading(false)
    })
    .then(function () {
      setIsLoaded(true);
      //setIsDataLoading(false)
    });
    
  }

  // Submit form
  const loadAllData = async () => {
    // Load filer data
    await loadFilterData()

    let params = {}
    searchParams.forEach((key, value) => {
      params[value] = key;
      setFilterCount((filterCount) => filterCount + 1)
      if(value === 'mode' && key === 'board') {
        setViewType('board')
      }
    });
    loadData(params)
  }

  useEffect(() => {
    loadAllData()

    if(urlParams.modelId) {
      showModel(urlParams.modelId)
    } 
  }, [])

  function reloadData(key, value) {
    let params = {}
    searchParams.forEach((key, value) => {
      params[value] = key;
    });
    params[key] = value

    // Load tab
    if(key != 'page') {
      params['page'] = 1
    }

    loadData(params)
  }


  function orderBy(key) {
    if(currentOrderBy.order === 'desc') {
      reloadData('order_by', key+'-asc')
      setCurrentOrderBy({
        key: key,
        order: 'asc',
      })
    } else {
      reloadData('order_by', key+'-desc')
      setCurrentOrderBy({
        key: key,
        order: 'desc',
      })
    }
  }

  // Filters
  const [filterCompanyId, setFilterCompanyId] = useState(searchParams.get('company_id') ? searchParams.get('company_id') : '')
  const [filterJobId, setFilterJobId] = useState(searchParams.get('job_id') ? searchParams.get('job_id') : '')
  const [filterLocationId, setFilterLocationId] = useState(searchParams.get('location_id') ? searchParams.get('location_id') : '')
  const [filterSkillId, setFilterSkillId] = useState(searchParams.get('skill_id') ? searchParams.get('skill_id') : '')
  const [filterFrom, setFilterFrom] = useState(searchParams.get('from') ? searchParams.get('from') : '')
  const [filterTo, setFilterTo] = useState(searchParams.get('to') ? searchParams.get('to') : '')
  const [filterCreatorId, setFilterCreatorId] = useState(searchParams.get('creator_id') ? searchParams.get('creator_id') : '')
  const [filterQuery, setFilterQuery] = useState(searchParams.get('query') ? searchParams.get('query') : '')

  function clearFilter(params = {}) {
    setFilterCompanyId('')
    setFilterJobId('')
    setFilterLocationId('')
    setFilterSkillId('')
    setFilterFrom('')
    setFilterTo('')
    setFilterCreatorId('')
    setFilterQuery('')
    setFilterCount(0)
    loadData(params)
  }

  function addFilter(key, value) {
    switch(key) {
      case 'company_id':
        setFilterCompanyId(value)
        setFilterJobId('')
        setFilterLocationId('')
        setFilterSkillId('')
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'job_id':
        setFilterJobId(value)
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'location_id':
        setFilterLocationId(value)
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'skill_id':
        setFilterSkillId(value)
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'from':
        setFilterFrom(value)
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'to':
        setFilterTo(value)
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'creator_id':
        setFilterCreatorId(value)
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'query':
        setFilterQuery(value)
        setFilterCount((filterCount) => filterCount + 1)
        break;
    }
    reloadData(key, value)
  }

  
  const [showModelSlideOpen, setShowModelSlideOpen] = useState(false)
  const [showModelId, setShowModelId] = useState(null)

  function showModel(modelId) {
    setShowModelSlideOpen(true)
    setShowModelId(modelId)
  }

  function closeShowSlide() {
    setShowModelSlideOpen(false)
    setShowModelId(null)
  }

  function updateBoard(activeId, containerId)
  {
    axios.post('recruit-job-applications-update-board', {
      _method: 'put',
      active_id: activeId,
      container_id: containerId
    })
    .then(function (response) {
        //
      })
      .catch(function (error) {
        //
      });
  }

  function switchToTable() {
    clearFilter({ mode: 'table'})
    setViewType('table')
  }

  function switchToBoard() {
    clearFilter({ mode: 'board' })
    setViewType('board')
  }

  if(!isLoaded) {
    return <div className="fullscreen-loading flex items-center justify-around">Loading..</div>
  } else {
    return (
      <div className="min-h-[100vh] bg-white pb-1 relative">
        {/* Page title & actions */}
        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 lg:fixed lg:top-0 lg:left-56 lg:right-0 z-10 bg-white">
          <div className="flex-1 min-w-0">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Recruit Job Applications</h1>
          </div>
          <div className="mt-4 flex sm:mt-0 sm:ml-4">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Link to="/recruit-job-applications/create"
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
                >
                  Create
                </Link>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item><Link to="/vendors/create" className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900">General Vendor</Link></Menu.Item>
                    <Menu.Item><Link to="/vendors/create?type=cast" className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900">Cast</Link></Menu.Item>
                    <Menu.Item><Link to="/vendors/create?type=crew" className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900">Crew</Link></Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        <div className="pt-20">
          <div className="px-4 py-2">
            <span className="align-middle mr-2 mb-2 inline-block py-2 items-center"><FilterIcon className="h-5 w-5 text-gray-500" aria-hidden="true" /></span>
            
            <div className="inline-block mr-2 relative mt-1 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input type="text" value={filterQuery} onChange={(event) => addFilter('query', event.target.value)} className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Name, email or phone" />
            </div>

            {(user.managed_companies.length > 1) && <select
              className="mr-2 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={filterCompanyId}
              onChange={(event) => addFilter('company_id', event.target.value)}
            >
              <option value="">All companies</option>
              {user.managed_companies.map((company) => (
                <option key={company.id} value={company.id}>{company.name}</option>
              ))}
            </select>}

            <select
              className="mr-2 mb-2 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={filterJobId}
              onChange={(event) => addFilter('job_id', event.target.value)}
            >
              <option value="">All jobs</option>
              {jobs.map((job) => (!filterCompanyId || (parseInt(filterCompanyId) === parseInt(job.company_id)) ? (<option key={job.id} value={job.id}>{job.title}</option>) : ''))}
            </select>
            
            <select
              className="mr-2 mb-2 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={filterLocationId}
              onChange={(event) => addFilter('location_id', event.target.value)}
            >
              <option value="">All locations</option>
              {locations.map((location) => (!filterCompanyId || (parseInt(filterCompanyId) === parseInt(location.company_id)) ? (<option key={location.id} value={location.id}>{location.location}</option>) : ''))}
            </select>

            <select
              className="mr-2 mb-2 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={filterSkillId}
              onChange={(event) => addFilter('skill_id', event.target.value)}
            >
              <option value="">All skills</option>
              {skills.map((skill) => (!filterCompanyId || (parseInt(filterCompanyId) === parseInt(skill.company_id)) ? (<option key={skill.id} value={skill.id}>{skill.name}</option>) : ''))}
            </select>

            <select
              className="mr-2 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={filterCreatorId}
              onChange={(event) => addFilter('creator_id', event.target.value)}
            >
              <option value="">All creators</option>
              <option value="portal">Applied on portal</option>
              <option value="manual">Created manually</option>
              <option value={user.id}>Created by me</option>
            </select>


            <DateRangePickerFilterField startDate={filterFrom} endDate={filterTo} onChangeStart={(value) => addFilter('from', value)} onChangeEnd={(value) => addFilter('to', value)} />

            {filterCount > 0 && <button
              onClick={() => clearFilter()}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-0"
            >
              Clear all
              <XIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </button>}
          </div>

          <div className="flex items-center justify-between">
            {/* Tabs */}              
            {viewType === 'table' ? (<div className="px-4 sm:px-4 lg:px-4 overflow-x-auto">
              <div className="hidden sm:block">
                <div className="border-b border-gray-20 px-4">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    <button
                      onClick={() => reloadData('status', '')}
                      className={classNames(
                        (!searchParams.get('status'))
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm capitalize focus:outline-none focus:ring-0'
                      )}
                    >
                      All
                    </button>
                    {tabs.map((tab, tabIndex) => (
                      (user.managed_companies.length === 1 || !tab.company_id || parseInt(tab.company_id) === parseInt(filterCompanyId)) && <button
                        onClick={() => reloadData('status', tab.id)}
                        key={tab.id}
                        className={classNames(
                          (tab.id === parseInt(searchParams.get('status')))
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                          'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm capitalize focus:outline-none focus:ring-0'
                        )}
                        style={{ color: tab.color }}
                      >
                        {tab.status}
                        {tab.count ? (
                          <span
                            className={classNames(
                              (tab.id === parseInt(searchParams.get('status')) || (!searchParams.get('status') && tab.status === 1)) ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                              'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                            )}
                          >
                            {tab.count}
                          </span>
                        ) : null}
                      </button>
                    ))}
                  </nav>
                </div>
              </div>
            </div>) : (<div></div>)}

            <div className="pr-3 flex items-center">
              <span className="mr-2 text-sm whitespace-nowrap pl-3">View type: {viewType}</span>
              <span className="relative z-0 inline-flex shadow-sm rounded-md">
                <button
                  type="button"
                  onClick={() => switchToTable()}
                  className={classNames(
                    (viewType === 'table')
                      ? 'bg-gray-200'
                      : 'bg-white',
                    'relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300  text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-0'
                  )}
                >
                  <TableIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  onClick={() => switchToBoard()}
                  className={classNames(
                    (viewType === 'board')
                      ? 'bg-gray-200'
                      : 'bg-white',
                    '-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-0'
                  )}
                >
                  <ViewBoardsIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </span>
            </div>
          </div>

          {/* Projects table (small breakpoint and up) */}
          { viewType === 'table' && <div className="mt-0 overflow-x-auto">
            <div className="align-middle inline-block min-w-full border-b border-gray-200">
              {isDataLoading && <SkeletonTable />}
              {!isDataLoading && <table className="min-w-full">
                <thead>
                  <tr className="border-t border-gray-200">                    
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                      <span className="whitespace-nowrap">#</span>
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                      <span className="whitespace-nowrap">Applicant Name</span>
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                      <button onClick={() => orderBy('job_id')} className="group inline-flex uppercase whitespace-nowrap">
                      Job
                        { (currentOrderBy.key === 'job_id') ? (<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                          { (currentOrderBy.order === 'desc') ? <ChevronDownIcon className="h-4 w-4" aria-hidden="true" /> : <ChevronUpIcon className="h-4 w-4" aria-hidden="true" /> }
                        </span>) : '' }
                      </button>
                    </th>
                    {(user.managed_companies.length > 1) && <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                      <button onClick={() => orderBy('company_id')} className="group inline-flex uppercase whitespace-nowrap">
                      Company
                        { (currentOrderBy.key === 'company_id') ? (<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                          { (currentOrderBy.order === 'desc') ? <ChevronDownIcon className="h-4 w-4" aria-hidden="true" /> : <ChevronUpIcon className="h-4 w-4" aria-hidden="true" /> }
                        </span>) : '' }
                      </button>
                    </th>}
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                      <span className="whitespace-nowrap">Job Location</span>
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                      <span className="whitespace-nowrap">Status</span>
                    </th>
                    <th scope="col" className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <button onClick={() => orderBy('creator_id')} className="group inline-flex uppercase whitespace-nowrap">
                        Created By
                        { (currentOrderBy.key === 'creator_id') ? (<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                          { (currentOrderBy.order === 'desc') ? <ChevronDownIcon className="h-4 w-4" aria-hidden="true" /> : <ChevronUpIcon className="h-4 w-4" aria-hidden="true" /> }
                        </span>) : '' }
                      </button>
                    </th>
                    <th scope="col" className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <button onClick={() => orderBy('created_at')} className="group inline-flex uppercase whitespace-nowrap">
                        Created At
                        { (currentOrderBy.key === 'created_at') ? (<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                          { (currentOrderBy.order === 'desc') ? <ChevronDownIcon className="h-4 w-4" aria-hidden="true" /> : <ChevronUpIcon className="h-4 w-4" aria-hidden="true" /> }
                        </span>) : '' }
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-100">
                  {items.map((item) => (
                    <tr key={item.id}>       
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                        {item.id}
                      </td>     
                      <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="">
                          <div>
                            <button onClick={() => { showModel(item.id) }} className="truncate text-blue-700 focus:ring-0">
                              <span>
                                <span className="text-indigo-600 hover:text-indigo-900">{item.full_name}</span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: item.job.title }}></td>
                      {(user.managed_companies.length > 1) && <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                        {item.job.company}
                      </td>}
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                        {item.job.location}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"> 
                        <span className="text-white uppercase inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium" style={{ backgroundColor: (flatStatuses[item.status_id]) ? flatStatuses[item.status_id].color : '#333' }}>{(flatStatuses[item.status_id]) ? flatStatuses[item.status_id].name : 'N/A'}</span>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"> 
                        {item.creator ? <span>{item.creator.name}</span> : <span>Portal</span>}
                      </td>
                      <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                        {item.created_at}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>}
            </div>
          </div>}

          {(viewType === 'board') && isDataLoading && <SkeletonTable />}

          {(viewType === 'board') && !isDataLoading && <div>
            <KanbanBoardMultipleContainers
              containerStyle={{
                maxHeight: kanbanMaxHeight + 'px',
              }}
              handle
              itemCount={15}
              scrollable
              dataColumns={tabs}
              dataItems={items}
              statuses={tabs} 
              showModel={showModel} 
              isShowCompany={user.managed_companies.length > 1}
              updateBoard={updateBoard}
            />
            {/* <KanbanBoardSortable columns={boardColumns} items={items} statuses={tabs} showModel={showModel} /> */}
          </div>}

          {/* Empty state */}
          {!items.length && isLoaded && viewType === 'table' ? (
            <EmptyStates createPath="/recruit-job-applications/create" />
          ) : '' }

          {/* Pagination */}
          {(items.length && isLoaded  && viewType === 'table') ? (<Pagination paginationMeta={paginationMeta} reloadData={reloadData} />) : ''}
          
        </div>

        <Show open={showModelSlideOpen} close={closeShowSlide} modelId={showModelId} user={user} reloadData={reloadData}  />
      </div>
    )
  }
}