
export default function Pagination(props) {
    const { paginationMeta = [], reloadData } = props

    function getPageFromLabel(label) {
        if(label === '&laquo; Previous') {
            if(paginationMeta.current > 1) {
                return paginationMeta.current - 1
            }
            return 1 
        }
        if(label === 'Next &raquo;') {
            if(paginationMeta.current < paginationMeta.last_page) {
                return paginationMeta.current + 1
            }
            return paginationMeta.last_page
        }
        return parseInt(label)
    }

    return (
        <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0 mb-10">
            <div className="-mt-px w-0 flex-1 flex"></div>
            <div className="md:-mt-px md:flex">
                {paginationMeta.links.map((paginationLink) => (<button
                    key={paginationLink.label}
                    className={(paginationLink.active ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500') +' hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'}
                    dangerouslySetInnerHTML={ {__html: paginationLink.label} }
                    onClick={() => reloadData('page', getPageFromLabel(paginationLink.label))}
                ></button>))}
                
            </div>
            <div className="-mt-px w-0 flex-1 flex justify-end mr-5"></div>
        </nav>
    )
}