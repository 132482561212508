const validateCRNumber = (value) => {
  if (!value) {
    return undefined; // No validation errors if the value is empty
  }

  if (!/^\d+$/.test(value)) {
    return "CR Number: Must Digits, no leading spaces, no special characters.";
  }

  if (value.length > 45) {
    return "CR Number cannot exceed 45 digits";
  }

  return undefined; // No validation errors
};

const validateGOSINumber = (value) => {
  if (!value) {
    return undefined; // No validation errors if the value is empty
  }

  if (!/^\d+$/.test(value)) {
    return "GOSI Number: Must Digits, no leading spaces, no special characters.";
  }

  if (value.length > 45) {
    return "GOSI Number cannot exceed 45 digits";
  }

  return undefined; // No validation errors
};

const validateFields = (values) => {
  const reg =
    /^(?=.{1,200}$)(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(:\d{2,5})?(\/[^\s]*)?$/;
  const regPhone = /^(?=.*\d)[\d+\-() ]{0,25}$/;
  const error = {};
  if (!values?.prefix) {
    error.prefix = "Shortcode is Required";
  } else if (!/^[A-Za-z]+$/.test(values?.prefix)) {
    error.prefix = "Shortcode Must contain only characters(with no spaces)";
  } else if (values?.prefix?.length > 5) {
    error.prefix = "Shortcode can't be greater than 5 characters";
  }
  if (!values?.name) {
    error.name = "Name is Required";
  } else if (
    !/^(?! )(?!.* $)[^!@#$%^&*()+=~`\\[\]{}|:;"'<>,.?/_-]{1,45}$/.test(
      values?.name
    )
  ) {
    error.name =
      "Name: Maximum 45 chars, no leading spaces, no special characters.";
  }
  if (!values?.legal_name) {
    error.legal_name = "Legal Name is Required";
  } else if (
    !/^(?! )(?!.* $)[^!@#$%^&*()+=~`\\[\]{}|:;"'<>,.?/_-]{1,45}$/.test(
      values?.legal_name
    )
  ) {
    error.legal_name =
      "Legal name: Maximum 45 chars, no leading spaces, no special characters.";
  }

  if (!values.city) {
    error.city = undefined; // No validation errors if the value is empty
  } else if (
    !/^(?!.*\d)(?!^\s)(?!.*\s$)[^\d!@#$%^&*()_+=\-[\]{}|\\:;"'<>,.?/~]{1,60}(?:\s[^\d!@#$%^&*()_+=\-[\]{}|\\:;"'<>,.?/~]{1,60})*$/.test(
      values.city
    )
  ) {
    error.city =
      "City: Maximum 45 chars, no leading spaces, no numbers, no special characters.";
  }
  if (!values.address) {
    error.address = undefined; // No validation errors if the value is empty
  } else if (!/^(?! )(?=.*\S)(?=.{1,80}$)[^\s ].*[^\s]$/.test(values.address)) {
    error.address = `Address: Maximum 2-80 chars, no leading spaces.`;
  }

  if (!values.coc_number) {
    error.coc_number = undefined; // No validation errors if the value is empty
  } else if (!/^\d+$/.test(values.coc_number)) {
    error.coc_number =
      "COC Number: Must Digits, no leading spaces, no special characters.";
  } else if (values.coc_number.length > 45) {
    error.coc_number = "COC Number cannot exceed 45 digits";
  }

  if (!values.vat_number) {
    error.vat_number = undefined; // No validation errors if the value is empty
  } else if (!/^\d+$/.test(values.vat_number)) {
    error.vat_number =
      "VAT Number: Must Digits, no leading spaces, no special characters.";
  } else if (values.vat_number.length > 45) {
    error.vat_number = "VAT Number cannot exceed 45 digits";
  }

  if (!values.gazt_number) {
    error.gazt_number = undefined; // No validation errors if the value is empty
  } else if (!/^\d+$/.test(values.gazt_number)) {
    error.gazt_number =
      "GAZT Number: Must Digits, no leading spaces, no special characters.";
  } else if (values.gazt_number.length > 45) {
    error.gazt_number = "GAZT Number cannot exceed 45 digits";
  }

  if (!values.municipality_number) {
    error.municipality_number = undefined; // No validation errors if the value is empty
  } else if (!/^\d+$/.test(values.municipality_number)) {
    error.municipality_number =
      "Municipality Number: Must Digits, no leading spaces, no special characters.";
  } else if (values.municipality_number.length > 45) {
    error.municipality_number = "Municipality Number cannot exceed 45 digits";
  }

  if (!values.signature_website_text) {
    error.signature_website_text = undefined;
  } else if (!/^(?!\s)(?!.*\s$).{1,50}$/.test(values.signature_website_text)) {
    error.signature_website_text =
      "Website text: Maximum 50 length, no leading spaces!";
  }
  if (!values.signature_website_url) {
    error.signature_website_url = undefined;
  } else if (!reg.test(values.signature_website_url)) {
    error.signature_website_url =
      "Website URL:Maximum 200 length, no leading spaces!";
  }
  if (!values.phone) {
    error.phone = undefined;
  } else if (!regPhone.test(values.phone)) {
    error.phone = "Please enter a valid phone number!";
  }
  if (!values.extention) {
    error.extention = undefined;
  } else if (!/^(?!\s)(?!.*\s$).{1,50}$/.test(values.extention)) {
    error.extention = "Extention: Maximum 50 length, no leading spaces!";
  }

  const instagramRegex =
    /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:[a-zA-Z0-9_\.]{1,30}\/)?([a-zA-Z0-9_\.]{1,30})$/;
  const facebookRegex =
    /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:\w+\/)?([A-Za-z0-9.]{5,})$/;

  const linkedinRegex =
    /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(?:in|company)\/([\w\.-]+)$/;

  const twitterRegex =
    /^https:\/\/(www\.)?twitter\.com\/[A-Za-z0-9_]{1,15}\/?$/;

  if (!values.signature_facebook_url) {
    error.signature_facebook_url = undefined;
  } else if (!facebookRegex.test(values.signature_facebook_url)) {
    error.signature_facebook_url =
      "Invalid URL. Facebook URL starting with 'https://', only name can provide";
  }

  if (!values.signature_instagram_url) {
    error.signature_instagram_url = undefined;
  } else if (!instagramRegex.test(values.signature_instagram_url)) {
    error.signature_instagram_url =
      "Invalid URL. Instagram URL starting with 'https://', only name can provide";
  }

  if (!values.signature_linkedin_url) {
    error.signature_linkedin_url = undefined;
  } else if (!linkedinRegex.test(values.signature_linkedin_url)) {
    error.signature_linkedin_url =
      "Invalid URL. LinkedIn URL starting with 'https://', only name can provide";
  }

  if (!values.signature_twitter_url) {
    error.signature_twitter_url = undefined;
  } else if (!twitterRegex.test(values.signature_twitter_url)) {
    error.signature_twitter_url =
      "Invalid URL. Twitter URL starting with 'https://', only name can provide (not end with /)";
  }

  return error;
};

export { validateCRNumber, validateGOSINumber, validateFields };
