import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import Select from 'react-select';



export default function SponserShipField(props) {
  const {
    salery,
    label,
    input,
    meta,
    placeholder ,
    col = 3,
    required = false,
    values,
  } = props;

   

  useEffect(()=>{
    input.onChange(values?.company_id);
  },[values?.company_id])
  
  

  return (
    <div className={"col-span-12 sm:col-span-" + col}>
      <label 
        htmlFor={input.name}
        className="block text-sm font-medium text-gray-700 mb-1"
      >
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="">
        <Select
          {...input}
          isDisabled = { values?.company_id && true }
          value={values?.company_id}
          
          components={{
            Menu: () => null,
            MenuList: () => null,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
        }}
          type="text"
          placeholder={placeholder}
          className={
            meta.error && meta.touched
              ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
              : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
          }
        />
        {meta.error && meta.touched && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {meta.error && meta.touched && (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      )}
    </div>
  );
}
