import { StarIcon } from '@heroicons/react/solid'

export default function RatingStars(props) {
    const { stars = 0 } = props

    function createElements(n){
        var elements = [];
        for(let i =0; i < n; i++){
            elements.push(<StarIcon key={i} className="h-6 w-6 text-yellow-500 inline-block mr-1" aria-hidden="true" />);
        }
        return elements;
    }

    return (
        <div>
            {createElements(stars)}
        </div>
    )
}