import React from "react";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { EyeIcon } from "@heroicons/react/solid";

export default function ViewRole() {
  let { id } = useParams();
  const [rolePermissionInfo, setRolePermissionInfo] = useState([]);
  const [allpermissions, setAllPermissions] = useState([]);

  const fileGetURL = process.env.REACT_APP_FILE_STARTURL;

  useEffect(() => {
    getRoleInfo();
  }, []);

  async function getRoleInfo() {
    await axios
      .get("role/edit/" + id, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // setSendingData(false);
        // resolve()

        setRolePermissionInfo(response.data.data);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      });
  }

  useEffect(() => {
    getPermissionsInfo();
  }, []);

  async function getPermissionsInfo() {
    await axios
      .get("permission/all", {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // setSendingData(false);
        // resolve()

        setAllPermissions(response.data.data);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      });
  }

  const idsInSecondaryArray = rolePermissionInfo.permissions?.map(
    (item) => item.id
  );
  const checkedIds = allpermissions
    .filter((item) => idsInSecondaryArray?.includes(item.id))
    .map((item) => item.id);

  const handleCheckedIdsChange = (newCheckedIds) => {
    console.log(newCheckedIds);
  };

  const handleCheckboxChange = (id) => {
    if (checkedIds?.includes(id)) {
      const newCheckedIds = checkedIds.filter((checkedId) => checkedId !== id);
      handleCheckedIdsChange(newCheckedIds);
    } else {
      const newCheckedIds = [...checkedIds, id];
      handleCheckedIdsChange(newCheckedIds);
    }
  };

  const isChecked = (id) => checkedIds?.includes(id);

  return (
    <div className="overflow-hidden">
      <div className="flex m-5">
        <div>
          <h4 className="text-[22px] font-bold">View Role</h4>
        </div>
      </div>

      <div className="bg-white rounded-md sm:p-5 p-0 sm:mx-5 mx-0">
        <h5 className="font-medium text-lg">
          {" "}
          # Name <span className="text-red-400">*</span>{" "}
        </h5>
        <h6 className="mt-3 font-medium">{rolePermissionInfo?.name}</h6>
      </div>
      <div className="bg-white rounded-md sm:p-5 p-0 sm:mx-5 mx-0 mt-2">
        <div>
          {allpermissions.map((item) => (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor="">
                <input
                  type="checkbox"
                  checked={isChecked(item.id)}
                  onChange={() => handleCheckboxChange(item.id)}
                  disabled
                  style={{ cursor: "not-allowed", marginRight: "5px" }}
                />
                {item.name}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
