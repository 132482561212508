import { useEffect, useState } from "react";

export default function FormPercentage(props) {
  const [filled, setFilled] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let filledCount = 0;
    let totalCount = 0;
    for (const [key, value] of Object.entries(props.setRequiredField)) {
      if (key in props.values === true) {
        let getvalue = props.values[key];
        if (typeof getvalue !== "boolean") {
          if (Array.isArray(getvalue)) {
            if (getvalue.length) {
              filledCount++;
            }
          } else {
            if (getvalue) {
              filledCount++;
            }
          }
        }
      }
      totalCount++;
    }
    setFilled(filledCount);
    setTotal(totalCount);

    if (filledCount === totalCount) {
      props.setButtonActive(false);
    } else {
      props.setButtonActive(true);
    }
  }, [props.setRequiredField, props.values, props.setButtonActive]);

  const percentage = filled / (total / 100);
  let getPercVal;
  if (percentage > 8) {
    getPercVal = Math.trunc(percentage) + " %";
  }

  return (
    <div className="h-auto w-auto bg-gray-200 fixed top-16 lg:top-14 left-0 lg:left-56 right-0 shadow">
      <div
        className="h-5 bg-indigo-600 transition-[width] relative"
        style={{ width: percentage + "%" }}
      >
        <div
          className="z-10 absolute left-0 lg:left-50 right-0 text-center font-bold text-white px-1 py-1"
          style={{ top: "-0.3rem", fontsize: "11px" }}
        >
          {getPercVal}
        </div>
      </div>
    </div>
  );
}
