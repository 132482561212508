import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useLocation } from "react-router-dom";

export default function TextFieldEmail(props) {
  const location = useLocation();
  const isEmployeeEditOrDraftEdit =
    location.pathname.includes("/employee/edit/") ||
    location.pathname.includes("/employee/draft/edit/");
  const isEmployeeCreate = location.pathname.includes("/employees/create");

  const isCompanyEditOrDraftEdit =
    location.pathname.includes("/company/edit/") ||
    location.pathname.includes("/company/draft/edit");
  const isCompanyCreate = location.pathname.includes("/company/create");
  const {
    label,
    input,
    meta,
    placeholder = null,
    col = 3,
    value,
    required = false,
    errorHold,
    setErrorHold,
  } = props;

  function handleChange(e) {
    setErrorHold(false);
    input.onChange(e.target.value);
  }
  return (
    <div className={"col-span-12 sm:col-span-" + col}>
      <label
        htmlFor={input?.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          {...input}
          onChange={(e) => handleChange(e)}
          type="text"
          placeholder={placeholder}
          className={
            isEmployeeEditOrDraftEdit && meta.error
              ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
              : isCompanyEditOrDraftEdit && meta.error
              ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
              : (isEmployeeCreate || isCompanyCreate) &&
                meta.error &&
                meta.touched
              ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
              : errorHold
              ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
              : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
          }
        />
        {isEmployeeEditOrDraftEdit && meta.error ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : isCompanyEditOrDraftEdit && meta.error ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : (isEmployeeCreate || isCompanyCreate) &&
          meta.error &&
          meta.touched ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : errorHold ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : (
          ""
        )}
      </div>

      {isEmployeeEditOrDraftEdit && meta.error ? (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      ) : isCompanyEditOrDraftEdit && meta.error ? (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      ) : (isEmployeeCreate || isCompanyCreate) &&
        meta.error &&
        meta.touched ? (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      ) : errorHold ? (
        <p className="mt-2 text-sm text-red-600">
          The email has already been taken.
        </p>
      ) : (
        ""
      )}
    </div>
  );
}
