import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useSearchParams, useNavigate  } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon, EyeIcon, RefreshIcon, TrashIcon, XIcon } from '@heroicons/react/solid'
import { FilterIcon } from '@heroicons/react/outline'

// Components
import Pagination from '../../components/Pagination';
import EmptyStates from '../../components/EmptyStates';
import SkeletonTable from '../../components/SkeletonTable';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

// Helpers
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// Search params as a object
const useCustomSearchParams = () => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = Object.fromEntries(
    new URLSearchParams(search)
  );

  return [searchAsObject, setSearch];
};

const statusColors = {
  open: 'bg-pink-500',
  pending: 'bg-purple-500',
  cancelled: 'bg-gray-500',
  resolved: 'bg-green-500',
}

const statusTextColors = {
  open: 'text-pink-500',
  pending: 'text-purple-500',
  cancelled: 'text-gray-500',
  resolved: 'text-green-500',
}

const statusLabels = {
  open: 'Unassigned',
  pending: 'Assigned',
  cancelled: 'Cancelled',
  resolved: 'Resolved',
}

export default function List(props) {
  const { user } = props
  const navigate = useNavigate()
  //const [search, setSearch] = useCustomSearchParams();
  let [searchParams, setSearchParams] = useSearchParams();

  // Set page title
  useEffect(() => {
    document.title = `Leaves Consumptions`;
  }, [])

  const [deleteItemModalOpen, setDeleteItemModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [filterCount, setFilterCount] = useState(0)
  const [items, setItems] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState([]);
  const [dataUrl, setDataUrl] = useState(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [tabs, setTabs] = useState([])
  
  // Order By
  let curretnOrderByValue = {
    key: 'created_at',
    order: 'desc',
  }
  if(searchParams.get('order_by')) {
    if(searchParams.get('order_by').key !== 'created_at' && searchParams.get('order_by').order !== 'desc') {
      const currentOrderByArray = searchParams.get('order_by').split("-");
      curretnOrderByValue = {
        key: currentOrderByArray[0],
        order: currentOrderByArray[1],
      }
    }
  }
  let [currentOrderBy, setCurrentOrderBy] = useState(curretnOrderByValue)
  
  
  const apiUrl = '/leave-consumptions'
  const loadData = (params = {}) => {
    setIsDataLoading(true)

    if(params === {}) {
      //params['status'] = ''
    }
    //console.log(params)
    setSearchParams(params)

    //console.log('Params', params)
    
    axios.get(apiUrl, {
      params: params
    })
    .then(function (response) {
      // handle success
      setIsLoaded(true);
      console.log(response.data.data)
      setItems(response.data.data);
      setPaginationMeta(response.data.meta)
      setTimeout(function() {
        setIsDataLoading(false)
      }, 300)
    })
    .catch(function (error) {
      // handle error
      setIsLoaded(true);
      setError(error);
    })
    .then(function () {
      setIsLoaded(true);
    });
    
  }

  function refreshData() {
    let params = {}
    searchParams.forEach((key, value) => {
      params[value] = key;
      setFilterCount((filterCount) => filterCount + 1)
    });

    loadData(params)
  }

  useEffect(() => {
    refreshData()
  }, [])

  function reloadData(key, value) {
    let params = {}
    searchParams.forEach((key, value) => {
      params[value] = key;
    });
    params[key] = value

    // Load tab
    if(key != 'page') {
      params['page'] = 1
    }

    loadData(params)
  }


  function orderBy(key) {
    if(currentOrderBy.order === 'desc') {
      reloadData('order_by', key+'-asc')
      setCurrentOrderBy({
        key: key,
        order: 'asc',
      })
    } else {
      reloadData('order_by', key+'-desc')
      setCurrentOrderBy({
        key: key,
        order: 'desc',
      })
    }
  }

  // Filters
  const [filterCompanyId, setFilterCompanyId] = useState(searchParams.get('company_id') ? searchParams.get('company_id') : '')
  const [filterUserId, setFilterUserId] = useState(searchParams.get('user_id') ? searchParams.get('user_id') : '')
  
  function clearFilter() {
    let params = {}
    setFilterCompanyId('')
    setFilterUserId('')
    setFilterCount(0)
    loadData(params)
  }

  function addFilter(key, value) {
    switch(key) {
      case 'company_id':
        setFilterCompanyId(value)
        setFilterUserId('')
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'user_id':
        setFilterUserId(value)
        setFilterCount((filterCount) => filterCount + 1)
        break;
    }
    reloadData(key, value)
  }


  if(!isLoaded) {
    return <div className="fullscreen-loading flex items-center justify-around">Loading..</div>
  } else {
    return (
      <div className="min-h-[100vh] bg-white pb-1 relative">
        {/* Page title & actions */}
        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 lg:fixed lg:top-0 lg:left-56 lg:right-0 lg:z-10 bg-white">
          <div className="flex-1 min-w-0">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Leaves Consumptions</h1>
          </div>
          <div className="mt-4 flex sm:mt-0 sm:ml-4">
            <Link to="/offices/create"
              type="button"
              className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
            >
              Create
            </Link>
          </div>
        </div>

        <div className="pt-20">
          <div className="px-4 py-2 flex justify-start">
            <span className="mr-2 inline-block py-2 items-center"><FilterIcon className="h-5 w-5 text-gray-500" aria-hidden="true" /></span>
            
            {(user.managed_companies.length > 1) && <select
              className="mr-2 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={filterCompanyId}
              onChange={(event) => addFilter('company_id', event.target.value)}
            >
              <option value="">All companies</option>
              {user.managed_companies.map((company) => (
                <option key={company.id} value={company.id}>{company.name}</option>
              ))}
            </select>}

            {filterCount > 0 && <button
              onClick={() => clearFilter()}
              type="button"
              className="inline-flex mr-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-0"
            >
              Clear all
              <XIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </button>}

            <button
              onClick={() => refreshData()}
              type="button"
              className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0"
            >
              <RefreshIcon className={(isDataLoading ? 'animate-spin' : '') + ' h-5 w-5'} aria-hidden="true" />
            </button>
          </div>

          {/* Tabs */}              
          <div className="grandizer-crud-tabs">
            <div className="hidden sm:block">
              <div className="border-b border-gray-20 px-4">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab, tabIndex) => (
                    <button
                      onClick={() => reloadData('status', tab.status)}
                      key={tab.name}
                      className={classNames(
                        (tab.status === searchParams.get('status') || (!searchParams.get('status') && tab.status === ''))
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                      )}
                    >
                      <span className={tab.status ? statusTextColors[tab.status] : ''}>{tab.name}</span>
                      {tab.count ? (
                        <span
                          className={classNames(
                            (tab.status === searchParams.get('status') || (!searchParams.get('status') && tab.status === 'open')) ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                          )}
                        >
                          {tab.count}
                        </span>
                      ) : null}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          {/* Projects table (small breakpoint and up) */}
          <div className="mt-0 overflow-x-auto">
            <div className="align-middle inline-block min-w-full border-b border-gray-200">
              {isDataLoading && <SkeletonTable />}
              {!isDataLoading && <table className="min-w-full">
                <thead>
                  <tr className="border-t border-gray-200">
                    
                    <th
                      className="w-full px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      scope="col"
                    >
                      <span className="whitespace-nowrap">Employee</span>
                    </th>

                    {items.columns.map((column) => (<th
                      className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      scope="col"
                    >
                      {column.label}
                    </th>))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-100">
                  {items.leaves.map((item) => (
                    <tr key={item.user_id}>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="">
                          <div>
                            <Link to={`/leave-consumptions/${item.user_id}`} className="truncate text-blue-700 ">
                              <span className="flex">
                                <span className="underline font-bold">{item.name}</span>
                                <EyeIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </Link>
                          </div>
                        </div>
                      </td>


                      {items.columns.map((column) => (<td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                        {item.data[column.id].total}
                      </td>))}
                    </tr>
                  ))}
                </tbody>
              </table>}
            </div>
          </div>

          {/* Empty state */}
          {!items.leaves.length && isLoaded ? (
            <EmptyStates />
          ) : '' }

          {/* Pagination */}
          {(items.leaves.length && isLoaded) ? (<Pagination paginationMeta={paginationMeta} reloadData={reloadData} />) : ''}
          
        </div>
      </div>
    )
  }
}
