import { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import { Link, useSearchParams, useNavigate  } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon, XIcon } from '@heroicons/react/solid'
import { FilterIcon, PencilAltIcon } from '@heroicons/react/outline'

// Components
import Pagination from '../../components/Pagination';
import EmptyStates from '../../components/EmptyStates';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

// Helpers
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// Search params as a object
const useCustomSearchParams = () => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = Object.fromEntries(
    new URLSearchParams(search)
  );

  return [searchAsObject, setSearch];
};


export default function List(props) {
  const { user } = props
  const navigate = useNavigate()
  //const [search, setSearch] = useCustomSearchParams();
  let [searchParams, setSearchParams] = useSearchParams();

  // Set page title
  useEffect(() => {
    document.title = `General Calculators`;
  }, [])

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState([]);
  const [dataUrl, setDataUrl] = useState(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  
  // Order By
  let curretnOrderByValue = {
    key: 'created_at',
    order: 'desc',
  }
  if(searchParams.get('order_by')) {
    if(searchParams.get('order_by').key !== 'created_at' && searchParams.get('order_by').order !== 'desc') {
      const currentOrderByArray = searchParams.get('order_by').split("-");
      curretnOrderByValue = {
        key: currentOrderByArray[0],
        order: currentOrderByArray[1],
      }
    }
  }
  let [currentOrderBy, setCurrentOrderBy] = useState(curretnOrderByValue)
  
  
  const apiUrl = '/calculators'
  const loadData = (params = {}) => {
    if(params === {}) {
      // Default params
      //params['status'] = 'open'
    }
    //console.log(params)
    setSearchParams(params)

    //console.log('Params', params)
    
    axios.get(apiUrl, {
      params: params
    })
    .then(function (response) {
      // handle success
      setIsLoaded(true);
      setItems(response.data.data);
      setPaginationMeta(response.data.meta)
      console.log(response.data.data)
    })
    .catch(function (error) {
      // handle error
      setIsLoaded(true);
      setError(error);
    })
    .then(function () {
      setIsLoaded(true);
    });
    
  }

  useEffect(() => {
    let params = {}
    searchParams.forEach((key, value) => {
      params[value] = key;
    });

    loadData(params)
  }, [])

  function reloadData(key, value) {
    let params = {}
    searchParams.forEach((key, value) => {
      params[value] = key;
    });
    params[key] = value

    // Load tab
    if(key != 'page') {
      params['page'] = 1
    }

    loadData(params)
  }


  function orderBy(key) {
    if(currentOrderBy.order === 'desc') {
      reloadData('order_by', key+'-asc')
      setCurrentOrderBy({
        key: key,
        order: 'asc',
      })
    } else {
      reloadData('order_by', key+'-desc')
      setCurrentOrderBy({
        key: key,
        order: 'desc',
      })
    }
  }

  // Permission
  function canUpdateCalculator() {
    return user.permissions.includes('manage calculators');
  }

  return (
    <div className="min-h-[100vh] bg-white pb-1 relative">
      {/* Page title & actions */}
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 lg:fixed lg:top-0 lg:left-56 lg:right-0 lg:z-10 bg-white">
        <div className="flex-1 min-w-0">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">General Calculators</h1>
        </div>
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          
        </div>
      </div>

      <div className="pt-20">
        {/* Projects table (small breakpoint and up) */}
        <div className="mt-0 overflow-x-auto">
          <div className="align-middle inline-block min-w-full border-b border-gray-200">
            <table className="min-w-full">
              <thead>
                <tr className="border-t border-gray-200">
                  <th
                    className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    scope="col"
                  >
                    
                  </th>
                  <th
                    className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    scope="col"
                  >
                    <span className="whitespace-nowrap">Name</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
                {items.map((item) => (
                  <tr key={item.id}>
                    <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                      {canUpdateCalculator() && <Link to={`/calculators/${item.id}/edit`} className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0">
                        <PencilAltIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                        Edit
                      </Link>}
                    </td>
                    <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                      <div className="">
                        <div>
                          <Link to={`/calculators/${item.id}`} className="truncate text-blue-700 ">
                            <span>
                              <span className="underline font-bold">{item.name}</span>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Empty state */}
        {!items.length && isLoaded ? (
          <EmptyStates createPath="" />
        ) : '' }

        {/* Pagination */}
        {(items.length && isLoaded) ? (<Pagination paginationMeta={paginationMeta} reloadData={reloadData} />) : ''}
        
      </div>
    </div>
  )
}
