import { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XIcon } from '@heroicons/react/outline'
import { Form, Field } from 'react-final-form'
import createDecorator from 'final-form-calculate'
import axios from 'axios';
import { ExclamationCircleIcon, SelectorIcon } from '@heroicons/react/solid'
import LoadingIcon from './buttons/LoadingIcon';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

// Form Multiple Validators
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
const required = value => (value ? undefined : 'Required')

// Form calculator
const calculator = createDecorator(
  {
    field: 'department_id', // when department_id changes...
    updates: {
      // ...update ticket_category_id to the result of this function
      ticket_category_id: () => null
    }
  },
)

export default function MoveTicketModal(props) {
  let navigate = useNavigate();
  const { open, confirm, close, confirmButtonText, title, ticketId, user } = props
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [departments, setDepartments] = useState([])
  const [categories, setCategories] = useState([])
  const [sendingData, setSendingData] = useState(false)

  useEffect(() => {
    // Deparment & Categories
    axios.get('tickets/create')
      .then(function (response) {
        // handle success
        //console.log(response.data);
        if(response.data.departments) {
          setDepartments(response.data.departments)
          setCategories(response.data.categories)
          setIsLoaded(true);
        } 
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
        setIsLoaded(true);
      })
      .then(function () {
        setIsLoaded(true);
      });
  }, [])

  // Send data
  const sendData = values => new Promise(resolve => {
    setSendingData(true)
    const formData = new FormData();
    formData.append('_method', 'put');
    formData.append('department_id', values.department_id);
    formData.append('ticket_category_id', values.ticket_category_id);


    if(values.attachments) {
      values.attachments.forEach((attachment) => {
        formData.append('attachments[]', attachment);
      });
    }

    axios.post('tickets/'+ticketId, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    .then(function (response) {
        setSendingData(false)
        resolve()
        if(response.data.data.id) {
          navigate('/tickets')
        }
      })
      .catch(function (error) {
        setSendingData(false)
        resolve()
      });
  })

  // Submit form
  const onSubmit = async values => {
    await sendData(values)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => close()}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <SelectorIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className='w-full'>
                      <p className="text-sm text-gray-500">
                        You will no longer have access to this ticket after moving
                      </p>
                      <Form
                        onSubmit={onSubmit}
                        decorators={[calculator]}
                        initialValues={{ priority: 'normal', attachments: [], visible_to_requester: true }}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                          <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <Field name="department_id" validate={required}>
                              {({ input, meta }) => (            
                                <div className="sm:col-span-3">
                                  <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                                  Department <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-1 relative rounded-md shadow-sm">
                                    <select
                                      {...input}
                                      className={ (meta.error && meta.touched) ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'}
                                    >
                                      <option value="">Select</option>
                                      {departments.map((department) => (
                                        <option value={department.id} key={department.id}>{department.name} {(user.managed_companies.length > 1) && <span>({department.company.name})</span>}</option>
                                      ))}
                                    </select>
          
                                    {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                    </div>}
                                  </div>
                                  {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
                                </div>
                              )}
                            </Field>

                            <Field name="ticket_category_id" validate={required}>
                              {({ input, meta }) => (            
                                <div className="sm:col-span-3">
                                  <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                                  Category <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-1 relative rounded-md shadow-sm">
                                    <select
                                      {...input}
                                      className={ (meta.error && meta.touched) ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'}
                                    >
                                      <option value="">Select</option>
                                      {categories.map((category) => (
                                        category.department_id == values.department_id ? <option value={category.id} key={category.id}>{category.name}</option> : ''
                                      ))}
                                    </select>
          
                                    {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                    </div>}
                                  </div>
                                  {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
                                </div>
                              )}
                            </Field>
                            </div>


                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                              <button
                                type="submit"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-0 sm:ml-3 sm:w-auto sm:text-sm"                          
                              >
                                <LoadingIcon loading={submitting} />
                                {submitting ? 'Processing..' : 'Confirm'}
                              </button>
                              <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-0sm:mt-0 sm:w-auto sm:text-sm"
                                onClick={() => close()}
                              >
                                Cancel
                              </button>
                            </div>
                
                          </form>
                          
                        )}  
                      />

                      </div>
                    </div>
                  </div>
                </div>
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
