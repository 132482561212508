import { useState, useEffect } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid'

export default function RadioGroupField(props) {
    const { label, input, meta, col = 3, options, required = false } = props
    const [ checkedValue, setCheckedValue ] = useState(input.value ? input.value : options[0].value)

   
    function handleChange(event) {
        setCheckedValue(event.target.value)
        input.onChange(event.target.value)
    }

    useEffect(() => {
        input.onChange(checkedValue)
    }, [])

    return (
        <div className={'sm:col-span-'+col}>
            <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <div className="mt-1 relative rounded-md">
                
                <fieldset className="mt-4 select-none">
                    <legend className="sr-only">{label}</legend>
                    <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {options.map((option, optionIndex) => (
                        <div key={option.value} className="flex items-center">
                        <input
                            id={input.name+'_'+optionIndex}
                            name={input.name}
                            type="radio"
                            defaultChecked={option.value === checkedValue}
                            value={option.value}
                            onChange={(event) => handleChange(event)}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor={input.name+'_'+optionIndex} className="pl-2 block text-sm font-medium text-gray-700">
                            {option.label}
                        </label>
                        </div>
                    ))}
                    </div>
                </fieldset>

                {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>}
            </div>
            {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
        </div>
    )
}