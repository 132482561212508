import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";

const PaginationAll = ({ currentPage, totalPages, onPageChange }) => {
  const [paginate, setPaginate] = useState();
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const handlePageChange = (pageNumber) => {
    onPageChange(pageNumber);
  };
  useEffect(() => {
    pager();
  }, [currentPage, totalPages]);
  const pager = () => {
    let pagination = [],
      i = 1;
    while (i <= totalPages) {
      if (
        i <= 3 || //the first three pages
        i >= totalPages - 2 || //the last three pages
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        //the currentPage, the page before and after
        pagination.push(
          <Link href={`?page=${i}`} key={i}>
            <a>{i}</a>
          </Link>
        );
        i++;
      } else {
        //any other page should be represented by ...
        pagination.push(<div>...</div>);
        //jump to the next page to be linked in the navigation
        i = i < currentPage ? currentPage - 1 : totalPages - 2;
      }
    }
    return setPaginate(pagination);
  };
  // console.log(paginate)

  return (
    <div className="flex justify-center mt-6 mb-3">
      <nav className="inline-flex gap-2">
        {isFirstPage ? (
          <button
            className="border-t-4 border-transparent inline-flex my-auto items-center"
            disabled
          >
            Previous
          </button>
        ) : (
          <button
            className="border-t-4 border-transparent inline-flex my-auto items-center"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <ChevronDoubleLeftIcon className="h-4 w-4 text-black my-auto items-center" />{" "}
            Previous{" "}
          </button>
        )}
        {paginate?.map((item, index) => (
          <span
            key={index}
            className={`px-3 py-1 cursor-pointer ${
              currentPage == item?.key
                ? "border-t-4 border-blue-600 text-black"
                : "border-t-4 border-transparent hover:border-gray-300"
            }`}
            onClick={() =>
              handlePageChange(item?.props?.children?.props?.children)
            }
          >
            {item?.props?.children}
          </span>
        ))}
        {/* {Array.from({ length: totalPages }, (_, i) => (
          <a
            key={i}
            href="#"
            className={`px-3 py-1 ${
              currentPage === i + 1
                ? 'border-t-4 border-blue-600 text-black'
                : 'border-t-4 border-transparent hover:border-gray-300'
            }`}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </a>

         ))} */}

        {/* <a className='border-t-4 border-blue-600 text-black w-[30px] text-center'>{currentPage}</a> */}

        {isLastPage ? (
          <button
            className="border-t-4 border-transparent inline-flex my-auto items-center"
            disabled
          >
            Next
          </button>
        ) : (
          <button
            className="border-t-4 border-transparent inline-flex my-auto items-center"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
            <ChevronDoubleRightIcon className="h-4 w-4 text-black my-auto items-center" />{" "}
          </button>
        )}
      </nav>
    </div>
  );
};

export default PaginationAll;
