import React from "react";
import { XIcon } from "@heroicons/react/outline";

const ClearIcon = ({ onClick, showClearIcon }) => {
  if (!showClearIcon) {
    return null; // Render nothing if showClearIcon is false
  }

  return (
    <button
      type="button"
      className="focus:outline-none"
      onClick={() => {
        onClick("");
      }}
    >
      <XIcon className="h-5 w-5 text-gray-400" />
    </button>
  );
};

export default ClearIcon;
