import { useState, useEffect, useRef } from "react";
import { Form, Field, FormSpy } from "react-final-form";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";
import domtoimage from "dom-to-image";

// Components
import TextField from "components/fields/TextField";
import UpdateCEOToggleField from "components/fields/UpdateCEOToggleField";
import DatePickerField from "components/fields/DatePickerField";
import UpdateNumberField from "components/fields/UpdateNumberField";
import FormPercentage from "components/forms/FormPercentage";
import FormTabs from "components/forms/FormTabs";
import FormSection from "components/forms/FormSection";
import UpdateColorField from "components/fields/UpdateColorField";
import UpdateGrandizerSingleFileUploadField from "components/fields/UpdateGrandizerSingleFileUploadField";
import UpdateLocationArrayField from "components/fields/UpdateLocationArrayField";
import GrandizerArrayField from "components/fields/GrandizerArrayField";
import GrandizerArrayCreditCardField from "components/fields/GrandizerArrayCreditCardField";

import URLTextField from "components/fields/URLTextField";
import ServiceSelect from "components/fields/UpdateServiceSelectField";
import DragLetterHeadImage from "components/DragLetterHeadImage";
import SelectedAreaInputField from "components/SelectedAreaInputField";
import LetterHeadCanvas from "./letterHeadCanvas";
import ErrorModification from "./ErrorModification";
import {
  validateCRNumber,
  validateGOSINumber,
  validateFields,
} from "helpers/validationForCompanies";

// Form Multiple Validators
const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
const required = (value) => (value ? undefined : "Required");

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

// ====================================== APP ==========================================
// Static variables

export default function UpdateCrudForm(props) {
  const {
    onSubmit,
    initialValues,
    setButtonActive,
    companyLocationData,
    getCEOId,
    sendError,
    draftId,
    user,
    companyX,
    companyY,
    setCompanyX,
    setCompanyY,
    doneX,
    doneY,
    setDoneX,
    setDoneY,
    paidX,
    paidY,
    setPaidX,
    setPaidY,
    rejectedX,
    rejectedY,
    setRejectedX,
    setRejectedY,
    letterPosition,
    setLetterPosition,
    width,
    setWidth,
    height,
    setHeight,
    servicesData,
    onUpdate,
    parentState,
    letterheadPV,
    company_stampPV,
    done_stampPV,
    paid_stampPV,
    rejected_stampPV,
    initialRequiredField,
    tabSelect,
    setCustomError,
    submitted,
    setSubmitted,
    nonRegisterederror,
    setNonRegisterederror,
    setErrorHold,
    isDisableSubmit,
    isDisableDraft,
    setIsDisableForDraft,
  } = props;
  const tabs = [
    {
      name: (
        <span>
          General<span className="text-red-500"> * </span>
        </span>
      ),
      key: "general",
      bgColor: null,
      fields: [
        {
          name: "prefix",
        },
        {
          name: "name",
        },
        {
          name: "legal_name",
        },
        {
          name: "min_avg_project_profit",
        },
        {
          name: "choose_existing_employee",
        },
      ],
    },
    {
      name: "Address",
      key: "address",
      bgColor: null,
      fields: [
        {
          name: "country",
        },
        {
          name: "city",
        },
        {
          name: "address",
        },
        {
          name: "national_address_file",
        },
      ],
    },
    {
      name: "Documents",
      key: "documents",
      bgColor: null,
      fields: [
        {
          name: "cr_number",
        },
        {
          name: "cr_expire_date",
        },
        {
          name: "cr_number_file",
        },
        {
          name: "gosi_number",
        },
        {
          name: "gosi_expire_date",
        },
        {
          name: "gosi_file",
        },
        {
          name: "vat_number",
        },
        {
          name: "vat_file",
        },
        {
          name: "coc_number",
        },
        {
          name: "coc_expire_date",
        },
        {
          name: "coc_file",
        },
        {
          name: "gazt_number",
        },
        {
          name: "gazt_expire_date",
        },
        {
          name: "gazt_file",
        },
        {
          name: "municipality_number",
        },
        {
          name: "municipality_expire_date",
        },
        {
          name: "municipality_file",
        },
      ],
    },
    {
      name: "Bank Detail",
      key: "bank_detail",
      bgColor: null,
      fields: [
        {
          name: "bank_account_file",
        },
        {
          name: "bank_accounts",
          columns: [
            { key: "name", label: "Bank Name" },
            { key: "number", label: "Account Number" },
          ],
        },
        {
          name: "credit_cards",
          columns: [
            { key: "name", label: "Bank Name" },
            { key: "number", label: "Card Number" },
          ],
        },
      ],
    },
    {
      name: (
        <span>
          Available Services<span className="text-red-500"> * </span>
        </span>
      ),
      key: "available_services",
      fields: servicesData.map((serviceName, keyindex) => ({
        name: serviceName?.name,
      })),
      bgColor: null,
    },
    {
      name: "Email Signature",
      key: "email_signature",
      bgColor: null,
      fields: [
        {
          name: "signature_website_text",
        },
        {
          name: "signature_website_url",
        },
        {
          name: "phone",
        },
        {
          name: "extention",
        },
        {
          name: "signature_facebook_url",
        },
        {
          name: "signature_instagram_url",
        },
        {
          name: "signature_linkedin_url",
        },
        {
          name: "signature_twitter_url",
        },
      ],
    },
    {
      name: (
        <span>
          Locations<span className="text-red-500"> * </span>
        </span>
      ),
      key: "locations",
      fields: [
        {
          name: "company_locations",
        },
      ],
      bgColor: null,
    },
    {
      name: (
        <span>
          Styles<span className="text-red-500"> * </span>
        </span>
      ),
      key: "styles",
      fields: [
        {
          name: "color",
        },
        {
          name: "text_color",
        },
        {
          name: "logo",
        },
        {
          name: "logo_dark",
        },
        {
          name: "favicon",
        },
      ],
      bgColor: null,
    },
    {
      name: (
        <span>
          Letter Head & Stamps<span className="text-red-500"> * </span>
        </span>
      ),
      key: "letter_head_stamps",
      fields: [
        {
          name: "letterheadbg",
        },
        {
          name: "company_stamp",
        },
        {
          name: "done_stamp",
        },
        {
          name: "paid_stamp",
        },
        {
          name: "rejected_stamp",
        },
      ],
      bgColor: null,
    },
  ];

  const [currentTab, setCurrentTab] = useState("general");
  const [usersData, setUsersData] = useState([]);
  const [error, setError] = useState(null);
  const [recevingData, setRecevingData] = useState(false);
  let navigate = useNavigate();
  const divRef = useRef(null);
  const imageRef = useRef();

  const [showModal, setShowModal] = useState(false);

  const [screenshot, setScreenshot] = useState(null);

  const captureScreenshot = () => {
    setShowModal(true);
    domtoimage.toPng(divRef.current).then((dataUrl) => {
      setScreenshot(dataUrl);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  function handleDragStart(e) {
    e.dataTransfer.setData("text/plain", e.target.id);
  }
  function handleDragOver(e) {
    e.preventDefault();
  }

  const handleLoad = () => {
    setWidth(imageRef.current.naturalWidth);
    setHeight(imageRef.current.naturalHeight);

    const { x, y } = imageRef.current.getBoundingClientRect();
    setLetterPosition({ x, y });
  };

  // ......................... //
  // const handleScreenshot = (e) => {
  // e.preventDefault()

  //   htmlToImage
  //   .toPng(divRef.current)
  //   .then(function (dataUrl) {
  //     setScreenShotUrl(dataUrl);
  //   });
  //   setShowModal(true)

  // };

  function handleDrop(e) {
    const id = e.dataTransfer.getData("text/plain");
    const img = document.getElementById(id);

    const { clientX, clientY } = e;
    const box = e.currentTarget.getBoundingClientRect();
    if (
      clientX > box.left &&
      clientX < box.right - img.width &&
      clientY > box.top &&
      clientY < box.bottom - img.height
    ) {
      if (id === "company_stamp") {
        setCompanyX(clientX - box.left - img.width / 2);
        setCompanyY(clientY - box.top - img.height / 2);
      }
      if (id === "done_stamp") {
        setDoneX(clientX - box.left - img.width / 2);
        setDoneY(clientY - box.top - img.height / 2);
      }

      if (id === "paid_stamp") {
        setPaidX(clientX - box.left - img.width / 2);
        setPaidY(clientY - box.top - img.height / 2);
      }
      if (id === "rejected_stamp") {
        setRejectedX(clientX - box.left - img.width / 2);
        setRejectedY(clientY - box.top - img.height / 2);
      }
    }
  }

  const loadData = async (params = {}) => {
    if (params === {}) {
    }
    await axios
      .get("/edit/users", {
        params: params,
      })
      .then(function (response) {
        setUsersData(response.data.data.users);
        setRecevingData(true);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      });
  };

  function saveAsDraft(values) {
    setIsDisableForDraft(true);
    const stampPosition = {
      company_stamp_x: companyX,
      company_stamp_y: companyY,
      done_stamp_x: doneX,
      done_stamp_y: doneY,
      paid_stamp_x: paidX,
      paid_stamp_y: paidY,
      rejected_stamp_x: rejectedX,
      rejected_stamp_y: rejectedY,
      letter_head_x: parentState?.x,
      letter_head_y: parentState?.y,
      letter_head_w: parentState?.width,
      letter_head_h: parentState?.height,
    };

    const letterbgdata = {
      fileUrl: values.letterheadbg?.fileUrl,
      fileName: values.letterheadbg?.fileName,
    };

    const companyStamp = {
      fileUrl: values.company_stamp?.fileUrl,
      fileName: values.company_stamp?.fileName,
    };
    const doneStamp = {
      fileUrl: values.done_stamp?.fileUrl,
      fileName: values.done_stamp?.fileName,
    };

    const paidStamp = {
      fileUrl: values.paid_stamp?.fileUrl,
      fileName: values.paid_stamp?.fileName,
    };

    const rejectedStamp = {
      fileUrl: values.rejected_stamp?.fileUrl,
      fileName: values.rejected_stamp?.fileName,
    };

    const national_address_file = {
      fileUrl: values.national_address_file?.fileUrl,
      fileName: values.national_address_file?.fileName,
    };

    const cr_number_file = {
      fileUrl: values.cr_number_file?.fileUrl,
      fileName: values.cr_number_file?.fileName,
    };

    const gosi_file = {
      fileUrl: values.gosi_file?.fileUrl,
      fileName: values.gosi_file?.fileName,
    };

    const vat_file = {
      fileUrl: values.vat_file?.fileUrl,
      fileName: values.vat_file?.fileName,
    };

    const coc_file = {
      fileUrl: values.coc_file?.fileUrl,
      fileName: values.coc_file?.fileName,
    };
    const gazt_file = {
      fileUrl: values.gazt_file?.fileUrl,
      fileName: values.gazt_file?.fileName,
    };

    const municipality_file = {
      fileUrl: values.municipality_file?.fileUrl,
      fileName: values.municipality_file?.fileName,
    };

    const bank_account_file = {
      fileUrl: values.bank_account_file?.fileUrl,
      fileName: values.bank_account_file?.fileName,
    };

    const logo = {
      fileUrl: values.logo?.fileUrl,
      fileName: values.logo?.fileName,
    };

    const logo_dark = {
      fileUrl: values.logo_dark?.fileUrl,
      fileName: values.logo_dark?.fileName,
    };

    const favicon = {
      fileUrl: values.favicon?.fileUrl,
      fileName: values.favicon?.fileName,
    };

    const finalDraftData = {
      ...values,
      ...stampPosition,
      letterheadbg: letterbgdata,
      company_stamp: companyStamp,
      done_stamp: doneStamp,
      paid_stamp: paidStamp,
      rejected_stamp: rejectedStamp,
      letter_head_full_width: width,
      letter_head_full_height: height,

      national_address_file: national_address_file,
      cr_number_file: cr_number_file,
      gosi_file: gosi_file,
      vat_file: vat_file,
      coc_file: coc_file,
      gazt_file: gazt_file,
      bank_account_file: bank_account_file,
      municipality_file: municipality_file,
      logo: logo,
      logo_dark: logo_dark,
      favicon: favicon,
    };

    const formData = new FormData();
    formData.append("user-id", user.id);
    formData.append("model", "company");
    formData.append("draft_id", draftId.draftId);
    formData.append("data", JSON.stringify(finalDraftData));

    axios
      .post("draft/store", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        // setUsersData(response.data.data.users);
        toast.success("Draft save!");
        setTimeout(function () {
          setIsDisableForDraft(false);
          navigate("/companies?status=save_as_draft");
        }, 2000);
      })
      .catch(function (error) {
        setTimeout(() => {
          setIsDisableForDraft(false);
        }, 2000);
        // handle error
        if (
          error.response.data.message ==
            'Attempt to assign property "parent_id" on null' ||
          error.response.data.message ==
            "Call to a member function delete() on null"
        ) {
          setTimeout(() => navigate("/companies"), 2000);
          toast.error("Record has been deleted with this ID!");
        } else {
          toast.error(error.response.data.message);
        }
        setError(error);
      });
  }

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validateFields}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
        errors,

        submitFailed,
        form: { getState },
      }) => {
        return (
          <form
            onSubmit={handleSubmit}
            noValidate
            encType="multipart/form-data"
          >
            <button
              disabled={isDisableSubmit}
              type="submit"
              id="mainFormSubmit"
            ></button>
            <button
              disabled={isDisableDraft}
              id="draftmainFormSubmit"
              type="button"
              onClick={() => saveAsDraft(getState().values)}
            ></button>
            <ErrorModification
              errors={errors}
              setCustomError={setCustomError}
            />
            <FormSpy
              subscription={{ submitFailed: true }}
              onChange={(v) => {
                const el = document.querySelector(".border-red-300");
                if (el) {
                  const parent = el.closest(".g-form-section");
                  if (parent) {
                    setCurrentTab(parent.dataset.tab);
                  }
                  el.scrollIntoView({ behavior: "smooth" });
                  //
                }
              }}
            />
            <FormTabs
              tabs={tabs}
              setCurrentTab={setCurrentTab}
              defaultTab={currentTab}
              values={values}
              initialRequiredField={initialRequiredField}
              formSelect={tabSelect}
              errors={errors}
              submitted={submitted}
              setSubmitted={setSubmitted}
              nonRegisterederror={nonRegisterederror}
            />
            <FormSection
              title=""
              tab="general"
              isShow={currentTab === "general"}
            >
              <Field name="prefix">
                {({ input, meta }) => (
                  <TextField
                    required={true}
                    label="# Company Shortcode"
                    input={input}
                    meta={meta}
                    placeholder="Enter ShortCode"
                    col={2}
                    // value={companyInfo?.prefix? companyInfo.prefix:" "}
                  />
                )}
              </Field>
              <Field name="name">
                {({ input, meta }) => (
                  <TextField
                    required={true}
                    label="# Name"
                    input={input}
                    meta={meta}
                    placeholder="Enter Company Name"
                    col={2}
                    // value={companyInfo?.name? companyInfo.name:" "}
                  />
                )}
              </Field>

              <Field name="legal_name">
                {({ input, meta }) => (
                  <TextField
                    required={true}
                    label="# Legal Name"
                    input={input}
                    meta={meta}
                    placeholder="Enter Leagal Name"
                    col={2}
                    // value={companyInfo?.legal_name? companyInfo.legal_name:" "}
                  />
                )}
              </Field>
              <Field name="min_avg_project_profit">
                {({ input, meta }) => (
                  <UpdateNumberField
                    required={true}
                    label="Min Avg Project Profit(%)"
                    input={input}
                    meta={meta}
                    placeholder={"add average..."}
                    col={6}
                    min={1}
                    max={100}
                    // value={companyInfo?.min_avg_project_profit? companyInfo.min_avg_project_profit:" "}
                  />
                )}
              </Field>
              {recevingData && (
                <Field name="choose_existing_employee">
                  {({ input, meta }) => (
                    <UpdateCEOToggleField
                      required={true}
                      label="Choose existing employee to be CEO"
                      input={input}
                      meta={meta}
                      col={6}
                      usersData={usersData}
                      setExistingCEOId={getCEOId}
                      emailError={sendError}
                      setErrorHold={setErrorHold}
                    />
                  )}
                </Field>
              )}
            </FormSection>

            <FormSection
              title=""
              tab="address"
              isShow={currentTab === "address"}
            >
              <Field name="country">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-2">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Country
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        }
                      >
                        <option value="">Select...</option>
                        <option value="1">Saudi</option>
                        <option value="2">Japan</option>
                        <option value="3">Egypt</option>
                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>
              <Field name="city">
                {({ input, meta }) => (
                  <TextField
                    label="# City"
                    col={2}
                    input={input}
                    meta={meta}
                    placeholder="Enter City"
                  />
                )}
              </Field>
              <Field name="address">
                {({ input, meta }) => (
                  <TextField
                    label="# Address"
                    col={2}
                    input={input}
                    placeholder="Enter Address"
                    meta={meta}
                  />
                )}
              </Field>
              <Field name="national_address_file">
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    label="# National Address File"
                    col={6}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
            </FormSection>

            <FormSection
              title=""
              tab="documents"
              isShow={currentTab === "documents"}
            >
              <Field name="cr_number" validate={validateCRNumber}>
                {({ input, meta }) => (
                  <TextField
                    label="# CR Number"
                    input={input}
                    meta={meta}
                    placeholder="Enter CR Number"
                    col={3}
                  />
                )}
              </Field>
              <Field name="cr_expire_date">
                {({ input, meta }) => (
                  <DatePickerField
                    label="# CR Expire Date"
                    input={input}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>
              <Field name="cr_number_file">
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    label="# CR File"
                    col={6}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <div className="border-t border-gray-200 col-span-6 pt-5 mt-5">
                <p className="mt-1 text-sm text-gray-500"></p>
              </div>
              <Field name="gosi_number" validate={validateGOSINumber}>
                {({ input, meta }) => (
                  <TextField
                    label="# GOSI Number"
                    input={input}
                    meta={meta}
                    placeholder="Enter GOSI Number"
                    col={3}
                  />
                )}
              </Field>
              <Field name="gosi_expire_date">
                {({ input, meta }) => (
                  <DatePickerField
                    label="# GOSI Certificate Expiry Date"
                    input={input}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>
              <Field name="gosi_file">
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    label="# GOSI Certificate File"
                    col={6}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <div className="border-t border-gray-200 col-span-6 pt-5 mt-5">
                <p className="mt-1 text-sm text-gray-500"></p>
              </div>
              <Field name="vat_number">
                {({ input, meta }) => (
                  <TextField
                    label="# VAT Number"
                    input={input}
                    meta={meta}
                    placeholder="Enter VAT Number"
                    col={3}
                  />
                )}
              </Field>
              <Field name="vat_expire_date">
                {({ input, meta }) => (
                  <DatePickerField
                    label="# VAT Expire Date"
                    input={input}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>
              <Field name="vat_file">
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    label="# VAT File"
                    col={3}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <div className="border-t border-gray-200 col-span-6 pt-5 mt-5">
                <p className="mt-1 text-sm text-gray-500"></p>
              </div>
              <Field name="coc_number">
                {({ input, meta }) => (
                  <TextField
                    label="# COC Number"
                    input={input}
                    meta={meta}
                    placeholder="Enter COC Number"
                    col={3}
                  />
                )}
              </Field>

              <Field name="coc_expire_date">
                {({ input, meta }) => (
                  <DatePickerField
                    label="# COC Expire Date"
                    input={input}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>
              <Field name="coc_file">
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    label="# COC File"
                    col={6}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <div className="border-t border-gray-200 col-span-6 pt-5 mt-5">
                <p className="mt-1 text-sm text-gray-500"></p>
              </div>
              <Field name="gazt_number">
                {({ input, meta }) => (
                  <TextField
                    label="# GAZT Number"
                    col={3}
                    input={input}
                    placeholder="Enter GAZT Number"
                    meta={meta}
                  />
                )}
              </Field>
              <Field name="gazt_expire_date">
                {({ input, meta }) => (
                  <DatePickerField
                    label="# Zakat Certificate Expiry Date"
                    input={input}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>
              <Field name="gazt_file">
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    label="# Zakat Certificate File"
                    col={6}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <div className="border-t border-gray-200 col-span-6 pt-5 mt-5">
                <p className="mt-1 text-sm text-gray-500"></p>
              </div>
              <Field name="municipality_number">
                {({ input, meta }) => (
                  <TextField
                    label="# Municipality Number"
                    col={3}
                    input={input}
                    placeholder="Enter Municipality Number"
                    meta={meta}
                  />
                )}
              </Field>
              <Field name="municipality_expire_date">
                {({ input, meta }) => (
                  <DatePickerField
                    label="# Municipality Expire Date"
                    input={input}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>
              <Field name="municipality_file">
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    label="# Municipality File"
                    col={6}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
            </FormSection>

            <FormSection title="" isShow={currentTab === "bank_detail"}>
              <Field name="bank_account_file">
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    label="# Bank Account File"
                    col={6}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <Field name="bank_accounts">
                {({ input, meta }) => {
                  return (
                    <GrandizerArrayField
                      label="# Bank Accounts"
                      input={input}
                      meta={meta}
                      columns={[
                        { key: "name", label: "Bank Name" },
                        { key: "number", label: "Account Number" },
                      ]}
                      setNonRegisterederror={setNonRegisterederror}
                    />
                  );
                }}
              </Field>
              <Field name="credit_cards">
                {({ input, meta }) => (
                  <GrandizerArrayCreditCardField
                    label="# Credit Cards"
                    input={input}
                    meta={meta}
                    columns={[
                      // { key: "bank_name", label: "Bank Name" },
                      // { key: "card_number", label: "Card Number" },
                      { key: "name", label: "Bank Name" },
                      { key: "number", label: "Card Number" },
                    ]}
                    setNonRegisterederror={setNonRegisterederror}
                  />
                )}
              </Field>
            </FormSection>

            <FormSection title="" isShow={currentTab === "available_services"}>
              {servicesData?.map((serviceName, keyindex) => {
                return (
                  <Field name={serviceName?.name} key={keyindex}>
                    {({ input, meta }) => (
                      <ServiceSelect
                        input={input}
                        meta={meta}
                        col={3}
                        required={true}
                        label={serviceName?.name}
                      />
                    )}
                  </Field>
                );
              })}
              {servicesData.length == 0 && (
                <p className="mt-2 text-sm text-red-600">
                  Services Not Avalaible!!
                </p>
              )}
            </FormSection>

            <FormSection title="" isShow={currentTab === "email_signature"}>
              <Field name="signature_website_text">
                {({ input, meta }) => (
                  <TextField
                    label="# Website Text"
                    input={input}
                    meta={meta}
                    placeholder="Website Text"
                    col={3}
                  />
                )}
              </Field>
              <Field name="signature_website_url">
                {({ input, meta }) => (
                  <URLTextField
                    label="# Website URL"
                    input={input}
                    meta={meta}
                    placeholder="https://company.com"
                    col={3}
                  />
                )}
              </Field>
              <Field name="phone">
                {({ input, meta }) => (
                  <TextField
                    label="# Phone"
                    input={input}
                    meta={meta}
                    placeholder="+966 123 456 789"
                    col={3}
                  />
                )}
              </Field>
              <Field name="extention">
                {({ input, meta }) => (
                  <TextField
                    label="# Extention"
                    input={input}
                    meta={meta}
                    placeholder="Extention"
                    col={3}
                  />
                )}
              </Field>
              <Field name="signature_facebook_url">
                {({ input, meta }) => (
                  <TextField
                    label="# Facebook URL"
                    input={input}
                    meta={meta}
                    placeholder="https://facebook.com/xyz"
                    col={3}
                  />
                )}
              </Field>
              <Field name="signature_instagram_url">
                {({ input, meta }) => (
                  <TextField
                    label="# Instagram URL"
                    input={input}
                    meta={meta}
                    placeholder="https://instagram.com/xyz"
                    col={3}
                  />
                )}
              </Field>
              <Field name="signature_linkedin_url">
                {({ input, meta }) => (
                  <TextField
                    label="# Linkedin URL"
                    input={input}
                    meta={meta}
                    placeholder="https://linkedin.com/comany/xyz"
                    col={3}
                  />
                )}
              </Field>
              <Field name="signature_twitter_url">
                {({ input, meta }) => (
                  <TextField
                    label="# Twitter URL"
                    input={input}
                    meta={meta}
                    placeholder="https://twitter.com/xyz"
                    col={3}
                  />
                )}
              </Field>
            </FormSection>

            <FormSection title="" isShow={currentTab === "locations"}>
              <Field name="company_locations">
                {({ input, meta }) => (
                  <UpdateLocationArrayField
                    label="Addresses"
                    input={input}
                    meta={meta}
                    columns={{
                      name: "Location Name",
                      country: "Country Name",
                    }}
                    required={true}
                    companyLocationData={companyLocationData}
                  />
                )}
              </Field>
            </FormSection>
            <FormSection title="" tab="styles" isShow={currentTab === "styles"}>
              <Field name="color" validate={required}>
                {({ input, meta }) => (
                  <UpdateColorField
                    required={true}
                    label="Color"
                    input={input}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>

              <Field name="text_color" validate={required}>
                {({ input, meta }) => (
                  <UpdateColorField
                    required={true}
                    label="Text Color"
                    input={input}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>

              <Field name="logo" validate={required}>
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    required={true}
                    label="# Logo"
                    col={2}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>

              <Field name="logo_dark" validate={required}>
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    required={true}
                    label="# Dark Logo"
                    col={2}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <Field name="favicon">
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    label="# Favicon (square)"
                    col={2}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
            </FormSection>
            <FormSection title="" isShow={currentTab === "letter_head_stamps"}>
              {/* <div className="sm:col-span-2 bg-white-900">
                <Field name="letterheadbg" validate={required}>
                    {({ input, meta }) => (
                      <>
                        <h5># Upload company letterhead</h5>
                        <p className="mt-2 text-gray-500">(upload an A4 image of your comoany letter head and hightlight the area in which you want the content to appear in)</p>
                        <UpdateGrandizerSingleFileUploadField
                          required={true}
                          // label="# Logo"
                          col={2}
                          input={input}
                          meta={meta}
                        />
                      </>
                    )}
                  </Field>
                <Field name="company_stamp" validate={required}>
                  {({ input, meta }) => (
                    <UpdateGrandizerSingleFileUploadField
                      required={true}
                      label="# company_stamp"
                      col={2}
                      input={input}
                      meta={meta}
                    />
                  )}
                </Field>

                <Field name="done_stamp" validate={required}>
                  {({ input, meta }) => (
                    <UpdateGrandizerSingleFileUploadField
                      required={true}
                      label="# done_stamp"
                      col={2}
                      input={input}
                      meta={meta}
                    />
                  )}
                </Field>

                <Field name="paid_stamp" validate={required}>
                  {({ input, meta }) => (
                    <UpdateGrandizerSingleFileUploadField
                      required={true}
                      label="# paid_stamp"
                      col={2}
                      input={input}
                      meta={meta}
                    />
                  )}
                </Field>

                <Field name="rejected_stamp" validate={required}>
                  {({ input, meta }) => (
                    <UpdateGrandizerSingleFileUploadField
                      required={true}
                      label="# rejected_stamp"
                      col={2}
                      input={input}
                      meta={meta}
                    />
                  )}
                </Field>
              </div> */}

              <div className="sm:col-span-2 bg-white-900">
                <Field name="letterheadbg" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <h5># Upload company letterhead</h5>
                      <p className="mt-2 text-gray-500">
                        (upload an A4 image of your company letter head and then
                        use the red highlighted box to set the area in which you
                        want the content to appear in)
                      </p>
                      <UpdateGrandizerSingleFileUploadField
                        required={true}
                        // label="# Logo"
                        col={2}
                        input={input}
                        meta={meta}
                      />
                    </>
                  )}
                </Field>

                <div style={{ position: "relative" }} className="mt-4">
                  <p
                    className="text-gray-500"
                    style={{ position: "absolute", right: "0" }}
                  >
                    cropped to square
                  </p>

                  <Field name="company_stamp" validate={required}>
                    {({ input, meta }) => (
                      <UpdateGrandizerSingleFileUploadField
                        required={true}
                        label="# Company Stamp"
                        col={2}
                        input={input}
                        meta={meta}
                      />
                    )}
                  </Field>
                  {values.company_stamp?.fileUrl && (
                    <img
                      src={values.company_stamp?.fileUrl}
                      alt=""
                      style={{
                        width: "100px",
                        height: "auto",
                        marginTop: "10px",
                      }}
                    />
                  )}
                </div>

                <div style={{ position: "relative" }} className="mt-4">
                  <p
                    className="text-gray-500"
                    style={{ position: "absolute", right: "0" }}
                  >
                    cropped to square
                  </p>
                  <Field name="done_stamp" validate={required}>
                    {({ input, meta }) => (
                      <UpdateGrandizerSingleFileUploadField
                        required={true}
                        label="# Done Stamp"
                        col={2}
                        input={input}
                        meta={meta}
                      />
                    )}
                  </Field>
                  {values.done_stamp?.fileUrl && (
                    <img
                      src={values.done_stamp?.fileUrl}
                      alt=""
                      style={{
                        width: "100px",
                        height: "auto",
                        marginTop: "10px",
                      }}
                    />
                  )}
                </div>

                <div style={{ position: "relative" }} className="mt-4">
                  <p
                    className="text-gray-500"
                    style={{ position: "absolute", right: "0" }}
                  >
                    cropped to square
                  </p>
                  <Field name="paid_stamp" validate={required}>
                    {({ input, meta }) => (
                      <UpdateGrandizerSingleFileUploadField
                        required={true}
                        label="# Paid Stamp"
                        col={2}
                        input={input}
                        meta={meta}
                      />
                    )}
                  </Field>
                  {values.paid_stamp?.fileUrl && (
                    <img
                      src={values.paid_stamp?.fileUrl}
                      alt=""
                      style={{
                        width: "100px",
                        height: "auto",
                        marginTop: "10px",
                      }}
                    />
                  )}
                </div>

                <div style={{ position: "relative" }} className="mt-4">
                  <p
                    className="text-gray-500"
                    style={{ position: "absolute", right: "0" }}
                  >
                    cropped to square
                  </p>
                  <Field name="rejected_stamp" validate={required}>
                    {({ input, meta }) => (
                      <UpdateGrandizerSingleFileUploadField
                        required={true}
                        label="# Rejected Stamp"
                        col={2}
                        input={input}
                        meta={meta}
                      />
                    )}
                  </Field>
                  {values.rejected_stamp?.fileUrl && (
                    <img
                      src={values.rejected_stamp?.fileUrl}
                      alt=""
                      style={{
                        width: "100px",
                        height: "auto",
                        marginTop: "10px",
                      }}
                    />
                  )}
                </div>
                {/* {values.letterheadbg &&
                values.company_stamp &&
                values.paid_stamp &&
                values.rejected_stamp &&
                values.done_stamp ? (
                  <div className="mt-5">
                    <span
                      className="bg-blue-900 text-white px-10 py-3 mt-5 rounded-md cursor-pointer"
                      // onClick={() => setShowModal(true)}
                      onClick={captureScreenshot}
                    >
                      Preview
                    </span>
                  </div>
                ) : (
                  ""
                )} */}

                {screenshot && showModal && (
                  <LetterHeadCanvas
                    screenShotUrl={screenshot}
                    setShowModal={setShowModal}
                  />
                )}
              </div>

              {/* <div
                ref={divRef}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                className="sm:col-span-4 bg-gray-500"
                style={{ position: "relative" }}
              >
                
                {values.letterheadbg?.image || letterheadPV ? (
                  <div style={{ position: "relative" }}>
                    <img
                      ref={imageRef}
                      onLoad={handleLoad}
                      src={
                        !values.letterheadbg?.image
                          ? "data:image/png;base64," + letterheadPV
                          : "data:image/png;base64," +
                            values.letterheadbg?.image
                      }
                      alt="letter head bg"
                      className="w-full h-full"
                    />
                  </div>
                ) : (
                  ""
                )}

                {values.company_stamp?.image || company_stampPV ? (
                  <DragLetterHeadImage
                    handleDragStart={handleDragStart}
                    id="company_stamp"
                    x={companyX}
                    y={companyY}
                    values={
                      !values.company_stamp?.image
                        ? "data:image/png;base64," + company_stampPV
                        : "data:image/png;base64," + values.company_stamp?.image
                    }
                  />
                ) : (
                  ""
                )}
                {values.done_stamp?.image || done_stampPV ? (
                  <DragLetterHeadImage
                    handleDragStart={handleDragStart}
                    x={doneX}
                    y={doneY}
                    id="done_stamp"
                    values={
                      !values.done_stamp?.image
                        ? "data:image/png;base64," + done_stampPV
                        : "data:image/png;base64," + values.done_stamp?.image
                    }
                  />
                ) : (
                  ""
                )}
                {values.paid_stamp?.image || paid_stampPV ? (
                  <DragLetterHeadImage
                    handleDragStart={handleDragStart}
                    x={paidX}
                    y={paidY}
                    id="paid_stamp"
                    values={
                      !values.paid_stamp?.image
                        ? "data:image/png;base64," + paid_stampPV
                        : "data:image/png;base64," + values.done_stamp?.image
                    }
                  />
                ) : (
                  ""
                )}
                {values.rejected_stamp?.image || rejected_stampPV ? (
                  <DragLetterHeadImage
                    handleDragStart={handleDragStart}
                    x={rejectedX}
                    y={rejectedY}
                    id="rejected_stamp"
                    values={
                      !values.rejected_stamp?.image
                        ? "data:image/png;base64," + rejected_stampPV
                        : "data:image/png;base64," +
                          values.rejected_stamp?.image
                    }
                  />
                ) : (
                  ""
                )}
                {values.letterheadbg.image || letterheadPV ? (
                  <SelectedAreaInputField
                    values={values.letterheadbg}
                    containerRef={divRef}
                    onUpdate={onUpdate}
                    parentState={parentState}
                  />
                ) : (
                  ""
                )}
              </div> */}

              <div
                ref={divRef}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                className="sm:col-span-4"
                style={{ position: "relative" }}
              >
                {values.letterheadbg?.fileUrl ? (
                  <div style={{ position: "relative" }}>
                    <img
                      ref={imageRef}
                      onLoad={handleLoad}
                      src={values.letterheadbg.fileUrl}
                      alt="letter head bg"
                      className="w-full h-full border-2 border-gray-500"
                    />
                  </div>
                ) : (
                  ""
                )}

                {values.company_stamp?.fileUrl ? (
                  <DragLetterHeadImage
                    handleDragStart={handleDragStart}
                    id="company_stamp"
                    x={companyX}
                    y={companyY}
                    values={values.company_stamp.fileUrl}
                  />
                ) : (
                  ""
                )}
                {values.done_stamp?.fileUrl ? (
                  <DragLetterHeadImage
                    handleDragStart={handleDragStart}
                    x={doneX}
                    y={doneY}
                    id="done_stamp"
                    values={values.done_stamp.fileUrl}
                  />
                ) : (
                  ""
                )}
                {values.paid_stamp?.fileUrl ? (
                  <DragLetterHeadImage
                    handleDragStart={handleDragStart}
                    x={paidX}
                    y={paidY}
                    id="paid_stamp"
                    values={values.paid_stamp.fileUrl}
                  />
                ) : (
                  ""
                )}
                {values.rejected_stamp?.fileUrl ? (
                  <DragLetterHeadImage
                    handleDragStart={handleDragStart}
                    x={rejectedX}
                    y={rejectedY}
                    id="rejected_stamp"
                    values={values.rejected_stamp.fileUrl}
                  />
                ) : (
                  ""
                )}
                {values.letterheadbg?.fileUrl ? (
                  <SelectedAreaInputField
                    values={values.letterheadbg}
                    containerRef={divRef}
                    onUpdate={onUpdate}
                    parentState={parentState}
                  />
                ) : (
                  ""
                )}
              </div>
            </FormSection>

            <FormSpy
              subscription={{ values: true }}
              component={FormPercentage}
              setRequiredField={setButtonActive}
              setButtonActive={props.savaButtonAction}
            />
          </form>
        );
      }}
    />
  );
}
