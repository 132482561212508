import TextField from "components/fields/TextField";
import React, { useState, useEffect } from "react";
import { Form, Field, FormSpy, useForm } from "react-final-form";
import axios from "axios";
import { toast } from "react-toastify";

export default function CrudForm(props) {
  const { onSubmit, initialValues } = props;
  const [allpermissions, setAllPermissions] = useState([]);
  const required = (value) => (value ? undefined : "Required");
  // const [chekedItems, setChekedItems] = useState([])

  // const handleCheckboxChange = (e , input) =>  {
  //    const itemId = Number(e.target.id.split('-')[1]);
  //    const isChecked = e.target.checked;
  //    if(isChecked) {
  //     console.log("itemId", itemId)
  //     setChekedItems([...chekedItems , itemId]);
  //     input.onChange([...chekedItems  , itemId])
  //    }
  //    else {

  //     setChekedItems((prev) => prev.filter((id) => id !== itemId));
  //     console.log("first" , chekedItems)
  //     input.onChange([...chekedItems  , itemId])

  //    }
  //    console.log("itemId" , itemId)

  // }

  useEffect(() => {
    getPermissionsInfo();
  }, []);

  async function getPermissionsInfo() {
    await axios
      .get("permission/all", {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // setSendingData(false);
        // resolve()

        setAllPermissions(response.data.data);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      });
  }

  const [checkedItems, setCheckedItems] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const withoutall = allpermissions.filter((item) => item.name !== "all");
  const mainArr = withoutall.map((item) => ({ id: item.id, name: item.name }));

  // const handleSelectAll = (event , input) => {
  //     const isChecked = event.target.checked;
  //     const newCheckedItems = {};
  //     mainArr.forEach(item => {
  //       newCheckedItems[item.id] = isChecked;
  //     });
  //     setCheckedItems(newCheckedItems);
  //     setSelectAll(isChecked);
  //     if (isChecked) {
  //       setSelectedItems(mainArr.map(item => item.id));
  //       input.onChange(mainArr.map(item => item.id))
  //     } else {
  //       setSelectedItems([]);
  //       input.onChange([])
  //     }
  //   };

  const handleChange = (event, input) => {
    const isChecked = event.target.checked;
    const itemId = event.target.value;
    const item = event.target.name;
    setCheckedItems({ ...checkedItems, [itemId]: isChecked });

    if (item === "1") {
      if (isChecked) {
        const allIds = allpermissions.map((item) => item.id);
        setSelectedItems(allIds);
        input.onChange(allIds);
      } else {
        setSelectedItems([]);
        input.onChange([]);
      }
    } else {
      if (isChecked) {
        setSelectedItems([...selectedItems, Number(itemId)]);
        input.onChange([...selectedItems, Number(itemId)]);
      } else {
        setSelectedItems(selectedItems.filter((id) => id !== Number(itemId)));
        input.onChange(selectedItems.filter((id) => id !== Number(itemId)));
      }
    }
  };

  console.log("first", selectedItems);

  // const [checkedItems, setCheckedItems] = useState([]);

  // // Create a new object for the "select all" input and add it to the original array

  // const handleChange = (event) => {
  //   const item = event.target.name;
  //   const isChecked = event.target.checked;
  //   if (item === '1') {
  //     if (isChecked) {
  //       // If "select all" is checked, check all other checkboxes
  //       const allIds = allpermissions.map((item) => item.id.toString());
  //       setCheckedItems(allIds);
  //     } else {
  //       // If "select all" is unchecked, uncheck all other checkboxes
  //       setCheckedItems([]);
  //     }
  //   } else {
  //     // If a regular checkbox is clicked, update the checkedItems state
  //     setCheckedItems((prev) =>
  //       isChecked ? [...prev,Number[item]] : prev.filter((id) => id !== Number[item])
  //     );
  //   }
  // };

  //  console.log("checkedItems" , checkedItems)

  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      // initialValues={initialValues}
      //decorators={[focusOnError]}
      FormState
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
        submitFailed,
        errors,
        form: { getState },
      }) => {
        //encType="multipart/form-data"
        return (
          <form onSubmit={handleSubmit}>
            <button id="mainSubmitRole" type="submit"></button>
            <div className="bg-white rounded-md sm:p-5 p-0  mx-0">
              {/* {console.log("values" , values)} */}
              <Field name="role_name" validate={required}>
                {({ input, meta }) => (
                  <TextField
                    required={true}
                    label="# Name"
                    input={input}
                    meta={meta}
                    placeholder="Enter Role"
                    col={6}
                  />
                )}
              </Field>
              {console.log("values", values)}
              <Field name="role_permissions">
                {({ input, meta }) => (
                  <div className="rounded-md sm:p-5 p-0  mx-0 mt-2">
                    <div className="bg-white">
                      {/* <label>
        <input
          type="checkbox"
          checked={selectAll}
          onChange={(e) => handleSelectAll(e , input)}
          style={{marginRight:"10px"}}
        />
        all
      </label> */}
                      {allpermissions.map((item) => (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label key={item.id}>
                            <input
                              type="checkbox"
                              value={item.id}
                              name={item.id}
                              checked={
                                checkedItems[item.id]
                                  ? checkedItems[item.id]
                                  : selectedItems.length ===
                                    allpermissions.length
                                  ? selectedItems.length
                                  : false
                              }
                              onChange={(e) => handleChange(e, input)}
                              style={{ marginRight: "10px" }}
                            />
                            {item.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Field>
            </div>
          </form>
        );
      }}
    />
  );
}
