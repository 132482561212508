import { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import { Link, useSearchParams, useNavigate  } from 'react-router-dom';
import { EnvelopeIcon, XIcon } from '@heroicons/react/solid'
import { CheckIcon, ChevronRightIcon, FilterIcon } from '@heroicons/react/outline'
import moment from 'moment';

// Components
import Pagination from '../../components/Pagination';
import EmptyStates from '../../components/EmptyStates';
import LoadingIcon from '../../components/buttons/LoadingIcon';
import SkeletonTable from '../../components/SkeletonTable';
import DateRangePickerFilterField from '../../components/filterFields/DateRangePickerFilterField';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

// Helpers
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// Search params as a object
const useCustomSearchParams = () => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = Object.fromEntries(
    new URLSearchParams(search)
  );

  return [searchAsObject, setSearch];
};

const statusTextColors = {
  open: 'text-pink-500',
  pending: 'text-purple-500',
  cancelled: 'text-gray-500',
  resolved: 'text-green-500',
}

const notificationTypes = [
  'Task',
  'New Request',
  'Send & Sign',
  'Approved',
  'Rejected',
  'On Hold',
  'Mention',
  'Job Application',
  'Support Ticket',
]

function typeColors(title) {
  let bgColor = ''
  switch (title) {
    case 'Send & Sign':
      bgColor = 'bg-blue-500';
      break;
    case 'Approved':
      bgColor = 'bg-green-500';
      break;
    case 'Rejected':
      bgColor = 'bg-red-500';
      break;
    case 'On Hold':
      bgColor = 'bg-yellow-500';
      break;
    case 'Mention':
      bgColor = 'bg-red-500';
      break;
    case 'Job Application':
      bgColor = 'bg-indigo-500';
      break;
    case 'Support Ticket':
      bgColor = 'bg-blue-500';
      break;
    default:
      bgColor = 'bg-gray-500'
  }

  return bgColor;
  
}

export default function List(props) {
  const { user } = props
  const navigate = useNavigate()
  //const [search, setSearch] = useCustomSearchParams();
  let [searchParams, setSearchParams] = useSearchParams();

  // Set page title
  useEffect(() => {
    document.title = `Support Tickets`;
  }, [])

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [filterCount, setFilterCount] = useState(0)
  const [items, setItems] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState([]);
  const [dataUrl, setDataUrl] = useState(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [tabs, setTabs] = useState([
    { name: 'Unread', color: '', status: '', count: 0, current: true },
    { name: 'All', color: 'bg-pink-500', status: 'cleared', count: 0, current: false },
  ])
  
  // Order By
  let curretnOrderByValue = {
    key: 'created_at',
    order: 'desc',
  }
  if(searchParams.get('order_by')) {
    if(searchParams.get('order_by').key !== 'created_at' && searchParams.get('order_by').order !== 'desc') {
      const currentOrderByArray = searchParams.get('order_by').split("-");
      curretnOrderByValue = {
        key: currentOrderByArray[0],
        order: currentOrderByArray[1],
      }
    }
  }
  let [currentOrderBy, setCurrentOrderBy] = useState(curretnOrderByValue)
  
  
  const apiUrl = '/notifications'
  const loadData = (params = {}) => {
    setIsDataLoading(true)

    if(params === {}) {
      //params['status'] = ''
    }
    //console.log(params)
    setSearchParams(params)

    //console.log('Params', params)
    
    axios.get(apiUrl, {
      params: params
    })
    .then(function (response) {
      // handle success
      setIsLoaded(true);
      setItems(response.data.data);
      setPaginationMeta(response.data)
      setTimeout(function() {
        setIsDataLoading(false)
      }, 300)
    })
    .catch(function (error) {
      // handle error
      setIsLoaded(true);
      setError(error);
    })
    .then(function () {
      setIsLoaded(true);
    });
    
  }

  
  const [markingAllRead, setMarkingAllRead] = useState(false);
  function markAllRead() {
    setMarkingAllRead(true)
    axios.post('notifications/mark-all-read', {
      _method: 'put'
    })
    .then(function (response) {
      // handle success
      setMarkingAllRead(false)
      clearFilter()
    })
    .catch(function (error) {
      // handle error
      clearFilter()
      setMarkingAllRead(false)
      setError(error);
    })
    .then(function () {
      setMarkingAllRead(false)
    });
  }

  useEffect(() => {
    let params = {}
    searchParams.forEach((key, value) => {
      params[value] = key;
      setFilterCount((filterCount) => filterCount + 1)
    });

    loadData(params)
  }, [])

  function reloadData(key, value) {
    let params = {}
    searchParams.forEach((key, value) => {
      params[value] = key;
    });
    params[key] = value

    // Load tab
    if(key != 'page') {
      params['page'] = 1
    }

    loadData(params)
  }


  function orderBy(key) {
    if(currentOrderBy.order === 'desc') {
      reloadData('order_by', key+'-asc')
      setCurrentOrderBy({
        key: key,
        order: 'asc',
      })
    } else {
      reloadData('order_by', key+'-desc')
      setCurrentOrderBy({
        key: key,
        order: 'desc',
      })
    }
  }

  // Filters
  const [filterType, setFilterType] = useState(searchParams.get('type') ? searchParams.get('type') : '')
  const [filterFrom, setFilterFrom] = useState(searchParams.get('from') ? searchParams.get('from') : '')
  const [filterTo, setFilterTo] = useState(searchParams.get('to') ? searchParams.get('to') : '')

  function clearFilter() {
    let params = {}
    setFilterType('')
    setFilterFrom('')
    setFilterTo('')
    setFilterCount(0)
    loadData(params)
  }

  function addFilter(key, value) {
    switch(key) {
      case 'type':
        setFilterType(value)
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'from':
        setFilterFrom(value)
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'to':
        setFilterTo(value)
        setFilterCount((filterCount) => filterCount + 1)
        break; 
    }
    reloadData(key, value)
  }

  if(!isLoaded) {
    return <div className="fullscreen-loading flex items-center justify-around">Loading..</div>
  } else {
    return (
      <div className="min-h-[100vh] bg-white pb-1 relative">
        {/* Page title & actions */}
        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 lg:fixed lg:top-0 lg:left-56 lg:right-0 lg:z-10 bg-white">
          <div className="flex-1 min-w-0">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Notifications</h1>
          </div>
          <div className="mt-4 flex sm:mt-0 sm:ml-4">
            <button 
                onClick={() => markAllRead()}
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
              >
                <LoadingIcon loading={markingAllRead} />
                {!markingAllRead && <CheckIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
                Mark all as read
            </button>
          </div>
        </div>

        <div className="pt-20">
          <div className="px-4 py-2 flex justify-start">
            <span className="mr-2 inline-block py-2 items-center"><FilterIcon className="h-5 w-5 text-gray-500" aria-hidden="true" /></span>
            <select
              className="mr-2 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={filterType}
              onChange={(event) => addFilter('type', event.target.value)}
            >
              <option value="">All types</option>
              {notificationTypes.map((notificationType) => (
                <option key={notificationType} value={notificationType}>{notificationType}</option>
              ))}
            </select>
          

            <DateRangePickerFilterField startDate={filterFrom} endDate={filterTo} onChangeStart={(value) => addFilter('from', value)} onChangeEnd={(value) => addFilter('to', value)} />

            {filterCount > 0 && <button
              onClick={() => clearFilter()}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-0"
            >
              Clear all
              <XIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </button>}
          </div>

          {/* Tabs */}              
          <div className="grandizer-crud-tabs">
            <div className="hidden sm:block">
              <div className="border-b border-gray-20 px-4">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab, tabIndex) => (
                    <button
                      onClick={() => reloadData('status', tab.status)}
                      key={tab.name}
                      className={classNames(
                        (tab.status === searchParams.get('status') || (!searchParams.get('status') && tab.status === ''))
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                      )}
                    >
                      <span className={tab.status ? statusTextColors[tab.status] : ''}>{tab.name}</span>
                      {tab.count ? (
                        <span
                          className={classNames(
                            (tab.status === searchParams.get('status') || (!searchParams.get('status') && tab.status === 'open')) ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                          )}
                        >
                          {tab.count}
                        </span>
                      ) : null}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          {/* Projects table (small breakpoint and up) */}
          <div className="mt-0 overflow-x-auto">
            <div className="align-middle inline-block min-w-full border-b border-gray-200">
              {isDataLoading && <SkeletonTable />}
              {!isDataLoading && <ul role="list" className="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0">
              {items.map((item) => (
                <li key={item.id} className="px-4">
                  <a href={process.env.REACT_APP_V1_URL+'/notifications/'+item.id} className="group block">
                    <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                      <div className="flex min-w-0 flex-1 items-center">
                        <div className="flex-shrink-0">
                          <img
                            className="h-12 w-12 rounded-full group-hover:opacity-75"
                            src={item.data.image}
                            alt=""
                          />
                        </div>
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="truncate text-sm font-medium text-purple-600">{item.data.sender} {item.data.assignee} {item.data.requester} {item.data.notifier}</p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              
                              <span className="">{item.data.message}</span>
                            </p>
                          </div>
                          <div className="hidden md:block">
                            <div>
                              <p className="text-sm text-gray-900">
                                <time dateTime={item.created_at}>{moment(item.created_at).calendar()}</time>
                              </p>
                              <p className="mt-2 flex items-center text-sm text-gray-500">
                                <span className={classNames(typeColors(item.data.title), "text-white uppercase inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium")}>{item.data.title}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>}
            </div>
          </div>

          {/* Empty state */}
          {!items.length && isLoaded ? (
            <EmptyStates />
          ) : '' }

          {/* Pagination */}
          {(items.length && isLoaded) ? (<Pagination paginationMeta={paginationMeta} reloadData={reloadData} />) : ''}
          
        </div>
      </div>
    )
  }
}
