import { useState, useEffect } from 'react'
import { CKEditor } from 'ckeditor4-react';

const PLACEHOLDERS = [
    {
        id: 'field_text',
        name: 'field_text',
        title: 'field_text',
        description: 'Add a text input field'
    },
    {
        id: 'field_text_required',
        name: 'field_text_required',
        title: 'field_text_required',
        description: 'Add a required text input field'
    },
    {
        id: 'field_number',
        name: 'field_number',
        title: 'field_number',
        description: 'Add a number input field'
    },
    {
        id: 'field_number_required',
        name: 'field_number_required',
        title: 'field_number_required',
        description: 'Add a required number input field'
    },
    {
        id: 'field_date',
        name: 'field_date',
        title: 'field_date',
        description: 'Add a date input field'
    },
    {
        id: 'field_date_required',
        name: 'field_date_required',
        title: 'field_date_required',
        description: 'Add a required date input field'
    },
    // @foreach($userDateFields as $key => $userDateField)
    // {
    //     id: '{{ $key }}',
    //     name: '{{ $key }}',
    //     title: '{{ $userDateField }}',
    //     description: ''
    // },
    // @endforeach
    // @foreach($userFields as $key => $userField)
    //     {
    //         id: '{{ $key }}',
    //         name: '{{ $key }}',
    //         title: '{{ $userField }}',
    //         description: ''
    //     },
    // @endforeach
    {
        id: 'division_name',
        name: 'division_name',
        title: 'Division Name',
        description: ''
    },
    {
        id: 'deparment_name',
        name: 'deparment_name',
        title: 'Department Name',
        description: ''
    },
];

const editorConfig = {
    extraPlugins: 'autocomplete,textmatch,toolbar,wysiwygarea,basicstyles,link,undo,placeholder',
    toolbar: [
        {
            name: 'document',
            items: ['Undo', 'Redo']
        },
        {
            name: 'basicstyles',
            items: ['Bold', 'Italic']
        },
    ],
    on: {
        instanceReady: function(evt) {

            const textTestCallback = (range) => {
                if (!range.collapsed) {
                    return null;
                }
            
                return evt.editor.plugins.textMatch.match(range, matchCallback);
            }
            
            const matchCallback = (text, offset) => {
                const pattern = /\[{2}([A-z]|\])*$/,
                    match = text.slice(0, offset)
                        .match(pattern);
            
                if (!match) {
                    return null;
                }
            
                return {
                    start: match.index,
                    end: offset
                };
            }
            
            const dataCallback = (matchInfo, callback) => {
                const data = PLACEHOLDERS.filter(function(item) {
                    const itemName = '[[' + item.name + ']]';
                    return itemName.indexOf(matchInfo.query.toLowerCase()) == 0;
                });
            
                callback(data);
            }

            const itemTemplate = '<li data-id="{id}">' +
                '<div><strong className="item-title">{title}</strong></div>' +
                '<div><i>{description}</i></div>' +
                '</li>',
                outputTemplate = '[[{title}]]<span>&nbsp;</span>';

                const autocomplete = new evt.editor.plugins.autocomplete(evt.editor, {
                textTestCallback: textTestCallback,
                dataCallback: dataCallback,
                itemTemplate: itemTemplate,
                outputTemplate: outputTemplate
            });

            // Override default getHtmlToInsert to enable rich content output.
            autocomplete.getHtmlToInsert = function(item) {
                return this.outputTemplate.output(item);
            }
        }
    }
};

// const rtlEditorConfig = {
//     extraPlugins: 'autocomplete,textmatch,toolbar,wysiwygarea,basicstyles,link,undo,placeholder',
//     toolbar: [
//         {
//             name: 'document',
//             items: ['Undo', 'Redo']
//         },
//         {
//             name: 'basicstyles',
//             items: ['Bold', 'Italic']
//         },
//     ],
//     contentsLangDirection: 'rtl',
//     on: {
//         instanceReady: function(evt) {
//             var itemTemplate = '<li data-id="{id}">' +
//                 '<div><strong className="item-title">{title}</strong></div>' +
//                 '<div><i>{description}</i></div>' +
//                 '</li>',
//                 outputTemplate = '[[{title}]]<span>&nbsp;</span>';

//             var autocomplete = new CKEditor.plugins.autocomplete(evt.editor, {
//                 textTestCallback: textTestCallback,
//                 dataCallback: dataCallback,
//                 itemTemplate: itemTemplate,
//                 outputTemplate: outputTemplate
//             });

//             // Override default getHtmlToInsert to enable rich content output.
//             autocomplete.getHtmlToInsert = function(item) {
//                 return this.outputTemplate.output(item);
//             }
//         }
//     }
// };

export default function FormBuilerEditorCKEditorField(props) {
    const { value, onChange, rtl } = props;
    const [rtlMode, setRtlMode] = useState(rtl);

    useEffect(() => {
        setRtlMode(rtl);
    }, [rtl]);

    if(rtlMode) {
        return (<div>
            {/* <CKEditor
                initData={value}
                config={rtlEditorConfig}
            /> */}
            <div className="text-xs">RTL mode</div>
        </div>)
    }

    return (<div>
        <CKEditor
            initData={value}
            config={editorConfig}
        />
    </div>)
}