import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-calculate";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { Switch } from "@headlessui/react";
import Select from "react-select";

// Components
import DropzoneField from "../../components/fields/DropzoneField";
import PrioritySelectField from "../../components/fields/PrioritySelectField";
import TextField from "../../components/fields/TextField";
import EmailField from "../../components/fields/EmailField";
import TextareaField from "../../components/fields/TextareaField";
import AlertModal from "../../components/AlertModal";
import SwitchField from "../../components/fields/SwitchField";
import CompanyMultiselectField from "../../components/fields/CompanyMultiselectField";
import ArrayField from "../../components/fields/ArrayField";
import DatePickerField from "../../components/fields/DatePickerField";
import CountrySelectField from "../../components/fields/CountrySelectField";
import PhoneNumberField from "../../components/fields/PhoneNumberField";
import ToggleField from "../../components/fields/ToggleField";
import RadioGroupField from "../../components/fields/RadioGroupField";
import AddressField from "../../components/fields/AddressField";
import NumberField from "../../components/fields/NumberField";
import TagsField from "../../components/fields/TagsField";
import { current } from "@reduxjs/toolkit";
import CheckboxesField from "../../components/fields/CheckboxesField";

import CompanySingleselectField from "../../components/fields/CompanySingleselectField";

import { category } from "./objects/supplierObjects";

import { validateAllFields } from "helpers/validationForVendors";

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

const tabs = [
  { name: "Information", key: "information" },
  { name: "Attributes", key: "attributes" },
  { name: "Media", key: "media" },
];

// Form Multiple Validators
const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
const required = (value) => (value ? undefined : "Required");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CreateCrud(props) {
  //const { user } = props
  let params = useParams();
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [vendorAgents, setVendorAgents] = useState([]);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState("information");
  const [model, setModel] = useState({});
  const [isNoPermission, setIsNoPermission] = useState(false);

  // Set page title
  useEffect(() => {
    document.title = `Update Vendor`;
  }, []);

  function reloadFormMeta() {
    // Creation form data
    axios
      .get("vendors/create")
      .then(function (response) {
        // handle success
        //console.log(response.data);
        if (response.data.vendor_categories) {
          setVendorCategories(response.data.vendor_categories);
          setVendorAgents(response.data.vendor_agents);
          setIsLoaded(true);
        }
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
        setIsLoaded(true);
      })
      .then(function () {
        setIsLoaded(true);
      });
  }

  function reloadData() {
    axios
      .get("vendors/" + params.modelId)
      .then(function (response) {
        // handle success
        //console.log(response.data.data);
        if (response.data.status === "no_permission") {
          setIsNoPermission(true);
        } else {
          if (response.data.data) {
            document.title = "Update Vendor: " + response.data.data.name;
            setModel(response.data.data);
          }
        }
        reloadFormMeta(response.data.data);
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
        //setIsLoaded(true);
      })
      .then(function () {
        //setIsLoaded(true);
      });
  }

  useEffect(() => {
    reloadData();
  }, []);

  function closeForm() {
    setAlertModalOpen(false);
    navigate("/suppliers/");
  }

  // Send data
  const sendData = (values) =>
    new Promise((resolve) => {
      setSendingData(true);
      const formData = new FormData();

      if (values.companies) {
        values.companies.forEach((company) => {
          formData.append("company_ids[]", company.value);
        });
      }
      formData.append("_method", "put");
      if (values.name) {
        formData.append("name", values.name);
      }
      if (values.email) {
        formData.append("email", values.email);
      }
      if (values.last_name) {
        formData.append("last_name", values.last_name);
      }
      if (values.dob) {
        formData.append("dob", values.dob);
      }
      if (values.id_iqama) {
        formData.append("id_iqama", values.id_iqama);
      }
      if (values.education_level) {
        formData.append("education_level", values.education_level);
      }
      if (values.nationality) {
        formData.append("nationality", values.nationality);
      }
      if (values.job_level) {
        formData.append("job_level", values.job_level);
      }
      if (values.phone_number) {
        formData.append("phone_number", values.phone_number);
      }
      if (values.second_phone_number) {
        formData.append("second_phone_number", values.second_phone_number);
      }
      if (values.can_relocate) {
        formData.append("can_relocate", values.can_relocate);
      }
      if (values.gender) {
        formData.append("gender", values.gender);
      }
      if (values.address) {
        formData.append("address", values.address.address);
      }
      if (values.address) {
        formData.append("address_city", values.address.city);
      }
      if (values.address) {
        formData.append("address_country", values.address.country);
      }
      if (values.cost) {
        formData.append("cost", values.cost);
      }
      if (values.hobbies) {
        formData.append("hobbies", JSON.stringify(values.hobbies));
      }
      if (values.available_from) {
        formData.append("available_from", values.available_from);
      }
      if (values.available_to) {
        formData.append("available_to", values.available_to);
      }
      if (values.description) {
        formData.append("description", values.description);
      }
      if (values.social_youtube) {
        formData.append("social_youtube", values.social_youtube);
      }
      if (values.social_twitter) {
        formData.append("social_twitter", values.social_twitter);
      }
      if (values.social_facebook) {
        formData.append("social_facebook", values.social_facebook);
      }
      if (values.social_instagram) {
        formData.append("social_instagram", values.social_instagram);
      }
      if (values.social_snapchat) {
        formData.append("social_snapchat", values.social_snapchat);
      }
      vendorCategories.forEach((vendorCategory) => {
        if (values["vendor_category_" + vendorCategory.id]) {
          if (values["vendor_category_" + vendorCategory.id].length) {
            values["vendor_category_" + vendorCategory.id].forEach((item) => {
              formData.append("vendor_category_item_ids[]", item);
            });
          }
        }
      });
      if (values.attachments) {
        values.attachments.forEach((attachment) => {
          formData.append("attachments[]", attachment);
        });
      }

      axios
        .post("vendors/" + params.modelId, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          setSendingData(false);
          resolve();
          if (response.data.data.id) {
            navigate("/vendors/" + response.data.data.id);
          }
        })
        .catch(function (error) {
          setSendingData(false);
          resolve();
        });
    });

  // Submit form
  const onSubmit = async (values) => {
    await sendData(values);
  };

  const [selectCategory, setSelectCategory] = useState("");
  const handleCategoryChange = (e, input) => {
    input.onChange(e);
    setSelectCategory(e.value);
  };
  // if (!isLoaded) {
  //   return (
  //     <div className="fullscreen-loading flex items-center justify-around">
  //       Loading..
  //     </div>
  //   );
  // } else {
  return (
    <div className="h-full">
      <Form
        keepDirtyOnReinitialize
        validate={validateAllFields}
        onSubmit={onSubmit}
        initialValues={{
          attachments: [],
          address: { country: "Saudi Arabia", city: null, address: null },
          companies: [],
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            {/* Page title & actions */}
            <div className="bg-white border-b border-gray-200 px-4 py-4 flex items-center justify-between sm:px-6 lg:px-8 fixed top-14 lg:top-0 right-0 left-0 lg:left-56  lg:fixed   lg:right-0 z-5">
              <div className="flex-1 min-w-0">
                <h1 className="text-lg font-medium leading-6 text-gray-900 truncate">
                  Edit Supplier:
                </h1>
              </div>
              <div className="mt-4 flex  ml-4">
                <a
                  href="#"
                  onClick={() => setAlertModalOpen(true)}
                  disabled={sendingData}
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-2 disabled:opacity-75"
                >
                  Cancel
                </a>
                <button
                  disabled={sendingData}
                  type="submit"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
                >
                  {sendingData ? "Submitting.." : "Submit"}
                </button>
              </div>
            </div>
            <main className="py-10 pt-20">
              <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8 mt-4">
                <div className="shadow sm:rounded-md sm:overflow-hidden w-full">
                  <div className="bg-white py-6 px-4 space-y-6 sm:p-6 relative">
                    {sendingData ? (
                      <div className="bg-white absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center z-50">
                        Please wait..
                      </div>
                    ) : (
                      ""
                    )}
                    <div>
                      <div>
                        <div className="space-y-8 divide-y divide-gray-200">
                          <div className="space-y-8 divide-y divide-gray-200">
                            <div>
                              <div
                                className={
                                  "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 "
                                }
                              >
                                {vendorCategories.map((vendorCategory) => (
                                  <div
                                    key={vendorCategory.id}
                                    className="sm:col-span-6"
                                  >
                                    {vendorCategory.items.length > 0 && (
                                      <div className="mb-5">
                                        <div className="border-t border-gray-200 col-span-6 pt-5 first:border-0 first:pt-0 first:-mt-5">
                                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            {vendorCategory.name}
                                          </h3>
                                          <p className="mt-1 text-sm text-gray-500"></p>
                                        </div>
                                        <Field
                                          name={
                                            "vendor_category_" +
                                            vendorCategory.id
                                          }
                                        >
                                          {({ input, meta }) => (
                                            <CheckboxesField
                                              label="Select items"
                                              col="6"
                                              input={input}
                                              meta={meta}
                                              items={vendorCategory.items}
                                              hasLabel={false}
                                            />
                                          )}
                                        </Field>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>

                              <div
                                className={
                                  "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 "
                                }
                              >
                                <Field name="company_id">
                                  {({ input, meta }) => (
                                    <CompanySingleselectField
                                      label="Belongs to company"
                                      col={6}
                                      input={input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>

                                <Field name="companies" validate={required}>
                                  {({ input, meta }) => (
                                    <CompanyMultiselectField
                                      required={true}
                                      label="Shared with companies"
                                      col={3}
                                      // col={isGeneral() ? 3 : 6}
                                      input={input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>

                                <Field name="name" validate={required}>
                                  {({ input, meta }) => (
                                    <TextField
                                      required={true}
                                      label={
                                        " # Name"
                                        // isGeneral()
                                        //   ? "# Name"
                                        //   : "# First Name"
                                      }
                                      input={input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                                <Field name="email">
                                  {({ input, meta }) => (
                                    <EmailField
                                      label="# Email"
                                      input={input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>

                                <Field name="general_vat_number">
                                  {({ input, meta }) => (
                                    <TextField
                                      label="# VAT Number"
                                      input={input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>

                                <Field name="general_cr_number">
                                  {({ input, meta }) => (
                                    <TextField
                                      label="# CR Number"
                                      input={input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                                <>
                                  <Field name="Companies">
                                    {({ input, meta }) => (
                                      <div className="col-span-12 sm:col-span-3">
                                        <label
                                          htmlFor={input.name}
                                          className="block text-sm font-medium text-gray-700"
                                        >
                                          # Companies{" "}
                                          <span className="text-red-400">
                                            *
                                          </span>
                                        </label>
                                        <Select
                                          {...input}
                                          // options={countryListing}
                                          // menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({
                                              ...base,
                                              zIndex: 9999,
                                            }),
                                          }}
                                        />
                                        {meta.error && meta.touched && (
                                          <p className="mt-2 text-sm text-red-600">
                                            {meta.error}
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </Field>

                                  <Field name="Category">
                                    {({ input, meta }) => (
                                      <div className="col-span-12 sm:col-span-3">
                                        <label
                                          htmlFor={input.name}
                                          className="block text-sm font-medium text-gray-700"
                                        >
                                          # Category{" "}
                                          <span className="text-red-400">
                                            *
                                          </span>
                                        </label>
                                        <Select
                                          {...input}
                                          options={category}
                                          onChange={(e) =>
                                            handleCategoryChange(e, input)
                                          }
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({
                                              ...base,
                                              zIndex: 9999,
                                            }),
                                          }}
                                        />
                                        {meta.error && meta.touched && (
                                          <p className="mt-2 text-sm text-red-600">
                                            {meta.error}
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </>

                                {(selectCategory === "Afghan" ||
                                  selectCategory === "Albanian") && (
                                  <Field
                                    name="general_addresses"
                                    validate={required}
                                  >
                                    {({ input, meta }) => (
                                      <ArrayField
                                        required={true}
                                        label="# Addresses"
                                        input={input}
                                        meta={meta}
                                        columns={[
                                          { key: "name", label: "# Name" },
                                          {
                                            key: "address",
                                            label: "# Address",
                                          },
                                        ]}
                                      />
                                    )}
                                  </Field>
                                )}

                                {selectCategory === "Albanian" && (
                                  <Field
                                    name="general_phones"
                                    validate={required}
                                  >
                                    {({ input, meta }) => (
                                      <ArrayField
                                        required={true}
                                        label="# Phones"
                                        input={input}
                                        meta={meta}
                                        columns={[
                                          { key: "name", label: "# Name" },
                                          {
                                            key: "number",
                                            label: "# Number",
                                          },
                                        ]}
                                      />
                                    )}
                                  </Field>
                                )}

                                {selectCategory === "Algerian" && (
                                  <Field
                                    name="general_bank_accounts"
                                    validate={required}
                                  >
                                    {({ input, meta }) => (
                                      <ArrayField
                                        required={true}
                                        label="# Bank Accounts"
                                        input={input}
                                        meta={meta}
                                        columns={[
                                          {
                                            key: "bank_name",
                                            label: "# Bank Name",
                                          },
                                          {
                                            key: "account_number",
                                            label: "# Account Number",
                                          },
                                          { key: "swift", label: "SWIFT" },
                                        ]}
                                      />
                                    )}
                                  </Field>
                                )}
                              </div>

                              {/* <div>
                                  <pre>{JSON.stringify(values, 0, 2)}</pre>
                                </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </form>
        )}
      />

      <AlertModal
        title="Close Form"
        confirm={closeForm}
        close={() => setAlertModalOpen(false)}
        open={alertModalOpen}
      />
    </div>
  );
  // }
}
