import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { toast } from "react-toastify";
import TextareaAutosize from "react-textarea-autosize";

import {
  CheckIcon,
  PaperClipIcon,
  UserIcon,
  XIcon,
  StarIcon,
  CollectionIcon,
  SelectorIcon,
  AdjustmentsIcon,
  BellIcon,
  UserCircleIcon,
  RefreshIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import axios from "axios";

// Components
import PriorityBadge from "../../components/PriorityBadge";
import DropzoneField from "../../components/fields/DropzoneField";
import UserSelectField from "../../components/fields/UserSelectField";
import ResolveTicketModal from "../../components/ResolveTicketModal";
import AlertModal from "../../components/AlertModal";
import PrioritySelectField from "../../components/fields/PrioritySelectField";
import StarRatings from "react-star-ratings";
import RatingStars from "../../components/RatingStars";
import RateModal from "../../components/RateModal";
import MoveTicketModal from "../../components/MoveTicketModal";
import Assignee from "../../components/Assignee";
import AssignTicketModal from "../../components/AssignTicketModal";
import AutoSave from "../../components/fields/AutoSave";
import SuccessNotification from "../../components/SuccessNotification";
import LoadingIcon from "../../components/buttons/LoadingIcon";
// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

// Form Multiple Validators
const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
const required = (value) => (value ? undefined : "Required");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const statusColors = {
  open: "bg-pink-500",
  pending: "bg-purple-500",
  cancelled: "bg-gray-500",
  resolved: "bg-green-500",
};

const statusTextColors = {
  open: "text-pink-500",
  pending: "text-purple-500",
  cancelled: "text-gray-500",
  resolved: "text-green-500",
};

const statusLabels = {
  open: "Unassigned",
  pending: "Assigned",
  cancelled: "Cancelled",
  resolved: "Resolved",
};

export default function TicketShow(props) {
  const { user } = props;
  const navigate = useNavigate();
  let params = useParams();
  const [error, setError] = useState(null);
  const [isNoPermission, setIsNoPermission] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [ticket, setTicket] = useState({});
  const [ticketLogs, setTicketLogs] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [timelineItems, setTimelineItems] = useState([]);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [resolveModalOpen, setResolveModalOpen] = useState(false);
  const [reopenModalOpen, setReopenModalOpen] = useState(false);
  const [rateModalOpen, setRateModalOpen] = useState(false);
  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [rating, setRating] = useState(0);

  function reloadTicketData() {
    axios
      .get("tickets/" + params.ticketId)
      .then(function (response) {
        // handle success
        //console.log(response.data.data);
        if (response.data.status === "no_permission") {
          setIsNoPermission(true);
        } else {
          if (response.data.data) {
            document.title =
              response.data.data.hid + ": " + response.data.data.name;

            if (user.id === response.data.data.requester_id) {
              setRating(response.data.data.ticket_rate);
            } else {
              setRating(response.data.data.requester_rate);
            }

            setTicket(response.data.data);
            reloadTicketLogsData();
            reloadAssignees();
            reloadTimeline();
          }
        }
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
        setIsLoaded(true);
      })
      .then(function () {
        setIsLoaded(true);
      });
  }

  function reloadTicketLogsData() {
    axios
      .get("tickets/" + params.ticketId + "/logs")
      .then(function (response) {
        if (response.data.data) {
          setTicketLogs(response.data.data);
        }
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
        setIsLoaded(true);
      })
      .then(function () {
        setIsLoaded(true);
      });
  }

  function reloadAssignees() {
    axios
      .get("tickets/" + params.ticketId + "/assignees")
      .then(function (response) {
        if (response.data.data) {
          setAssignees(response.data.data);
        }
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
        setIsLoaded(true);
      })
      .then(function () {
        setIsLoaded(true);
      });
  }
  function reloadTimeline() {
    axios
      .get("tickets/" + params.ticketId + "/timeline")
      .then(function (response) {
        if (response.data.data) {
          setTimelineItems(response.data.data);
        }
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
        setIsLoaded(true);
      })
      .then(function () {
        setIsLoaded(true);
      });
  }

  useEffect(() => {
    // Load Data
    reloadTicketData();
  }, []);

  // Send data
  const sendData = (values) =>
    new Promise((resolve) => {
      setSendingData(true);
      const formData = new FormData();
      formData.append("ticket_id", ticket.id);
      formData.append("content", values.content);
      if (values.attachments) {
        values.attachments.forEach((attachment) => {
          formData.append("attachments[]", attachment);
        });
      }

      axios
        .post("ticket-logs", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          setSendingData(false);
          resolve();
          if (response.data.data.id) {
            reloadTicketLogsData();
          }
        })
        .catch(function (error) {
          setSendingData(false);
          resolve();
        });
    });

  // Submit form
  const onSubmit = async (values) => {
    await sendData(values);
  };

  function updatePriority(value) {
    axios
      .post("tickets/" + ticket.id, {
        _method: "put",
        priority: value,
      })
      .then(function (response) {
        toast.success("Ticket saved successfully!");
      })
      .catch(function (error) {
        //
      });
  }

  // Cancel Ticket
  function cancelTicket() {
    axios
      .post("tickets/" + ticket.id, {
        _method: "put",
        status: "cancelled",
      })
      .then(function (response) {
        setCancelModalOpen(false);
        reloadTicketData();
        toast.success("Ticket cancelled!");
      })
      .catch(function (error) {
        setCancelModalOpen(false);
      });
  }

  // Poke
  const [isPoking, setIsPoking] = useState(false);
  function pokeTicket() {
    setIsPoking(true);
    axios
      .post("ticket-logs-poke", {
        ticket_id: ticket.id,
      })
      .then(function (response) {
        setIsPoking(false);
        toast.success("Sent notification to ticket participants");
        reloadTicketData();
      })
      .catch(function (error) {
        setIsPoking(false);
      });
  }

  // Resolve Ticket
  function resolveTicket() {
    axios
      .post("tickets/" + ticket.id, {
        _method: "put",
        status: "resolved",
        rating: rating,
      })
      .then(function (response) {
        setResolveModalOpen(false);
        reloadTicketData();
        toast.success("Ticket resolved!");
      })
      .catch(function (error) {
        setResolveModalOpen(false);
      });
  }

  // Re-open Ticket
  function reopenTicket() {
    let status = "open";
    if (ticket.assigned_to) {
      status = "pending";
    }
    axios
      .post("tickets/" + ticket.id, {
        _method: "put",
        status: status,
        rating: rating,
      })
      .then(function (response) {
        setReopenModalOpen(false);
        reloadTicketData();
      })
      .catch(function (error) {
        setReopenModalOpen(false);
      });
  }

  // Save Rating
  function saveRating() {
    axios
      .post("tickets/" + ticket.id, {
        _method: "put",
        rating: rating,
      })
      .then(function (response) {
        setRateModalOpen(false);
        reloadTicketData();
      })
      .catch(function (error) {
        setRateModalOpen(false);
      });
  }

  function closeAssignModal() {
    setAssignModalOpen(false);
    reloadTicketData();
  }

  // Permission calculators for authorized people
  function canUpdatePriority() {
    return true;
  }
  function canUpdateAssignee() {
    if (user.id === 1 || user.is_group_ceo) {
      return true;
    }
    if (user.id != ticket.requester.id) {
      return true;
    }
    return false;
  }
  function canCreateChildTicket() {
    if (ticket.parent_id) {
      return false;
    }
    return canUpdateAssignee();
  }
  function canMoveTicket() {
    if (ticket.assigned_to) {
      return false;
    }
    return canUpdateAssignee();
  }

  if (isNoPermission) {
    return (
      <div className="fullscreen-loading flex items-center justify-around">
        Error!You have no permission
      </div>
    );
  } else {
    return (
      <>
        <div className="min-h-full bg-gray-100 relative pt-10">
          {!isLoaded ? (
            <div className="bg-white absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center z-50">
              Loading..
            </div>
          ) : (
            ""
          )}
          {/* Page title & actions */}
          <div className="bg-white border-b border-gray-200 px-4 py-4 flex items-center justify-between sm:px-6 lg:px-8 fixed top-14 lg:top-0 right-0 left-0 lg:left-56  lg:fixed lg:top-0 lg:left-56 lg:right-0 lg:z-10 bg-white">
            <div className="flex-1 min-w-0">
              {ticket.parent_id && (
                <h1 className="text-lg font-medium leading-6 text-gray-900 truncate">
                  Child ticket of: {ticket.parent.name}
                </h1>
              )}
            </div>
            <div className="mt-4 flex mt-0 ml-4">
              {ticket.parent_id ? (
                <div>
                  <Link
                    to={"/tickets/" + ticket.parent.id}
                    reloadDocument={true}
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-2"
                  >
                    Go to parent
                  </Link>
                  <Link
                    to="/tickets"
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-2"
                  >
                    Close
                  </Link>
                </div>
              ) : (
                <Link
                  to="/tickets"
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-2"
                >
                  Close
                </Link>
              )}
            </div>
          </div>
          {isLoaded ? (
            <main className="py-10 pt-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                  <div className="flex-shrink-0">
                    <div className="relative">
                      <img
                        className="h-16 w-16 rounded-full"
                        src={ticket.requester.avatar_url}
                        alt=""
                      />
                      <span
                        className="absolute inset-0 shadow-inner rounded-full"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div>
                    <h1 className="text-2xl font-bold text-gray-900">
                      {ticket.requester.name}
                    </h1>
                    <p className="text-sm font-medium text-gray-500">
                      <time dateTime="2020-08-25">{ticket.created_at}</time>
                    </p>
                  </div>
                </div>

                {ticket.status === "pending" || ticket.status === "open" ? (
                  <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    {canUpdateAssignee() && (
                      <button
                        onClick={() => setAssignModalOpen(true)}
                        type="button"
                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
                      >
                        <UserCircleIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        {ticket.assignee ? "Reassign" : "Assign"}
                      </button>
                    )}

                    <AssignTicketModal
                      title="Assign"
                      close={() => closeAssignModal()}
                      open={assignModalOpen}
                      ticket={ticket}
                      assignees={assignees}
                    />

                    {canMoveTicket() && (
                      <button
                        onClick={() => setMoveModalOpen(true)}
                        type="button"
                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
                      >
                        <SelectorIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Move
                      </button>
                    )}

                    <MoveTicketModal
                      title="Move this ticket"
                      close={() => setMoveModalOpen(false)}
                      open={moveModalOpen}
                      ticketId={ticket.id}
                      user={user}
                    />

                    {canCreateChildTicket() && (
                      <Link
                        to={"/tickets/" + ticket.id + "/create"}
                        type="button"
                        className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
                      >
                        <CollectionIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Create child ticket
                      </Link>
                    )}

                    <button
                      onClick={() => pokeTicket()}
                      type="button"
                      className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
                    >
                      <LoadingIcon loading={isPoking} />
                      {!isPoking && (
                        <InformationCircleIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                      )}
                      Poke
                    </button>

                    <button
                      onClick={() => setCancelModalOpen(true)}
                      type="button"
                      className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-0"
                    >
                      <XIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      Cancel Ticket
                    </button>

                    <button
                      onClick={() => setResolveModalOpen(true)}
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-"
                    >
                      <CheckIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      Resolve Ticket
                    </button>

                    <AlertModal
                      title="Cancel Ticket"
                      note="Are you sure you want to cancel this ticket?"
                      confirm={cancelTicket}
                      close={() => setCancelModalOpen(false)}
                      open={cancelModalOpen}
                    />
                    <ResolveTicketModal
                      title="Resolve Ticket"
                      note="Are you sure you want to resolve this ticket?"
                      confirm={resolveTicket}
                      setParentRating={setRating}
                      close={() => setResolveModalOpen(false)}
                      open={resolveModalOpen}
                    />
                  </div>
                ) : (
                  ""
                )}

                {(ticket.status === "cancelled" ||
                  ticket.status === "resolved") && (
                  <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    {(ticket.status === "cancelled" ||
                      ticket.status === "resolved") && (
                      <button
                        onClick={() => setReopenModalOpen(true)}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-"
                      >
                        <RefreshIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Reopen Ticket
                      </button>
                    )}
                    <AlertModal
                      title="Reopen Ticket"
                      note="Are you sure you want to reopen this ticket?"
                      confirm={reopenTicket}
                      close={() => setReopenModalOpen(false)}
                      open={reopenModalOpen}
                    />

                    {ticket.status === "resolved" ? (
                      <>
                        <button
                          onClick={() => setRateModalOpen(true)}
                          type="button"
                          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-0"
                        >
                          <StarIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                          />
                          Rate
                        </button>

                        <RateModal
                          title="Rate your experiene"
                          confirm={saveRating}
                          parentRating={rating}
                          setParentRating={setRating}
                          close={() => setRateModalOpen(false)}
                          open={rateModalOpen}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>

              <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                  {/* Description list*/}
                  <section aria-labelledby="applicant-information-title">
                    <div className="bg-white shadow sm:rounded-lg">
                      <div className="px-4 py-5 sm:px-6">
                        <h2
                          id="applicant-information-title"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          {ticket.hid}: {ticket.name}
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          <span className="text-gray-900">
                            {ticket.category.name}
                          </span>{" "}
                          in{" "}
                          <span className="text-gray-900">
                            {ticket.department.name}{" "}
                            {user.managed_companies.length > 1 && (
                              <span>({ticket.company.name})</span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                          {assignees.length ? (
                            <div className="sm:col-span-3 relative">
                              <div className="flex justify-between items-end">
                                <div>
                                  <label className="block text-sm font-medium text-gray-700">
                                    Status
                                  </label>
                                  <div className="h-9 mt-1 pt-2 relative rounded-md shadow-sm text-sm text-gray-900">
                                    <span
                                      className={classNames(
                                        statusColors[ticket.status],
                                        "text-white uppercase inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                                      )}
                                    >
                                      {statusLabels[ticket.status]}
                                    </span>
                                  </div>
                                </div>

                                {canUpdatePriority() && (
                                  <PrioritySelectField
                                    label="Priority"
                                    input={{
                                      value: ticket.priority,
                                      onChange: (value) =>
                                        updatePriority(value),
                                    }}
                                    meta={{ error: false, touched: false }}
                                  />
                                )}

                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Assigned To
                                  </dt>
                                  <dd className="mt-1 py-2 text-sm text-gray-900">
                                    <div className="">
                                      {ticket.assignee ? (
                                        <div className="flex items-center mb-1">
                                          <div className="flex flex-shrink-0 mr-1">
                                            <img
                                              key={
                                                "requester_" +
                                                ticket.assignee.id
                                              }
                                              className="max-w-none h-5 w-5 rounded-full ring-2 ring-white"
                                              src={ticket.assignee.avatar_url}
                                              alt={ticket.assignee.name}
                                            />
                                          </div>
                                          <span className="flex-shrink-0 text-xs leading-5 font-medium">
                                            {ticket.assignee.name}
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="flex items-center mb-1">
                                          <div className="flex flex-shrink-0 mr-1"></div>
                                          <span className="flex-shrink-0 text-xs leading-5 font-medium">
                                            Unassigned
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </dd>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </dl>

                        {/* <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Priority</dt>
                                            <dd className="mt-1 text-sm text-gray-900"><PriorityBadge priority={ticket.priority} /></dd>
                                        </div>
                                        
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Status</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{ticket.status}</dd>
                                        </div>

                                        { ticket.assignee ? ( <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Assigned To</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                <div className="">
                                                    <div className="flex items-center mb-1">
                                                        <div className="flex flex-shrink-0 mr-1">
                                                            <img
                                                                key={'requester_'+ticket.assignee.id}
                                                                className="max-w-none h-5 w-5 rounded-full ring-2 ring-white"
                                                                src={ticket.assignee.avatar_url}
                                                                alt={ticket.assignee.name}
                                                            />
                                                        </div>
                                                        <span className="flex-shrink-0 text-xs leading-5 font-medium">
                                                            {ticket.assignee.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </dd>
                                        </div> ) : ''}

                                    </dl> */}

                        <dl className="mt-10 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                          {ticket.ticket_rate && (
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Rated by requester
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                <RatingStars stars={ticket.ticket_rate} />{" "}
                              </dd>
                            </div>
                          )}

                          {ticket.requester_rate && (
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Rated by manager
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                <RatingStars stars={ticket.requester_rate} />{" "}
                              </dd>
                            </div>
                          )}

                          <div className="sm:col-span-3">
                            <dt className="text-sm font-medium text-gray-500">
                              Content
                            </dt>
                            <dd
                              className="mt-1 text-sm text-gray-900"
                              dangerouslySetInnerHTML={{
                                __html: ticket.content,
                              }}
                            ></dd>
                          </div>

                          {ticket.additional_fields.map((additionalField) => (
                            <div
                              key={additionalField.label}
                              className="sm:sm:col-span-3"
                            >
                              <dt className="text-sm font-medium text-gray-500">
                                {additionalField.label}
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {additionalField.value}
                              </dd>
                            </div>
                          ))}

                          {ticket.attachments.length ? (
                            <div className="sm:col-span-3">
                              <dt className="text-sm font-medium text-gray-500">
                                Attachments
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                <ul
                                  role="list"
                                  className="border border-gray-200 rounded-md divide-y divide-gray-200"
                                >
                                  {ticket.attachments.map((attachment) => (
                                    <li
                                      key={attachment.file_name}
                                      className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                                    >
                                      <div className="w-0 flex-1 flex items-center">
                                        <PaperClipIcon
                                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                        />
                                        <span className="ml-2 flex-1 w-0 truncate">
                                          {attachment.file_name}
                                        </span>
                                      </div>
                                      <div className="ml-4 flex-shrink-0">
                                        <a
                                          href={attachment.url}
                                          target="_blank"
                                          rel="noreferrer"
                                          className="font-medium text-blue-600 hover:text-blue-500"
                                        >
                                          Download
                                        </a>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </dd>
                            </div>
                          ) : (
                            ""
                          )}
                        </dl>
                      </div>
                    </div>
                  </section>

                  {/* Comments*/}
                  <section aria-labelledby="notes-title">
                    <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                      <div className="divide-y divide-gray-200">
                        <div className="px-4 py-5 sm:px-6">
                          <h2
                            id="notes-title"
                            className="text-lg font-medium text-gray-900"
                          >
                            Comments
                          </h2>
                        </div>

                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start space-x-4">
                            <div className="flex-shrink-0">
                              <img
                                className="inline-block h-10 w-10 rounded-full"
                                src={user.avatar_url}
                                alt=""
                              />
                            </div>
                            <div className="min-w-0 flex-1">
                              <Form
                                onSubmit={onSubmit}
                                render={({
                                  handleSubmit,
                                  form,
                                  submitting,
                                  pristine,
                                  values,
                                }) => (
                                  <form
                                    onSubmit={async (event) => {
                                      handleSubmit(event);
                                      form.reset();
                                    }}
                                    encType="multipart/form-data"
                                  >
                                    <div className="relative">
                                      <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                                        <label
                                          htmlFor="comment"
                                          className="sr-only"
                                        >
                                          Add your comment
                                        </label>

                                        <Field
                                          name="content"
                                          validate={required}
                                        >
                                          {({ input, meta }) => (
                                            <div>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                <TextareaAutosize
                                                  placeholder="Add your comment..."
                                                  minRows={6}
                                                  className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
                                                  cacheMeasurements
                                                  {...input}
                                                />
                                                {/* <textarea
                                                                                {...input}
                                                                                placeholder="Add your comment..."
                                                                                rows={6}
                                                                                className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
                                                                            /> */}
                                              </div>
                                              {/* <div className="px-4">{meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}</div> */}
                                            </div>
                                          )}
                                        </Field>

                                        {/* Spacer element to match the height of the toolbar */}
                                        <div
                                          className="py-2"
                                          aria-hidden="true"
                                        >
                                          {/* Matches height of button in toolbar (1px border + 36px content height) */}
                                          <div className="py-px">
                                            <div className="h-9" />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
                                        <div className="flex items-center space-x-5">
                                          <div className="flex items-center">
                                            {/* <button
                                                                    type="submit"
                                                                    className="-m-2.5 w-10 h-10 rounded-full flex items-center justify-center text-gray-400 hover:text-gray-500"
                                                                    >
                                                                    <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                                                                    <span className="sr-only">Attach a file</span>
                                                                    </button> */}
                                          </div>
                                        </div>
                                        <div className="flex-shrink-0">
                                          <button
                                            disabled={
                                              sendingData ||
                                              pristine ||
                                              !values.content
                                            }
                                            type="submit"
                                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
                                          >
                                            Post
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="bg-white p-4 mt-5 border border-gray-300 rounded-lg">
                                      <Field name="attachments">
                                        {({ input, meta }) => (
                                          <DropzoneField
                                            {...input}
                                            label=""
                                            meta={meta}
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  </form>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="px-4 py-6 sm:px-6">
                        <ul role="list" className="space-y-8">
                          {ticketLogs.map((ticketLog) => (
                            <li key={ticketLog.id}>
                              <div className="flex space-x-3">
                                <div className="flex-shrink-0">
                                  <img
                                    className="h-10 w-10 rounded-full"
                                    src={ticketLog.creator.avatar_url}
                                    alt=""
                                  />
                                </div>
                                <div className="w-full">
                                  <div className="text-sm">
                                    <span className="font-medium text-gray-900">
                                      {ticketLog.creator.name}

                                      {ticketLog.creator.id ===
                                        ticket.requester.id && (
                                        <span
                                          key={ticketLog.id}
                                          className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                                        >
                                          Requester
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                  <div
                                    className="mt-1 text-sm text-gray-700"
                                    dangerouslySetInnerHTML={{
                                      __html: ticketLog.content,
                                    }}
                                  ></div>
                                  <div className="mt-2 text-sm space-x-2">
                                    <span className="text-gray-500 font-medium">
                                      {ticketLog.created_at}
                                    </span>{" "}
                                  </div>
                                  {ticketLog.attachments.length ? (
                                    <div className="mt-2 text-sm">
                                      <dd className="mt-1 text-sm text-gray-900">
                                        <ul
                                          role="list"
                                          className="border border-gray-200 rounded-md divide-y divide-gray-200"
                                        >
                                          {ticketLog.attachments.map(
                                            (attachment) => (
                                              <li
                                                key={attachment.file_name}
                                                className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                                              >
                                                <div className="w-0 flex-1 flex items-center">
                                                  <PaperClipIcon
                                                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                  />
                                                  <span className="ml-2 flex-1 w-0 truncate">
                                                    {attachment.file_name}
                                                  </span>
                                                </div>
                                                <div className="ml-4 flex-shrink-0">
                                                  <a
                                                    href={attachment.url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="font-medium text-blue-600 hover:text-blue-500"
                                                  >
                                                    Download
                                                  </a>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </dd>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>

                <section
                  aria-labelledby="timeline-title"
                  className="lg:col-start-3 lg:col-span-1"
                >
                  <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                    <h2
                      id="timeline-title"
                      className="text-lg font-medium text-gray-900"
                    >
                      Activities
                    </h2>

                    {/* Activity Feed */}
                    <div className="mt-6 flow-root">
                      <ul role="list" className="-mb-8">
                        {timelineItems.map((item, itemIdx) => (
                          <li key={item.id}>
                            <div className="relative pb-8">
                              {itemIdx !== timelineItems.length - 1 ? (
                                <span
                                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                  aria-hidden="true"
                                />
                              ) : null}
                              <div className="relative flex space-x-3">
                                <div>
                                  {item.type === "timeline" ? (
                                    <span className="bg-gray-400 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
                                      <AdjustmentsIcon
                                        className="w-5 h-5 text-white"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.type === "timeline_reminder" ? (
                                    <span className="bg-indigo-400 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
                                      <BellIcon
                                        className="w-5 h-5 text-white"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.type === "timeline_childticket" ||
                                  item.type ===
                                    "timeline_hidden_childticket" ? (
                                    <span className="bg-gray-400 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
                                      <CollectionIcon
                                        className="w-5 h-5 text-white"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.type === "timeline_assigned" ? (
                                    <span className="bg-gray-400 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
                                      <UserIcon
                                        className="w-5 h-5 text-white"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.type === "timeline_resolved" ? (
                                    <span className="bg-green-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
                                      <CheckIcon
                                        className="w-5 h-5 text-white"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.type === "timeline_cancelled" ? (
                                    <span className="bg-red-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
                                      <XIcon
                                        className="w-5 h-5 text-white"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="min-w-0 flex-1 flex justify-between space-x-4">
                                  <div>
                                    <p className="text-sm text-gray-900">
                                      {item.type === "timeline_childticket" ||
                                      item.type ===
                                        "timeline_hidden_childticket" ? (
                                        <span>
                                          Created a child ticket (
                                          <Link
                                            to={"/tickets/" + item.content}
                                            reloadDocument={true}
                                          >
                                            Click to view
                                          </Link>
                                          )
                                        </span>
                                      ) : (
                                        item.content + " "
                                      )}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                      <time dateTime={item.created_at}>
                                        {item.created_at}{" "}
                                        <span className="text-gray-400">
                                          by
                                        </span>{" "}
                                        {item.creator.name}
                                      </time>
                                    </p>
                                  </div>
                                  <div className="text-right text-sm whitespace-nowrap text-gray-500"></div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}

                        <li>
                          <div className="relative pb-8">
                            <div className="relative flex space-x-3">
                              <div>
                                <span className="bg-gray-400 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
                                  <UserIcon
                                    className="w-5 h-5 text-white"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                              <div className="min-w-0 flex-1 flex justify-between space-x-4">
                                <div>
                                  <p className="text-sm text-gray-900">
                                    Ticket created
                                  </p>
                                  <p className="text-sm text-gray-500">
                                    <time dateTime={ticket.created_at}>
                                      {ticket.created_at}{" "}
                                      <span className="text-gray-400">by</span>{" "}
                                      {ticket.requester.name}
                                    </time>
                                  </p>
                                </div>
                                <div className="text-right text-sm whitespace-nowrap text-gray-500"></div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </div>
            </main>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}
