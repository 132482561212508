import { useState, useEffect, useMemo, useRef } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import Leaflet from 'leaflet';
import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export const officeIcon = new Leaflet.Icon({
  iconUrl: '/grandizer_marker.png',
  iconSize: [50, 50], // size of the icon
});

export default function Map(props) {
    const { lat, lng, officeLat, officeLng, userAvatarUrl } = props
    const markerRef = useRef(null)
    const position = {
      lat: lat,
      lng: lng,
    }
    const officePosition = {
      lat: officeLat,
      lng: officeLng,
    }

    function userIcon() {
      return new Leaflet.Icon({
        iconUrl: userAvatarUrl,
        iconSize: [30, 30], // size of the icon
        className: 'rounded-full border-2 border-white shadow-lg',
      });
    }

    function LocationMarker() {
        return position === null ? null : (
          <Marker 
            position={position}
            draggable={false}
            ref={markerRef}
            icon={userIcon()}
        >
            <Popup>Employee Location: <a href={'https://maps.google.com/?q='+lat+','+lng} target="_blank" rel="nofollow">View on Google Map</a></Popup>
          </Marker>
        )
    }

    function OfficeMarker() {
      return (!officeLat || !officeLng) ? null : (
        <Marker 
          icon={officeIcon}
          position={officePosition}
          draggable={false}
          ref={markerRef}
      >
          <Popup>Office Location: <a href={'https://maps.google.com/?q='+officeLat+','+officeLng} target="_blank" rel="nofollow">View on Google Map</a></Popup>
        </Marker>
      )
  }

    return (
      <div className="relative h-[250px]">
        <MapContainer
            style={{ height: '100%'}}
            center={ { lat: lat, lng: lng } }
            zoom={10}
            scrollWheelZoom={false}>
            <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker />
            <OfficeMarker />
        </MapContainer>
      </div> 
    )
}