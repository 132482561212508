const validateAllFields = (values) => {
    let errors = {};
    if (!values?.name) {
        errors.name = "Name is Required";
    } else if (
        !/^(?!.*\d)(?! )(?!.* $)[^!@#$%^&*()+=~`\\[\]{}|:;"'<>,.?/_-]{1,45}$/.test(
            values?.name
        )
    ) {
        errors.name =
            "Name: Maximum 45 chars, no leading spaces, no special characters.";
    }

    const emailRegex =
        /^(?=.{1,254}$)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,20}$/;

    if (!values.email) {
        // errors.email = "Corporate's Email is Required";
    } else if (!emailRegex.test(values.email)) {
        errors.email = "Provide valid Email!";
    } else if (values.email.length > 50) {
        errors.email = "Provided email is too long";
    }
    if (values.vat_number?.length > 45) {
        errors.vat_number = "Maximum 45 numbers are allowed";
    }
    if (values.cr_number?.length > 45) {
        errors.cr_number = "Maximum 45 numbers are allowed";
    }

    if (!values.companies) {
        errors.companies = "Company  is Required";
    }
    if (!values.catagories) {
        errors.catagories = "Category  is Required";
    }


    // if (values?.emergency_contact_name === undefined) {
    //     errors.emergency_contact_name = undefined;
    // } else if (
    //     !/^(?! )(?!.* $)[^!@#$%^&*()+=~`\\[\]{}|:;"'<>,.?/_-]{1,45}$/.test(
    //         values?.emergency_contact_name
    //     )
    // ) {
    //     errors.emergency_contact_name =
    //         "Emergency Name: Maximum 45 chars, no leading spaces, no special characters.";
    // }
    // const regPhone = /^(?=.*\d)[\d+\-()]+(?:[ ]*[\d+\-()]+)*$/;
    // if (!values?.emergency_contact_mobile_number) {
    //     errors.emergency_contact_mobile_number = undefined;
    // } else if (!regPhone.test(values?.emergency_contact_mobile_number)) {
    //     errors.emergency_contact_mobile_number =
    //         "Please enter a valid Mobile Number";
    // }



    return errors;
};

export { validateAllFields };





