import { ExclamationCircleIcon } from "@heroicons/react/solid";

export default function TextFieldSalery(props) {
  const {
    salery,
    label,
    input,
    meta,
    placeholder = null,
    col = 3,
    required = false,
  } = props;

  return (
    <div className={"col-span-12 sm:col-span-" + col}>
      <label
        htmlFor={input.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label}{" "}
        {label === "# Salary Details" && (
          <span className="text-red-400">*</span>
        )}
      </label>
      <div className="mt-3 relative  flex">
        <p className="w-[30%] md:w-[20%] shadow-none items-center">{salery}</p>
        <input
          {...input}
          type="number"
          placeholder={placeholder}
          className={
            meta.error
              ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
              : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
          }
        />
        {meta.error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {meta.error && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
    </div>
  );
}
