import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";

export default function TextFieldSaleryTotal(props) {
  const {
    salery,
    label,
    input,
    meta,
    placeholder,
    col = 3,
    required = false,
    values,
  } = props;

  useEffect(()=>{
    input.onChange(parseInt(values?.basic_salary) + parseInt(values?.housing_salary) + parseInt(values?.transpiration_salary));
  },[values?.basic_salary , values?.housing_salary , values?.transpiration_salary])
  
  

  return (
    <div className={"col-span-12 sm:col-span-" + col}>
      <label
        htmlFor={input.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label} 
      </label>
      <div className="mt-3 relative  flex">
        <p className="w-[30%] md:w-[20.5%] shadow-none items-center">{salery}</p>
        <input
          {...input}
          value={parseInt(values?.basic_salary) + parseInt(values?.housing_salary) + parseInt(values?.transpiration_salary) ? parseInt(values?.basic_salary) + parseInt(values?.housing_salary) + parseInt(values?.transpiration_salary)  : ""}
          type="text"
          placeholder={placeholder}
          style={{userSelect: "none"}}
          className={
            meta.error && meta.touched
              ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
              : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
          }
          disabled
        />
        <p 
        // className="items-center border-t-2 border-r-2 border-b-2 border-black p-1"
        className={
            meta.error && meta.touched
              ? "pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded-md items-center border-t-2 border-r-2 border-b-2 p-1"
              : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md placeholder-gray-300 items-center border-t-2 border-r-2 border-b-2 p-1"
        }
        >SAR</p>
        {meta.error && meta.touched && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {meta.error && meta.touched && (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      )}
    </div>
  );
}
