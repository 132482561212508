import React, {useEffect} from 'react';
import classNames from 'classnames';

import {Handle, Remove} from './components';

import styles from './Item.module.css';

export const Item = React.memo(
  React.forwardRef(
    (
      {
        item,
        showModel,
        isShowCompany = false,
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
        })
      ) : (
        <li
          className={classNames(
            styles.Wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay, 
            'shadow-sm shadow-gray-300',
            'kanban-item',
          )}
          style={
            {
              ...wrapperStyle,
              transition: [transition, wrapperStyle?.transition]
                .filter(Boolean)
                .join(', '),
              '--translate-x': transform
                ? `${Math.round(transform.x)}px`
                : undefined,
              '--translate-y': transform
                ? `${Math.round(transform.y)}px`
                : undefined,
              '--scale-x': transform?.scaleX
                ? `${transform.scaleX}`
                : undefined,
              '--scale-y': transform?.scaleY
                ? `${transform.scaleY}`
                : undefined,
              '--index': index,
              '--color': color,
            }
          }
          ref={ref}
        >
          <div
            className={classNames(
              styles.Item,
              dragging && styles.dragging,
              handle && styles.withHandle,
              dragOverlay && styles.dragOverlay,
              disabled && styles.disabled,
              'bg-white shadow-sm',
                dragOverlay && "shadow-sm shadow-gray-600",
            )}
            style={style}
            data-cypress="draggable-item"
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            <div className="flex items-center space-x-3 mr-4">
            <div className="flex-shrink-0">
              <button onClick={showModel} className="h-10 w-10 rounded-full overflow-hidden"><img className="w-10" src={item?.photo_url} alt="" /></button>
            </div>
            {/* <div className="flex-1 min-w-0"> */}
              {/* <a href="#" onClick={showModel} className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900">#{item?.id}: {item?.full_name}</p>
                <p className="text-sm text-gray-500">{item?.job?.title}</p>
                {isShowCompany && <p className="text-sm text-gray-500">{item?.company?.company_name}</p>}
                <p className="text-sm text-gray-500">{item?.created_at}</p>
              </a> */}
            {/* </div> */}
            </div>
            <div>
                <button onClick={showModel} className="block text-left text-sm font-medium text-gray-900">#{item?.id}: {item?.full_name}</button>
                <p className="text-sm text-gray-500">{item?.job?.title}</p>
                {isShowCompany && <p className="text-sm text-gray-500">{item?.company?.company_name}</p>}
                <p className="text-sm text-gray-500">{item?.created_at}</p>
            </div>
            <span className={styles.Actions}>
              {onRemove ? (
                <Remove className={styles.Remove} onClick={onRemove} />
              ) : null}
              {handle ? <Handle {...handleProps} {...listeners} /> : null}
            </span>
          </div>
        </li>
      );
    }
  )
);
