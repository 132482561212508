import { Fragment } from "react";
import { Link } from "react-router-dom";
import * as SolidIcons from "@heroicons/react/solid";
import * as OutlineIcons from "@heroicons/react/outline";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";

// Dynamic Icons
export const HeroIcon = (props) => {
  const { icon, className = "w-6 h-6 text-gray-600", outline = true } = props;

  const Icon = outline ? OutlineIcons[icon] : SolidIcons[icon];

  return <Icon className={className} />;
};

// Helpers
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const grandizerV1Url = process.env.REACT_APP_V1_URL;
const quickLinks = [
  {
    name: "Knowledge Base",
    href: grandizerV1Url + "/knowledge-base",
    bgColorClass: "bg-indigo-500",
    version: 1,
  },
  {
    name: "General Calculators",
    href: "/calculators",
    bgColorClass: "bg-indigo-500",
    version: 2,
  },
];

export default function SideNav(props) {
  const { user, navItems, badgeCountDashboard, badgeCountNotification } = props;

  console.log(props, "ooo");
  const location = window.location.pathname;
  const FRONTEND_APP_URL = process.env.REACT_APP_URL;

  function isMenuOpen(item) {
    let isOpen = false;

    item.children.forEach((child) => {
      if (child.v2_path) {
        const pathLength = child.v2_path.length;
        const currentPath = location.substring(0, pathLength);
        if (child.v2_path === currentPath) {
          isOpen = true;
        }
      }
    });

    return isOpen;
  }

  function isSubMenuActive(subItem) {
    let isActive = false;

    if (subItem.v2_path) {
      const pathLength = subItem.v2_path.length;
      const currentPath = location.substring(0, pathLength);
      if (subItem.v2_path === currentPath) {
        isActive = true;
      }
    }

    return isActive;
  }

  return (
    <div className="hidden lg:flex lg:flex-col lg:w-56 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pt-5 lg:bg-gray-800">
      {/* Static sidebar for desktop */}
      <div className="flex items-center flex-shrink-0 px-6 relative">
        <a href={grandizerV1Url}>
          <img className="w-36" src={user.company_logo_url} alt="Workflow" />
        </a>
        <span className="absolute right-3 -top-1 text-white text-sm">BETA</span>
      </div>
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="mt-6 h-0 flex-1 flex flex-col overflow-y-auto">
        {/* User account dropdown */}
        {/* <Menu as="div" className="px-3 relative inline-block text-left">
                <div>
                <Menu.Button className="group w-full bg-gray-700 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-0">
                    <span className="flex w-full justify-between items-center">
                    <span className="flex min-w-0 items-center justify-between space-x-3">
                        <img
                        className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                        src={user.avatar_url}
                        alt=""
                        />
                        <span className="flex-1 flex flex-col min-w-0">
                        <span className="text-gray-100 text-sm font-medium truncate">{ user.name }</span>
                        <span className="text-gray-300 text-sm truncate">CEO</span>
                        </span>
                    </span>
                    <SelectorIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-100 group-hover:text-gray-300"
                        aria-hidden="true"
                    />
                    </span>
                </Menu.Button>
                </div>
                <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                >
                <Menu.Items className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                    <div className="px-4 py-3">
                    <p className="text-sm">Signed in as</p>
                    <p className="text-sm font-medium text-gray-900 truncate">{user.name}</p>
                    </div>
                    <div className="py-1">
                    {user.dropdown_menu_items ? user.dropdown_menu_items.map((dropdownMenuItem) => (
                        <Menu.Item key={dropdownMenuItem.title}>
                            <a
                            href={dropdownMenuItem.url}
                            className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
                            >
                            {dropdownMenuItem.title}
                            </a>
                        </Menu.Item>
                    )) : ''}
                    </div>
                </Menu.Items>
                </Transition>
            </Menu> */}
        {/* Sidebar Search */}
        {/* <div className="px-3 mt-5">
                <label htmlFor="search" className="sr-only">
                Search
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                <div
                    className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                    aria-hidden="true"
                >
                    <SearchIcon className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
                </div>
                <input
                    type="text"
                    name="search"
                    id="search"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-9 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Search"
                />
                </div>
            </div> */}
        {/* Navigation */}
        <div className="h-full mb-6 overflow-y-scroll ml-1 grandizer-scrollbar">
          <nav className="px-3">
            <div className="space-y-1">
              {navItems
                ? navItems.map((item) =>
                    !item.children ? (
                      <div key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:text-white hover:bg-gray-800",
                            "group w-full flex items-center relative py-2 text-sm font-medium rounded-md relative"
                          )}
                        >
                          <HeroIcon
                            icon={item.icon}
                            className={classNames(
                              item.current
                                ? "text-white"
                                : "text-gray-300 group-hover:text-white",
                              "mr-2 flex-shrink-0 h-6 w-6"
                            )}
                          />
                          <span className="truncate">{item.name}</span>
                          {item.beta ? (
                            <span className="beta-badge">BETA</span>
                          ) : (
                            ""
                          )}
                          {item.key === "dashboard" ? (
                            <span className="bg-red-500 text-white ml-auto mr-2 inline-block py-0.5 px-3 text-xs rounded-full">
                              {badgeCountDashboard}
                            </span>
                          ) : (
                            ""
                          )}
                        </a>
                      </div>
                    ) : (
                      <Disclosure
                        as="div"
                        key={item.name}
                        className="space-y-1"
                        defaultOpen={isMenuOpen(item)}
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                item.current
                                  ? "bg-gray-900 text-white"
                                  : "text-gray-300 hover:text-white hover:bg-gray-800",
                                "group w-full flex items-center relative py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-0"
                              )}
                            >
                              <HeroIcon
                                icon={item.icon}
                                className={classNames(
                                  item.current
                                    ? "text-white"
                                    : "text-gray-300 group-hover:text-white",
                                  "mr-2 flex-shrink-0 h-6 w-6"
                                )}
                              />
                              <span className="flex-1">{item.name}</span>
                              {item.beta ? (
                                <span className="beta-badge">BETA</span>
                              ) : (
                                ""
                              )}
                              <svg
                                className={classNames(
                                  open
                                    ? "text-white rotate-90"
                                    : "text-gray-300",
                                  "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-white transition-colors ease-in-out duration-150"
                                )}
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  d="M6 6L14 10L6 14V6Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="space-y-1 bg-gray-700 rounded-md">
                              {item.children.map((subItem) =>
                                subItem.menu_type === "v2" ? (
                                  <Link
                                    key={subItem.key}
                                    to={subItem.v2_path}
                                    reloadDocument={true}
                                    className={classNames(
                                      isSubMenuActive(subItem)
                                        ? "text-gray-900 bg-gray-50"
                                        : "text-white",
                                      "group w-full flex items-center relative pl-3 pr-2 py-2 text-sm font-medium rounded-md hover:text-gray-900 hover:bg-gray-50"
                                    )}
                                  >
                                    <HeroIcon
                                      icon={subItem.icon}
                                      className={classNames(
                                        isSubMenuActive(subItem)
                                          ? "text-gray-500"
                                          : "text-gray-300 group-hover:text-gray-500",
                                        "mr-2 flex-shrink-0 h-4 w-4"
                                      )}
                                    />
                                    <span className="truncate text-xs relative">
                                      {subItem.name}
                                    </span>
                                    {subItem.beta ? (
                                      <span className="beta-badge beta-badge-child">
                                        BETA
                                      </span>
                                    ) : (
                                      ""
                                    )}

                                    {subItem.key === "notifications" ? (
                                      <span className="bg-red-500 text-white ml-auto mr-2 inline-block py-0.5 px-3 text-xs rounded-full">
                                        {badgeCountNotification}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Link>
                                ) : (
                                  <Disclosure.Button
                                    key={subItem.name}
                                    as="a"
                                    href={subItem.href}
                                    className="group w-full flex items-center relative pl-3 pr-2 py-2 text-sm font-medium text-white rounded-md hover:text-gray-900 hover:bg-gray-50"
                                  >
                                    <HeroIcon
                                      icon={subItem.icon}
                                      className={classNames(
                                        item.current
                                          ? "text-white"
                                          : "text-gray-300 group-hover:text-gray-500",
                                        "mr-2 flex-shrink-0 h-4 w-4"
                                      )}
                                    />
                                    <span className="truncate text-xs relative">
                                      {subItem.name}
                                    </span>
                                    {subItem.beta ? (
                                      <span className="beta-badge beta-badge-child">
                                        BETA
                                      </span>
                                    ) : (
                                      ""
                                    )}

                                    {subItem.key === "notifications" ? (
                                      <span className="bg-red-500 text-white ml-auto mr-2 inline-block py-0.5 px-3 text-xs rounded-full">
                                        {badgeCountNotification}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Disclosure.Button>
                                )
                              )}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    )
                  )
                : ""}
            </div>

            <div className="mt-8">
              {/* Secondary navigation */}
              <h3
                className="px-1 text-xs font-semibold text-white uppercase tracking-wider"
                id="desktop-teams-headline"
              >
                Quick Links
              </h3>
              <div
                className="mt-1 space-y-1"
                role="group"
                aria-labelledby="desktop-teams-headline"
              >
                {quickLinks.map((quickLink) =>
                  quickLink.version === 1 ? (
                    <a
                      title={quickLink.name}
                      key={quickLink.name}
                      href={FRONTEND_APP_URL + quickLink.v2_path}
                      className="group flex items-center px-1 py-2 text-sm font-medium text-white rounded-md hover:text-gray-900 hover:bg-gray-50"
                    >
                      <span
                        className={classNames(
                          quickLink.bgColorClass,
                          "w-[10px] h-[10px] mr-4 rounded-full"
                        )}
                        aria-hidden="true"
                      />
                      <span className="">{quickLink.name}</span>
                    </a>
                  ) : (
                    <Link
                      title={quickLink.name}
                      key={quickLink.name}
                      to={FRONTEND_APP_URL + quickLink.v2_path}
                      className="group flex items-center px-1 py-2 text-sm font-medium text-white rounded-md hover:text-gray-900 hover:bg-gray-50"
                    >
                      <span
                        className={classNames(
                          quickLink.bgColorClass,
                          "w-[10px] h-[10px] mr-4 rounded-full block"
                        )}
                        aria-hidden="true"
                      />
                      <span className="">{quickLink.name}</span>
                    </Link>
                  )
                )}
              </div>
            </div>
          </nav>
        </div>
        <div className="flex-shrink-0 flex bg-gray-700">
          <div className="w-full">
            <Menu as="div" className="px-1 w-full relative text-left">
              <div>
                <Menu.Button className="group w-full bg-gray-700 rounded-md px-3 py-3 text-sm text-left font-medium text-gray-700 focus:outline-none focus:ring-0">
                  <span className="flex w-full justify-between items-center">
                    <span className="flex min-w-0 items-center justify-between space-x-3">
                      <img
                        className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                        src={user.avatar_url}
                        alt=""
                      />
                      <span className="flex-1 flex flex-col min-w-0">
                        <span className="text-sm font-medium text-white truncate">
                          {user.name}
                        </span>
                        <span className="text-xs font-medium text-gray-300 truncate"></span>
                      </span>
                    </span>
                    <SelectorIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-100 group-hover:text-gray-300"
                      aria-hidden="true"
                    />
                  </span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-10 mx-3 origin-top absolute right-0 left-0 bottom-14 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                  <div className="py-1">
                    {user.dropdown_menu_items
                      ? user.dropdown_menu_items.map((dropdownMenuItem) => (
                          <Menu.Item key={dropdownMenuItem.title}>
                            <a
                              href={dropdownMenuItem.url}
                              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
                            >
                              {dropdownMenuItem.title}
                            </a>
                          </Menu.Item>
                        ))
                      : ""}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}
