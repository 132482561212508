import { useState, useEffect } from 'react'
import Select from 'react-select'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import axios from 'axios';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CompanyMultiselectField(props) {
    const { label, input, meta, col = 3, required = false } = props
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([])
    const updatedSelectedCompanies = (companies) => { 
        if(companies.length) {
            return companies.map((company) => ({ label: company.name, value: company.id, ...company }));
        }
        return [];
    }
    const [selectedCompanies, setSelectedCompanies] = useState(updatedSelectedCompanies(input.value))

    useEffect(() => {
        // Get user detail
        axios.get('/companies-multiselect')
            .then(function (response) {
                //console.log(response.data)
                setItems(response.data.data)
                setIsLoaded(true);
            })
            .catch(function (error) {
            // handle error
            //setIsLoaded(true);
            //setError(error);
            })
            .then(function () {
            //setIsLoaded(true);
            });    
    }, [])


    function handleChange(value)
    {
        input.onChange(value)
    }

    if(isLoaded) {
        return (
            <div className={'sm:col-span-'+col}>
                <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                    {label} {required && <span className="text-red-500">*</span>}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <Select
                        classNamePrefix="react-select"
                        defaultValue={selectedCompanies} 
                        isMulti
                        options={items} 
                        className="basic-multi-select"
                        onChange={handleChange}
                    />
                    {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>}
                </div>
                {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
            </div>
        )
    }
}
