import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Form, Field } from 'react-final-form'
import createDecorator from 'final-form-calculate'

// Components
import TextField from '../../components/fields/TextField';
import AlertModal from '../../components/AlertModal';
import DepartmentSelectField from '../../components/fields/DepartmentSelectField';
import SuccessNotification from '../../components/SuccessNotification';
import RoleMultiselectField from 'components/fields/RoleMultiselectField';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

// Form Multiple Validators
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
const required = value => (value ? undefined : 'Required')

export default function EditCrud(props) {
  //const { user } = props
  let params = useParams()
  let navigate = useNavigate();
  const [isNoPermission, setIsNoPermission] = useState(false)
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [sendingData, setSendingData] = useState(false)
  const [calculator, setCalculator] = useState({})
  const [savedNotificationShow, setSavedNotificationShow] = useState(false)

  // Set page title
  useEffect(() => {
    document.title = `General Calculators`;
  }, [])

  function reloadData() {
    axios.get('calculators/'+params.calculatorId+'/edit')
    .then(function (response) {
        // handle success
        //console.log(response.data.data);
        if(response.data.status === 'no_permission') {
            setIsNoPermission(true)
        } else {
            if(response.data.data) {
                document.title = 'Update: '+response.data.data.name;
                setCalculator(response.data.data)
                //console.log(response.data.data)
            } 
        }
        setIsLoaded(true);
    })
    .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
        setIsLoaded(true);
    })
    .then(function () {
        setIsLoaded(true);
    });
  }
  
  useEffect(() => {
    reloadData()
  }, [])

  function closeForm() {
    setAlertModalOpen(false)
    navigate("/calculators");
  }

  // Send data
  const sendData = values => new Promise(resolve => {
    setSendingData(true)
    const formData = new FormData();
    formData.append('_method', 'put');
    formData.append('name', values.name);
    formData.append('permissions', JSON.stringify(values.permissions));
    formData.append('roles', JSON.stringify(values.roles));

    axios.post('calculators/'+params.calculatorId, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    .then(function (response) {
        setSendingData(false)
        resolve()
      })
      .catch(function (error) {
        setSendingData(false)
        resolve()
      });
  })

  // Submit form
  const onSubmit = async values => {
    await sendData(values)
    setSavedNotificationShow(true)
    setTimeout(function() {
      setSavedNotificationShow(false)
    }, 3000)
  }

  if(!isLoaded) {
    return <div className="fullscreen-loading flex items-center justify-around">Loading..</div>
  } else {
    if(isNoPermission) {
      return <div className="fullscreen-loading flex items-center justify-around">404 Not Found</div>
    } else {
      return (
        <div className="h-full">
          <Form
            keepDirtyOnReinitialize
            onSubmit={onSubmit}
            initialValues={{ ...calculator, permissions: {companies: calculator.companies, departments: calculator.departments } }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                {/* Page title & actions */}
                <div className="bg-white border-b border-gray-200 px-4 py-4 flex items-center justify-between sm:px-6 lg:px-8 fixed top-14 lg:top-0 right-0 left-0 lg:left-56  lg:fixed lg:top-0 lg:left-56 lg:right-0 lg:z-10 bg-white">
                  <div className="flex-1 min-w-0">
                    <h1 className="text-lg font-medium leading-6 text-gray-900 truncate">Update: {calculator.name}</h1>
                  </div>
                  <div className="mt-4 flex mt-0 ml-4"> 
                    <a href="#" onClick={() => setAlertModalOpen(true)} disabled={sendingData} className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-2 disabled:opacity-75">
                      Cancel
                    </a>
                    <button
                      disabled={sendingData}
                      type="submit"
                      className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
                    >
                      {sendingData ? 'Updating..' : 'Update'}
                    </button>
                  </div>
                </div>
                <main className="py-10 pt-20">
                  <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8 mt-6">
                    <div className="shadow sm:rounded-md sm:overflow-hidden w-full">
                      <div className="bg-white py-6 px-4 space-y-6 sm:p-6 relative">
                        {sendingData ? (<div className="bg-white absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center z-50">
                          Please wait..
                        </div>) : ''}
                        <div>
                          <div> 
                            <div className="space-y-8 divide-y divide-gray-200">
                              <div className="space-y-8 divide-y divide-gray-200">
                                <div>
                                  <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Calculator information</h3>
                                    {/* <p className="mt-1 text-sm text-gray-500">
                                      Some note here
                                    </p> */}
                                  </div>
                                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <Field name="name" validate={required}>
                                      {({ input, meta }) => (            
                                        <TextField required={true} label="Ticket Name" input={input} meta={meta} />
                                      )}
                                    </Field>

                                    <Field name="roles">
                                      {({ input, meta }) => (     
                                        <RoleMultiselectField label="Allowed roles" description="Leave empty to allow all" input={input} meta={meta} />
                                        )}
                                    </Field>    
                                  
                                    <Field name="permissions">
                                      {({ input, meta }) => (     
                                        <DepartmentSelectField label="Permissions" input={input} />
                                        )}
                                    </Field>
                                                        
                                    {/* <div className="sm:col-span-4">
                                      <pre>{JSON.stringify(values, 0, 2)}</pre>
                                    </div> */}
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </form>
              
            )}  
          />
          
          <AlertModal title="Close Form" confirm={closeForm} close={() => setAlertModalOpen(false)} open={alertModalOpen} />
          <SuccessNotification title="Successfully saved!" show={savedNotificationShow} setShow={setSavedNotificationShow} />
        </div>
      )
    }
  }
}

