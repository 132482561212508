 export const nationalityArabicListing = [
    {
      value: "أفغانستان" , 
      label: "أفغانستان",
    },
    {
      value: "جزر آلاند" , 
      label: "جزر آلاند",
    },
    {
      value: "ألبانيا" , 
      label: "ألبانيا",
    },
    {
      value: "الجزائر" , 
      label: "الجزائر",
    },
    {
      value: "ساموا الأمريكية" , 
      label: "ساموا الأمريكية",
    },
    {
      value: "أندورا" , 
      label: "أندورا",
    },
    {
      value: "أنغولا" , 
      label: "أنغولا",
    },
    
    {
      value: "أنغيلا" , 
      label: "أنغيلا",
    },
    {
      value: "أنتاركتيكا" , 
      label: "أنتاركتيكا",
    },
    {
      value: "أنتيغوا وبربودا" , 
      label: "أنتيغوا وبربودا",
    },
    {
      value: "الأرجنتين" , 
      label: "الأرجنتين",
    },
    {
      value: "أرمينيا" , 
      label: "أرمينيا",
    },
    {
      value: "أروبا" , 
      label: "أروبا",
    },
    {
      value: "أستراليا" , 
      label: "أستراليا",
    },
    {
      value: "النمسا" , 
      label: "النمسا",
    },
    {
      value: "أذربيجان" , 
      label: "أذربيجان",
    },
    {
      value: "جزر البهاما" , 
      label: "جزر البهاما",
    },
    
    {
      value: "البحرين" , 
      label: "البحرين",
    },
    
    {
      value: "بنغلاديش" , 
      label: "بنغلاديش",
    },
    
    {
      value: "بربادوس" , 
      label: "بربادوس",
    },
    
    {
      value: "بيلاروسيا" , 
      label: "بيلاروسيا",
    },
    {
      value: "بلجيكا" , 
      label: "بلجيكا",
    },
    {
      value: "بليز" , 
      label: "بليز",
    },
    
    {
      value: "بنين" , 
      label: "بنين",
    },
    
    {
      value: "برمودا" , 
      label: "برمودا",
    },
    {
      value: "بوتان" , 
      label: "بوتان",
    },
    {
      value: "بوليفيا" , 
      label: "بوليفيا",
    },
    
    {
      value: "بونير وسانت يوستاتيوس وسابا" , 
      label: "بونير وسانت يوستاتيوس وسابا",
    },
    {
      value: "البوسنة والهرسك" , 
      label: "البوسنة والهرسك",
    },
    
    {
      value: "بوتسوانا" , 
      label: "بوتسوانا",
    },
    
    {
      value: "جزيرة بوفيت" , 
      label: "جزيرة بوفيت",
    },
    {
      value: "البرازيل" , 
      label: "البرازيل",
    },
    {
      value: "إقليم المحيط البريطاني الهندي" , 
      label: "إقليم المحيط البريطاني الهندي",
    },
    {
      value: "بروناي دار السلام" , 
      label: "بروناي دار السلام",
    },
    
    {
      value: "بلغاريا" , 
      label: "بلغاريا",
    },
    
    {
      value: "بوركينا فاسو" , 
      label: "بوركينا فاسو",
    },
    
    {
      value: "بوروندي" , 
      label: "بوروندي",
    },
    
    {
      value: "كمبوديا" , 
      label: "كمبوديا",
    },
    
    {
      value: "الكاميرون" , 
      label: "الكاميرون",
    },
    
    {
      value: "كندا" , 
      label: "كندا",
    },
    
    
    {
      value: "الرأس الأخضر" , 
      label: "الرأس الأخضر",
    },
    
    {
      value: "جزر كايمان" , 
      label: "جزر كايمان",
    },
    
    {
      value: "جمهورية افريقيا الوسطى" , 
      label: "جمهورية افريقيا الوسطى",
    },
    
    
    {
      value: "تشاد" , 
      label: "تشاد",
    },
    
    {
      value: "تشيلي" , 
      label: "تشيلي",
    },
    
    {
      value: "الصين" , 
      label: "الصين",
    },
    
    {
      value: "جزيرة الكريسماس" , 
      label: "جزيرة الكريسماس",
    },
    
    {
      value: "جزر كوكوس (كيلينغ)" , 
      label: "جزر كوكوس (كيلينغ)",
    },
    {
      value: "كولومبيا" , 
      label: "كولومبيا",
    },
    
    {
      value: "جزر القمر" , 
      label: "جزر القمر",
    },
    {
        value: "الكونغو" , 
        label: "الكونغو ",
      },
      {
        value: "الكونغو ، جمهورية الكونغو الديمقراطية" , 
        label: "الكونغو ، جمهورية الكونغو الديمقراطية ",
      },
      {
        value: "جزر كوك" , 
        label: "جزر كوك ",
      },
      {
        value: "كوستا ريكا" , 
        label: "كوستا ريكا ",
      },
      {
        value: "ساحل العاج" , 
        label: "ساحل العاج ",
      },
      {
        value: "كرواتيا" , 
        label: "كرواتيا ",
      },
      {
        value: "كوبا" , 
        label: "كوبا ",
      },
      
      {
        value: "كوراكاو" , 
        label: "كوراكاو ",
      },
      {
        value: "قبرص" , 
        label: "قبرص ",
      },
      {
        value: "الجمهورية التشيكية" , 
        label: "الجمهورية التشيكية ",
      },
      {
        value: "الدنمارك" , 
        label: "الدنمارك ",
      },
      {
        value: "جيبوتي" , 
        label: "جيبوتي ",
      },
      {
        value: "دومينيكا" , 
        label: "دومينيكا ",
      },
      {
        value: "جمهورية الدومنيكان" , 
        label: "جمهورية الدومنيكان ",
      },
      {
        value: "الاكوادور" , 
        label: "الاكوادور ",
      },
      {
        value: "مصر" , 
        label: "مصر ",
      },
      
      {
        value: "السلفادور" , 
        label: "السلفادور ",
      },
      {
        value: "غينيا الإستوائية" , 
        label: "غينيا الإستوائية ",
      },
      {
        value: "إريتريا" , 
        label: "إريتريا ",
      },
      
      {
        value: "إستونيا" , 
        label: "إستونيا ",
      },
      {
        value: "أثيوبيا" , 
        label: "أثيوبيا ",
      },
      
      {
        value: "جزر فوكلاند (مالفيناس)" , 
        label: "جزر فوكلاند (مالفيناس) ",
      },{
        value: "جزر فاروس" , 
        label: "جزر فاروس ",
      },
      
      {
        value: "فيجي" , 
        label: "فيجي ",
      },
      {
        value: "فنلندا" , 
        label: "فنلندا ",
      },
      {
        value: "فرنسا" , 
        label: "فرنسا ",
      },
      {
        value: "غيانا الفرنسية" , 
        label: "غيانا الفرنسية ",
      },
      
      {
        value: "بولينيزيا الفرنسية" , 
        label: "بولينيزيا الفرنسية ",
      },
      {
        value: "المناطق الجنوبية لفرنسا" , 
        label: "المناطق الجنوبية لفرنسا ",
      },
      {
        value: "الجابون" , 
        label: "الجابون ",
      },
      {
        value: "غامبيا" , 
        label: "غامبيا ",
      },
      
      {
        value: "جورجيا" , 
        label: "جورجيا ",
      },
      {
        value: "ألمانيا" , 
        label: "ألمانيا ",
      },
      {
        value: "غانا" , 
        label: "غانا ",
      },
      {
        value: "جبل طارق" , 
        label: "جبل طارق ",
      },
      {
        value: "اليونان" , 
        label: "اليونان ",
      },
      {
        value: "الأرض الخضراء" , 
        label: "الأرض الخضراء ",
      },
      {
        value: "غرينادا" , 
        label: "غرينادا ",
      },
      {
        value: "جوادلوب" , 
        label: "جوادلوب ",
      },
      {
        value: "غوام" , 
        label: "غوام ",
      },
      {
        value: "غواتيمالا" , 
        label: "غواتيمالا ",
      },
      {
        value: "غيرنسي" , 
        label: "غيرنسي ",
      },
      {
        value: "غينيا" , 
        label: "غينيا ",
      },
      {
        value: "غينيا بيساو" , 
        label: "غينيا بيساو ",
      },
      {
        value: "غيانا" , 
        label: "غيانا ",
      },
      {
        value: "قلب الجزيرة وجزر ماكدونالز" , 
        label: "قلب الجزيرة وجزر ماكدونالز ",
      },
      {
        value: "الكرسي الرسولي (دولة الفاتيكان)" , 
        label: "الكرسي الرسولي (دولة الفاتيكان) ",
      },
      {
        value: "هندوراس" , 
        label: "هندوراس ",
      },
      {
        value: "هونج كونج" , 
        label: "هونج كونج ",
      },
      {
        value: "هنغاريا" , 
        label: "هنغاريا ",
      },
      {
        value: "أيسلندا" , 
        label: "أيسلندا ",
      },
      {
        value: "الهند" , 
        label: "الهند ",
      },
      {
        value: "إندونيسيا" , 
        label: "إندونيسيا ",
      },
      {
        value: "جمهورية إيران الإسلامية" , 
        label: "جمهورية إيران الإسلامية ",
      },
      {
        value: "العراق" , 
        label: "العراق ",
      },
      {
        value: "أيرلندا" , 
        label: "أيرلندا ",
      },
      {
        value: "جزيرة آيل أوف مان" , 
        label: "جزيرة آيل أوف مان ",
      },
      {
        value: "إسرائيل" , 
        label: "إسرائيل ",
      },
      {
        value: "إيطاليا" , 
        label: "إيطاليا ",
      },
      {
        value: "جامايكا" , 
        label: "جامايكا ",
      },
      {
        value: "اليابان" , 
        label: "اليابان ",
      },
      {
        value: "جيرسي" , 
        label: "جيرسي ",
      },
      {
        value: "الأردن" , 
        label: "الأردن ",
      },
      {
        value: "كازاخستان" , 
        label: "كازاخستان ",
      },
      {
        value: "كينيا" , 
        label: "كينيا ",
      },
      {
        value: "كيريباتي" , 
        label: "كيريباتي ",
      },
      {
        value: "كوريا، الجمهورية الشعبية الديمقراطية" , 
        label: "كوريا، الجمهورية الشعبية الديمقراطية ",
      },
      {
        value: "جمهورية كوريا" , 
        label: "جمهورية كوريا ",
      },
      {
        value: "كوسوفو" , 
        label: "كوسوفو ",
      },
      {
        value: "الكويت" , 
        label: "الكويت ",
      },
      {
        value: "قيرغيزستان" , 
        label: "قيرغيزستان ",
      },
      {
        value: "جمهورية لاو الديمقراطية الشعبية" , 
        label: "جمهورية لاو الديمقراطية الشعبية ",
      },
      {
        value: "لاتفيا" , 
        label: "لاتفيا ",
      },
      {
        value: "لبنان" , 
        label: "لبنان ",
      },
      {
        value: "ليسوتو" , 
        label: "ليسوتو ",
      },
      {
        value: "ليبيريا" , 
        label: "ليبيريا ",
      },
      {
        value: "الجماهيرية العربية الليبية" , 
        label: "الجماهيرية العربية الليبية ",
      },
      {
        value: "ليختنشتاين" , 
        label: "ليختنشتاين ",
      },
      {
        value: "ليتوانيا" , 
        label: "ليتوانيا ",
      },
      {
        value: "لوكسمبورغ" , 
        label: "لوكسمبورغ ",
      },
      {
        value: "ماكاو" , 
        label: "ماكاو ",
      },
      {
        value: "مقدونيا ، جمهورية يوغوسلافيا السابقة" , 
        label: "مقدونيا ، جمهورية يوغوسلافيا السابقة ",
      },
      {
        value: "مدغشقر" , 
        label: "مدغشقر ",
      },
      {
        value: "ملاوي" , 
        label: "ملاوي ",
      },
      {
        value: "ماليزيا" , 
        label: "ماليزيا ",
      },
      {
        value: "جزر المالديف" , 
        label: "جزر المالديف ",
      },
      {
        value: "مالي" , 
        label: "مالي ",
      },
      {
        value: "مالطا" , 
        label: "مالطا ",
      },
      {
        value: "جزر مارشال" , 
        label: "جزر مارشال ",
      },
      {
        value: "مارتينيك" , 
        label: "مارتينيك ",
      },
      {
        value: "موريتانيا" , 
        label: "موريتانيا ",
      },
      {
        value: "موريشيوس" , 
        label: "موريشيوس ",
      },
      {
        value: "مايوت" , 
        label: "مايوت ",
      },
      {
        value: "المكسيك" , 
        label: "المكسيك ",
      },
      {
        value: "ولايات ميكرونيزيا الموحدة" , 
        label: "ولايات ميكرونيزيا الموحدة ",
      },
      {
        value: "جمهورية مولدوفا" , 
        label: "جمهورية مولدوفا ",
      },
      {
        value: "موناكو" , 
        label: "موناكو ",
      },
      {
        value: "منغوليا" , 
        label: "منغوليا ",
      },
      {
        value: "الجبل الأسود" , 
        label: "الجبل الأسود ",
      },
      {
        value: "مونتسيرات" , 
        label: "مونتسيرات ",
      },
      {
        value: "المغرب" , 
        label: "المغرب ",
      },
      {
        value: "موزمبيق" , 
        label: "موزمبيق ",
      },
      {
        value: "ميانمار" , 
        label: "ميانمار ",
      },
      {
        value: "ناميبيا" , 
        label: "ناميبيا ",
      },
      {
        value: "ناورو" , 
        label: "ناورو ",
      },
      {
        value: "نيبال" , 
        label: "نيبال ",
      },
      {
        value: "هولندا" , 
        label: "هولندا ",
      },
      {
        value: "جزر الأنتيل الهولندية" , 
        label: "جزر الأنتيل الهولندية ",
      },
      {
        value: "كاليدونيا الجديدة" , 
        label: "كاليدونيا الجديدة ",
      },
      {
        value: "نيوزيلاندا" , 
        label: "نيوزيلاندا ",
      },
      {
        value: "نيكاراغوا" , 
        label: "نيكاراغوا ",
      },
      {
        value: "النيجر" , 
        label: "النيجر ",
      },
      {
        value: "نيجيريا" , 
        label: "نيجيريا ",
      },
      {
        value: "نيوي" , 
        label: "نيوي ",
      },
      {
        value: "جزيرة نورفولك" , 
        label: "جزيرة نورفولك ",
      },
      {
        value: "جزر مريانا الشمالية" , 
        label: "جزر مريانا الشمالية ",
      },
      {
        value: "النرويج" , 
        label: "النرويج ",
      },
      {
        value: "سلطنة عمان" , 
        label: "سلطنة عمان ",
      },
      {
        value: "باكستان" , 
        label: "باكستان ",
      },
      {
        value: "بالاو" , 
        label: "بالاو ",
      },
      {
        value: "الأراضي الفلسطينية المحتلة" , 
        label: "الأراضي الفلسطينية المحتلة ",
      },
      {
        value: "بنما" , 
        label: "بنما ",
      },
      {
        value: "بابوا غينيا الجديدة" , 
        label: "بابوا غينيا الجديدة ",
      },
      {
        value: "باراغواي" , 
        label: "باراغواي ",
      },
      {
        value: "بيرو" , 
        label: "بيرو ",
      },
      {
        value: "فيلبيني" , 
        label: "فيلبيني ",
      },
      {
        value: "بيتكيرن" , 
        label: "بيتكيرن ",
      },
      {
        value: "بولندا" , 
        label: "بولندا ",
      },
      {
        value: "البرتغال" , 
        label: "البرتغال ",
      },
      {
        value: "بورتوريكو" , 
        label: "بورتوريكو ",
      },
      {
        value: "دولة قطر" , 
        label: "دولة قطر ",
      },
      {
        value: "جمع شمل" , 
        label: "جمع شمل ",
      },
      {
        value: "رومانيا" , 
        label: "رومانيا ",
      },
      {
        value: "الاتحاد الروسي" , 
        label: "الاتحاد الروسي ",
      },
      {
        value: "رواندا" , 
        label: "رواندا ",
      },
      {
        value: "سانت بارتيليمي" , 
        label: "سانت بارتيليمي ",
      },
      {
        value: "سانت هيلانة" , 
        label: "سانت هيلانة ",
      },
      {
        value: "سانت كيتس ونيفيس" , 
        label: "سانت كيتس ونيفيس ",
      },
      {
        value: "القديسة لوسيا" , 
        label: "القديسة لوسيا ",
      },
      {
        value: "القديس مارتن" , 
        label: "القديس مارتن ",
      },
      {
        value: "سانت بيير وميكلون" , 
        label: "سانت بيير وميكلون ",
      },
      {
        value: "سانت فنسنت وجزر غرينادين" , 
        label: "سانت فنسنت وجزر غرينادين ",
      },
      {
        value: "ساموا" , 
        label: "ساموا ",
      },
      {
        value: "سان مارينو" , 
        label: "سان مارينو ",
      },
      {
        value: "ساو تومي وبرينسيبي" , 
        label: "ساو تومي وبرينسيبي ",
      },
      {
        value: "المملكة العربية السعودية" , 
        label: "المملكة العربية السعودية ",
      },
      {
        value: "السنغال" , 
        label: "السنغال ",
      },
      {
        value: "صربيا" , 
        label: "صربيا ",
      },
      {
        value: "صربيا والجبل الأسود" , 
        label: "صربيا والجبل الأسود ",
      },
      {
        value: "سيشيل" , 
        label: "سيشيل ",
      },
      {
        value: "سيرا ليون" , 
        label: "سيرا ليون ",
      },
      {
        value: "سنغافورة" , 
        label: "سنغافورة ",
      },
      {
        value: "سينت مارتن" , 
        label: "سينت مارتن ",
      },
      {
        value: "سلوفاكيا" , 
        label: "سلوفاكيا ",
      },
      {
        value: "سلوفينيا" , 
        label: "سلوفينيا ",
      },
      {
        value: "جزر سليمان" , 
        label: "جزر سليمان ",
      },
      {
        value: "الصومال" , 
        label: "الصومال ",
      },
      {
        value: "جنوب أفريقيا" , 
        label: "جنوب أفريقيا ",
      },
      {
        value: "جورجيا الجنوبية وجزر ساندويتش الجنوبية" , 
        label: "جورجيا الجنوبية وجزر ساندويتش الجنوبية ",
      },
      {
        value: "جنوب السودان" , 
        label: "جنوب السودان ",
      },
      {
        value: "إسبانيا" , 
        label: "إسبانيا ",
      },
      {
        value: "سيريلانكا" , 
        label: "سيريلانكا ",
      },
      {
        value: "السودان" , 
        label: "السودان ",
      },
      {
        value: "سورينام" , 
        label: "سورينام ",
      },
      {
        value: "سفالبارد وجان ماين" , 
        label: "سفالبارد وجان ماين ",
      },
      {
        value: "سوازيلاند" , 
        label: "سوازيلاند ",
      },
      {
        value: "السويد" , 
        label: "السويد ",
      },
      {
        value: "سويسرا" , 
        label: "سويسرا ",
      },
      {
        value: "الجمهورية العربية السورية" , 
        label: "الجمهورية العربية السورية ",
      },
      {
        value: "مقاطعة تايوان الصينية" , 
        label: "مقاطعة تايوان الصينية ",
      },
      {
        value: "طاجيكستان" , 
        label: "طاجيكستان ",
      },
      {
        value: "جمهورية تنزانيا المتحدة" , 
        label: "جمهورية تنزانيا المتحدة ",
      },
      {
        value: "تايلاند" , 
        label: "تايلاند ",
      },
      {
        value: "تيمور ليشتي" , 
        label: "تيمور ليشتي ",
      },
      {
        value: "توجو" , 
        label: "توجو ",
      },
      {
        value: "توكيلاو" , 
        label: "توكيلاو ",
      },
      {
        value: "تونغا" , 
        label: "تونغا ",
      },
      {
        value: "ترينداد وتوباغو" , 
        label: "ترينداد وتوباغو ",
      },
      {
        value: "تونس" , 
        label: "تونس ",
      },
      {
        value: "ديك رومى" , 
        label: "ديك رومى ",
      },
      {
        value: "تركمانستان" , 
        label: "تركمانستان ",
      },
      {
        value: "جزر تركس وكايكوس" , 
        label: "جزر تركس وكايكوس ",
      },
      {
        value: "توفالو" , 
        label: "توفالو ",
      },
      {
        value: "أوغندا" , 
        label: "أوغندا ",
      },
      {
        value: "أوكرانيا" , 
        label: "أوكرانيا ",
      },
      {
        value: "الإمارات العربية المتحدة" , 
        label: "الإمارات العربية المتحدة ",
      },
      {
        value: "المملكة المتحدة" , 
        label: "المملكة المتحدة ",
      },
      {
        value: "الولايات المتحدة" , 
        label: "الولايات المتحدة ",
      },
      {
        value: "جزر الولايات المتحدة البعيدة الصغرى" , 
        label: "جزر الولايات المتحدة البعيدة الصغرى ",
      },
      {
        value: "أوروغواي" , 
        label: "أوروغواي ",
      },
      {
        value: "أوزبكستان" , 
        label: "أوزبكستان ",
      },
      {
        value: "فانواتو" , 
        label: "فانواتو ",
      },
      {
        value: "فنزويلا" , 
        label: "فنزويلا ",
      },
      {
        value: "فييت نام" , 
        label: "فييت نام ",
      },
      {
        value: "جزر العذراء البريطانية" , 
        label: "جزر العذراء البريطانية ",
      },
      {
        value: "جزر فيرجن ، الولايات المتحدة" , 
        label: "جزر فيرجن ، الولايات المتحدة ",
      },
      {
        value: "واليس وفوتونا" , 
        label: "واليس وفوتونا ",
      },
      {
        value: "الصحراء الغربية" , 
        label: "الصحراء الغربية ",
      },
      {
        value: "اليمن" , 
        label: "اليمن ",
      },
      {
        value: "زامبيا" , 
        label: "زامبيا ",
      },
      {
        value: "زيمبابوي" , 
        label: "زيمبابوي ",
      },
    ]


    export const nationalListing = [
        {
            value: "Afghan" , 
            label: "Afghan ",
          },
          {
            value: "Albanian" , 
            label: "Albanian ",
          },
          {
            value: "Algerian" , 
            label: "Algerian ",
          },
          {
            value: "American" , 
            label: "American ",
          },
          {
            value: "Andorran" , 
            label: "Andorran ",
          },
          {
            value: "Angolan" , 
            label: "Angolan ",
          },
          {
            value: "Antiguans" , 
            label: "Antiguans ",
          },
          {
            value: "Argentinean" , 
            label: "Argentinean ",
          },
          {
            value: "Armenian" , 
            label: "Armenian ",
          },
          {
            value: "Australian" , 
            label: "Australian ",
          },
          {
            value: "Austrian" , 
            label: "Austrian ",
          },
          {
            value: "Azerbaijani" , 
            label: "Azerbaijani ",
          },
          {
            value: "Bahamian" , 
            label: "Bahamian ",
          },
          {
            value: "Bahraini" , 
            label: "Bahraini ",
          },
          {
            value: "Bangladeshi" , 
            label: "Bangladeshi ",
          },
          {
            value: "Barbadian" , 
            label: "Barbadian ",
          },
          {
            value: "Barbudans" , 
            label: "Barbudans ",
          },
          {
            value: "Batswana" , 
            label: "Batswana ",
          },
          {
            value: "Belarusian" , 
            label: "Belarusian ",
          },
          {
            value: "Belgian" , 
            label: "Belgian ",
          },
          {
            value: "Belizean" , 
            label: "Belizean ",
          },
          {
            value: "Bhutanese" , 
            label: "Bhutanese ",
          },
          {
            value: "Bolivian" , 
            label: "Bolivian ",
          },
          {
            value: "Bosnian" , 
            label: "Bosnian ",
          },
          {
            value: "Brazilian" , 
            label: "Brazilian ",
          },
          {
            value: "British" , 
            label: "British ",
          },
          {
            value: "Bruneian" , 
            label: "Bruneian ",
          },
          {
            value: "Bulgarian" , 
            label: "Bulgarian ",
          },
          {
            value: "Burkinabe" , 
            label: "Burkinabe ",
          },
          {
            value: "Burmese" , 
            label: "Burmese ",
          },
          {
            value: "Burundian" , 
            label: "Burundian ",
          },
          {
            value: "Cambodian" , 
            label: "Cambodian ",
          },
          {
            value: "Cameroonian" , 
            label: "Cameroonian ",
          },
          {
            value: "Canadian" , 
            label: "Canadian ",
          },
          {
            value: "Cape Verdean" , 
            label: "Cape Verdean ",
          },
          {
            value: "Central African" , 
            label: "Central African ",
          },
          {
            value: "Chadian" , 
            label: "Chadian ",
          },
          {
            value: "Chilean" , 
            label: "Chilean ",
          },
          {
            value: "Chinese" , 
            label: "Chinese ",
          },
          {
            value: "Colombian" , 
            label: "Colombian ",
          },
          {
            value: "Comoran" , 
            label: "Comoran ",
          },
          {
            value: "Congolese" , 
            label: "Congolese ",
          },
          {
            value: "Costa Rican" , 
            label: "Costa Rican ",
          },
          {
            value: "Croatian" , 
            label: "Croatian ",
          },
          {
            value: "Cuban" , 
            label: "Cuban ",
          },
          {
            value: "Cypriot" , 
            label: "Cypriot ",
          },
          {
            value: "Czech" , 
            label: "Czech ",
          },
          {
            value: "Danish" , 
            label: "Danish ",
          },
          {
            value: "Djibouti" , 
            label: "Djibouti ",
          },
          {
            value: "Dominican" , 
            label: "Dominican ",
          },
          {
            value: "Dutch" , 
            label: "Dutch ",
          },
          {
            value: "East Timorese" , 
            label: "East Timorese ",
          },
          {
            value: "Ecuadorean" , 
            label: "Ecuadorean ",
          },
          {
            value: "Egyptian" , 
            label: "Egyptian ",
          },
          {
            value: "Emirian" , 
            label: "Emirian ",
          },
          {
            value: "Equatorial Guinean" , 
            label: "Equatorial Guinean ",
          },
          {
            value: "Eritrean" , 
            label: "Eritrean ",
          },
          {
            value: "Estonian" , 
            label: "Estonian ",
          },
          {
            value: "Ethiopian" , 
            label: "Ethiopian ",
          },
          {
            value: "Fijian" , 
            label: "Fijian ",
          },
          {
            value: "Filipino" , 
            label: "Filipino ",
          },
          {
            value: "Finnish" , 
            label: "Finnish ",
          },
          {
            value: "French" , 
            label: "French ",
          },
          {
            value: "Gabonese" , 
            label: "Gabonese ",
          },
          {
            value: "Gambian" , 
            label: "Gambian ",
          },
          {
            value: "Georgian" , 
            label: "Georgian ",
          },
          {
            value: "German" , 
            label: "German ",
          },
          {
            value: "Ghanaian" , 
            label: "Ghanaian ",
          },
          {
            value: "Greek" , 
            label: "Greek ",
          },
          {
            value: "Grenadian" , 
            label: "Grenadian ",
          },
          {
            value: "Guatemalan" , 
            label: "Guatemalan ",
          },
          {
            value: "Guinea-Bissauan" , 
            label: "Guinea-Bissauan ",
          },
          {
            value: "Guinean" , 
            label: "Guinean ",
          },
          {
            value: "Guyanese" , 
            label: "Guyanese ",
          },
          {
            value: "Haitian" , 
            label: "Haitian ",
          },
          {
            value: "Herzegovinian" , 
            label: "Herzegovinian ",
          },
          {
            value: "Honduran" , 
            label: "Honduran ",
          },
          {
            value: "Hungarian" , 
            label: "Hungarian ",
          },
          {
            value: "Icelander" , 
            label: "Icelander ",
          },
          {
            value: "Indian" , 
            label: "Indian ",
          },
          {
            value: "Indonesian" , 
            label: "Indonesian ",
          },
          {
            value: "Iranian" , 
            label: "Iranian ",
          },
          {
            value: "Iraqi" , 
            label: "Iraqi ",
          },
          {
            value: "Irish" , 
            label: "Irish ",
          },
          {
            value: "Italian" , 
            label: "Italian ",
          },
          {
            value: "Ivorian" , 
            label: "Ivorian ",
          },
          {
            value: "Jamaican" , 
            label: "Jamaican ",
          },{
            value: "Japanese" , 
            label: "Japanese ",
          },
          {
            value: "Jordanian" , 
            label: "Jordanian ",
          },
          {
            value: "Kazakhstani" , 
            label: "Kazakhstani ",
          },
          {
            value: "Kittian and Nevisian" , 
            label: "Kittian and Nevisian ",
          },
          {
            value: "Kuwaiti" , 
            label: "Kuwaiti ",
          },
          {
            value: "Kyrgyz" , 
            label: "Kyrgyz ",
          },
          {
            value: "Laotian" , 
            label: "Laotian ",
          },
          {
            value: "Latvian" , 
            label: "Latvian ",
          },
          {
            value: "Lebanese" , 
            label: "Lebanese ",
          },
          {
            value: "Liberian" , 
            label: "Liberian ",
          },
          {
            value: "Libyan" , 
            label: "Libyan ",
          },
          {
            value: "Liechtensteiner" , 
            label: "Liechtensteiner ",
          },
          {
            value: "Lithuanian" , 
            label: "Lithuanian ",
          },
          {
            value: "Luxembourger" , 
            label: "Luxembourger ",
          },
          {
            value: "Macedonian" , 
            label: "Macedonian ",
          },
          {
            value: "Malagasy" , 
            label: "Malagasy ",
          },
          {
            value: "Malawian" , 
            label: "Malawian ",
          },
          {
            value: "Malaysian" , 
            label: "Malaysian ",
          },
          {
            value: "Maldivan" , 
            label: "Maldivan ",
          },
          {
            value: "Malian" , 
            label: "Malian ",
          },
          {
            value: "Maltese" , 
            label: "Maltese ",
          },
          {
            value: "Marshallese" , 
            label: "Marshallese ",
          },
          {
            value: "Mauritanian" , 
            label: "Mauritanian ",
          },
          {
            value: "Mauritian" , 
            label: "Mauritian ",
          },
          {
            value: "Mexican" , 
            label: "Mexican ",
          },
          {
            value: "Micronesian" , 
            label: "Micronesian ",
          },
          {
            value: "Moldovan" , 
            label: "Moldovan ",
          },
          {
            value: "Monacan" , 
            label: "Monacan ",
          },
          {
            value: "Mongolian" , 
            label: "Mongolian ",
          },
          {
            value: "Moroccan" , 
            label: "Moroccan ",
          },
          {
            value: "Mosotho" , 
            label: "Mosotho ",
          },
          {
            value: "Motswana" , 
            label: "Motswana ",
          },
          {
            value: "Mozambican" , 
            label: "Mozambican ",
          },
          {
            value: "Namibian" , 
            label: "Namibian ",
          },
          {
            value: "Nauruan" , 
            label: "Nauruan ",
          },
          {
            value: "Nepalese" , 
            label: "Nepalese ",
          },
          {
            value: "New Zealander" , 
            label: "New Zealander ",
          },
          {
            value: "Ni-Vanuatu" , 
            label: "Ni-Vanuatu ",
          },
          {
            value: "Nicaraguan" , 
            label: "Nicaraguan ",
          },
          {
            value: "Nigerien" , 
            label: "Nigerien ",
          },
          {
            value: "North Korean" , 
            label: "North Korean ",
          },
          {
            value: "Northern Irish" , 
            label: "Northern Irish ",
          },
          {
            value: "Norwegian" , 
            label: "Norwegian ",
          },
          {
            value: "Omani" , 
            label: "Omani ",
          },
          {
            value: "Pakistani" , 
            label: "Pakistani ",
          },
          {
            value: "Palauan" , 
            label: "Palauan ",
          },
          {
            value: "Palestinian" , 
            label: "Palestinian ",
          },
          {
            value: "Panamanian" , 
            label: "Panamanian ",
          },
          {
            value: "Papua New Guinean" , 
            label: "Papua New Guinean ",
          },
          {
            value: "Paraguayan" , 
            label: "Paraguayan ",
          },
          {
            value: "Peruvian" , 
            label: "Peruvian ",
          },
          {
            value: "Polish" , 
            label: "Polish ",
          },
          {
            value: "Portuguese" , 
            label: "Portuguese ",
          },
          {
            value: "Qatari" , 
            label: "Qatari ",
          },
          {
            value: "Romanian" , 
            label: "Romanian ",
          },
          {
            value: "Russian" , 
            label: "Russian ",
          },
          {
            value: "Rwandan" , 
            label: "Rwandan ",
          },
          {
            value: "Saint Lucian" , 
            label: "Saint Lucian ",
          },
          {
            value: "Salvadoran" , 
            label: "Salvadoran ",
          },
          {
            value: "Samoan" , 
            label: "Samoan ",
          },
          {
            value: "San Marinese" , 
            label: "San Marinese ",
          },
          {
            value: "Sao Tomean" , 
            label: "Sao Tomean ",
          },
          {
            value: "Saudi" , 
            label: "Saudi ",
          },
          {
            value: "Scottish" , 
            label: "Scottish ",
          },
          {
            value: "Senegalese" , 
            label: "Senegalese ",
          },
          {
            value: "Serbian" , 
            label: "Serbian ",
          },
          {
            value: "Seychellois" , 
            label: "Seychellois ",
          },
          {
            value: "Sierra Leonean" , 
            label: "Sierra Leonean ",
          },
          {
            value: "Singaporean" , 
            label: "Singaporean ",
          },
          {
            value: "Slovakian" , 
            label: "Slovakian ",
          },
          {
            value: "Slovenian" , 
            label: "Slovenian ",
          },
          {
            value: "Solomon Islander" , 
            label: "Solomon Islander ",
          },
          {
            value: "Somali" , 
            label: "Somali ",
          },
          {
            value: "South African" , 
            label: "South African ",
          },
          {
            value: "South Korean" , 
            label: "South Korean ",
          },
          {
            value: "Spanish" , 
            label: "Spanish ",
          },
          {
            value: "Sri Lankan" , 
            label: "Sri Lankan ",
          },
          {
            value: "Sudanese" , 
            label: "Sudanese ",
          },
          {
            value: "Surinamer" , 
            label: "Surinamer ",
          },
          {
            value: "Swazi" , 
            label: "Swazi ",
          },
          {
            value: "Swedish" , 
            label: "Swedish ",
          },
          {
            value: "Swiss" , 
            label: "Swiss ",
          },
          {
            value: "Syrian" , 
            label: "Syrian ",
          },
          {
            value: "Taiwanese" , 
            label: "Taiwanese ",
          },
          {
            value: "Tajik" , 
            label: "Tajik ",
          },
          {
            value: "Tanzanian" , 
            label: "Tanzanian ",
          },
          {
            value: "Thai" , 
            label: "Thai ",
          },
          {
            value: "Togolese" , 
            label: "Togolese ",
          },
          {
            value: "Tongan" , 
            label: "Tongan ",
          },
          {
            value: "Trinidadian or Tobagonian" , 
            label: "Trinidadian or Tobagonian ",
          },
          {
            value: "Tunisian" , 
            label: "Tunisian ",
          },
          {
            value: "Turkish" , 
            label: "Turkish ",
          },
          {
            value: "Tuvaluan" , 
            label: "Tuvaluan ",
          },
          {
            value: "Ugandan" , 
            label: "Ugandan ",
          },
          {
            value: "Ukrainian" , 
            label: "Ukrainian ",
          },
          {
            value: "Uruguayan" , 
            label: "Uruguayan ",
          },
          {
            value: "Uzbekistani" , 
            label: "Uzbekistani ",
          },
          {
            value: "Venezuelan" , 
            label: "Venezuelan ",
          },
          {
            value: "Vietnamese" , 
            label: "Vietnamese ",
          },
          {
            value: "Welsh" , 
            label: "Welsh ",
          },
          {
            value: "Yemenite" , 
            label: "Yemenite ",
          },
          {
            value: "Zambian" , 
            label: "Zambian ",
          },
          {
            value: "Zimbabwean" , 
            label: "Zimbabwean ",
          },
    ]



    export const countryListing = [
      {
          value: "Aruba" , 
          label: "Aruba ",
        },
        {
          value: "Afghanistan" , 
          label: "Afghanistan ",
        },
        {
          value: "Angola" , 
          label: "Angola ",
        },
        {
          value: "Anguilla" , 
          label: "Anguilla ",
        },
        {
          value: "Åland Islands" , 
          label: "Åland Islands ",
        },
        {
          value: "Albania" , 
          label: "Albania ",
        },
        {
          value: "Andorra" , 
          label: "Andorra ",
        },
        {
          value: "United Arab Emirates" , 
          label: "United Arab Emirates ",
        },
        {
          value: "Argentina" , 
          label: "Argentina ",
        },
        {
          value: "Armenia" , 
          label: "Armenia ",
        },
        {
          value: "American Samoa" , 
          label: "American Samoa ",
        },
        {
          value: "Antarctica" , 
          label: "Antarctica ",
        },
        {
          value: "French Southern and Antarctic Lands" , 
          label: "French Southern and Antarctic Lands ",
        },
        {
          value: "Antigua and Barbuda" , 
          label: "Antigua and Barbuda ",
        },
        {
          value: "Australia" , 
          label: "Australia ",
        },
        {
          value: "Austria" , 
          label: "Austria ",
        },
        {
          value: "Azerbaijan" , 
          label: "Azerbaijan ",
        },
        {
          value: "Burundi" , 
          label: "Burundi ",
        },
        {
          value: "Belgium" , 
          label: "Belgium ",
        },
        {
          value: "Benin" , 
          label: "Benin ",
        },
        {
          value: "Burkina Faso" , 
          label: "Burkina Faso ",
        },
        {
          value: "Bangladesh" , 
          label: "Bangladesh ",
        },
        {
          value: "Bulgaria" , 
          label: "Bulgaria ",
        },
        {
          value: "Bahrain" , 
          label: "Bahrain ",
        },
        {
          value: "Bahamas" , 
          label: "Bahamas ",
        },
        {
          value: "Bosnia and Herzegovina" , 
          label: "Bosnia and Herzegovina ",
        },
        {
          value: "Saint Barthélemy" , 
          label: "Saint Barthélemy ",
        },
        {
          value: "Saint Helena, Ascension and Tristan da Cunha" , 
          label: "Saint Helena, Ascension and Tristan da Cunha ",
        },
        {
          value: "Belarus" , 
          label: "Belarus ",
        },
        {
          value: "Belize" , 
          label: "Belize ",
        },
        {
          value: "Bermuda" , 
          label: "Bermuda ",
        },
        {
          value: "Bolivia" , 
          label: "Bolivia ",
        },
        {
          value: "Caribbean Netherlands" , 
          label: "Caribbean Netherlands ",
        },
        {
          value: "Brazil" , 
          label: "Brazil ",
        },
        {
          value: "Barbados" , 
          label: "Barbados ",
        },
        {
          value: "Brunei" , 
          label: "Brunei",
        },
        {
          value: "Bhutan" , 
          label: "Bhutan ",
        },
        {
          value: "Bouvet Island" , 
          label: "Bouvet Island ",
        },
        {
          value: "Botswana" , 
          label: "Botswana ",
        },
        {
          value: "Central African Republic" , 
          label: "Central African Republic ",
        },
        {
          value: "Canada" , 
          label: "Canada ",
        },
        {
          value: "Cocos (Keeling) Islands" , 
          label: "Cocos (Keeling) Islands ",
        },
        {
          value: "Switzerland" , 
          label: "Switzerland ",
        },
        {
          value: "Chile" , 
          label: "Chile ",
        },
        {
          value: "China" , 
          label: "China ",
        },
        {
          value: "Ivory Coast" , 
          label: "Ivory Coast ",
        },
        {
          value: "Cameroon" , 
          label: "Cameroon ",
        },
        {
          value: "DR Congo" , 
          label: "DR Congo ",
        },
        {
          value: "Republic of the Congo" , 
          label: "Republic of the Congo ",
        },
        {
          value: "Cook Islands" , 
          label: "Cook Islands ",
        },
        {
          value: "Colombia" , 
          label: "Colombia ",
        },
        {
          value: "Comoros" , 
          label: "Comoros ",
        },
        {
          value: "Cape Verde" , 
          label: "Cape Verde ",
        },
        {
          value: "Costa Rica" , 
          label: "Costa Rica ",
        },
        {
          value: "Cuba" , 
          label: "Cuba ",
        },
        {
          value: "Curaçao" , 
          label: "Curaçao ",
        },
        {
          value: "Christmas Island" , 
          label: "Christmas Island ",
        },
        {
          value: "Cayman Islands" , 
          label: "Cayman Islands ",
        },
        {
          value: "Cyprus" , 
          label: "Cyprus ",
        },
        {
          value: "Czechia" , 
          label: "Czechia ",
        },
        {
          value: "Germany" , 
          label: "Germany ",
        },
        {
          value: "Djibouti" , 
          label: "Djibouti ",
        },
        {
          value: "Dominica" , 
          label: "Dominica ",
        },
        {
          value: "Denmark" , 
          label: "Denmark ",
        },
        {
          value: "Dominican Republic" , 
          label: "Dominican Republic ",
        },
        {
          value: "Algeria" , 
          label: "Algeria ",
        },
        {
          value: "Ecuador" , 
          label: "Ecuador ",
        },
        {
          value: "Egypt" , 
          label: "Egypt ",
        },
        {
          value: "Eritrea" , 
          label: "Eritrea ",
        },
        {
          value: "Western Sahara" , 
          label: "Western Sahara ",
        },
        {
          value: "Spain" , 
          label: "Spain ",
        },
        {
          value: "Estonia" , 
          label: "Estonia ",
        },
        {
          value: "Ethiopia" , 
          label: "Ethiopia ",
        },
        {
          value: "Finland" , 
          label: "Finland ",
        },
        {
          value: "Fiji" , 
          label: "Fiji ",
        },
        {
          value: "Falkland Islands" , 
          label: "Falkland Islands ",
        },
        {
          value: "France" , 
          label: "France ",
        },
        {
          value: "Faroe Islands" , 
          label: "Faroe Islands ",
        },
        {
          value: "Micronesia" , 
          label: "Micronesia ",
        },
        {
          value: "Gabon" , 
          label: "Gabon ",
        },
        {
          value: "United Kingdom" , 
          label: "United Kingdom ",
        },
        {
          value: "Georgia" , 
          label: "Georgia ",
        },
        {
          value: "Guernsey" , 
          label: "Guernsey ",
        },
        {
          value: "Ghana" , 
          label: "Ghana ",
        },
        {
          value: "Gibraltar" , 
          label: "Gibraltar ",
        },
        {
          value: "Guinea" , 
          label: "Guinea ",
        },
        {
          value: "Guadeloupe" , 
          label: "Guadeloupe ",
        },{
          value: "Gambia" , 
          label: "Gambia ",
        },
        {
          value: "Guinea-Bissau" , 
          label: "Guinea-Bissau ",
        },
        {
          value: "Equatorial Guinea" , 
          label: "Equatorial Guinea ",
        },
        {
          value: "Greece" , 
          label: "Greece ",
        },
        {
          value: "Grenada" , 
          label: "Grenada ",
        },
        {
          value: "Greenland" , 
          label: "Greenland ",
        },
        {
          value: "Guatemala" , 
          label: "Guatemala ",
        },
        {
          value: "French Guiana" , 
          label: "French Guiana ",
        },
        {
          value: "Guam" , 
          label: "Guam ",
        },
        {
          value: "Guyana" , 
          label: "Guyana ",
        },
        {
          value: "Hong Kong" , 
          label: "Hong Kong ",
        },
        {
          value: "Heard Island and McDonald Islands" , 
          label: "Heard Island and McDonald Islands ",
        },
        {
          value: "Honduras" , 
          label: "Honduras ",
        },
        {
          value: "Croatia" , 
          label: "Croatia ",
        },
        {
          value: "Haiti" , 
          label: "Haiti ",
        },
        {
          value: "Hungary" , 
          label: "Hungary ",
        },
        {
          value: "Indonesia" , 
          label: "Indonesia ",
        },
        {
          value: "Isle of Man" , 
          label: "Isle of Man ",
        },
        {
          value: "India" , 
          label: "India ",
        },
        {
          value: "British Indian Ocean Territory" , 
          label: "British Indian Ocean Territory ",
        },
        {
          value: "Ireland" , 
          label: "Ireland ",
        },
        {
          value: "Iran" , 
          label: "Iran ",
        },
        {
          value: "Iraq" , 
          label: "Iraq ",
        },
        {
          value: "Iceland" , 
          label: "Iceland ",
        },
        {
          value: "Israel" , 
          label: "Israel ",
        },
        {
          value: "Italy" , 
          label: "Italy ",
        },
        {
          value: "Jamaica" , 
          label: "Jamaica ",
        },
        {
          value: "Jersey" , 
          label: "Jersey ",
        },
        {
          value: "Jordan" , 
          label: "Jordan ",
        },
        {
          value: "Japan" , 
          label: "Japan ",
        },
        {
          value: "Kazakhstan" , 
          label: "Kazakhstan ",
        },
        {
          value: "Kenya" , 
          label: "Kenya ",
        },
        {
          value: "Kyrgyzstan" , 
          label: "Kyrgyzstan ",
        },
        {
          value: "Cambodia" , 
          label: "Cambodia ",
        },
        {
          value: "Kiribati" , 
          label: "Kiribati ",
        },
        {
          value: "Saint Kitts and Nevis" , 
          label: "Saint Kitts and Nevis ",
        },
        {
          value: "South Korea" , 
          label: "South Korea ",
        },
        {
          value: "Kosovo" , 
          label: "Kosovo ",
        },
        {
          value: "Kuwait" , 
          label: "Kuwait ",
        },
        {
          value: "Laos" , 
          label: "Laos ",
        },
        {
          value: "Lebanon" , 
          label: "Lebanon ",
        },
        {
          value: "Liberia" , 
          label: "Liberia ",
        },
        {
          value: "Libya" , 
          label: "Libya ",
        },
        {
          value: "Saint Lucia" , 
          label: "Saint Lucia ",
        },
        {
          value: "Liechtenstein" , 
          label: "Liechtenstein ",
        },
        {
          value: "Sri Lanka" , 
          label: "Sri Lanka ",
        },
        {
          value: "Lesotho" , 
          label: "Lesotho ",
        },
        {
          value: "Lithuania" , 
          label: "Lithuania ",
        },
        {
          value: "Luxembourg" , 
          label: "Luxembourg ",
        },
        {
          value: "Latvia" , 
          label: "Latvia ",
        },
        {
          value: "Macau" , 
          label: "Macau ",
        },
        {
          value: "Saint Martin" , 
          label: "Saint Martin ",
        },
        {
          value: "Morocco" , 
          label: "Morocco ",
        },
        {
          value: "Monaco" , 
          label: "Monaco ",
        },
        {
          value: "Moldova" , 
          label: "Moldova ",
        },
        {
          value: "Madagascar" , 
          label: "Madagascar ",
        },
        {
          value: "Maldives" , 
          label: "Maldives ",
        },
        {
          value: "Mexico" , 
          label: "Mexico ",
        },
        {
          value: "Marshall Islands" , 
          label: "Marshall Islands ",
        },
        {
          value: "North Macedonia" , 
          label: "North Macedonia ",
        },
        {
          value: "Mali" , 
          label: "Mali ",
        },
        {
          value: "Malta" , 
          label: "Malta ",
        },
        {
          value: "Myanmar" , 
          label: "Myanmar ",
        },
        {
          value: "Montenegro" , 
          label: "Montenegro ",
        },
        {
          value: "Mongolia" , 
          label: "Mongolia ",
        },
        {
          value: "Northern Mariana Islands" , 
          label: "Northern Mariana Islands ",
        },
        {
          value: "Mozambique" , 
          label: "Mozambique ",
        },
        {
          value: "Mauritania" , 
          label: "Mauritania ",
        },
        {
          value: "Montserrat" , 
          label: "Montserrat ",
        },
        {
          value: "Martinique" , 
          label: "Martinique ",
        },
        {
          value: "Mauritius" , 
          label: "Mauritius ",
        },
        {
          value: "Malawi" , 
          label: "Malawi ",
        },
        {
          value: "Malaysia" , 
          label: "Malaysia ",
        },
        {
          value: "Mayotte" , 
          label: "Mayotte ",
        },
        {
          value: "Namibia" , 
          label: "Namibia ",
        },
        {
          value: "New Caledonia" , 
          label: "New Caledonia ",
        },
        {
          value: "Niger" , 
          label: "Niger ",
        },
        {
          value: "Norfolk Island" , 
          label: "Norfolk Island ",
        },
        {
          value: "Nigeria" , 
          label: "Nigeria ",
        },
        {
          value: "Nicaragua" , 
          label: "Nicaragua ",
        },
        {
          value: "Niue" , 
          label: "Niue ",
        },
        {
          value: "Netherlands" , 
          label: "Netherlands ",
        },
        {
          value: "Norway" , 
          label: "Norway ",
        },
        {
          value: "Nepal" , 
          label: "Nepal ",
        },
        {
          value: "Nauru" , 
          label: "Nauru ",
        },
        {
          value: "New Zealand" , 
          label: "New Zealand ",
        },
        {
          value: "Oman" , 
          label: "Oman ",
        },
        {
          value: "Pakistan" , 
          label: "Pakistan ",
        },
        {
          value: "Panama" , 
          label: "Panama ",
        },
        {
          value: "Pitcairn Islands" , 
          label: "Pitcairn Islands ",
        },
        {
          value: "Peru" , 
          label: "Peru ",
        },
        {
          value: "Philippines" , 
          label: "Philippines ",
        },
        {
          value: "Palau" , 
          label: "Palau ",
        },
        {
          value: "Papua New Guinea" , 
          label: "Papua New Guinea ",
        },
        {
          value: "Poland" , 
          label: "Poland ",
        },
        {
          value: "Puerto Rico" , 
          label: "Puerto Rico ",
        },
        {
          value: "North Korea" , 
          label: "North Korea ",
        },
        {
          value: "Portugal" , 
          label: "Portugal ",
        },
        {
          value: "Paraguay" , 
          label: "Paraguay ",
        },
        {
          value: "Palestine" , 
          label: "Palestine ",
        },
        {
          value: "French Polynesia" , 
          label: "French Polynesia ",
        },
        {
          value: "Qatar" , 
          label: "Qatar ",
        },
        {
          value: "Réunion" , 
          label: "Réunion ",
        },  
        {
          value: "Romania" , 
          label: "Romania ",
        },  
        {
          value: "Russia" , 
          label: "Russia ",
        },  
        {
          value: "Rwanda" , 
          label: "Rwanda ",
        },  
        {
          value: "Saudi Arabia" , 
          label: "Saudi Arabia ",
        },  
        {
          value: "Sudan" , 
          label: "Sudan ",
        },  
        {
          value: "Senegal" , 
          label: "Senegal ",
        },  
        {
          value: "Singapore" , 
          label: "Singapore ",
        },  
        {
          value: "South Georgia" , 
          label: "South Georgia ",
        },  
        {
          value: "Svalbard and Jan Mayen" , 
          label: "Svalbard and Jan Mayen ",
        },  
        {
          value: "Solomon Islands" , 
          label: "Solomon Islands ",
        },  
        {
          value: "Sierra Leone" , 
          label: "Sierra Leone ",
        },  
        {
          value: "El Salvador" , 
          label: "El Salvador ",
        },  
        {
          value: "San Marino" , 
          label: "San Marino ",
        },  
        {
          value: "Somalia" , 
          label: "Somalia ",
        },  
        {
          value: "Saint Pierre and Miquelon" , 
          label: "Saint Pierre and Miquelon ",
        },  
        {
          value: "Serbia" , 
          label: "Serbia ",
        },  
        {
          value: "South Sudan" , 
          label: "South Sudan ",
        },  
        {
          value: "São Tomé and Príncipe" , 
          label: "São Tomé and Príncipe ",
        },  
        {
          value: "Suriname" , 
          label: "Suriname ",
        },  
        {
          value: "Slovakia" , 
          label: "Slovakia ",
        },  
        {
          value: "Slovenia" , 
          label: "Slovenia ",
        },  
        {
          value: "Sweden" , 
          label: "Sweden ",
        },  
        {
          value: "Eswatini" , 
          label: "Eswatini ",
        },  
        {
          value: "Sint Maarten" , 
          label: "Sint Maarten ",
        },  
        {
          value: "Seychelles" , 
          label: "Seychelles ",
        },  
        {
          value: "Syria" , 
          label: "Syria ",
        },  
        {
          value: "Turks and Caicos Islands" , 
          label: "Turks and Caicos Islands ",
        },  
        {
          value: "Chad" , 
          label: "Chad ",
        },  
        {
          value: "Togo" , 
          label: "Togo ",
        },  
        {
          value: "Thailand" , 
          label: "Thailand ",
        },  
        {
          value: "Tajikistan" , 
          label: "Tajikistan ",
        },  
        {
          value: "Tokelau" , 
          label: "Tokelau ",
        },  
        {
          value: "Turkmenistan" , 
          label: "Turkmenistan ",
        },  
        {
          value: "Timor-Leste" , 
          label: "Timor-Leste ",
        },  
        {
          value: "Tonga" , 
          label: "Tonga ",
        },  
        {
          value: "Trinidad and Tobago" , 
          label: "Trinidad and Tobago ",
        },  
        {
          value: "Tunisia" , 
          label: "Tunisia ",
        },  
        {
          value: "Turkey" , 
          label: "Turkey ",
        },  
        {
          value: "Tuvalu" , 
          label: "Tuvalu ",
        },  
        {
          value: "Taiwan" , 
          label: "Taiwan ",
        },  
        {
          value: "Tanzania" , 
          label: "Tanzania ",
        },  
        {
          value: "Uganda" , 
          label: "Uganda ",
        },  
        {
          value: "Ukraine" , 
          label: "Ukraine ",
        },  
        {
          value: "United States Minor Outlying Islands" , 
          label: "United States Minor Outlying Islands ",
        },  
        {
          value: "Uruguay" , 
          label: "Uruguay ",
        },  
        {
          value: "United States" , 
          label: "United States ",
        },  
        {
          value: "Uzbekistan" , 
          label: "Uzbekistan ",
        },  
        {
          value: "Vatican City" , 
          label: "Vatican City ",
        },  
        {
          value: "Saint Vincent and the Grenadines" , 
          label: "Saint Vincent and the Grenadines ",
        },  
        {
          value: "Venezuela" , 
          label: "Venezuela ",
        },  
        {
          value: "British Virgin Islands" , 
          label: "British Virgin Islands ",
        },  
        {
          value: "United States Virgin Islands" , 
          label: "United States Virgin Islands ",
        },  
        {
          value: "Vietnam" , 
          label: "Vietnam ",
        },  
        {
          value: "Vanuatu" , 
          label: "Vanuatu ",
        },  
        {
          value: "Wallis and Futuna" , 
          label: "Wallis and Futuna ",
        },  
        {
          value: "Samoa" , 
          label: "Samoa ",
        },  
        {
          value: "Yemen" , 
          label: "Yemen ",
        },  
        {
          value: "South Africa" , 
          label: "South Africa ",
        },  
        {
          value: "Zambia" , 
          label: "Zambia ",
        },  
        {
          value: "Zimbabwe" , 
          label: "Zimbabwe ",
        },  
        {
          value: "Dhekelia" , 
          label: "Dhekelia ",
        },  
        {
          value: "Somaliland" , 
          label: "Somaliland ",
        },  
        {
          value: "USNB Guantanamo Bay" , 
          label: "USNB Guantanamo Bay ",
        },  
        {
          value: "Brazilian I." , 
          label: "Brazilian I. ",
        },  
        {
          value: "N. Cyprus" , 
          label: "N. Cyprus ",
        },  
        {
          value: "Cyprus U.N. Buffer Zone" , 
          label: "Cyprus U.N. Buffer Zone ",
        },  
        {
          value: "Cyprus U.N. Buffer Zone" , 
          label: "Cyprus U.N. Buffer Zone ",
        },  
        {
          value: "Siachen Glacier" , 
          label: "Siachen Glacier ",
        },  
        {
          value: "Baikonur" , 
          label: "Baikonur ",
        },  
        {
          value: "Akrotiri" , 
          label: "Akrotiri ",
        },  
        {
          value: "Southern Patagonian Ice Field" , 
          label: "Southern Patagonian Ice Field ",
        }, 
        {
          value: "Bir Tawil" , 
          label: "Bir Tawil ",
        },
        {
          value: "Indian Ocean Ter." , 
          label: "Indian Ocean Ter. ",
        }, 
        {
          value: "Coral Sea Is." , 
          label: "Coral Sea Is. ",
        },
        {
          value: "Spratly Is." , 
          label: "Spratly Is. ",
        },
        {
          value: "Clipperton I." , 
          label: "Clipperton I. ",
        },
        {
          value: "Ashmore and Cartier Is." , 
          label: "Ashmore and Cartier Is. ",
        },
        {
          value: "Bajo Nuevo Bank" , 
          label: "Bajo Nuevo Bank ",
        },
        {
          value: "Serranilla Bank" , 
          label: "Serranilla Bank ",
        },
        {
          value: "Scarborough Reef" , 
          label: "Scarborough Reef ",
        },
        {
          value: "Europe Union" , 
          label: "Europe Union ",
        },
  ]