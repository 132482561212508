import NavBar from "layout/NavBar";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CrudForm from "./components/CrudForm";
import axios from "axios";
import { toast } from "react-toastify";
import AlertModal from "../../components/AlertModal";

import moment from "moment/moment";

const pageTitle = "Create Employee";

export default function CreateEmployee(props) {
  const { user } = props;
  const [isDisableDraft, setIsDisableDraft] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(false);
  const [customError, setCustomError] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [nonRegisterederror, setNonRegisterederror] = useState({});

  const [sendingData, setSendingData] = useState(false);
  const [stateData, setStateData] = useState(true);
  const [errorHold, setErrorHold] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);

  let navigate = useNavigate();

  // Set page title
  useEffect(() => {
    document.title = pageTitle;
  }, []);

  const [tabSelect, setTabSelect] = useState("employee");

  let initialRequiredField = {
    personal_information: {
      name: "",
      nationality: "",
      res_country: "",
      res_country_ar: "",
      hometown_address: "",
      local_address: "",
    },

    contact_information: {
      email: "",
      cellphone_number: "",
    },
    bank_passport_information: {
      passport_no: "",
      passport_expire_date: "",
      passport_file: null,
    },
    company_information: {
      joining_date: "",
      company_id: "",
      company_location_id: "",
      department_id: "",
      job_title: "",
      job_title_ar: "",
      sponsorship: "",
      position_id: "",
    },
    iqama_medical_information: {
      national_iqama_id: "",
      national_iqama_id_expire_date: "",
      iqama_id_file: "",
    },
    salary_information: {
      basic_salary: "",
      housing_salary: "",
      transpiration_salary: "",
    },
    working_hours: {},
    job_type: {
      job_type: "",
    },
  };

  let initialValues = {
    name: "",
    nationality: "",
    res_country: "",
    res_country_ar: "",
    hometown_address: "",
    local_address: "",
    email: "",
    cellphone_number: "",
    passport_no: "",
    passport_expire_date: "",
    passport_file: null,
    joining_date: "",
    sponsorship: "",
    company_id: "",
    company_location_id: "",
    job_title: "",
    job_title_ar: "",
    department_id: "",
    position_id: "",
    national_iqama_id: "",
    national_iqama_id_expire_date: "",
    iqama_id_file: "",
    basic_salary: "",
    housing_salary: "",
    transpiration_salary: "",
    job_type: "",
    Working_at_office: "",
  };

  const [data, setData] = useState();

  function closeForm() {
    setAlertModalOpen(false);
    navigate("/employees");
  }

  // Send data
  const sendData = (values) =>
    new Promise((resolve) => {
      setSendingData(false);
      setIsDisableSubmit(true);
      const formData = new FormData();
      const roleIds = values.roles?.map((item) => parseInt(item.id));
      if (values.name) {
        formData.append("name", values.name);
      }
      if (values.name_arabic) {
        formData.append("name_arabic", values.name_arabic);
      }
      if (values.profile_pic_url) {
        formData.append("profile_pic_url", values.profile_pic_url.fileUrl);
      }
      if (values.gender) {
        formData.append("gender", values.gender);
      }
      if (values.dob) {
        formData.append("dob", moment(values.dob).format("YYYY-MM-DD"));
      }
      if (values.nationality) {
        formData.append("nationality", values.nationality?.value);
      }
      if (values.nationality_ar) {
        formData.append("nationality_ar", values.nationality_ar?.value);
      }
      if (values.res_country) {
        formData.append("resident_country", values.res_country?.value);
      }
      if (values.res_country_ar) {
        formData.append(
          "resident_country_arabic",
          values.res_country_ar?.value
        );
      }

      if (values.hometown_address) {
        formData.append("hometown_address", values.hometown_address);
      }

      if (values.local_address) {
        formData.append("local_address", values.local_address);
      }

      if (values.marital_status) {
        if (values.marital_status === "Single") {
          formData.append("marital_status", values.marital_status);
        }
        if (values.marital_status === "Married") {
          formData.append("marital_status", values.marital_status);
          formData.append("number_of_wife", values?.number_of_wife);
          formData.append("number_of_children", values?.number_of_children);
        }
        if (values.marital_status === "Divorced") {
          formData.append("marital_status", values.marital_status);
          formData.append("number_of_children", values?.number_of_children);
        }
      }

      // contact information

      if (values.email) {
        formData.append("email", values.email);
      }

      if (values.personal_email) {
        formData.append("personal_email", values.personal_email);
      }
      if (values.cellphone_number) {
        formData.append("cell_phone_number", values.cellphone_number);
      }
      if (values.alternative_mobile) {
        formData.append("alternative_mobile", values.alternative_mobile);
      }
      if (values.emergency_contact_relationship) {
        formData.append(
          "emergency_contact_relationship",
          values.emergency_contact_relationship
        );
      }
      if (values.emergency_contact_name) {
        formData.append(
          "emergency_contact_name",
          values.emergency_contact_name
        );
      }
      if (values.emergency_contact_mobile_number) {
        formData.append(
          "emergency_contact_mobile_number",
          values.emergency_contact_mobile_number
        );
      }

      // bank & passport Information

      if (values.passport_no) {
        formData.append("passport_no", values.passport_no);
      }
      if (values.passport_expire_date) {
        formData.append(
          "passport_expire_date",
          moment(values.passport_expire_date).format("YYYY-MM-DD h:mm:ss")
        );
      }
      if (values.passport_file) {
        formData.append("passport_file", values.passport_file.fileUrl);
        formData.append("passport_filename", values.passport_file.fileName);
      }

      if (values.iban_file) {
        formData.append("iban_file", values.iban_file.fileUrl);
        formData.append("iban_filename", values.iban_file.fileName);
      }
      if (values.bank_accounts?.length > 0) {
        const bank_accounts = values.bank_accounts.map((account) => {
          return {
            bank_name: account.name,
            iban: account.number,
          };
        });
        formData.append("bank_accounts", JSON.stringify(bank_accounts));
      }

      // Company Information

      if (values.joining_date) {
        formData.append(
          "joining_date",
          moment(values.joining_date).format("YYYY-MM-DD h:mm:ss")
        );
      }
      if (values.leaving_date) {
        formData.append(
          "leaving_date",
          moment(values.leaving_date).format("YYYY-MM-DD h:mm:ss")
        );
      }

      if (values.position_id) {
        formData.append("position_id", values.position_id?.id);
      }

      // if (values.sponsorship) {
      //   formData.append("sponsorship" , values.sponsorship?.value)
      //  }

      // if (!values.position) {
      //   formData.append("position_id" , data?.id )
      //  }

      if (values.roles) {
        formData.append("roles", JSON.stringify(roleIds));
      }
      if (values.company_id) {
        formData.append("company_id", values.company_id.id);
        formData.append("sponsorship", values.sponsorship.value);
      }

      if (values.company_location_id) {
        formData.append("company_location_id", values.company_location_id.id);
      }
      if (values.department_id) {
        formData.append("department_id", values.department_id.id);
      }

      if (values.division_id) {
        formData.append("division_id", values?.division_id?.id);
      }
      if (values.job_title) {
        formData.append("job_title", values.job_title);
      }
      if (values.job_title_ar) {
        formData.append("job_title_arabic", values.job_title_ar);
      }

      if (values.line_manager_id) {
        formData.append("line_manager_id", values.line_manager_id?.id);
      }

      // iqama & medical information

      if (values.national_iqama_id) {
        formData.append("iqama_id", values.national_iqama_id);
      }

      if (values.national_iqama_id_expire_date) {
        formData.append(
          "iqama_id_expire_date",
          moment(values.national_iqama_id_expire_date).format(
            "YYYY-MM-DD h:mm:ss"
          )
        );
      }
      if (values.iqama_id_file) {
        formData.append("iqama_id_file", values.iqama_id_file.fileUrl);
        formData.append("iqama_id_filename", values.iqama_id_file.fileName);
      }
      if (values.medical_membership_no) {
        formData.append("medical_membership_no", values.medical_membership_no);
      }

      // sallary Information

      if (values.basic_salary) {
        formData.append("basic_salary", values.basic_salary);
      }
      if (values.housing_salary) {
        formData.append("housing_salary", values.housing_salary);
      }
      if (values.transpiration_salary) {
        formData.append("transpiration_salary", values.transpiration_salary);
      }
      // if (values.salary_total) {
      //   formData.append("salary_total", values.salary_total);
      // }

      // working hours

      const businessHours = {
        friday: [
          values.friday_timing
            ? values.friday_timing
            : { open: null, close: null },
        ],
        saturday: [
          values.saturday_timing
            ? values?.saturday_timing
            : { open: null, close: null },
        ],
        sunday: [
          values.sunday_timing
            ? values.sunday_timing
            : { open: null, close: null },
        ],
        monday: [
          values.monday_timing
            ? values.monday_timing
            : { open: null, close: null },
        ],
        tuesday: [
          values.tuesday_timing
            ? values.tuesday_timing
            : { open: null, close: null },
        ],
        wednesday: [
          values.wednesday_timing
            ? values.wednesday_timing
            : { open: null, close: null },
        ],
        thursday: [
          values.thursday_timing
            ? values.thursday_timing
            : { open: null, close: null },
        ],
      };

      if (values.Working_at_office) {
        formData.append("office_id", values.Working_at_office?.id);
      }

      // if (businessHours) {
      formData.append("business_hours", JSON.stringify(businessHours));
      // }

      if (values.timezone) {
        formData.append("timezone", values.timezone);
      }
      if (values.close_payroll_day) {
        formData.append(
          "close_payroll_day",
          parseInt(values.close_payroll_day)
        );
      }

      //group_department_toggle
      if (values.group_department_toggle) {
        formData.append(
          "group_department_toggle",
          values?.group_department_toggle
        );
      }

      if (values.job_type) {
        formData.append("job_type", values.job_type);
      }

      // if (values.is_remote) {
      // formData.append("is_remote", values.is_remote);
      if (values.is_remote === true) {
        formData.append("is_remote", 1);
      } else {
        formData.append("is_remote", 0);
      }
      // }

      if (values.contracts) {
        formData.append("contracts", values.contracts);
      }
      if (values.contracts_file) {
        formData.append("contracts_file", values.contracts_file.fileUrl);
        formData.append("contracts_fileName", values.contracts_file.fileName);
      }

      formData.append("working_days", 1);

      axios
        .post("employees/store", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.status === 200) toast.success(response.data.message);
          else toast.error(response.data.message);

          setTimeout(function () {
            navigate("/employees");
          }, 2000);
        })
        .catch(function (error) {
          const emailError = JSON.parse(error.response.data.message);
          if (emailError.email) {
            if (emailError.email[0] === "The email has already been taken.") {
              setErrorHold(true);
              setSendingData(false);
              toast.error(`${emailError.email[0]} (In Contact Info Tab)`);
            }
          } else {
            toast.error(error.message);
            setErrorHold(false);
            setSendingData(false);
          }
          resolve();
        });
    });

  const onSubmit = async (values) => {
    await sendData(values);
  };

  const errorShowingHanlder = () => {
    setIsDisableSubmit(true);
    if (JSON.stringify(customError) !== "{}") {
      setSubmitted(true);
      toast.error("Please validate your highlighted Tabs!");
      setIsDisableSubmit(false);
    }
  };
  return (
    <div className="h-full">
      {/* Page title & actions */}
      <NavBar pageTitle={pageTitle}>
        <a
          href="#"
          onClick={() => setAlertModalOpen(true)}
          disabled={sendingData}
          className="inline-flex items-center px-[10px] py-[5px] sm:px-3 sm:py-2 border border-gray-300 shadow-sm text-xs sm:text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-[3px] sm:mr-2 disabled:opacity-75"
        >
          Cancel
        </a>
        <button
          disabled={isDisableDraft}
          type="button"
          // onClick={() => saveAsDraft(getState().values)}
          onClick={() =>
            document.getElementById("draftmainFormSubmitEmployee").click()
          }
          className="mr-[3px] sm:mr-2 relative inline-flex items-center px-[7px] py-[3px] xs:px-[10px] xs:py-[5px] sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-0 disabled:opacity-75"
        >
          {isDisableDraft ? "Submitting.." : "Save and Continue Later"}
        </button>

        {JSON.stringify(customError) !== "{}" ? (
          <button
            disabled={stateData}
            type="submit"
            onClick={errorShowingHanlder}
            className="relative inline-flex items-center px-[10px] py-[5px] sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
          >
            {isDisableSubmit ? "Submitting..." : "Create"}
          </button>
        ) : (
          <button
            disabled={stateData}
            type="submit"
            onClick={() =>
              document.getElementById("mainFormSubmitEmployee").click()
            }
            className="relative inline-flex items-center px-[10px] py-[5px] sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
          >
            {isDisableSubmit ? "Submitting..." : "Create"}
          </button>
        )}
      </NavBar>
      {/* /Page title & actions */}

      <main className="">
        <div className="max-w-3xl mx-auto px-0 sm:px-6  md:space-x-5 lg:max-w-full lg:px-8 mt-0 relative pb-0 lg:pb-6">
          <CrudForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            savaButtonAction={(val) => setStateData(val)}
            initialRequiredField={initialRequiredField}
            tabSelect={tabSelect}
            user={user}
            setData={setData}
            isDisableDraft={isDisableDraft}
            setIsDisableDraft={setIsDisableDraft}
            errorHold={errorHold}
            setErrorHold={setErrorHold}
            customError={customError}
            setCustomError={setCustomError}
            setSubmitted={setSubmitted}
            submitted={submitted}
            nonRegisterederror={nonRegisterederror}
            setNonRegisterederror={setNonRegisterederror}
            isDisableSubmit={isDisableSubmit}
          />
        </div>
      </main>
      <AlertModal
        title="Close Form"
        confirm={closeForm}
        close={() => setAlertModalOpen(false)}
        open={alertModalOpen}
      />
    </div>
  );
}
