import React, { useState } from "react";
import axios from "axios";
import { TrashIcon, PencilAltIcon } from "@heroicons/react/solid";
// import PaginationAll from './components/pagination';
import PaginationAll from "components/pagination";
// import Pagination from 'components/Pagination';
import { useMemo, useEffect } from "react";
import { toast } from "react-toastify";
import AlertModal from "components/AlertModal";
import SkeletonTable from "components/SkeletonTable";
import EmptyStates from "components/EmptyStates";

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

export default function List() {
  const [showModal, setShowModal] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [serviceId, setServiceId] = useState();
  const [deletingItem, setDeletingItem] = useState({ name: null });
  const [deleteItemModalOpen, setDeleteItemModalOpen] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const itemsPerPage = 5;
  const initialValue = {
    name: "",
  };
  const [formValues, setFormValues] = useState(initialValue);

  const [data, setData] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // reset current page when search query changes
  };

  const fetchData = async () => {
    setIsDataLoading(true);

    try {
      const res = await axios.get("/service/index");
      setData(res.data?.data);
      setIsDataLoading(false);
    } catch (error) {}
  };

  const addService = () => {
    axios
      .post("/service/store", formValues)
      .then((response) => {
        if (response.data) setShowModal(false);
        fetchData();
        setShowModal(false);
        toast.success("Record save successfully! ");
      })
      .catch((error) => {});

    setFormValues({ ...formValues, ...initialValue });
  };

  const filteredData = useMemo(() => {
    if (!searchQuery) {
      return data;
    }

    return data?.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, data]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = filteredData?.slice(startIndex, endIndex);

  useEffect(() => {
    fetchData();
  }, []);

  const confirmDeleteService = async () => {
    try {
      const response = await axios.get(`/service/delete/${deletingItem.id}`);
      setDeleteItemModalOpen(false);
      fetchData();
    } catch (error) {
      setDeleteItemModalOpen(false);
    }
  };

  const singleService = async (id) => {
    setServiceId(id);
    try {
      const response = await axios.get(`/service/edit/${id}`);
      setFormValues(response.data.data);
      setShowModal(true);
    } catch (error) {}
  };

  const updateService = async () => {
    await axios
      .post(`/service/update/${serviceId}`, {
        ...formValues,
      })
      .then((response) => {
        fetchData();
        setShowModal(false);
        toast.success("Record update successfully!");
      })
      .catch((error) => {});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (serviceId) updateService();
    else addService();
  };

  const modelOpen = () => {
    setShowModal(true);
    setFormValues({ ...formValues, ...initialValue });
    setServiceId(null);
  };
  function deleteItem(item) {
    setDeletingItem(item);
    setDeleteItemModalOpen(true);
  }
  return (
    <div>
      <div className="sticky top-0 flex lg:z-50 h-20 sm:h-14 mt-5 lg:mt-0 flex-shrink-0 bg-white shadow">
        <div className="flex flex-1 justify-between px-1 sm:px-4">
          <div className="flex items-center mt-1 sm:mt-0 h-[100%] my-auto align-middle">
            <h1 className="text-sm sm:text-lg font-medium leading-6 text-gray-900 sm:truncate">
              Services
            </h1>
          </div>
          <div className="flex items-center mb-3 sm:mb-0 h-[100%] my-auto align-middle">
            <button
              className="relative inline-flex items-center px-2 py-1 sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
              onClick={modelOpen}
            >
              Add New Service
            </button>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 col-span-full lg:col-span-3">
        <div className="col-span-12 lg:col-span-3">
          <div className="mt-1 relative rounded-md">
            <form className="pr-3 pt-3 pb-3">
              <input
                className="p-2 w-full lg:w-2/5 outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded"
                type="text"
                placeholder="Search by service name..."
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </form>
          </div>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        {isDataLoading && <SkeletonTable />}
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr className="border-t border-gray-200">
              <th
                scope="col"
                className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Actions
              </th>
              <th
                scope="col"
                className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Service Name
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedData?.map((item, index) => (
              <tr className="bg-white border-b-[1px] border-gray-200">
                <td className="px-6 py-4 flex gap-3">
                  <button onClick={() => deleteItem(item)}>
                    <TrashIcon
                      className="w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </button>
                  <button onClick={() => singleService(item.id)}>
                    <PencilAltIcon
                      className="w-5 text-green-400"
                      aria-hidden="true"
                    />
                  </button>
                </td>
                <td
                  scope="row"
                  className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left"
                >
                  {item.name}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        {showModal ? (
          <>
            <form
              onSubmit={handleSubmit}
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto md:w-[60%] lg:w-[40%] xl:w-[30%] my-6 mx-auto max-w-3xl">
                <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-[100%] bg-white outline-none focus:outline-none">
                  <div className="relative p-6 flex-auto">
                    <div className="flex justify-between">
                      <div>
                        <h1 className="text-lg font-medium">Add Service</h1>
                      </div>
                      {/* <div>
                                                <p className='cursor-pointer' onClick={() => setShowModal(false)}>x</p>
                                            </div> */}
                    </div>
                    <div className="mt-4">
                      <label
                        for="service_name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Service Name
                      </label>
                      <input
                        type="text"
                        id="default-input"
                        required
                        value={formValues.name}
                        name="name"
                        onChange={handleChange}
                        placeholder="Ex : # Hr Finance"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        style={{ width: "full%" }}
                      />
                    </div>
                    <div className="flex gap-2">
                      <button
                        className="w-full bg-indigo-600 hover:bg-indigo-700 text-white mt-2 p-3 rounded-md"
                        type="submit"
                      >
                        {serviceId ? "Update Service" : "Add Service"}
                      </button>
                      {
                        <button
                          className="w-full bg-red-500 hover:bg-red-700 text-white mt-2 p-3 rounded-md"
                          onClick={() => setShowModal(false)}
                        >
                          Close
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        ) : null}
      </div>
      <AlertModal
        title="Delete Company"
        note={`Are you sure you want to delete "${deletingItem.name}" Company.`}
        confirm={confirmDeleteService}
        close={() => setDeleteItemModalOpen(false)}
        open={deleteItemModalOpen}
      />
      <PaginationAll
        currentPage={currentPage}
        totalPages={Math.ceil(data?.length / itemsPerPage)}
        onPageChange={handlePageChange}
      />
      {!displayedData?.length ? <EmptyStates /> : ""}
    </div>
  );
}
