export default function FormSection(props) {
    const { title, subTitle, children, isShow = true, tab } = props;

    return (<div data-tab={tab} className={isShow ? 'g-form-section' : 'g-form-section hidden'}>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white py-6 px-4 sm:p-6 ">
                {title && <div className="mb-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
                    <p className="mt-1 text-sm text-gray-500">{subTitle}</p>
                </div>}
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    {children}
                </div>
            </div>
        </div>
    </div>)
}