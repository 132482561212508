import { useState, useEffect } from 'react';
import axios from 'axios';
import FormBuilerEditorCKEditorField from './formBuilderEditorFields/FormBuilerEditorCKEditorField';
import FormBuilerEditorToggleField from './formBuilderEditorFields/FormBuilerEditorToggleField';
import Error from 'layout/Error';
import Loader from 'layout/Loader';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

export default function FormBuilderFieldEditor(props) {
    const { fieldIndex, formFieldValue, updateField, formFields } = props;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(true);
    const [formField, setFormField] = useState(formFieldValue);
    const [fieldOptionCount, setFieldOptionCount] = useState(0);
    const [formBuilderData, setFormBuilderData] = useState({
        user_fields: [],
        user_date_fields: [],
    });

    useEffect(() => {
        // axios.get('form-builder/data')
        //     .then(function (response) {
        //         console.log('res', response);
        //         // handle success
        //         setFormBuilderData(response.data)
        //         setIsLoaded(true);
        //     })
        //     .catch(function (error) {
        //         console.log('res', error);
        //         // handle error
        //         setError(error);
        //     });
    }, []);

    useEffect(() => {
        setFormField(formFieldValue)
        setFieldOptionCount(0)
    }, [formFieldValue]);

    useEffect(() => {
        updateField(fieldIndex, formField)
    }, [formField]);

    // Computed
    const dbCompanyFormFields = function () {
        let fields = [];
        formFields.forEach(function(formField) {
            if (formField.type === 'dbmodel') {
                if (formField.model === 'companies') {
                    fields.push(formField);
                }
            }
        });
        return fields;
    }
    // /Computed

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    function generateRandomOptionValue() {
        let optionValue = 'option-'+getRandomInt(99999999);
        let optionValueIndex = formField.values.findIndex(x => x.value === optionValue);
        while (optionValueIndex >= 0) {
            optionValue = 'option-'+getRandomInt(99999999);
            optionValueIndex = formField.values.findIndex(x => x.value === optionValue);
        }
        return optionValue;
    }   

    function addFieldOption() {
        let newFormField = {...formField}
        let count = newFormField.values.length + 1;
        setFieldOptionCount(() => count);

        newFormField.values.push(
            {
                label: 'Option '+count,
                value: generateRandomOptionValue(),
                selected: false,
            }
        );

        setFormField(newFormField);
    }
    
    function removeFieldOption(index) {
        let newFormField = {...formField}
        newFormField.values.splice(index, 1);
        setFormField(newFormField);
    }

    function onChangeFieldOption(ev, index) {
        let newFormField = {...formField}
        newFormField.values[index].label = ev.target.value;
        setFormField(newFormField);
    }

    if (error) {
        return <Error error={error} />;
    }

    if (!isLoaded) {
        return <Loader fullscreen={false} />;
    }

    return (<div className="p-2">
        {formField !== null && <div>
            <h2 className="font-bold mb-2">Field Attributes [ID: {formField.name}]</h2>

            <div>
                {/* ======= START FORM FIELD EDITOR ===== */}

                <div className="grid grid-cols-12 gap-6 mt-5">
                    {!['table', 'paragraph', 'header', 'boolean'].includes(formField.type) && <div className="col-span-12 lg:col-span-3">
                        <FormBuilerEditorToggleField label="Required" value={formField.required} onChange={(value) => {setFormField({...formField, required: value})}} />
                    </div>}

                    {!['dbmodel', 'supplierdata', 'header'].includes(formField.type) && <div className="col-span-12 lg:col-span-3">
                        <FormBuilerEditorToggleField label={'RTL'+((formField.type !== 'table') ? ' value' : '')} value={formField.rtl} onChange={(value) => {setFormField({...formField, rtl: value})}}  />
                    </div>}

                    {!['table', 'dbmodel', 'supplierdata'].includes(formField.type) && <div className="col-span-12 lg:col-span-3">
                        <FormBuilerEditorToggleField label="RTL label" value={formField.rtl_label} onChange={(value) => {setFormField({...formField, rtl_label: value})}} />
                    </div>}

                    {['file'].includes(formField.type) && <div className="col-span-12 lg:col-span-3">
                        <FormBuilerEditorToggleField label="Mutiple upload" value={formField.multiple} onChange={(value) => {setFormField({...formField, multiple: value})}} />
                    </div>}
                </div>

                <div className="mt-5">
                    <div className="flex justify-between">
                        <label className="block text-sm font-medium text-gray-700">Label <span className="ml-1 text-red-500">*</span></label>
                        <span className="text-sm text-gray-500">Hidden on form</span>
                    </div>
                    <div className="mt-1">
                        <input value={formField.label} onChange={(ev) => {setFormField({...formField, label: ev.target.value})}} dir={(formField.rtl_label) ? 'rtl' : 'ltr'} type="text" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div>
                </div>

                {/* <div v-if="formField.type === 'paragraph'" className="input-form mt-5">
                    <ckeditor v-model="formField.text" :config="editorConfig" v-show="!formField.rtl"></ckeditor>
                    <ckeditor v-model="formField.text" :config="rtlEditorConfig" v-show="formField.rtl"></ckeditor>
                </div> */}

                { ['paragraph'].includes(formField.type) && <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700">Content</label>
                    <div className="mt-3">
                        <FormBuilerEditorCKEditorField rtl={formField.rtl} value={formField.text} onChange={(value) => {setFormField({...formField, text: value})}} />
                    </div>
                </div>}

                { ['select'].includes(formField.type) && <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700">Auto Filled</label>
                    <select value={formField.preFilled} onChange={(ev) => {setFormField({...formField, preFilled: ev.target.value})}} className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="">None</option>
                        <option value="bank_accounts">Company Bank Accounts</option>
                        <option value="company_locations">Company Locations</option>
                        <option value="credit_cards">Company Credit Cards</option>
                        <option value="employee_bank_accounts">Employee Bank Accounts</option>
                    </select>
                </div>}

                { ['file'].includes(formField.type) && <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700">File types allowed</label>
                    <select value={formField.fileTypesAllowed} onChange={(ev) => {setFormField({...formField, fileTypesAllowed: ev.target.value})}} className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="">All</option>
                        <option value="pdf">PDF Only</option>
                        <option value="pdf_image">PDF & Image (JPG, PNG)</option>
                    </select>
                </div>}

                { ['supplierdata'].includes(formField.type) && <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700">Data Fields</label>
                    <div className="mt-3">
                        <div className="inline-flex items-start mr-4">
                            <div className="flex h-5 items-center">
                                <input 
                                    checked={formField.data_field.bank_accounts} 
                                    onChange={(ev) =>  {setFormField({...formField, data_field: {...formField.data_field, bank_accounts: ev.target.checked}})}} 
                                    type="checkbox" id="data_field_bank_accounts" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" 
                                />
                            </div>
                            <div className="ml-2 text-sm">
                                <label for="data_field_bank_accounts" className="font-medium text-gray-700 select-none">Bank Accounts</label>
                            </div>
                        </div>
                        <div className="inline-flex items-start mr-4">
                            <div className="flex h-5 items-center">
                                <input 
                                    checked={formField.data_field.phones} 
                                    onChange={(ev) =>  {setFormField({...formField, data_field: {...formField.data_field, phones: ev.target.checked}})}} 
                                    type="checkbox" id="data_field_phones" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" 
                                />
                            </div>
                            <div className="ml-2 text-sm">
                                <label for="data_field_phones" className="font-medium text-gray-700 select-none">Phones</label>
                            </div>
                        </div>
                        <div className="inline-flex items-start mr-4">
                            <div className="flex h-5 items-center">
                                <input 
                                    checked={formField.data_field.addresses} 
                                    onChange={(ev) =>  {setFormField({...formField, data_field: {...formField.data_field, addresses: ev.target.checked}})}} 
                                    type="checkbox" id="data_field_addresses" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" 
                                />
                            </div>
                            <div className="ml-2 text-sm">
                                <label for="data_field_addresses" className="font-medium text-gray-700 select-none">Addresses</label>
                            </div>
                        </div>
                        <div className="inline-flex items-start mr-4">
                            <div className="flex h-5 items-center">
                                <input 
                                    checked={formField.data_field.vat} 
                                    onChange={(ev) =>  {setFormField({...formField, data_field: {...formField.data_field, vat: ev.target.checked}})}} 
                                    type="checkbox" id="data_field_vat" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" 
                                />
                            </div>
                            <div className="ml-2 text-sm">
                                <label for="data_field_vat" className="font-medium text-gray-700 select-none">VAT</label>
                            </div>
                        </div>
                        <div className="inline-flex items-start mr-4">
                            <div className="flex h-5 items-center">
                                <input 
                                    checked={formField.data_field.cr} 
                                    onChange={(ev) =>  {setFormField({...formField, data_field: {...formField.data_field, cr: ev.target.checked}})}} 
                                    type="checkbox" id="data_field_cr" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" 
                                />
                            </div>
                            <div className="ml-2 text-sm">
                                <label for="data_field_cr" className="font-medium text-gray-700 select-none">CR</label>
                            </div>
                        </div>
                    </div>
                </div>}

                { (formField.preFilled && formField.preFilled != 'employee_bank_accounts' && ['select'].includes(formField.type)) && <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700">Auto Filled based on</label>
                    <select value={formField.prefilledBasedOn} onChange={(ev) => {setFormField({...formField, prefilledBasedOn: ev.target.value})}} className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="">None</option>
                        
                    </select>
                </div>}

                {/* @if($mode == 'full')
                <div className="input-form mt-5"
                        v-if="formField.preFilled && formField.preFilled != 'employee_bank_accounts' && formField.type === 'select'">
                    <label className="form-label w-full flex flex-col sm:flex-row">
                        Auto Filled based on
                    </label>
                    <select v-model="formField.prefilledBasedOn" className="form-select">
                        <option value="">None</option>
                        <option v-if="logicFormField.type === 'dbmodel'"
                                :value="logicFormField.name"
                                v-for="logicFormField in dbCompanyFormFields">
                            @{{ logicFormField.label }}
                        </option>
                    </select>
                </div>
                    <div className="input-form mt-5"
                        v-if="formField.preFilled && formField.preFilled == 'employee_bank_accounts' && formField.type === 'select'">
                    <label className="form-label w-full flex flex-col sm:flex-row">
                        Auto Filled based on
                    </label>
                    <select v-model="formField.prefilledBasedOn" className="form-select">
                        <option value="">None</option>
                        <option v-if="logicFormField.type === 'dbmodel'"
                                :value="logicFormField.name"
                                v-for="logicFormField in dbEmployeesFormFields">
                            @{{ logicFormField.label }}
                        </option>
                    </select>
                </div>
                @endif */}

                { ['currency'].includes(formField.type) && <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700">Currency</label>
                    <select value={formField.currency} onChange={(ev) => {setFormField({...formField, currency: ev.target.value})}} className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="SAR">SAR</option>
                        <option value="USD">USD</option>
                        <option value="USD">EUR</option>
                        <option value="USD">GBP</option>
                        <option value="EGP">EGP</option>
                    </select>
                </div>}

                { ['select', 'radio-group', 'checkbox-group'].includes(formField.type) && <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700">Options <span className="ml-1 text-red-500">*</span></label>
                    <div className="mt-3">
                        {formField.values.map((option, index) => (
                            <div className="flex justify-between" key={option.label}>
                                <input type="text" className="g-input mb-2 mr-2 w-full" value={option.label} onChange={(ev) => {onChangeFieldOption(ev, index)}} />
                                {formField.values.length > 1 && <button type="button" className="h-[38px] text-sm text-red-500" onClick={() => removeFieldOption(index)}>Delete</button>}
                            </div>
                        ))}
                        <div className="text-right">
                            <button type="button" className="g-default-btn-sm" onClick={addFieldOption}>Add option +</button>
                        </div>
                    </div>

                </div>}

                {/* <div className="input-form mt-5"
                        v-if="formField.type === 'dbmodel'">
                    <label className="form-label w-full flex flex-col sm:flex-row">
                        DB Model <span className="ml-1 text-red-500">*</span>
                    </label>
                    <select v-model="formField.model" className="form-select">
                        @if(in_array(config('grandizer.version'), ['full', 'plus']))
                        <option value="companies">List of companies</option>
                        @endif
                        <option value="employees">List of employees</option>
                        @if(config('grandizer.version') == 'basic')
                            <option value="divisions">List of divisions</option>
                        @endif
                    </select>
                </div>
                <div className="input-form mt-5"
                        v-if="formField.model == 'companies'">

                    <div className="form-check mt-4">
                        <input id="based_on_company-switch" className="form-check-switch" type="checkbox" v-model="formField.based_on_company">
                        <label className="form-check-label" for="based_on_company-switch">Filter based on company manged by user</label>
                    </div>
                </div>
                <div className="input-form mt-5"
                        v-if="formField.model === 'employees'">
                    <label className="form-label w-full flex flex-col sm:flex-row">
                        Auto Filled based on
                    </label>
                    <select v-model="formField.prefilledBasedOn" className="form-select">
                        <option value="">None</option>
                        <option v-if="logicFormField.type === 'dbmodel'"
                                :value="logicFormField.name"
                                v-for="logicFormField in dbCompanyFormFields">
                            @{{ logicFormField.label }}
                        </option>
                        <option value="behalf">Based on chosen company on behalf
                        </option>
                    </select>
                </div> */}

                { ['dbmodel'].includes(formField.type) && <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700">DB Model <span className="ml-1 text-red-500">*</span></label>
                    <select value={formField.model} onChange={(ev) => {setFormField({...formField, model: ev.target.value})}} className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="companies">List of companies</option>
                        <option value="employees">List of employees</option>
                        {/* <option value="divisions">List of divisions</option> Was for basic Grandizer Only  */}
                    </select>
                </div>}

                { ['companies'].includes(formField.model) && <div className="mt-5">
                    <FormBuilerEditorToggleField label="Filter based on company manged by user" value={formField.based_on_company} onChange={(value) => {setFormField({...formField, based_on_company: value})}} />
                </div>}

                { ['employees'].includes(formField.model) && <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700">Auto Filled based on</label>
                    <select value={formField.prefilledBasedOn} onChange={(ev) => {setFormField({...formField, prefilledBasedOn: ev.target.value})}} className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="">None</option>
                        {dbCompanyFormFields().map((dbCompanyFormField) => (
                            <option key={dbCompanyFormField.name} value={dbCompanyFormField.name}>{dbCompanyFormField.label }</option>
                        ))}
                        <option value="behalf">Based on chosen company on behalf</option>
                    </select>
                </div>}

                { ['date', 'text', 'textarea'].includes(formField.type) && <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700">Auto Filled</label>
                    
                    { ['text', 'textarea'].includes(formField.type) && <select value={formField.preFilled} onChange={(ev) => {setFormField({...formField, preFilled: ev.target.value})}} className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="">None</option>
                        {formBuilderData.user_fields.map((userField) => (
                            <option key={userField.key} value={userField.key}>{userField.label}</option>
                        ))}
                    </select>}

                    { ['date'].includes(formField.type) && <select value={formField.preFilled} onChange={(ev) => {setFormField({...formField, preFilled: ev.target.value})}} className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="">None</option>
                        {formBuilderData.user_date_fields.map((userDateField) => (
                            <option key={userDateField.key} value={userDateField.key}>{userDateField.label}</option>
                        ))}
                    </select>}
                </div>}
                
                { ['date', 'text', 'textarea'].includes(formField.type) && <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700">Auto Filled Editable</label>
                    <select value={formField.preFilledEditable} onChange={(ev) => {setFormField({...formField, preFilledEditable: ev.target.value})}} className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </select>
                </div>}
                
                { ['select'].includes(formField.type) && <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700">temp</label>
                    
                </div>}
                { ['select'].includes(formField.type) && <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700">temp</label>
                    
                </div>}

                

{/* 

                        

                        <div className="input-form mt-5"
                             v-show="!formField.preFilled"
                             v-if="formField.type === 'table'">
                            <label className="form-label w-full flex flex-col sm:flex-row">
                                Columns (max 10) <span className="ml-1 text-red-500">*</span>
                            </label>
                            <div>
                                <div className="p-2 rounded mb-5" style="border: 1px solid #ccc" v-for="(column, index) in formField.columns">
                                    <div className="flex justify-between">
                                        <input type="text" className="form-control mb-2 mr-2 w-full" v-model="column.label">

                                        <select v-model="column.type" className="form-select mr-2" style="height: 38px;">
                                            <option value="text">Text</option>
                                            <option value="date">Date</option>
                                            <option value="currency">Currency</option>
                                            <option value="number">Number</option>
                                            <option value="select">Select</option>
                                            @if($mode == 'full')
                                            <option value="dbmodel">DB Model</option>
                                            @endif
                                        </select>

                                        <div className="form-check mr-2" style="height: 38px;">
                                            <input :id="'column-'+index" className="form-check-switch" type="checkbox" v-model="column.required">
                                            <label className="form-check-label" :for="'column-'+index">Required</label>
                                        </div>

                                        <button v-show="formField.columns.length > 1" style="height: 38px;" className="btn btn-danger btn-sm" @click="removeTableFieldColumn(index)">Delete</button>
                                    </div>
                                    @if($mode == 'full')
                                    <div v-if="column.type === 'dbmodel'" className="input-form mt-5">
                                        <label className="form-label w-full flex flex-col sm:flex-row">
                                            DB Model <span className="ml-1 text-red-500">*</span>
                                        </label>
                                        <select v-model="column.model" className="form-select">
                                            @if(in_array(config('grandizer.version'), ['full', 'plus']))
                                                <option value="companies">List of companies</option>
                                            @endif
                                            <option value="employees">List of employees</option>
                                            @if(config('grandizer.version') == 'basic')
                                                <option value="divisions">List of divisions</option>
                                            @endif
                                        </select>
                                    </div>
                                    @endif
                                    <div className="input-form mt-5"
                                         v-if="column.model == 'companies'">

                                        <div className="form-check mt-4">
                                            <input id="based_on_company-switch" className="form-check-switch" type="checkbox" v-model="column.based_on_company">
                                            <label className="form-check-label" for="based_on_company-switch">Filter based on company manged by user</label>
                                        </div>
                                    </div>
                                    <div className="input-form mt-5"
                                         v-if="column.model === 'employees'">
                                        <label className="form-label w-full flex flex-col sm:flex-row">
                                            Auto Filled based on
                                        </label>
                                        <select v-model="column.prefilledBasedOn" className="form-select">
                                            <option value="">None</option>
                                            <option v-if="logicFormField.type === 'dbmodel'"
                                                    :value="logicFormField.name"
                                                    v-for="logicFormField in dbCompanyFormFields">
                                                @{{ logicFormField.label }}
                                            </option>
                                            <option value="behalf">Based on chosen company on behalf
                                            </option>
                                        </select>
                                    </div>
                                    <div className="input-form mt-5"
                                         v-if="column.type === 'paragraph'">
                                        <label className="form-label w-full flex flex-col sm:flex-row">
                                            Auto Filled based on
                                        </label>
                                        <select v-model="column.prefilledBasedOn" className="form-select">
                                            <option value="">None</option>
                                            <option v-if="logicFormField.type === 'dbmodel'"
                                                    :value="logicFormField.name"
                                                    v-for="logicFormField in dbAllFormFields">
                                                @{{ logicFormField.label }}
                                            </option>
                                        </select>
                                    </div>
                                    <div className="input-form mt-5"
                                         v-if="column.type === 'currency'">
                                        <label className="form-label w-full flex flex-col sm:flex-row">
                                            Currency
                                        </label>
                                        <select v-model="column.currency" className="form-select">
                                            <option value="SAR">SAR</option>
                                            <option value="USD">USD</option>
                                            <option value="USD">EUR</option>
                                            <option value="USD">GBP</option>
                                            <option value="EGP">EGP</option>
                                        </select>
                                    </div>
                                    @if($mode == 'full')
                                    <div className="input-form mt-5"
                                         v-if="column.type === 'select'">
                                        <label className="form-label w-full flex flex-col sm:flex-row">
                                            Auto Filled
                                        </label>
                                        <select v-model="column.preFilled" className="form-select">
                                            <option value="">None</option>
                                            <option value="bank_accounts">Company Bank Accounts</option>
                                            <option value="company_locations">Company Locations</option>
                                            <option value="credit_cards">Company Credit Cards</option>
                                            <option value="employee_bank_accounts">Employee Bank Accounts</option>
                                        </select>
                                    </div>
                                    @endif
                                    <div className="input-form mt-5" v-if="column.type === 'select' && !column.preFilled">
                                        <label className="form-label w-full flex flex-col sm:flex-row">
                                            Options <span className="ml-1 text-red-500">*</span>
                                        </label>
                                        <div>
                                            <div className="flex justify-between" v-for="(option, optionIndex) in column.options">
                                                <input type="text" className="form-control mb-2 mr-2 w-full" v-model="option.label">
                                                <button v-show="column.options.length > 1" style="height: 38px;" className="btn btn-danger btn-sm" @click="removeTableColumnOption(index, optionIndex)">Delete</button>
                                            </div>
                                            <div className="text-right">
                                                <button className="btn btn-default btn-sm bg-white" @click="addTableColumnOption(index)">Add option +</button>
                                            </div>
                                        </div>
                                    </div>

                                    @if($mode == 'full')
                                    <div className="input-form mt-5"
                                         v-if="column.type === 'text'">
                                        <label className="form-label w-full flex flex-col sm:flex-row">
                                            Auto Filled
                                        </label>
                                        <select v-model="column.preFilled" className="form-select">
                                            <option value="">None</option>
                                            @foreach($userFields as $key => $userField)
                                                <option value="{{ $key }}">{{ $userField }}</option>
                                            @endforeach
                                        </select>
                                    </div>
                                    <div className="input-form mt-5"
                                         v-if="column.type === 'date'">
                                        <label className="form-label w-full flex flex-col sm:flex-row">
                                            Auto Filled
                                        </label>
                                        <select v-model="column.preFilled" className="form-select">
                                            <option value="">None</option>
                                            @foreach($userDateFields as $key => $userDateField)
                                                <option value="{{ $key }}">{{ $userDateField }}</option>
                                            @endforeach
                                        </select>
                                    </div>
                                    <div className="form-check mt-5" v-if="column.type === 'date' || column.type === 'text'">
                                        <input :id="'column-prefilled-'+index" className="form-check-switch" type="checkbox" v-model="column.preFilledEditable">
                                        <label className="form-check-label" :for="'column-prefilled-'+index">Auto Filled Editable</label>
                                    </div>
                                    <div className="input-form mt-5"
                                         v-if="column.preFilled && column.preFilled != 'employee_bank_accounts' && column.type === 'select'">
                                        <label className="form-label w-full flex flex-col sm:flex-row">
                                            Auto Filled based on
                                        </label>
                                        <select v-model="column.prefilledBasedOn" className="form-select">
                                            <option value="">None</option>
                                            <option v-if="logicFormField.type === 'dbmodel'"
                                                    :value="logicFormField.name"
                                                    v-for="logicFormField in dbCompanyFormFields">
                                                @{{ logicFormField.label }}
                                            </option>
                                        </select>
                                    </div>
                                     <div className="input-form mt-5"
                                             v-if="column.preFilled && column.preFilled == 'employee_bank_accounts' && column.type === 'select'">
                                            <label className="form-label w-full flex flex-col sm:flex-row">
                                                Auto Filled based on
                                            </label>
                                            <select v-model="column.prefilledBasedOn" className="form-select">
                                                <option value="">None</option>
                                                <option v-if="logicFormField.type === 'dbmodel'"
                                                        :value="logicFormField.name"
                                                        v-for="logicFormField in dbEmployeesFormFields">
                                                    @{{ logicFormField.label }}
                                                </option>
                                            </select>
                                      </div>
                                    @endif
                                </div>
                                <div className="text-left" v-if="formField.columns.length < 10">
                                    <button className="btn btn-default btn-sm bg-white" @click="addTableFieldColumn">Add column +</button>
                                </div>
                            </div>
                        </div>

                       

                        
                        <div className="input-form mt-5"
                             v-if="formField.type === 'paragraph'">
                            <label className="form-label w-full flex flex-col sm:flex-row">
                                Auto Filled based on
                            </label>
                            <select v-model="formField.prefilledBasedOn" className="form-select">
                                <option value="">None</option>
                                <option v-if="logicFormField.type === 'dbmodel'"
                                        :value="logicFormField.name"
                                        v-for="logicFormField in dbAllFormFields">
                                    @{{ logicFormField.label }}
                                </option>

                            </select>
                        </div>

                        @if($mode == 'full' || $mode == 'recruit')
                        <div className="input-form mt-5" v-if="canDoLogic(formField.name)">
                            <h2 className="font-bold mb-2">Field Logic</h2>
                            <label className="form-label w-full flex flex-col sm:flex-row">
                                Show this field if
                            </label>
                            <div className="grid grid-cols-12 gap-2">
                                <div className="col-span-5">
                                    <select className="form-select" v-model="formField.logicFormField" @change="changeLogicFormField($event, index)">
                                        <option value="">None</option>
                                        <option v-if="((logicFormField.type === 'select' && !logicFormField.preFilled) || logicFormField.type === 'radio-group' || logicFormField.type === 'number' || logicFormField.type === 'currency' || logicFormField.type === 'boolean' || logicFormField.type === 'date') && logicFormField.name !== formField.name"
                                                :disabled="logicFormField.logicFormField === formField.name"
                                                :value="logicFormField.name"
                                                v-for="logicFormField in formFields">
                                            @{{ logicFormField.label }}
                                        </option>
                                    </select>
                                </div>
                                <div className="col-span-3 text-center">
                                    <select className="form-select" v-model="formField.logicFormFieldMath" v-if="formField.logicFormField"  @change="changeLogicFormFieldMath($event, index)">
                                        <option value="==">equal to</option>
                                        <option v-if="formField.logicFormField.substring(0, 4) === 'date'" value="between">between</option>
                                        <option v-if="formField.logicFormField.substring(0, 8) === 'currency' || formField.logicFormField.substring(0, 6) === 'number' || formField.logicFormField.substring(0, 4) === 'date'" value=">">></option>
                                        <option v-if="formField.logicFormField.substring(0, 8) === 'currency' || formField.logicFormField.substring(0, 6) === 'number' || formField.logicFormField.substring(0, 4) === 'date'" value="<"><</option>
                                        <option v-if="formField.logicFormField.substring(0, 8) === 'currency' || formField.logicFormField.substring(0, 6) === 'number' || formField.logicFormField.substring(0, 4) === 'date'" value=">=">>=</option>
                                        <option v-if="formField.logicFormField.substring(0, 8) === 'currency' || formField.logicFormField.substring(0, 6) === 'number' || formField.logicFormField.substring(0, 4) === 'date'" value="<="><=</option>
                                    </select>
                                </div>
                                <div className="col-span-4" v-if="formField.logicFormField">
                                    <input type="number" step="any" className="form-control" v-model="formField.logicFormFieldValue" v-if="formField.logicFormField.substring(0, 6) === 'number' || formField.logicFormField.substring(0, 8) === 'currency'">
                                    <select className="form-select" v-model="formField.logicFormFieldValue" v-if="formField.logicFormField.substring(0, 6) === 'select' || formField.logicFormField.substring(0, 11) === 'radio-group'">
                                        <option :value="logicFormFieldOption.label" v-for="logicFormFieldOption in formField.logicFormFieldOptions">@{{ logicFormFieldOption.label }}</option>
                                    </select>
                                    <select className="form-select" v-model="formField.logicFormFieldValue" v-if="formField.logicFormField.substring(0, 7) === 'boolean'">
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                    <div className="relative bg-white" v-if="formField.logicFormField.substring(0, 4) === 'date' && formField.logicFormFieldMath !== 'between'">
                                        <div className="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4">
                                            <i data-feather="calendar" className="w-4 h-4"></i>
                                        </div>
                                        <vuejs-datepicker v-model="formField.logicFormFieldValue" className="form-control pl-12" :format="'dd/mm/yyyy'" :clear-button="true" :input-className="'w-93'" :use-utc="true" @input="formField.logicFormFieldValue = fixDate($event)"></vuejs-datepicker>
                                    </div>

                                    <div className="bg-white" v-if="formField.logicFormField.substring(0, 4) === 'date' && formField.logicFormFieldMath === 'between'">
                                        <date-range-picker
                                            className="w-full"
                                            :ref="'daterange_'+formField.name"
                                            :opens="'left'"
                                            :locale-data="{ firstDay: 0, format: 'dd/mm/yyyy' }"
                                            :single-date-picker="false"
                                            :time-picker="false"
                                            :show-week-numbers="false"
                                            :auto-apply="true"
                                            v-model="formField.logicFormFieldValue"
                                        >
                                    </date-range-picker>
                                    </div>
                                </div>
                            </div>

                           {{--START Extra Conditions--}}
                            <div className="mt-2" v-for="(extraCondition, ecIndex) in formField.extraConditions">
                                <div v-if="extraCondition.type === 'group'">
                                    <div className="grid grid-cols-12 gap-2">
                                        <div className="col-span-2">
                                            <select className="form-select" v-model="extraCondition.logicFormFieldJoin">
                                                <option value="&&">AND</option>
                                                <option value="||">OR</option>
                                            </select>
                                        </div>
                                        <div className="col-span-10">
                                            <div className="p-2 rounded" style="border: 1px solid #ccc">
                                                <div className="grid grid-cols-12 gap-2 mb-2" v-for="(extraGroupCondition, ecgIndex) in extraCondition.conditions">
                                                    <div className="col-span-2" v-if="ecgIndex > 0">
                                                        <select className="form-select" v-model="extraGroupCondition.logicFormFieldJoin">
                                                            <option value="&&">AND</option>
                                                            <option value="||">OR</option>
                                                        </select>
                                                    </div>
                                                    <div :className="(ecgIndex > 0) ? 'col-span-3' : 'col-span-5'">
                                                        <select className="form-select" v-model="extraGroupCondition.logicFormField" @change="changeExtraGroupLogicFormField($event, index, ecIndex, ecgIndex)">
                                                            <option value="">None</option>
                                                            <option v-if="((logicFormField.type === 'select' && !logicFormField.preFilled) || logicFormField.type === 'radio-group' || logicFormField.type === 'number' || logicFormField.type === 'boolean' || logicFormField.type === 'date') && logicFormField.name !== formField.name"
                                                                    :disabled="logicFormField.logicFormField === formField.name"
                                                                    :value="logicFormField.name"
                                                                    v-for="(logicFormField, index) in formFields">
                                                                @{{ logicFormField.label }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className="col-span-3 text-center">
                                                        <select className="form-select" v-model="extraGroupCondition.logicFormFieldMath" v-if="extraGroupCondition.logicFormField"  @change="changeExtraGroupLogicFormFieldMath($event, index, ecIndex, ecgIndex)">
                                                            <option value="==">equal to</option>
                                                            <option v-if="extraGroupCondition.logicFormField.substring(0, 4) === 'date'" value="between">between</option>
                                                            <option v-if="extraGroupCondition.logicFormField.substring(0, 8) === 'currency' || extraGroupCondition.logicFormField.substring(0, 6) === 'number' || extraGroupCondition.logicFormField.substring(0, 4) === 'date'" value=">">></option>
                                                            <option v-if="extraGroupCondition.logicFormField.substring(0, 8) === 'currency' || extraGroupCondition.logicFormField.substring(0, 6) === 'number' || extraGroupCondition.logicFormField.substring(0, 4) === 'date'" value="<"><</option>
                                                            <option v-if="extraGroupCondition.logicFormField.substring(0, 8) === 'currency' || extraGroupCondition.logicFormField.substring(0, 6) === 'number' || extraGroupCondition.logicFormField.substring(0, 4) === 'date'" value=">=">>=</option>
                                                            <option v-if="extraGroupCondition.logicFormField.substring(0, 8) === 'currency' || extraGroupCondition.logicFormField.substring(0, 6) === 'number' || extraGroupCondition.logicFormField.substring(0, 4) === 'date'" value="<="><=</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <div v-if="extraGroupCondition.logicFormField">
                                                            <input type="number" className="form-control" v-model="extraGroupCondition.logicFormFieldValue" v-if="extraGroupCondition.logicFormField.substring(0, 6) === 'number' || extraGroupCondition.logicFormField.substring(0, 8) === 'currency'">
                                                            <select className="form-select" v-model="extraGroupCondition.logicFormFieldValue" v-if="extraGroupCondition.logicFormField.substring(0, 6) === 'select'">
                                                                <option :value="logicFormFieldOption.label" v-for="logicFormFieldOption in extraGroupCondition.logicFormFieldOptions">@{{ logicFormFieldOption.label }}</option>
                                                            </select>
                                                            <select className="form-select" v-model="extraGroupCondition.logicFormFieldValue" v-if="extraGroupCondition.logicFormField.substring(0, 7) === 'boolean'">
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </select>
                                                            <div className="relative bg-white" v-if="extraGroupCondition.logicFormField.substring(0, 4) === 'date' && extraGroupCondition.logicFormFieldMath !== 'between'">
                                                                <div className="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4">
                                                                    <i data-feather="calendar" className="w-4 h-4"></i>
                                                                </div>
                                                                <vuejs-datepicker v-model="extraGroupCondition.logicFormFieldValue" className="form-control pl-12" :format="'dd/MM/yyyy'" :clear-button="true" :input-className="'w-89'" :use-utc="true" @input="extraGroupCondition.logicFormFieldValue = fixDate($event)"></vuejs-datepicker>
                                                            </div>

                                                            <div className="bg-white" v-if="extraGroupCondition.logicFormField.substring(0, 4) === 'date' && extraGroupCondition.logicFormFieldMath === 'between'">
                                                                <date-range-picker
                                                                    className="w-full"
                                                                    :ref="'daterange_'+extraGroupCondition.name"
                                                                    :opens="'left'"
                                                                    :locale-data="{ firstDay: 0, format: 'dd/mm/yyyy' }"
                                                                    :single-date-picker="false"
                                                                    :time-picker="false"
                                                                    :show-week-numbers="false"
                                                                    :auto-apply="true"
                                                                    v-model="extraGroupCondition.logicFormFieldValue"
                                                                >
                                                                </date-range-picker>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-1">
                                                        <a href="javascript:;" className="btn btn-danger btn-sm w-full" style="height: 38px;" @click="deleteExtraGroupCondition(index, ecIndex, ecgIndex)">&#10005;</a>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between mt-5">
                                                    <button className="btn btn-danger btn-sm" @click="deleteExtraCondition(index, ecIndex)">Delete group</button>
                                                    <button @click="addConditionToExtraGroup(index, ecIndex)" className="btn btn-sm btn-default bg-white">Add condition +</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-12 gap-2 mt-2" v-if="extraCondition.type === 'condition'">
                                    <div className="col-span-2">
                                        <select className="form-select" v-model="extraCondition.logicFormFieldJoin">
                                            <option value="&&">AND</option>
                                            <option value="||">OR</option>
                                        </select>
                                    </div>
                                    <div className="col-span-3">
                                        <select className="form-select" v-model="extraCondition.logicFormField" @change="changeExtraLogicFormField($event, index, ecIndex)">
                                            <option value="">None</option>
                                            <option v-if="((logicFormField.type === 'select' && !logicFormField.preFilled) || logicFormField.type === 'radio-group' || logicFormField.type === 'number' || logicFormField.type === 'boolean' || logicFormField.type === 'date') && logicFormField.name !== formField.name"
                                                    :disabled="logicFormField.logicFormField === formField.name"
                                                    :value="logicFormField.name"
                                                    v-for="(logicFormField, index) in formFields">
                                                @{{ logicFormField.label }}
                                            </option>
                                        </select>
                                    </div>
                                    <div className="col-span-3 text-center">
                                        <select className="form-select" v-model="extraCondition.logicFormFieldMath" v-if="extraCondition.logicFormField"  @change="changeExtraLogicFormFieldMath($event, index, ecIndex)">
                                            <option value="==">equal to</option>
                                            <option v-if="extraCondition.logicFormField.substring(0, 4) === 'date'" value="between">between</option>
                                            <option v-if="extraCondition.logicFormField.substring(0, 8) === 'currency' || extraCondition.logicFormField.substring(0, 6) === 'number' || extraCondition.logicFormField.substring(0, 4) === 'date'" value=">">></option>
                                            <option v-if="extraCondition.logicFormField.substring(0, 8) === 'currency' || extraCondition.logicFormField.substring(0, 6) === 'number' || extraCondition.logicFormField.substring(0, 4) === 'date'" value="<"><</option>
                                            <option v-if="extraCondition.logicFormField.substring(0, 8) === 'currency' || extraCondition.logicFormField.substring(0, 6) === 'number' || extraCondition.logicFormField.substring(0, 4) === 'date'" value=">=">>=</option>
                                            <option v-if="extraCondition.logicFormField.substring(0, 8) === 'currency' || extraCondition.logicFormField.substring(0, 6) === 'number' || extraCondition.logicFormField.substring(0, 4) === 'date'" value="<="><=</option>
                                        </select>
                                    </div>
                                    <div className="col-span-3">
                                        <div v-if="extraCondition.logicFormField">
                                            <input type="number" className="form-control" v-model="extraCondition.logicFormFieldValue" v-if="extraCondition.logicFormField.substring(0, 6) === 'number' || extraCondition.logicFormField.substring(0, 8) === 'currency'">
                                            <select className="form-select" v-model="extraCondition.logicFormFieldValue" v-if="extraCondition.logicFormField.substring(0, 6) === 'select'">
                                                <option :value="logicFormFieldOption.label" v-for="logicFormFieldOption in extraCondition.logicFormFieldOptions">@{{ logicFormFieldOption.label }}</option>
                                            </select>
                                            <select className="form-select" v-model="extraCondition.logicFormFieldValue" v-if="extraCondition.logicFormField.substring(0, 7) === 'boolean'">
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                            <div className="relative bg-white" v-if="extraCondition.logicFormField.substring(0, 4) === 'date' && extraCondition.logicFormFieldMath !== 'between'">
                                                <div className="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4">
                                                    <i data-feather="calendar" className="w-4 h-4"></i>
                                                </div>
                                                <vuejs-datepicker v-model="extraCondition.logicFormFieldValue" className="form-control pl-12" :format="'dd/MM/yyyy'" :clear-button="true" :input-className="'w-89'" :use-utc="true" @input="extraCondition.logicFormFieldValue = fixDate($event)"></vuejs-datepicker>
                                            </div>

                                            <div className="bg-white" v-if="extraCondition.logicFormField.substring(0, 4) === 'date' && extraCondition.logicFormFieldMath === 'between'">
                                                <date-range-picker
                                                    className="w-full"
                                                    :ref="'daterange_'+extraCondition.name"
                                                    :opens="'left'"
                                                    :locale-data="{ firstDay: 0, format: 'dd/mm/yyyy' }"
                                                    :single-date-picker="false"
                                                    :time-picker="false"
                                                    :show-week-numbers="false"
                                                    :auto-apply="true"
                                                    v-model="extraCondition.logicFormFieldValue"
                                                >
                                                </date-range-picker>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <a href="javascript:;" className="btn btn-danger btn-sm" style="height: 38px;" @click="deleteExtraCondition(index, ecIndex)">Delete</a>
                                    </div>
                                </div>
                            </div>
                            {{--END Extra Conditions--}}

                            <div className="text-right mt-2">
                                <button @click="addExtraCondition(index)" className="btn btn-sm btn-default bg-white mr-2">Add condition +</button>
                                <button @click="addExtraConditionGroup(index)" className="btn btn-sm btn-default bg-white">Add group +</button>
                            </div>
                        </div>
                        @endif */}

                    {/* <button className="btn btn-danger mt-5" onClick="closeEditField">Close</button></div> */}
                {/* ======= END FORM FIELD EDITOR ===== */}
            </div>

            {process.env.NODE_ENV === 'development' && <div>
                <pre>{JSON.stringify(formField, 0, 2)}</pre>
            </div>}
        </div>}
    </div>)
}