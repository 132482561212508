
import React, { useState, useEffect } from 'react'
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import axios from 'axios';
import {
  RefreshIcon,
  TrashIcon,
  PencilAltIcon,
  EyeIcon,
  MailIcon,
} from "@heroicons/react/solid";
import { FilterIcon } from "@heroicons/react/outline";
import { Disclosure, Transition } from "@headlessui/react";

import NavBar from 'layout/NavBar';
import Tabs from 'layout/Tabs';
import Error from 'layout/Error';
import Loader from 'layout/Loader';
import SkeletonTable from 'components/SkeletonTable';
import moment from "moment/moment";
import EmptyStates from 'components/EmptyStates';
import AlertModal from 'components/AlertModal';
import PaginationAll from 'components/pagination';



const pageTitle = "Departments";
const statusTextColors = {
    open: "text-black",
    pending: "text-purple-500",
    cancelled: "text-gray-500",
    resolved: "text-green-500",
  };
  


export default function ListDepartment() {
  const [departementData, setDepartementData] = useState();
  let [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [deleteItemModalOpen, setDeleteItemModalOpen] = useState(false);

  // method 
  const [deletingItem, setDeletingItem] = useState({ name: null });


  const [currentPage, setCurrentPage] = useState(1);

  const [filterStatus, setFilterStatus] = useState(
    searchParams.get("status") ? searchParams.get("status") : ""
  );
  const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
  const displayedData = items?.slice(startIndex, endIndex);

    const [tabs, setTabs] = useState([
        { name: "Departments", color: "black", status: "", count: 0, current: true },
        // {
        //   name: "Saved as draft",
        //   color: "bg-pink-500",
        //   status: "save_as_draft",
        //   count: 0,
        //   current: false,
        // },
      ]);

      const newArray = displayedData.map((item) => {
        const words = item?.name?.split(' ');
        const firstTwoWords = words.slice(0, 2); // slice the array to include only the first two elements
        const firstLetters = firstTwoWords.map((word) => {
          return word.charAt(0); // return the first character of each word
        });
        return firstLetters.join(''); // join the first letters to form a new string
      });
      

      let navigate = useNavigate();
      useEffect(() => {
        getDepartmentList();
      }, []);
      async function getDepartmentList() {

      await axios
      .get("/departments/index")
      .then(function (responses) {
        setItems(responses.data.data);
      })
      .catch(function (error) {
        // handle error
        // setError(error);
      });
    }

    console.log(departementData);

  function updateItem(item) {
    navigate(`/departments/view/${item.id}`);
  }

  function editItem(item) {
  
      navigate(`/department/edit/${item.id}`);
    
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function deleteItem(item) {
    setDeletingItem(item);
    setDeleteItemModalOpen(true);
  }

  function confirmDeleteItem() {
      axios
        .delete("departments/delete/" + deletingItem.id, {
          _method: "delete",
        })
        .then(function (response) {
          setDeleteItemModalOpen(false);
          getDepartmentList();
          // setIsLoaded(true);
          // setTimeout(function () {
          //   setIsDataLoading(false);
          // }, 300);
          setDeletingItem({ name: null });
        })
        .catch(function (error) {
          // setDeleteItemModalOpen(false);
          // refreshData();
          // setIsLoaded(true);
          setDeletingItem({ name: null });
        });
  }

  return (
    <div className="min-h-[100vh] bg-gray-50 pb-1">
   <NavBar pageTitle={pageTitle}>
        <Link
          to="/department/create"
          type="button"
          className="relative inline-flex items-center px-2 py-1 sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
        >
          Add New Department
        </Link>
        
      </NavBar>
      <div className="px-4 py-3 col-span-full lg:col-span-3">
          {/* {filterStatus != "save_as_draft" && ( */}
            <Disclosure>
              <div>
                <button
                //   onClick={() => refreshData()}
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0"
                >
                  <RefreshIcon
                    className={
                    //   (isDataLoading ? "animate-spin" : "") + 
                      " mr-2 h-5 w-5"
                    }
                    aria-hidden="true"
                  />
                  Refresh
                </button>

                <Disclosure.Button className="ml-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">
                  <FilterIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Filters
                </Disclosure.Button>

                {/* {filterCount > 0 && (
                <button
                  onClick={() => clearFilter()}
                  type="button"
                  className="ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-red-600 focus:outline-none focus:ring-0"
                >
                  Clear filters
                  <XIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </button>
              )} */}
                <div className="float-right">
                  <select
                    className="rounded-md outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 bg-[#ffffff]"
                    name=""
                    id=""
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                  </select>
                </div>
              </div>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="text-gray-500">
                  <div className="mt-3 px-3 py-3 rounded-lg bg-white shadow">
                    {/* <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
                    <div className="col-span-6">
                      {user.managed_companies.length > 1 && (
        <div className="col-span-12 lg:col-span-3">
            <div className="mt-1 relative rounded-md">
              <form className="pr-3 pt-3 pb-3">
                <input
                  type="text"
                  name="name"
                                placeholder="Search by company name..."
                  value={addCompanyFilter}
                  onChange={(event) => addFilter(event)}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  className="p-2 w-full lg:w-2/5 outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded"
                />
                              <div className="float-right">
                                
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                
                  </div> */}
                    <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
                      <div>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="name"
                            placeholder="Search by company name..."
                            // value={addCompanyFilter}
                            // onChange={(event) => addFilter(event, "name")}
                            // onKeyPress={(e) => {
                            //   e.key === "Enter" && e.preventDefault();
                            // }}
                            id="name"
                            autoComplete="given-name"
                            className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="mt-2.5">
                          <select
                            className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            // value={statusEmployeeFilter}
                            // onChange={(event) =>
                            //   addFilter(event,"status")
                            // }
                          >
                            <option value="">Search by status...</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <div className="mt-2.5"></div>
                      </div>
                    </div>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </Disclosure>
          {/* )} */}
          {/* {filterStatus != "save_as_draft" && (
            <div className="col-span-12 lg:col-span-3">
              <div className="mt-1 relative rounded-md">
                <form className="pr-3 pt-3 pb-3">
                  <div className="float-right">
                    <select
                      className="rounded-md outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 bg-[#ffffff]"
                      name=""
                      id=""
                      value={itemsPerPage}
                      onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                      <option value="200">200</option>
                 </select>
                </div>
              </form>
            </div>
          </div>
          )} */}
        </div>
        <Tabs
          tabs={tabs}
          statusTextColors={statusTextColors}
          searchParams={searchParams}
        //   addFilter={addFilterSwitch}
        />
        <div className="mt-0 overflow-x-auto">
          <div className="align-middle inline-block min-w-full border-b border-gray-200">
            {/* {isDataLoading && <SkeletonTable />} */}
            {/* {!isDataLoading && ( */}
              <table className="min-w-full table-auto">
                <thead>
                  {filterStatus != "save_as_draft" && (
                    <tr className="border-t border-gray-200">
                      <th
                        className="px-9 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Action
                      </th>
                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        <span className="whitespace-nowrap">Name</span>
                      </th>
                      {filterStatus != "save_as_draft" && (
                        <th
                          className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          scope="col"
                        >
                          <span className="whitespace-nowrap">Company</span>
                        </th>
                      )}
                      {filterStatus != "save_as_draft" && (
                        <th
                          scope="col"
                          className="md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                        </th>
                      )}

                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Manager
                      </th>
                    </tr>
                  )}
                  {filterStatus == "save_as_draft" && (
                    <tr className="border-t border-gray-200">
                      <th
                        className="px-9 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Action
                      </th>
                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Name
                      </th>
                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        <span className="whitespace-nowrap">Created At</span>
                      </th>
                    </tr>
                  )}
                </thead>
                <tbody className="bg-white divide-y divide-gray-100">
                  {filterStatus != "save_as_draft" &&
                    displayedData.map((item , index) => (
                      <tr
                        className=""
                        key={item.id}
                      >
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-left">
                        <button
                            to="/departments/view"
                            onClick={() => updateItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
                          >
                            <EyeIcon
                              className="h-4 w-4 text-blue-400"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            onClick={() => editItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
                          >
                            <PencilAltIcon
                              className="h-4 w-4 text-green-400"
                              aria-hidden="true"
                            />
                          </button>
                          { item.id  && (
                          <button
                            onClick={() => deleteItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700  focus:outline-none focus:ring-0"
                          >
                            <TrashIcon
                              className="h-4 w-4 text-red-400"
                              aria-hidden="true"
                            />
                          </button>
                          )}
                           
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                         <span className='rounded-full bg-blue-50 border-1 border-gray-200 p-2 text-xs mr-2 text-blue-400'>{newArray[index]}</span>  {item?.name}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {item.company_id}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {item.manager_id}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
          </div>
        </div>

        <AlertModal
          title="Delete Company"
          note={`Are you sure you want to delete "${deletingItem.name}" Department.`}
          confirm={confirmDeleteItem}
          close={() => setDeleteItemModalOpen(false)}
          open={deleteItemModalOpen}
        />

        {filterStatus != "save_as_draft" && (
        <PaginationAll
          currentPage={currentPage}
          totalPages={Math.ceil(items?.length / itemsPerPage)}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  )
}