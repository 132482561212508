import { ChevronDownIcon, ChevronDoubleUpIcon, MenuAlt4Icon } from '@heroicons/react/solid'

export default function PriorityBadge(props) {
    const { priority } = props
    return (
        <span className="flex items-center">
            {(priority === 'urgent') && <ChevronDoubleUpIcon className="h-5 w-5 text-red-500" aria-hidden="true" /> }
            {(priority === 'normal') && <MenuAlt4Icon className="h-5 w-5 text-yellow-500" aria-hidden="true" /> }
            {(priority === 'low') && <ChevronDownIcon className="h-5 w-5 text-blue-500" aria-hidden="true" /> }
            <span className="ml-1 block truncate">{priority.toUpperCase()}</span>
        </span>
    )
}