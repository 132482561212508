const validateAllFields = (values) => {
  let errors = {};
  if (!values?.name) {
    errors.name = "Name is Required";
  } else if (
    !/^(?!.*\d)(?! )(?!.* $)[^!@#$%^&*()+=~`\\[\]{}|:;"'<>,.?/_-]{1,45}$/.test(
      values?.name
    )
  ) {
    errors.name =
      "Name: Maximum 45 chars, no leading spaces, no special characters.";
  }

  if (!values?.name_arabic) {
    errors.name_arabic = undefined;
  } else if (
    !/^(?=.{1,45}$)(?![\u0660-\u0669\u06F0-\u06F9])[\u0600-\u06FF][\u0600-\u06FF\s]*[\u0600-\u06FF]$/.test(
      values?.name_arabic
    )
  ) {
    errors.name_arabic =
      "Arabic Name: Maximum 45 Arabic chars, no leading spaces, no special characters.";
  }
  if (values?.nationality === undefined) {
    errors.nationality = "Nationality is Required";
  }
  if (values?.res_country === undefined) {
    errors.res_country = "Resident Country is Required";
  }
  if (values?.res_country_ar === undefined) {
    errors.res_country_ar = "Resident Country(AR) is Required";
  }

  if (!values.hometown_address && values.nationality !== "Saudi") {
    errors.hometown_address = "Home Town Address is Required"; // No validation errors if the value is empty
  } else if (
    !/^(?! )(?=.*\S)(?=.{1,80}$)[^\s ].*[^\s]$/.test(values.hometown_address)
  ) {
    errors.hometown_address = `HT Address: Maximum 2-80 chars, no leading spaces.`;
  }

  if (!values.local_address) {
    errors.local_address = "Local Address is Required"; // No validation errors if the value is empty
  } else if (
    !/^(?! )(?=.*\S)(?=.{1,80}$)[^\s ].*[^\s]$/.test(values.local_address)
  ) {
    errors.local_address = `Local Address: Maximum 2-80 chars, no leading spaces.`;
  }
  const emailRegex =
    /^(?=.{1,254}$)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,20}$/;

  if (!values.email) {
    errors.email = "Corporate's Email is Required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Provide valid Email!";
  } else if (values.email.length > 50) {
    errors.email = "Provided email is too long";
  }
  if (!values.personal_email) {
    errors.personal_email = undefined;
  } else if (!emailRegex.test(values.personal_email)) {
    errors.personal_email = "Provide valid Email!";
  } else if (values.personal_email.length > 50) {
    errors.personal_email = "Provided email is too long";
  }

  if (values?.emergency_contact_name === undefined) {
    errors.emergency_contact_name = undefined;
  } else if (
    !/^(?! )(?!.* $)[^!@#$%^&*()+=~`\\[\]{}|:;"'<>,.?/_-]{1,45}$/.test(
      values?.emergency_contact_name
    )
  ) {
    errors.emergency_contact_name =
      "Emergency Name: Maximum 45 chars, no leading spaces, no special characters.";
  }
  const regPhone = /^(?=.*\d)[\d+\-()]+(?:[ ]*[\d+\-()]+)*$/;
  if (!values?.emergency_contact_mobile_number) {
    errors.emergency_contact_mobile_number = undefined;
  } else if (!regPhone.test(values?.emergency_contact_mobile_number)) {
    errors.emergency_contact_mobile_number =
      "Please enter a valid Mobile Number";
  }
  const passportNoRegex = /^(?!.*\s)[a-zA-Z0-9](?:[- ]?[a-zA-Z0-9]){5,20}$/;
  if (!values?.passport_no && values.nationality !== "Saudi") {
    errors.passport_no = "Passport No is Required";
  } else if (
    !passportNoRegex.test(values?.passport_no) &&
    values.nationality !== "Saudi"
  ) {
    errors.passport_no =
      "Passport No: Maximum 6-20, no leading spaces, only - allow";
  }
  if (
    (values.passport_expire_date === "" ||
      values.passport_expire_date === null) &&
    values.nationality !== "Saudi"
  ) {
    errors.passport_expire_date = "Passport Expire Date is Required";
  }
  if (values.joining_date === "" || values.joining_date === null) {
    errors.joining_date = "Joining Date is Required";
  }
  if (!values.company_id) {
    errors.company_id = "Company is Required";
  }
  if (!values.company_location_id) {
    errors.company_location_id = "Company Location is Required";
  }

  if (!values.position_id) {
    errors.position_id = "Position is Required";
  }
  if (!values.department_id) {
    errors.department_id = "Department is Required";
  }
  const jobTitRegex = /^(?! )(?=.*\S)[-\w].*[-\w]$/;
  if (!values.job_title) {
    errors.job_title = "Job Title is Required";
  } else if (!jobTitRegex.test(values.job_title)) {
    errors.job_title =
      "Job Title: no leading spaces, allow _ - and numbers, Arabic(not allowed)";
  } else if (values.job_title.length > 150) {
    errors.job_title = "Job Title is too long!";
  }

  const jobTitRegexAr =
    /^(?! )(?:[\u0600-\u06FF_-]+(?:[\s_-][\u0600-\u06FF_-]+)*)?(?<! )$/;

  if (!values.job_title_ar) {
    errors.job_title_ar = "Job Title(Arabic) is Required";
  } else if (!jobTitRegexAr.test(values.job_title_ar)) {
    errors.job_title_ar =
      "Job Title AR: no leading spaces, allow _ -, allow arabic only";
  } else if (values.job_title_ar.length > 150) {
    errors.job_title_ar = "Job Title AR is too long!";
  }
  const iqamaRegex = /^\d{1,20}$/;
  if (!values.national_iqama_id) {
    errors.national_iqama_id = "National/Iqama Id is Required";
  } else if (!iqamaRegex.test(values.national_iqama_id)) {
    errors.national_iqama_id =
      "National/Iqama Id: 1-20 digits long, no leading spaces";
  }
  if (
    values.national_iqama_id_expire_date === "" ||
    values.national_iqama_id_expire_date === null
  ) {
    errors.national_iqama_id_expire_date =
      "National Iqama Expire Date is Required";
  }
  return errors;
};

export { validateAllFields };
