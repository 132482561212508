import React, { useState, useEffect } from 'react';

export default function CheckboxesField(props) {
    const {label, input, items, hasLabel = true, required = false} = props
    const [selectedItems, setSelectedItems] = useState(input.value ? input.value : [])

    function onItemCheck(event, companyId) {
        const itemId = parseInt(event.target.value)
        if(event.target.checked) {
            let newArray = [...selectedItems, itemId];
            let newCleanArray = [...new Set(newArray)];
            setSelectedItems(newCleanArray)
        } else {
            let newArray = selectedItems.slice();
            const findIndex = newArray.indexOf(itemId)
            if(findIndex >= 0) {
                newArray.splice(findIndex, 1)
                setSelectedItems(newArray)
            }
        }
    }

    useEffect(() => {
        input.onChange(selectedItems)
    }, [selectedItems]);

    return (
    <>
        <div className="sm:col-span-4 select-none">
            {hasLabel && <label className="block text-sm font-medium text-gray-700 mb-5">
                {label} {required && <span className="text-red-500">*</span>}
            </label>}

            <div className="mt-4">
                { items.map((item) => (<fieldset key={item.id} className="inline-block mr-6 space-y-2 mb-2">
                    <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                            <input
                                id={input.name+'_'+item.id}
                                aria-describedby={'item_'+item.id+'_description'}
                                name={'item_'+item.id}
                                type="checkbox"
                                className="focus:ring-0 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                value={item.id}
                                onChange={(event) => onItemCheck(event)}
                                checked={selectedItems.indexOf(item.id) >= 0}
                            />
                        </div>
                        <div className="ml-1 text-sm">
                            <label htmlFor={input.name+'_'+item.id} className="font-medium text-gray-700">
                                {item.name}
                            </label>
                        </div>
                    </div>
                </fieldset>))}
            </div>
        </div>

        {/* <div>
            <pre>{JSON.stringify(input.value, 0, 2)}</pre>
        </div> */}
    </>
    )
  }
  