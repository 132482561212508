import { ExclamationCircleIcon, XIcon } from "@heroicons/react/solid";
import { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { EyeIcon } from "@heroicons/react/solid";

import axios from "axios";

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

export default function SingleFileUploadField(props) {
  const {
    label,
    input,
    meta,
    placeholder = null,
    col = 3,
    accept = "",
    required = false,
  } = props;
  const [sendingData, setSendingData] = useState({
    fileUrl: null,
    fileName: null,
    filePath: null,
    image: null,
  });
  //faizi code
  const [isFileValid, setIsFileValid] = useState(false);
  const [fileError, setFileError] = useState("");
  //!
  const inputRef = useRef(null);

  useEffect(() => {}, [sendingData]);

  const changeHandler = (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("model", "company");

    if (file) {
      axios({
        method: "post",
        url: "/upload",
        data: formData,
        header: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          sendingData.fileUrl = response.data.fileUrl;
          sendingData.fileName = response.data.fileName;
          sendingData.filePath = response.data.filePath;
          sendingData.image = response.data.image;
          setSendingData({
            fileUrl: response.data.fileUrl,
            fileName: response.data.fileName,
            filePath: response.data.filePath,
            image: response.data.image,
          });
          //faizi code
          setIsFileValid(false);
          setFileError("");
          //!

          input.onChange(sendingData);
        })
        .catch(function (error) {
          // toast.error(error.message);
          //faizi code
          setIsFileValid(true);
          setFileError(
            error?.response?.data?.message ||
              "The file must be a file of type: jpeg, png, jpg, gif, svg, pdf."
          );
          //!
        });
    }
  };

  function clearImage() {
    setFileError("");
    setSendingData({
      fileUrl: null,
      fileName: null,
      filePath: null,
      image: null,
    });
    input.onChange(null);
    inputRef.current.value = null;
  }

  return (
    <div className={"col-span-12 sm:col-span-" + col}>
      <label
        htmlFor={input.name}
        className="text-sm font-medium text-gray-700 mb-1 inline-flex"
      >
        {label} {required && <span className="text-red-500">*</span>}{" "}
        {sendingData.filePath &&
          // input.name != "letterheadbg" &&
          // input.name != "company_stamp" &&
          // input.name != "done_stamp" &&
          // input.name != "paid_stamp" &&
          // input.name != "rejected_stamp" &&
          sendingData.fileName != null && (
            <button
              onClick={() => clearImage()}
              type="button"
              className=" border border-transparent shadow-sm text-[12px] font-bold rounded-full bg-red-400 text-white hover:bg-red-500 focus:outline-none focus:ring-0 ml-2 align-middle h-[100%] m-auto"
            >
              <XIcon className="h-3 w-3" aria-hidden="true" />
            </button>
          )}
        {sendingData.filePath &&
          // input.name != "letterheadbg" &&
          // input.name != "company_stamp" &&
          // input.name != "done_stamp" &&
          // input.name != "paid_stamp" &&
          // input.name != "rejected_stamp" &&
          sendingData.fileName != null && (
            <a
              rel="nofollow"
              href={sendingData.filePath}
              target="_blank"
              title={sendingData.fileName}
            >
              <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
            </a>
          )}
      </label>

      <div className="relative">
        <input
          name={input.name}
          id={input.id}
          onChange={changeHandler}
          type="file"
          placeholder={placeholder}
          accept={
            accept
              ? accept
              : "image/png, image/jpeg, image/jpg, image/gif, image/svg+xml, application/pdf"
          }
          className={
            (meta.error && meta.touched) || isFileValid
              ? "border py-2 px-2  rounded-md block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              : "border border-slate-300 py-2 px-2 rounded-md block w-full sm:text-sm placeholder-gray-300 mb-2"
          }
          ref={inputRef}
          // value={input.value}
        />

        {((meta.error && meta.touched) || isFileValid) && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {(isFileValid || (meta.error && meta.touched)) && (
        <p className="mt-2 text-sm text-red-600">{fileError || meta.error}</p>
      )}
    </div>
  );
}
