import NavBar from "layout/NavBar";
import React from "react";
import CrudForm from "./components/CrudForm";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "layout/Loader";
import axios from "axios";
import { toast } from "react-toastify";

const pageTitle = "Create Department";

export default function CreateDepartment() {
  let initialValues = {
    color: "",
    text_color: "",
    name: "",
    choose_existing_employee_to_manager: false,
  };

  let navigate = useNavigate();
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [stateData, setStateData] = useState(true);
  const [sendingData, setSendingData] = useState(false);
  const [errorHold, setErrorHold] = useState(false);

  function closeForm() {
    setAlertModalOpen(false);
    navigate("/departments");
  }

  // Send data
  const sendData = (values) =>
    new Promise((resolve) => {
      setSendingData(false);
      const formData = new FormData();

      const roleIds = values.roles?.map((item) => parseInt(item.id));
      const supervisorIds = values.supervisors?.map((item) =>
        parseInt(item.id)
      );
      const companiesIds = values.companies?.map((item) => parseInt(item.id));

      debugger;
      if (
        values.choose_existing_employee_to_manager?.choose_manager &&
        values.choose_existing_employee_to_manager?.ceo_id
      ) {
        formData.append(
          "choose_manager",
          values.choose_existing_employee_to_manager?.choose_manager
        );
        formData.append(
          "manager_id",
          values.choose_existing_employee_to_manager?.ceo_id
        );
      } else {
        formData.append("choose_manager", false);
        formData.append(
          "manager_name",
          values.choose_existing_employee_to_manager?.manager_name
        );
        formData.append(
          "manager_email",
          values.choose_existing_employee_to_manager?.manager_email
        );
      }
      if (values.color) {
        formData.append("color", values?.color);
      }
      if (values.text_color) {
        formData.append("text_color", values?.text_color);
      }
      if (values.name) {
        formData.append("name", values?.name);
      }

      if (values.company_id) {
        formData.append("company_id", values.company_id?.id);
      }

      if (values.roles) {
        formData.append("roles", JSON.stringify(roleIds));
      }

      if (values.supervisors) {
        formData.append("supervisors", JSON.stringify(supervisorIds));
      }

      if (values.companies) {
        formData.append("companies", JSON.stringify(companiesIds));
      }
      if (values.group_department_toggle) {
        formData.append("is_related", values.group_department_toggle);
        formData.append(
          "global_department_id",
          values.global_department_id?.id
        );
      }

      // if (values.group_department_toggle) {
      //   formData.append("global_department_id", values.global_department_id?.id);
      // }

      if (values.is_use_for_ticket) {
        formData.append("is_use_for_ticket", values?.is_use_for_ticket);
      }

      axios
        .post("departments/store", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.data.status === 200) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }

          // setTimeout(function () {
          //   navigate("/employees");
          // }, 2000);
        })
        .catch(function (error) {
          setSendingData(false);
          setErrorHold(false);
          toast.error(error.message);
          resolve();
        });
    });

  const onSubmit = async (values) => {
    await sendData(values);
  };

  // Set page title
  useEffect(() => {
    document.title = pageTitle;
  }, []);

  return (
    <div className="h-full">
      {/* Page title & actions */}
      <NavBar pageTitle={pageTitle}>
        <a
          href="#"
          onClick={() => setAlertModalOpen(true)}
          disabled={sendingData}
          className="inline-flex items-center px-[10px] py-[5px] sm:px-3 sm:py-2 border border-gray-300 shadow-sm text-xs sm:text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-[3px] sm:mr-2 disabled:opacity-75"
        >
          Cancel
        </a>
        <button
          disabled={stateData}
          type="submit"
          onClick={() => document.getElementById("mainFormDepartment").click()}
          className="relative inline-flex items-center px-[10px] py-[5px] sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
        >
          {sendingData ? "Submitting..." : "Create"}
        </button>
      </NavBar>
      {/* /Page title & actions */}

      <main className="">
        <div className="max-w-3xl mx-auto px-0 sm:px-6  md:space-x-5 lg:max-w-full lg:px-8 mt-0 relative pb-0 lg:pb-6">
          {sendingData && <Loader fullscreen={false} />}
          <CrudForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            savaButtonAction={(val) => setStateData(val)}
            sendError={errorHold}
          />
        </div>
      </main>
    </div>
  );
}
