import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import moment from 'moment';

export default function AttendanceCalendar(props) {
    const { items, onMonthChange } = props;

    function handleDatesSetEvent(payload) {
        onMonthChange(payload);
        
    }

    return (
        <div>
            <FullCalendar
                timeZone="Asia/Kuwait"
                plugins={[ dayGridPlugin ]}
                initialView="dayGridMonth"
                events={items}
                datesSet={handleDatesSetEvent}
                showNonCurrentDates={false}
            />
        </div>
    )
}