import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";
import { EyeIcon } from "@heroicons/react/solid";
import NavBar from "layout/NavBar";

export default function CompanyVue() {
  let companyId = useParams();
  const [companyInfo, setCompanyInfo] = useState({});
  const [departmentInfo, setDepartmentInfo] = useState({});
  const [ceoInfo, setCEOInfo] = useState({});
  const [companyData, setCompanyData] = useState(false);
  const [departementData, setDepartementData] = useState([]);

  const pageTitle = "Company Profile";

  let navigate = useNavigate();
  useEffect(() => {
    getCompanyInfo();
  }, []);

  async function getCompanyInfo() {
    await axios
      .get("companies/" + companyId.companyId, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // setSendingData(false);
        // resolve()

        setCompanyInfo(response.data.data.company);
        setDepartmentInfo(response.data.data);
        setCEOInfo(response.data.data.ceo);
        setCompanyData(true);

        // navigate("/companies");
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      });

    await axios
      .get("/crete/companies-with-departments")
      .then(function (response) {
        setDepartementData(response.data.data.departments);
      })
      .catch(function (error) {
        // handle error
        // setError(error);
      });
  }

  function backButton() {
    navigate("/companies");
  }

  return (
    <div className=" text-sm">
      <NavBar pageTitle={pageTitle}>
        <button
          className="flex float-right px-[7px] py-[3px] rounded-md bg-[#1C3FAA] text-white font-medium sm:px-[15px] sm:py-[7px] sm:mr-0  mr-2"
          onClick={backButton}
        >
          Back
        </button>
      </NavBar>
      {/* <div className="flex m-2 sm:m-10">
                <div className="w-[50%] font-bold">
                    <h4 className="text-[22px]">Company Profile</h4> 
                </div>
                <div className="font-bold w-[50%]">
                    <button className="flex float-right px-[15px] py-[7px] rounded-md bg-[#1C3FAA] text-white font-medium" onClick={backButton}> 
                         Back
                    </button>
                </div>
            </div> */}

      <div className="grid grid-cols-1 sm:m-10 m-0 ">
        <div className="col-span-1 bg-white rounded-lg sm:p-5 p-0 mb-5 px-2 ">
          <div>
            <div className="border-b-2 mt-4 sm:mt-0">
              <h3 className="text-lg leading-6 font-bold text-[20px]  pb-1">
                {companyInfo?.name ? companyInfo.name.slice(0, 40) : "N/A"}
              </h3>

              <p className="mt-1 max-w-2xl text-xs  pb-5">
                {" "}
                {companyInfo?.legal_name ? companyInfo.legal_name : "N/A"}
              </p>
            </div>
            <div className="grid xl:grid-cols-2 pt-8">
              <div>
                <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                  <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                    CEO Corporate Email:
                  </td>
                  <td
                    className={`pl-0 sm:pl-[10%] sm:w-[62%] w-[100%]     50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%] md:overflow-auto lg:w-[62%] lg:overflow-auto xl:w-[62%] xl:overflow-auto break-words`}
                  >
                    {ceoInfo?.email ? ceoInfo.email : "N/A"}
                  </td>
                </tr>
                <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                  <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                    CEO Name:
                  </td>
                  <td
                    className={`pl-0 sm:pl-[10%] sm:w-[62%]  w-[100%]     50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%]  md:overflow-auto lg:w-[62%]  lg:overflow-auto xl:w-[62%]  xl:overflow-auto`}
                  >
                    {ceoInfo?.name ? ceoInfo.name : "N/A"}
                  </td>
                </tr>
              </div>
              <div>
                <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                  <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                    Company ShortCode:
                  </td>
                  <td className="sm:pl-[10%] pl-0 sm:w-[62%]  w-[100%] sm:pb-0 pb-5">
                    {companyInfo?.prefix}
                  </td>
                </tr>
                <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                  <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                    Min Avg Project Profit(%):
                  </td>
                  <td className="sm:pl-[10%] pl-0 sm:w-[62%]  w-[100%] sm:pb-0 pb-5">
                    {companyInfo?.min_avg_project_profit}
                  </td>
                </tr>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 bg-white rounded-md sm:p-5 p-0 mb-5 px-2 ">
          <h4 className="font-bold text-[20px] uppercase pb-4 border-b-2 mb-7 mt-4 sm:mt-0">
            Address
          </h4>
          <div className="grid xl:grid-cols-2 ">
            <div>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  Country:
                </td>
                <td className="sm:pl-[10%] pl-0 sm:w-[62%]  text-sm  w-[100%] sm:pb-0 pb-5">
                  {companyInfo?.country === "1"
                    ? "Saudi"
                    : companyInfo?.country === "2"
                    ? "Japan"
                    : companyInfo?.country === "3"
                    ? "Egypt"
                    : "N/A"}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  City:
                </td>
                <td
                  className={`pl-0 sm:pl-[10%] sm:w-[62%]  w-[100%]   text-sm    50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%]  md:overflow-auto lg:w-[62%]  lg:overflow-auto xl:w-[62%]  xl:overflow-auto`}
                >
                  {companyInfo?.city ? companyInfo.city : "N/A"}
                </td>
              </tr>
            </div>
            <div>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  Address:
                </td>
                <td
                  className={`pl-0  sm:pl-[10%] sm:w-[62%]  w-[100%]     50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%]  md:overflow-auto lg:w-[62%]  lg:overflow-auto xl:w-[62%]  xl:overflow-auto`}
                >
                  {companyInfo?.address ? companyInfo.address : "N/A"}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  National Address File:
                </td>
                <td className="sm:pl-[10%] pl-0 sm:w-[62%]  text-sm  w-[100%] sm:pb-0 pb-5">
                  {companyInfo?.national_address_fileurl &&
                  companyInfo?.national_address_fileurl != "null" &&
                  companyInfo?.national_address_fileurl != null ? (
                    <a
                      target="_blank"
                      href={companyInfo.national_address_fileurl}
                    >
                      <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            </div>
          </div>
        </div>
        <div className="col-span-1 bg-white rounded-md sm:p-5 p-0 mb-5 px-2 ">
          <h4 className="font-bold text-[20px] pb-4 border-b-2">DOCUMENTS</h4>
          <div className="grid xl:grid-cols-2 mt-8   ">
            <div>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  CR Number:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]  text-sm  50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto `}
                >
                  {companyInfo?.cr_number ? companyInfo.cr_number : "N/A"}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold mr-0    sm:w-[40%] w-[100%]    pb-0">
                  CR Expire Date:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]   text-sm   50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  {companyInfo?.cr_expire_date
                    ? moment(companyInfo.cr_expire_date).format("DD/MM/YYYY")
                    : "N/A"}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  CR File:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]  text-sm    50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  {companyInfo?.cr_number_filename &&
                  companyInfo?.cr_number_filename != "" &&
                  companyInfo?.cr_number_filename != "null" ? (
                    <a target="_blank" href={companyInfo.cr_number_fileurl}>
                      <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  GOSI Number:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]   text-sm   50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  {companyInfo?.gosi_number ? companyInfo.gosi_number : "N/A"}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  GOSI Certificate Expiry Date:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]  text-sm    50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  {companyInfo?.gosi_expire_date
                    ? moment(companyInfo.gosi_expire_date).format("DD/MM/YYYY")
                    : "N/A"}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  GOSI Certificate File:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]   text-sm   50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  {companyInfo?.gosi_filename &&
                  companyInfo?.gosi_filename != "" &&
                  companyInfo?.gosi_filename != "null" ? (
                    <a target="_blank" href={companyInfo.gosi_fileurl}>
                      <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  VAT Number:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]   text-sm   50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  {companyInfo?.vat_number ? companyInfo.vat_number : "N/A"}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  VAT File:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]   text-sm   50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  {companyInfo?.vat_filename &&
                  companyInfo?.vat_filename != "" &&
                  companyInfo?.vat_filename != "null" ? (
                    <a target="_blank" href={companyInfo.vat_fileurl}>
                      <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  COC Number:
                </td>
                <td
                  className={`pl-0   sm:w-[52%] text-sm w-[100%] align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto `}
                >
                  {companyInfo?.coc_number ? companyInfo.coc_number : "N/A"}
                </td>
              </tr>
            </div>
            <div>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  COC Expire Date:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%] text-sm  50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  {companyInfo?.coc_expire_date
                    ? moment(companyInfo.coc_expire_date).format("DD/MM/YYYY")
                    : "N/A"}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  COC File:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]  text-sm    50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  {companyInfo?.coc_filename &&
                  companyInfo?.coc_filename != "" &&
                  companyInfo?.coc_filename != "null" ? (
                    <a target="_blank" href={companyInfo.coc_fileurl}>
                      <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  GAZT Number:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]  text-sm    50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  {companyInfo?.gazt_number ? companyInfo.gazt_number : "N/A"}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  Zakat Certificate Expiry Date:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]   text-sm   50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  {companyInfo?.gazt_expire_date
                    ? moment(companyInfo.gazt_expire_date).format("DD/MM/YYYY")
                    : "N/A"}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  Zakat Certificate File:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]    text-sm  50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  {companyInfo?.gazt_filename &&
                  companyInfo?.gazt_filename != "" &&
                  companyInfo?.gazt_filename != "null" ? (
                    <a target="_blank" href={companyInfo.gazt_fileurl}>
                      <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  Municipality Number:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]  text-sm    50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  {companyInfo?.municipality_number
                    ? companyInfo.municipality_number
                    : "N/A"}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  Municipality Expire Date:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]   text-sm   50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  {companyInfo?.municipality_expire_date
                    ? moment(companyInfo.municipality_expire_date).format(
                        "DD/MM/YYYY"
                      )
                    : "N/A"}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px] ">
                <td className=" font-bold   sm:w-[40%] w-[100%]    pb-0">
                  Municipality File:
                </td>
                <td
                  className={`pl-0   sm:w-[52%]  w-[100%]   text-sm   50    align-middle pb-5 break-all sm:overflow-auto md:w-[52%]  md:overflow-auto lg:w-[52%]  lg:overflow-auto xl:w-[52%]  xl:overflow-auto`}
                >
                  <a href="#">
                    {companyInfo?.municipality_filename &&
                    companyInfo?.municipality_filename != "" &&
                    companyInfo?.municipality_filename != "null" ? (
                      <a
                        target="_blank"
                        href={companyInfo.municipality_fileurl}
                      >
                        <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </a>
                </td>
              </tr>
            </div>
          </div>
        </div>
        <div className="col-span-1 bg-white rounded-md sm:p-5 p-0 w-full mb-5 px-2 ">
          <h4 className="font-bold text-[20px] pb-4 border-b-2">BANK DETAIL</h4>
          <tr className="grid grid-flow-row sm:flex mt-8">
            <td className=" font-bold   sm:w-[50%] w-[100%]    pb-5">
              Bank Account File:
            </td>
            <td className="sm:pl-[0%] pl-0  sm:w-[50%] w-[100%] sm:pb-0 pb-5">
              <a href="#">
                {companyInfo?.bank_account_filename &&
                companyInfo?.bank_account_filename != "" &&
                companyInfo?.bank_account_filename != "null" ? (
                  <a target="_blank" href={companyInfo.bank_account_fileurl}>
                    <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                  </a>
                ) : (
                  "N/A"
                )}
              </a>
            </td>
          </tr>
          <div className="py-5 sm:grid ">
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
              <h4 className=" font-bold pb-3 text-[18px]">Bank Accounts:</h4>
              <table className="w-full ">
                <thead>
                  <tr className="w-100%">
                    <th
                      className=" py-3  text-left font-bold uppercase tracking-wider 
                      w-[50%]"
                      scope="col"
                    >
                      Bank Name
                    </th>
                    <th
                      className="py-3  text-left  font-bold uppercase tracking-wider w-[50%] "
                      scope="col"
                    >
                      Account Number
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-100">
                  {companyInfo?.bank_accounts
                    ? JSON.parse(companyInfo?.bank_accounts).map(
                        (bankAccount, index) => (
                          <tr key={index}>
                            <td>
                              <div
                                className={` pb-5 w-[80%] text-sm  break-words`}
                              >
                                {bankAccount.name}
                              </div>
                            </td>
                            <td>
                              <div
                                className={` pl-0 pb-10 w-[80%] text-sm break-words whitespace-normal`}
                              >
                                {bankAccount.number}
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    : "_"}
                </tbody>
              </table>
            </dd>
          </div>
          <div className="py-5 ">
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <h4 className=" font-bold pb-3 text-[18px]">Credit Cards:</h4>
              <table className="min-w-full rounded">
                <thead>
                  <tr className="w-[100%]   ">
                    <th
                      className=" py-3  text-left  font-bold uppercase tracking-wider 
                      w-[50%]"
                      scope="col"
                    >
                      Bank Name
                    </th>
                    <th
                      className=" py-3  text-left  font-bold uppercase tracking-wider 
                      w-[50%]"
                      scope="col"
                    >
                      Card Number
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-100">
                  {companyInfo?.credit_cards
                    ? JSON.parse(companyInfo?.credit_cards).map(
                        (bankAccount, index) => (
                          <tr key={index}>
                            <td>
                              <div
                                className={` pb-5 w-[80%] text-sm break-words`}
                              >
                                {" "}
                                {bankAccount.bank_name ?? bankAccount.name}{" "}
                              </div>
                            </td>
                            <td>
                              <div
                                className={` pl-0 pb-10 w-[80%] text-sm break-words whitespace-normal`}
                              >
                                {" "}
                                {bankAccount.card_number ??
                                  bankAccount.number}{" "}
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    : "_"}
                </tbody>
              </table>
            </dd>
          </div>
        </div>
        <div className="col-span-1 bg-white rounded-md sm:p-5 p-0 mb-5 px-2 ">
          <h4 className="font-bold text-[20px] pb-4 border-b-2">
            AVAILABLE SERVICES
          </h4>
          <div className="grid xl:grid-cols-2 mt-8">
            {companyInfo?.departments &&
              JSON.parse(companyInfo?.departments)?.map((item) => {
                return (
                  <div>
                    <tr className="grid grid-flow-row sm:flex">
                      <td className=" font-bold sm:w-[30%] w-[100%]  pb-0">
                        {item.serviceName}
                      </td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[62%]  w-[100%] sm:pb-0 pb-5">
                        {item.departmentId ? item.departmentId : "N/A"}
                      </td>
                    </tr>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="col-span-1 bg-white rounded-md sm:p-5 p-0 mb-5 px-2 ">
          <h4 className="font-bold text-[20px] pb-4 border-b-2">
            EMAIL SIGNATURE
          </h4>
          <div className="grid xl:grid-cols-2 mt-8">
            <div>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  Website Text:
                </td>
                <td
                  className={`pl-0 sm:pl-[5%] sm:w-[62%]  w-[100%]  text-sm   50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%]  md:overflow-auto lg:w-[62%]  lg:overflow-auto xl:w-[62%]  xl:overflow-auto  `}
                >
                  {companyInfo?.signature_website_text
                    ? companyInfo.signature_website_text
                    : "N/A"}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  Website URL:
                </td>
                <td
                  className={`pl-0  sm:pl-[5%] sm:w-[62%]  w-[100%] text-sm    50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%]  md:overflow-auto lg:w-[62%]  lg:overflow-auto xl:w-[62%]  xl:overflow-auto `}
                >
                  {companyInfo?.signature_website_url ? (
                    <a
                      className="text-blue-800 hover:border-b-2 hover:border-b-blue-800 line-clamp-1"
                      target="_blank"
                      href={companyInfo.signature_website_url}
                    >
                      {companyInfo.signature_website_url}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  Phone:
                </td>
                <td
                  className={`pl-0 sm:pl-[5%] sm:w-[62%]  w-[100%]  text-sm   50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%]  md:overflow-auto lg:w-[62%]  lg:overflow-auto xl:w-[62%]  xl:overflow-auto`}
                >
                  {companyInfo?.phone ? companyInfo.phone : "N/A"}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  Extention:
                </td>
                <td
                  className={`pl-0   sm:pl-[5%] sm:w-[62%]  w-[100%]  text-sm   50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%]  md:overflow-auto lg:w-[62%]  lg:overflow-auto xl:w-[62%]  xl:overflow-auto`}
                >
                  {companyInfo?.extention ? companyInfo.extention : "N/A"}
                </td>
              </tr>
            </div>
            <div>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  Facebook URL:
                </td>
                <td
                  className={`pl-0  sm:pl-[5%] sm:w-[62%]  w-[100%]  text-sm   50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%]  md:overflow-auto lg:w-[62%]  lg:overflow-auto xl:w-[62%]  xl:overflow-auto`}
                >
                  {companyInfo?.signature_facebook_url ? (
                    <a
                      className="text-blue-800 hover:border-b-2 hover:border-b-blue-800 line-clamp-1"
                      target="_blank"
                      href={companyInfo.signature_facebook_url}
                    >
                      {companyInfo.signature_facebook_url}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                <td className={` font-bold sm:w-[30%] w-[100%]    pb-0`}>
                  Instagram URL:
                </td>
                <td
                  className={`pl-0 sm:pl-[5%] sm:w-[62%]  w-[100%]   text-sm  50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%]  md:overflow-auto lg:w-[62%]  lg:overflow-auto xl:w-[62%]  xl:overflow-auto`}
                >
                  {companyInfo?.signature_instagram_url ? (
                    <a
                      className="text-blue-800 hover:border-b-2 hover:border-b-blue-800 line-clamp-1"
                      target="_blank"
                      href={companyInfo.signature_instagram_url}
                    >
                      {companyInfo.signature_instagram_url}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                <td className={`  font-bold sm:w-[30%] w-[100%]    pb-0 `}>
                  Linkedin URL:
                </td>
                <td
                  className={`pl-0  sm:pl-[5%] sm:w-[62%]  w-[100%]  text-sm   50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%]  md:overflow-auto lg:w-[62%]  lg:overflow-auto xl:w-[62%]  xl:overflow-auto`}
                >
                  {companyInfo?.signature_linkedin_url ? (
                    <a
                      className="text-blue-800 hover:border-b-2 hover:border-b-blue-800 line-clamp-1"
                      target="_blank"
                      href={companyInfo.signature_linkedin_url}
                    >
                      {companyInfo.signature_linkedin_url}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  Twitter URL:
                </td>
                <td
                  className={`pl-0  sm:pl-[5%] sm:w-[62%]  w-[100%]  text-sm   50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%]  md:overflow-auto lg:w-[62%]  lg:overflow-auto xl:w-[62%]  xl:overflow-auto`}
                >
                  {companyInfo?.signature_twitter_url ? (
                    <a
                      className="text-blue-800 hover:border-b-2 hover:border-b-blue-800 line-clamp-1"
                      target="_blank"
                      href={companyInfo.signature_twitter_url}
                    >
                      {companyInfo.signature_twitter_url}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            </div>
          </div>
        </div>
        <div className="col-span-1 bg-white rounded-md sm:p-5 p-0 w-full mb-5 px-2 ">
          <>
            <h4 className="font-bold text-[20px] uppercase pb-4 border-b-2">
              Company Locations
            </h4>
            <div className="py-5 sm:grid">
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                <table className="min-w-full ">
                  <thead>
                    <tr className=" w-100%">
                      <th
                        className=" py-3  text-left  font-bold uppercase tracking-wider 
                      w-[50%]"
                        scope="col"
                      >
                        Location Name
                      </th>
                      <th
                        className=" py-3  text-left  font-bold uppercase tracking-wider w-[50%]"
                        scope="col"
                      >
                        Country
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-100">
                    {companyInfo?.company_locations
                      ? JSON.parse(companyInfo?.company_locations).map(
                          (companyLocation, index) => (
                            <tr key={index} className="">
                              <td className="">
                                <div className="w-96">
                                  <div className=" text-sm break-words">
                                    {companyLocation.name ?? ""}
                                  </div>
                                </div>
                              </td>
                              <td className="">
                                <div>
                                  <div className="w-full sm:w-80 text-sm break-words">
                                    {companyLocation.country ?? ""}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      : "_"}
                  </tbody>
                </table>
              </dd>
            </div>
          </>
        </div>
        <div className="col-span-1 bg-white rounded-md sm:p-5 p-0 mb-5 px-2 ">
          <h4 className="font-bold text-[20px] pb-4 border-b-2">STYLES</h4>
          <div className="grid xl:grid-cols-2 mt-8">
            <div>
              <tr className="grid grid-flow-row sm:flex sm:h-[50px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  Color:
                </td>
                <td className="sm:pl-[10%] pl-0 sm:w-[62%]  w-[100%] sm:pb-0 pb-5">
                  <input
                    className="w-16"
                    type="color"
                    value={companyInfo?.color ? companyInfo.color : "N/A"}
                  />
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[50px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  Text Color:
                </td>
                <td className="sm:pl-[10%] pl-0 sm:w-[62%]  w-[100%] sm:pb-0 pb-5">
                  <input
                    className="w-16"
                    type="color"
                    value={
                      companyInfo?.text_color ? companyInfo.text_color : "N/A"
                    }
                  />
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[50px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  Logo:
                </td>
                <td className="sm:pl-[10%] pl-0 sm:w-[62%]  w-[100%] sm:pb-0 pb-5">
                  {companyInfo?.logo &&
                  companyInfo?.logo != "undefined" &&
                  companyInfo?.logo != "" &&
                  companyInfo?.logo != "null" ? (
                    <a target="_blank" href={companyInfo.logo}>
                      <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            </div>
            <div>
              <tr className="grid grid-flow-row sm:flex sm:h-[50px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  Dark Logo:
                </td>
                <td className="sm:pl-[10%] pl-0 sm:w-[62%]  w-[100%] sm:pb-0 pb-5">
                  {companyInfo?.logo_dark &&
                  companyInfo?.logo_dark != "undefined" &&
                  companyInfo?.logo_dark != "" &&
                  companyInfo?.logo_dark != "null" ? (
                    <a target="_blank" href={companyInfo.logo_dark}>
                      <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
              <tr className="grid grid-flow-row sm:flex sm:h-[50px]">
                <td className=" font-bold sm:w-[30%] w-[100%]    pb-0">
                  Favicon:
                </td>
                <td className="sm:pl-[10%] pl-0 sm:w-[62%]  w-[100%] sm:pb-0 pb-5">
                  {companyInfo?.favicon &&
                  companyInfo?.favicon != "undefined" &&
                  companyInfo?.favicon != "" &&
                  companyInfo?.favicon != "null" ? (
                    <a target="_blank" href={companyInfo.favicon}>
                      <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
