import React, { useState , useEffect } from 'react'
import { Form, Field, FormSpy, useForm } from "react-final-form";
import ColorField from 'components/fields/ColorField';
import Select from 'react-select';
import axios from 'axios';
import FormPercentage from "components/forms/FormPercentage";
import TextField from 'components/fields/TextField';
import ManagerToggleField from 'components/fields/ManagerToggleField';
import CustomToggleField from 'components/fields/customToggleField';
import UpdateColorField from "components/fields/UpdateColorField";


export default function UpdateCrudForm(props) {

    const { initialValues , onSubmit , savaButtonAction } = props;
    const required = (value) => (value ? undefined : "Required");
    const [dropDownData, setDropDownData] = useState();
    
    useEffect(() => {
        getDropDOwnData();
    }, []) 

    const getDropDOwnData = () => {
        axios.get("departments/create").
        then((response) => setDropDownData(response.data?.data) ).
        catch((error) => console.log("department error" , error))
    }

   const companyOptions = dropDownData?.companies?.map(item => ({id: item.id , value: item.name , label: item.name}))
   const supervisorsOptions = dropDownData?.supervisors?.map(item => ({id: item.id , value: item.name , label: item.name}))
   const rolesOptions = dropDownData?.roles?.map(item => ({id: item.id , value: item.name , label: item.name}))
   const groupDepartmentOptions = dropDownData?.globalDepartments?.map(item => ({id: item.id , value: item.name , label: item.name}))


  return (
    <Form
    keepDirtyOnReinitialize
    onSubmit={onSubmit}
    initialValues={initialValues}
    //decorators={[focusOnError]}
    FormState
    render={({
      handleSubmit,
      form,
      submitting,
      pristine,
      values,
      submitFailed,
      form: { getState },
    }) => {
      return (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
               <button id="mainFormSubmitDepartment" type="submit"></button>
               <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mt-3'>
               <Field name="color" validate={required}>
              {({ input, meta }) => (
                <UpdateColorField
                required={true}
                label="Color"
                input={input}
                meta={meta}
                col={3}
              />
              )}
            </Field>   
            <Field name="text_color" validate={required}>
              {({ input, meta }) => (
                <UpdateColorField
                required={true}
                label="Text Color"
                input={input}
                meta={meta}
                col={3}
              />
              )}
            </Field>
            <Field name="company_id">
              {({ input, meta }) => (
                <div className="col-span-12 sm:col-span-3">
                  <label
                    htmlFor={input.name}
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    # Company
                  </label>
                  <Select {...input} options={companyOptions}/>
                  {meta.error && meta.touched && (
                    <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                  )}
                </div>
              )}
            </Field>

            <Field name="name" validate={required}>
              {({ input, meta }) => (
                <TextField
                  required={true}
                  label="# Department Name"
                  input={input}
                  meta={meta}
                  placeholder="Enter Name"
                  col={3}
                />
              )}
            </Field>

            <Field name="choose_existing_employee_to_manager" validate={required}>
              {({ input, meta }) => (
                <ManagerToggleField
                  required={true}
                  label="Choose existing employee to be manager"
                  input={input}
                  meta={meta}
                  col={6}
                  usersData={dropDownData?.supervisors}
                //   emailError={sendError}
                />
              )}
            </Field>

            <Field name="supervisors">
              {({ input, meta }) => (
                <div className="col-span-12 sm:col-span-6">
                  <label
                    htmlFor={input.name}
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    # Supervisors
                  </label>
                  <Select
                    {...input}
                    isMulti
                    name="supervisors"
                    options={supervisorsOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  {meta.error && meta.touched && (
                    <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                  )}
                </div>
              )}
            </Field>

            <Field name="roles">
              {({ input, meta }) => (
                <div className="col-span-12 sm:col-span-6">
                  <label
                    htmlFor={input.name}
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    # Addditional roles to the manager
                  </label>
                  <Select
                    {...input}
                    isMulti
                    name="roles"
                    options={rolesOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                  {meta.error && meta.touched && (
                    <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                  )}
                </div>
              )}
            </Field>

            <Field name="companies">
              {({ input, meta }) => (
                <div className="col-span-12 sm:col-span-6">
                  <label
                    htmlFor={input.name}
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    #  Have access to 
                  </label>
                  <Select
                   {...input}
                   isMulti
                   name="access to"
                   options={companyOptions}
                   className="basic-multi-select"
                   classNamePrefix="select"
                   menuPortalTarget={document.body}
                   styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                   />
                  {meta.error && meta.touched && (
                    <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                  )}
                </div>
              )}
            </Field>

            <Field name="group_department_toggle">
              {({ input, meta }) => (
                <CustomToggleField
                  input={input}
                  meta={meta}
                  label = "Is it related to a group department"
                />
              )}
            </Field>

            { values?.group_department_toggle === true &&   
             <Field name="global_department_id">
              {({ input, meta }) => (
                <div className="col-span-12 sm:col-span-6">
                  <label
                    htmlFor={input.name}
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    #  Group Department 
                  </label>
                  <Select {...input} options={groupDepartmentOptions}/>
                  {meta.error && meta.touched && (
                    <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                  )}
                </div>
              )}
            </Field> }

            <Field name="is_use_for_ticket">
              {({ input, meta }) => (
                <CustomToggleField
                  
                  input={input}
                  meta={meta}
                  label = "Use for support Tickets?"
                />
              )}
            </Field>

            </div>

            <FormSpy
            subscription={{ values: true }}
            component={FormPercentage}
            setRequiredField={initialValues}
            setButtonActive={savaButtonAction}
          />
              </form>
      );
    }}
    />
  )
}
