import { useState } from 'react';

export default function MobileApp(props) {
  const [currentTab, setCurrentTab] = useState('ios');
  const qrUrl = '/qr/'+process.env.REACT_APP_ATTENDANCE_QR;
  const attendanceUrl = process.env.REACT_APP_ATTENDANCE_URL;

  const tabs = [
    { name: 'iOS', id: 'ios' },
    { name: 'Android', id: 'android' },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className="p-10">
      <div className="border-b border-gray-20 px-4">
        <div className="sm:flex sm:items-baseline">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Installation instructions</h3>
          <div className="mt-4 sm:mt-0 sm:ml-10">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <button
                  onClick={() => setCurrentTab(tab.id)}
                  className={classNames(
                    currentTab === tab.id
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  aria-current={currentTab === tab.id ? 'page' : undefined}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-3xl py-12 px-4 sm:px-6 lg:px-8">
        { currentTab === 'ios' && <div className="overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:p-6">
            <div className="mb-5">
              <p className="text-md mb-4 font-bold">Scan QR Code to install the app from AppStore</p>
              <p><img src="/qr/grandizer_ios_app.png" className="block mx-auto lg:w-72 border border-gray-400 rounded-lg shadow-sm" /></p>
            </div>
          </div>
        </div>}

        { currentTab === 'android' && <div className="overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:p-6">
            <div className="mb-5">
              <p className="text-md mb-4 font-bold">Scan QR Code to install the app from Google Play</p>
              <p><img src="/qr/grandizer_android_app.png" className="block mx-auto lg:w-72 border border-gray-400 rounded-lg shadow-sm" /></p>
            </div>
          </div>
        </div>}
      </div>
    </div>
  )
}
