import { useState } from "react";
import { Switch } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import Select from "react-select";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ToggleField(props) {
  const {
    label,
    subLabel = "",
    input,
    meta,
    col = 3,
    required = false,
    usersData,
    emailError,
    setErrorHold,
  } = props;
  const [enabled, setEnabled] = useState(input.value);
  const [fieldEnabled, setFielsEnabled] = useState(false);

  const [dataRows, setDataRows] = useState(
    input.value ? input.value : { ceo_name: "", ceo_email: "" }
  );
  const [CEOdata, setCEOData] = useState(
    input.value ? input.value : { choose_manager: false, ceo_id: "" }
  );
  const [ceoname, SetCeoName] = useState();
  const [ceoemail, SetCeoEmail] = useState();
  const [emailValidation, setEmailValidation] = useState(false);
  const [myError, setMyError] = useState("");

  function handleUserDataChange(value) {
    CEOdata.ceo_id = value.value;
    setCEOData(CEOdata);
    input.onChange(CEOdata);
  }

  function handleChange(value) {
    if (value) {
      dataRows.ceo_name = "";
      dataRows.ceo_email = "";
      setDataRows(dataRows);
      CEOdata.choose_manager = value;
    }
    setEnabled(value);
    setFielsEnabled(value);
    input.onChange(value);
  }

  function handleCEONameChange(event) {
    const value = event.target.value;
    console.log(value, "valueeee");
    dataRows.ceo_name = value;
    SetCeoName(value);
    setDataRows(dataRows);
    if (value.length > 34) {
      input.onChange(false);
      setMyError("Name can't be greater than 34 characters!");

      return false;
    }
    if (value !== "" && dataRows.ceo_email == "") {
      input.onChange(false);
      setMyError("");
    } else if (value == "") {
      input.onChange(false);
      setMyError("");
    } else if (value !== "" && dataRows.ceo_email !== "") {
      input.onChange(dataRows);
      setMyError("");
    }
  }

  function handleCEOEmailChange(event) {
    setErrorHold(false);
    const value = event.target.value;
    dataRows.ceo_email = value;
    SetCeoEmail(value);
    setDataRows(dataRows);

    const validRegex =
      /^(?=[^\s@])[a-zA-Z0-9\-_.]{0,40}@(?=[^\s@])[a-zA-Z.]{1,20}$/;

    if (!validRegex.test(value)) {
      input.onChange(false);
      setEmailValidation(true);
    } else if (value !== "" && dataRows.ceo_name == "") {
      input.onChange(false);
      setEmailValidation(false);
    } else if (value == "") {
      input.onChange(false);
      setEmailValidation(false);
    } else if (value !== "" && myError) {
      setEmailValidation(false);
      input.onChange(false);
    } else if (value !== "" && dataRows.ceo_name !== "" && myError === "") {
      setEmailValidation(false);
      input.onChange(dataRows);
    }
  }

  const updatedUserData = usersData.map((userData) => ({
    label: userData.name + " (" + userData.email + ")",
    value: userData.id,
    ...userData,
  }));

  return (
    <>
      <div className={"pt-8 col-span-12 sm:col-span-" + col}>
        <Switch.Group as="div" className="flex items-center">
          <Switch
            checked={enabled}
            onChange={handleChange}
            className={classNames(
              enabled ? "bg-indigo-600" : "bg-gray-200",
              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
              )}
            />
          </Switch>
          {/* <Switch.Label as="span" className="ml-3">
            <span className="text-sm font-medium text-gray-900">{label}</span>
            {subLabel && (
              <span className="text-sm text-gray-500">({subLabel})</span>
            )}
          </Switch.Label> */}
          <span className="text-sm font-medium text-gray-900 ml-3 mt-1">
            {label}
          </span>
        </Switch.Group>
      </div>
      {fieldEnabled ? (
        <div className="col-span-12 sm:col-span-6">
          <label
            htmlFor={input.name}
            className="block text-sm font-medium text-gray-700"
          >
            CEO{required && <span className="text-red-500">*</span>}
          </label>
          <Select
            label="CEO"
            onChange={(value) => handleUserDataChange(value)}
            options={updatedUserData}
            defaultValue={"Select..."}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        </div>
      ) : (
        <>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="ceo_name"
              className="block text-sm font-medium text-gray-700"
            >
              # CEO Name {required && <span className="text-red-500">*</span>}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                {...input}
                type="text"
                className={
                  (meta.error && meta.touched) || myError
                    ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                    : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                }
                onChange={(event) => handleCEONameChange(event)}
                value={ceoname}
                placeholder="Enter CEO Name"
              />
              {((meta.error && meta.touched) || myError) && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {((meta.error && meta.touched) || myError) && (
              <p className="mt-2 text-sm text-red-600">
                {meta.error || myError}
              </p>
            )}
          </div>

          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="ceo_corporate_name"
              className="block text-sm font-medium text-gray-700"
            >
              # CEO Corporate Email
              {required && <span className="text-red-500"> *</span>}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                {...input}
                type="email"
                className={
                  meta.error && meta.touched
                    ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                    : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                }
                onChange={(event) => handleCEOEmailChange(event)}
                value={ceoemail}
                placeholder="Enter CEO Corporate Email"
              />
              {emailValidation && (
                <p className="mt-2 text-sm text-red-600">abc@example.com</p>
              )}
              {emailError && (
                <p className="mt-2 text-sm text-red-600">
                  The CEO email has already been taken.
                </p>
              )}
            </div>
            {meta.error && meta.touched && (
              <p className="mt-2 text-sm text-red-600">{meta.error}</p>
            )}
          </div>
        </>
      )}
    </>
  );
}
