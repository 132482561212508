import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { RefreshIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { ChevronDownIcon, FilterIcon } from "@heroicons/react/outline";
import { Disclosure, Transition } from "@headlessui/react";
import { toast } from "react-toastify";

import NavBar from "layout/NavBar";
import Loader from "layout/Loader";
// import SkeletonTable from 'components/SkeletonTable';
import moment from "moment/moment";
import PaginationAll from "components/pagination";
import { Item } from "components/kanbanBoard/Item";

//Pagination Import
import DatePicker from "react-date-picker";
//Skelton
import SkeletonTable from "components/SkeletonTable";
import DatePickerField from "components/fields/DatePickerField";
import DateRangePickerFilterField from "components/filterFields/DateRangePickerFilterField";
import ClearIcon from "components/ClearIcon";
import Error from "layout/Error";
import ReactPaginate from "react-paginate";
//call back

const pageTitle = "All Transaction";
const grandizerV1Url = process.env.REACT_APP_V1_URL;

export default function ListWorkflow() {
  // for Paginantion
  const [apiTotalPage, setApiTotalpage] = useState(0);
  const [isToggleFrom, setIsToggleFrom] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [firstPage, setFirstPage] = useState();

  const [items, setItems] = useState();
  const [newitems, setNewitems] = useState();

  // const [filter, setFilter ] = useState();
  const [catagories, setCatagories] = useState();
  const [companies, setCompanies] = useState();
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [priority, setPriority] = useState();

  const [searchWorkflow, setSearchWorkflow] = useState("");
  const [searchCatagory, setSearchCatagory] = useState("");
  const [searchCompany, setSearchCompany] = useState("");
  const [searchId, setSearchId] = useState("");
  const [prio, setPrio] = useState("");
  const [searchFrom, setSearchFrom] = useState("");
  const [searchTo, setSearchTo] = useState("");
  const [searchFormatedFrom, setSearchFormatedFrom] = useState();
  const [searchFormatedTo, setSearchFormatedTo] = useState("");
  const [upDown, setUpDown] = useState(false);
  const [upDownUpdown, setUpDownUpdown] = useState(false);

  const [isdownload, setIsDownload] = useState(false);
  const [dataDownload, setDataDownload] = useState();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [error, setError] = useState();

  // for total_records_per_page
  const [itemsPerPage, setItemsPerPage] = useState(10);

  //for skelton
  const [isDataLoading, setIsDataLoading] = useState(false);

  //for loader
  const [isLoaded, setIsLoaded] = useState(false);

  //for active tab
  const [activeCell, setActiveCell] = useState(null);

  //current tab
  const [currentTab, setCurrentTab] = useState("");

  const [tryy, setTryy] = useState("");

  let [searchParams, setSearchParams] = useSearchParams();
  let url;
  let export_file;
  let recordPerPage;

  // console.log(currentPage, "------------------------------------current_page----------------->")
  const [filterStatus, setFilterStatus] = useState(
    searchParams.get("status") ? searchParams.get("status") : ""
  );

  const sendRequest = useCallback(() => {
    // Send Axios request here
    apiHit();
  }, [
    searchId,
    itemsPerPage,
    searchCompany,
    prio,
    searchCatagory,
    searchWorkflow,
    searchFrom,
    currentPage,
    upDown,
    searchTo,
    currentTab,
    tryy,
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      sendRequest();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [
    searchId,
    itemsPerPage,
    searchCompany,
    prio,
    searchCatagory,
    searchWorkflow,
    searchFrom,
    currentPage,
    upDown,
    searchTo,
    sendRequest,
  ]);
  useEffect(() => {
    if (isdownload) {
      apiHit();
    }
  }, [isdownload]);
  const apiHit = async () => {
    if (!isdownload) {
      setIsDataLoading(true);
    }

    let id = searchId ? `id=${searchId}&` : "";
    let priorityy = prio ? `prio=${prio}&` : "";
    let companyy = searchCompany ? `company_name=${searchCompany}&` : "";
    let catagoryy = searchCatagory ? `category=${searchCatagory}&` : "";
    let workfloww = searchWorkflow ? `workflow=${searchWorkflow}&` : "";
    let fromm = searchFormatedFrom
      ? `from=${
          searchFormatedFrom === "Invalid date" ? "" : searchFormatedFrom
        }&`
      : "";
    let too = searchFormatedTo
      ? `to=${searchFormatedTo === "Invalid date" ? "" : searchFormatedTo}&`
      : "";

    let sorting = `sort=${upDown ? "desc" : "asc"}`;
    export_file = isdownload ? `export_file="xlsx" ` : "";
    recordPerPage = itemsPerPage ? `itemsPerPage=${itemsPerPage}&` : "";
    // let tab = currentTab ? `currentTab=${currentTab}` : "";

    await axios
      .get(
        `/workflow/amount/request?${id}${priorityy}${companyy}${catagoryy}${workfloww}${fromm}${too}${export_file}${recordPerPage}${sorting}&status=${
          currentTab == "" ? tryy : currentTab
        }&page=${currentPage}`
      )
      .then(function (response) {
        try {
          if (!isdownload) {
            setItems(response.data.data.data);
            setNewitems(response.data.data.data);
            // setFilter(response);
            setApiTotalpage(response.data.data.last_page);
            setFirstPage(response.data.data.current_page);
            setTimeout(function () {
              setIsDataLoading(false);
            }, 300);
            setIsLoaded(true);
          }
          if (isdownload) {
            if (response.data.data === undefined && response.data.message) {
              toast.error(response.data.message);
              setIsDownload(false);
              setLoading(false);
              return false;
            }

            const link = document.createElement("a");
            link.href = response.data.data;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDataDownload(response.data.data);
            setIsDownload(false);
            setLoading(false);
          }
        } catch (error) {
          setErrors(error.response.data);
          setLoading(false);
        }
      })
      .catch(function (error) {
        setError(error);
        setLoading(false);
        setIsDownload(false);
        setIsDataLoading(false);
        setIsDownload(false);
        toast.error(error?.message);
        setItems([]);
      });
  };
  useEffect(() => {
    workflowData();
  }, []);
  const workflowData = async () => {
    await axios
      .get("/workflow/data")
      .then(function (response) {
        try {
          setCatagories(response?.data?.data?.categories);
          setCompanies(response?.data?.data?.companies);
          setId(response?.data?.data?.id);
          setName(response?.data?.data?.name);
          setPriority(response?.data?.data?.priority);
        } catch (error) {}
      })
      .catch(function (error) {});
  };
  function truncateString(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    }

    return str.substring(0, maxLength - 3) + "...";
  }
  const handleSearchChange = (event, key) => {
    if (key === "workflow") {
      const truncString = truncateString(event.target.value, 10);
      // if(truncString.length===13){

      // }
      console.log(truncString, "truncString");
      setSearchWorkflow(event.target.value);
      setCurrentPage(0);
    }
    if (key === "catagory") {
      const truncString = truncateString(event.target.value, 10);
      setSearchCatagory(event.target.value);
      setCurrentPage(0);
    }
    if (key === "company") {
      const truncString = truncateString(event.target.value, 10);
      setSearchCompany(event.target.value);
      setCurrentPage(0);
    }
    if (key === "prio") {
      setPrio(event.target.value);
      setCurrentPage(0);
    }
    if (key === "id") {
      setSearchId(event.target.value);
      setCurrentPage(0);
    }
  };
  function refreshData() {
    // window.location.reload(false);

    if (
      searchCatagory === "" &&
      searchCompany === "" &&
      searchFrom === "" &&
      searchTo === "" &&
      searchId === "" &&
      prio === "" &&
      searchWorkflow === "" &&
      itemsPerPage === 10
    ) {
      apiHit();
      return false;
    }
    setSearchCatagory("");
    setSearchCompany("");
    setSearchFrom("");
    setSearchTo("");
    setSearchId("");
    setPrio("");
    setItemsPerPage(10);
    setSearchWorkflow("");
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    //   setCurrent(pageNumber);

    // if(filter){

    // setCurrentPage(e.selected + 1);
    // setCurrent(e.selected + 1);
    // }
    // loadData(pageNumber);
  };
  function selectDate(val) {
    setSearchFrom(val);
    let formatedDate = moment(val).format("YYYY/MM/DD");
    setSearchFormatedFrom(formatedDate);
    setCurrentPage(0);
  }
  function selectDateTo(val) {
    setSearchTo(val);
    let formatdate = moment(val).format("YYYY/MM/DD");
    setSearchFormatedTo(formatdate);
    setCurrentPage(0);
  }
  function dateReverseAgain() {
    setUpDown((upDown) => !upDown);
  }
  function ascendingDate() {
    let acsen = newitems.sort(
      (a, b) =>
        new Date(...a.created_at.split("/").reverse()) -
        new Date(...b.created_at.split("/").reverse())
    );
    setItems(acsen);
    // console.log(acsen, "---------------------------------ascendingDate----------------------->")
  }
  function descendingDate() {
    let descending = newitems.sort(
      (a, b) =>
        new Date(...b.created_at.split("/").reverse()) -
        new Date(...a.created_at.split("/").reverse())
    );
    setItems(descending);
    // console.log(descending, "-----------------------------------------------descendingdate-------------------------------------->")
  }
  function amountAscending() {
    let newArr = [...newitems].map((e, array, Arr) => {
      return { ...e, amount: e.amount.replace(",", "") };
    });
    const numAscending = [...newArr].sort((a, b) => a.amount - b.amount);
    let localeStringArr = [...numAscending].map((e, array, Arr) => {
      return { ...e, amount: JSON.parse(e.amount).toLocaleString() };
    });

    setItems(localeStringArr);
    // console.log(newArr, "-----------------------------------------numAscending--------------------------->")
  }

  function amountDescending() {
    let newArray = [...newitems].map((e, array, Arr) => {
      return { ...e, amount: e.amount.replace(",", "") };
    });
    const numDescending = [...newArray].sort((a, b) => b.amount - a.amount);
    let localeStringArr = [...numDescending].map((e, array, Arr) => {
      return { ...e, amount: JSON.parse(e.amount).toLocaleString() };
    });
    setItems(localeStringArr);
    // console.log(numDescending, "----------------------------------------numDescending--------------------------->")
  }
  const isClicked = () => {
    setIsDownload(true);
    setLoading(true);
  };
  const handleTabClick = (e) => {
    console.log(e, "ENAME");
    // setActiveCell(e.id === activeCell ? null : e.id);
    setActiveCell(e.id);

    if (e.name == "In Progress") {
      // setCurrentTab(e.name === currentTab ? null : "waiting");

      setTryy("waiting");
      setCurrentTab("");
    } else if (e.name == "Completed 100%") {
      setTryy(100);
      setCurrentTab("");

      // setCurrentTab(e.name === currentTab ? null : "approved");
    } else {
      // setCurrentTab(e.name === currentTab ? null : e.name.toLowerCase());

      setCurrentTab(e.name.toLowerCase());
      setTryy("");
    }
  };

  const tabData = [
    { id: 0, name: "Approved" },
    { id: 1, name: "Waiting" },
    { id: 2, name: "Cancelled" },
    { id: 3, name: "In Progress" },
    { id: 4, name: "Completed 100%" },
  ];

  // const formatDate = (isoDateString) => {
  //   const options = {
  //     year: "numeric",
  //     month: "numeric",
  //     day: "numeric",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //   };

  //   const date = new Date(isoDateString);
  //   return date.toLocaleString("en-US", options);
  // };

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);

    // Format time component
    const timeOptions = { hour: "2-digit", minute: "2-digit" };
    const timeString = date.toLocaleTimeString("en-US", timeOptions);

    // Format date component
    const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
    const dateString = date.toLocaleDateString("en-US", dateOptions);

    // Combine date and time
    return `${timeString}, ${dateString}`;
  };

  if (error) {
    return <Error error={error} />;
  }
  if (!isLoaded) {
    return <Loader fullscreen={false} />;
  }
  return (
    <>
      <div className="min-h-[100vh] bg-gray-50 pb-1 ">
        <div className="  bg-white pt-5">
          <tr className="w-7/12  bg-white cursor-pointer ">
            {tabData.map((e) => (
              <td
                className={`md:table-cell 
                px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left ${
                  activeCell == e.id
                    ? "bg-gray-50 border-b border-blue-500 font-bold"
                    : " border-b-0"
                }`}
                onClick={() => handleTabClick(e)}
              >
                {e.name}
              </td>
            ))}
          </tr>
        </div>
        <div className="px-4 py-3 col-span-full lg:col-span-3">
          <Disclosure>
            <div className="sm:flex sm:justify-between sm:items-center">
              <div className="flex">
                <button
                  onClick={() => refreshData()}
                  type="button"
                  className="inline-flex sm:w-auto w-[50%] items-center px-4 py-2 border h-full border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0"
                >
                  <RefreshIcon
                    className={`mr-2 h-5 w-5 ${
                      isDataLoading && "animate-spin"
                    }`}
                    aria-hidden="true"
                  />
                  Refresh
                </button>
                <Disclosure.Button className="ml-2  sm:mt-0 sm:w-auto w-[50%] inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">
                  <FilterIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Filters
                </Disclosure.Button>
              </div>
              <div className="flex items-center mt-2 sm:mt-0">
                {loading ? (
                  <button
                    onClick={isClicked}
                    disabled={loading}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-white bg-slate-600 focus:outline-none focus:ring-0 focus:border-indigo-500 border-gray-300 bg-[#ffffff]"
                  >
                    {loading ? "loading..." : "export"}
                  </button>
                ) : (
                  <button
                    onClick={isClicked}
                    disabled={loading}
                    className="inline-flex items-center sm:w-auto w-[50%] px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 focus:border-indigo-500 border-gray-300 bg-[#ffffff]"
                  >
                    Export
                  </button>
                )}
                <select
                  className="rounded-md sm:w-auto w-[50%] outline-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 bg-[#ffffff] ml-4"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
              </div>
            </div>

            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="text-gray-500">
                <div className="mt-3 px-3 py-3 rounded-lg bg-white shadow">
                  <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 ">
                    <div>
                      <div className="mt-2.5">
                        <input
                          type="text"
                          placeholder="Search by ID"
                          value={searchId}
                          onChange={(e) => handleSearchChange(e, "id")}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="mt-2.5 sm:flex items-center ">
                        <span className="text-sm font-medium text-gray-700 mr-2">
                          From
                        </span>
                        <DatePicker
                          type="date"
                          value={searchFrom}
                          onChange={selectDate}
                          clearIcon={
                            <ClearIcon
                              onClick={setSearchFrom}
                              showClearIcon={Boolean(searchFrom)}
                            />
                          }
                          className="block w-full rounded-md border-gray-300 py-1 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="mt-2.5 sm:flex items-center">
                        <span className="text-sm font-medium text-gray-700 mr-2">
                          To
                        </span>
                        <DatePicker
                          value={searchTo}
                          onChange={selectDateTo}
                          clearIcon={
                            <ClearIcon
                              showClearIcon={Boolean(searchTo)}
                              onClick={setSearchTo}
                            />
                          }
                          className="block w-full rounded-md border-gray-300 py-1 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <div className="mt-2.5">
                        <select
                          className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={prio}
                          onChange={(e) => handleSearchChange(e, "prio")}
                        >
                          <option value="">Priority</option>
                          <option value={priority?.Urgent}>
                            {priority?.Urgent}
                          </option>
                          <option value={priority?.High}>
                            {priority?.High}
                          </option>
                          <option value={priority?.low}>{priority?.low}</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <div className="mt-2.5">
                        <select
                          className={`block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                          value={searchCompany}
                          onChange={(e) => handleSearchChange(e, "company")}
                        >
                          <option value="">Company</option>
                          {companies?.map((item, index) => {
                            return (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div>
                      <div className="mt-2.5">
                        <select
                          className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={searchCatagory}
                          onChange={(e) => handleSearchChange(e, "catagory")}
                        >
                          <option value="">Category</option>
                          {catagories?.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item.length > 10
                                  ? `${item.substring(0, 7)}...`
                                  : item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div>
                      <div className="mt-2.5">
                        <select
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                          value={searchWorkflow}
                          onChange={(e) => handleSearchChange(e, "workflow")}
                        >
                          <option value="">Workflow</option>
                          {name?.map((item, index) => {
                            return (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div>
                      <div className="mt-2.5"></div>
                    </div>
                  </div>
                </div>
              </Disclosure.Panel>
            </Transition>
          </Disclosure>
        </div>
        <div className="mt-0 overflow-x-auto">
          <div className="align-middle inline-block min-w-full border-b border-gray-200">
            {isDataLoading && <SkeletonTable />}

            {!isDataLoading && (
              <table className="min-w-full overflow-x-auto">
                <thead>
                  {filterStatus !== "save_as_draft" && (
                    <tr className="border-t border-gray-200">
                      <th
                        className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        <span className="whitespace-nowrap">ID</span>
                      </th>
                      <th
                        className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        <span className="whitespace-nowrap">
                          Name & Requester
                        </span>
                      </th>
                      <th
                        className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        <span className="whitespace-nowrap">Company</span>
                      </th>
                      <th
                        className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Status
                      </th>
                      <th
                        className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Progress
                      </th>
                      <th
                        className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        PAYMENT TO NAME
                      </th>

                      <th
                        className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        PAYMENT METHOD
                      </th>
                      <th
                        className=" whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        AMOUNT
                      </th>
                      <th
                        className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        PAYMENT FROM ACCOUNT
                      </th>
                      <th
                        className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        PAYMENT TO ACCOUNT
                      </th>
                      <th
                        className="... flex items-center whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider h-full m-auto align-middle"
                        scope="col"
                      >
                        Created At
                      </th>
                    </tr>
                  )}
                </thead>
                <tbody className="bg-white divide-y divide-gray-100">
                  {items && items.length > 0 ? (
                    items.map((e, index) => (
                      <tr className="" key={index}>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.workflow_id ? e?.workflow_id : "N/A"}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          <div>
                            <a
                              className="pointer-events-auto text-blue-700 underline"
                              href={
                                grandizerV1Url +
                                "/all-workflow-requests/" +
                                e?.workflow_request_id
                              }
                            >
                              {e?.workflow_name ? e?.workflow_name : "N/A"}
                            </a>
                          </div>
                          <div>{e?.requester ? e?.requester : "N/A"}</div>
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.company_name ? e?.company_name : "N/A"}
                        </td>
                        <td
                          className={`md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium  ${
                            e?.workflow_request.status == "cancelled"
                              ? "text-red-500"
                              : e?.workflow_request.status == "approved"
                              ? "text-green-500"
                              : e?.workflow_request.status == "waiting"
                              ? "text-yellow-500"
                              : "text-red-500"
                          } text-left `}
                        >
                          {e?.workflow_request
                            ? e?.workflow_request.status
                                .charAt(0)
                                .toUpperCase() +
                              e?.workflow_request.status.slice(1)
                            : "N/A"}
                          <div className="text-gray-600 font-light">
                            {e?.workflow_request
                              ? formatDate(e?.workflow_request?.created_at)
                              : "N/A"}
                          </div>
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-green-500 text-left">
                          {e?.workflow_request.percentage
                            ? e?.workflow_request?.percentage
                            : "N/A"}
                          {e?.workflow_request?.percentage ? "%" : " "}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.payment_to_name ? e?.payment_to_name : "-"}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.payment_method ? e?.payment_method : "-"}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.amount ? e?.amount : "0"}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.payment_from_account
                            ? e?.payment_from_account
                            : "-"}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.payment_to_account ? e?.payment_to_account : "-"}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.created_at ? e?.created_at : "N/A"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="9"
                        className="px-6 py-4 text-center text-gray-500"
                      >
                        No records found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        {items && items.length > 0 && (
          <div className="my-4">
            {/* 
        <ReactPaginate
          previousLabel={(firstPage === 1 ? "" : "<< ") + "Previous"}
          nextLabel={"Next" + (firstPage === apiTotalPage ? "" : ">>")}
          pageCount={apiTotalPage}
          // pageRangeDisplayed={6}

          onPageChange={handlePageChange}
          containerClassName={"flex justify-center "}
          pageClassName={"mr-2 ml-2 border-gray-300 border-t-2 pl-2 pr-2"}
          pageLinkClassName={" "}
          previousClassName="pl-2 pr-2"
          previousLinkClassName=""
          nextClassName=" pl-2 pr-2"
          nextLinkClassName=""
          activeClassName={"active border-t-2 border-blue-500"}
          // onPageActive={2}
          pageRangeDisplayed={0}
          forcePage={0}
          page={0}
        />

      {console.log(firstPage, "current_page")}
    
    
      
    */}
            <PaginationAll
              currentPage={firstPage}
              totalPages={apiTotalPage}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </>
  );
}
