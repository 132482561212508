import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useSearchParams, useNavigate  } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon, XIcon } from '@heroicons/react/solid'
import { FilterIcon } from '@heroicons/react/outline'

// Components
import Pagination from '../../components/Pagination';
import EmptyStates from '../../components/EmptyStates';
import PriorityBadge from '../../components/PriorityBadge';
import SkeletonTable from '../../components/SkeletonTable';
import DateRangePickerFilterField from '../../components/filterFields/DateRangePickerFilterField';
import NavBar from '../../layout/NavBar';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

// Helpers
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// ====================================== APP ==========================================
// Static variables
const pageTitle = 'Support Tickets';

const statusColors = {
  open: 'bg-pink-500',
  pending: 'bg-purple-500',
  cancelled: 'bg-gray-500',
  resolved: 'bg-green-500',
}

const statusTextColors = {
  open: 'text-pink-500',
  pending: 'text-purple-500',
  cancelled: 'text-gray-500',
  resolved: 'text-green-500',
}

const statusLabels = {
  open: 'Unassigned',
  pending: 'Assigned',
  cancelled: 'Cancelled',
  resolved: 'Resolved',
}

export default function List(props) {
  const { user } = props
  let [searchParams, setSearchParams] = useSearchParams();

  // Set page title
  useEffect(() => {
    document.title = pageTitle;
  }, [])

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [filterCount, setFilterCount] = useState(0)
  const [items, setItems] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState([]);
  const [dataUrl, setDataUrl] = useState(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [tabs, setTabs] = useState([
    { name: 'All', color: '', status: '', count: 0, current: true },
    { name: 'Unassigned', color: 'bg-pink-500', status: 'open', count: 0, current: false },
    { name: 'Assigned', color: 'bg-purple-500', status: 'pending', count: 0, current: false },
    { name: 'Cancelled', color: 'bg-gray-500', status: 'cancelled', count: 0, current: false },
    { name: 'Resolved', color: 'bg-green-500', status: 'resolved', current: false },
  ])
  
  // Order By
  let curretnOrderByValue = {
    key: 'created_at',
    order: 'desc',
  }
  if(searchParams.get('order_by')) {
    if(searchParams.get('order_by').key !== 'created_at' && searchParams.get('order_by').order !== 'desc') {
      const currentOrderByArray = searchParams.get('order_by').split("-");
      curretnOrderByValue = {
        key: currentOrderByArray[0],
        order: currentOrderByArray[1],
      }
    }
  }
  let [currentOrderBy, setCurrentOrderBy] = useState(curretnOrderByValue)
  
  
  const apiUrl = '/tickets'
  const loadData = (params = {}) => {
    setIsDataLoading(true)

    if(params === {}) {
      //params['status'] = ''
    }
    
    setSearchParams(params)

    //console.log('Params', params)
    
    axios.get(apiUrl, {
      params: params
    })
    .then(function (response) {
      // handle success
      setIsLoaded(true);
      setItems(response.data.data);
      setPaginationMeta(response.data.meta)
      setTimeout(function() {
        setIsDataLoading(false)
      }, 300)
    })
    .catch(function (error) {
      // handle error
      setIsLoaded(true);
      setError(error);
    })
    .then(function () {
      setIsLoaded(true);
    });
    
  }

  useEffect(() => {
    let params = {}
    searchParams.forEach((key, value) => {
      params[value] = key;
      setFilterCount((filterCount) => filterCount + 1)
    });

    loadData(params)
  }, [])

  function reloadData(key, value) {
    let params = {}
    searchParams.forEach((key, value) => {
      params[value] = key;
    });
    params[key] = value

    // Load tab
    if(key != 'page') {
      params['page'] = 1
    }

    loadData(params)
  }


  function orderBy(key) {
    if(currentOrderBy.order === 'desc') {
      reloadData('order_by', key+'-asc')
      setCurrentOrderBy({
        key: key,
        order: 'asc',
      })
    } else {
      reloadData('order_by', key+'-desc')
      setCurrentOrderBy({
        key: key,
        order: 'desc',
      })
    }
  }

  // Filters
  const [filterCreatedCompanyId, setFilterCreatedCompanyId] = useState(searchParams.get('created_company_id') ? searchParams.get('created_company_id') : '')
  const [filterCompanyId, setFilterCompanyId] = useState(searchParams.get('company_id') ? searchParams.get('company_id') : '')
  const [filterDepartmentId, setFilterDepartmentId] = useState(searchParams.get('department_id') ? searchParams.get('department_id') : '')
  const [filterPriority, setFilterPriority] = useState(searchParams.get('priority') ? searchParams.get('priority') : '')
  const [filterFrom, setFilterFrom] = useState(searchParams.get('from') ? searchParams.get('from') : '')
  const [filterTo, setFilterTo] = useState(searchParams.get('to') ? searchParams.get('to') : '')

  function clearFilter() {
    let params = {}
    setFilterCreatedCompanyId('')
    setFilterCompanyId('')
    setFilterDepartmentId('')
    setFilterPriority('')
    setFilterFrom('')
    setFilterTo('')
    setFilterCount(0)
    loadData(params)
  }

  function addFilter(key, value) {
    switch(key) {
      case 'created_company_id':
        setFilterCreatedCompanyId(value)
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'company_id':
        setFilterCompanyId(value)
        setFilterDepartmentId('')
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'department_id':
        setFilterDepartmentId(value)
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'priority':
        setFilterPriority(value)
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'from':
        setFilterFrom(value)
        setFilterCount((filterCount) => filterCount + 1)
        break;
      case 'to':
        setFilterTo(value)
        setFilterCount((filterCount) => filterCount + 1)
        break; 
    }
    reloadData(key, value)
  }

  if(!isLoaded) {
    return <div className="fullscreen-loading flex items-center justify-around">Loading..</div>
  } else {
    return (
      <div className="min-h-[100vh] bg-white pb-1 relative">
        {/* Page title & actions */}
        <NavBar pageTitle={pageTitle}>
          <Link to="/tickets/create"
            type="button"
            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
          >
            Create
          </Link>
        </NavBar>
        {/* /Page title & actions */}

        {/* Filters */}
        <div className="pt-2">
          <div className="px-4 py-2">
            <span className="align-middle mr-2 mb-2 inline-block py-2 items-center"><FilterIcon className="h-5 w-5 text-gray-500" aria-hidden="true" /></span>
            {(user.managed_companies.length > 1) && <select
              className="mr-2 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={filterCreatedCompanyId}
              onChange={(event) => addFilter('created_company_id', event.target.value)}
            >
              <option value="">Created by all companies</option>
              {user.managed_companies.map((company) => (
                <option key={company.id} value={company.id}>{company.name}</option>
              ))}
            </select>}
            
            {(user.support_ticket_companies.length > 1) && <select
              className="mr-2 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={filterCompanyId}
              onChange={(event) => addFilter('company_id', event.target.value)}
            >
              <option value="">Assigned to all companies</option>
              {user.support_ticket_companies.map((company) => (
                <option key={company.id} value={company.id}>{company.name}</option>
              ))}
            </select>}
            {(filterCompanyId && user.managed_companies.length > 1 && user.support_ticket_departments.length > 1) && <select
              className="mr-2 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={filterDepartmentId}
              onChange={(event) => addFilter('department_id', event.target.value)}
            >
              <option value="">All departments</option>
              {user.support_ticket_departments.map((department) => {
                if(parseInt(department.company_id) === parseInt(filterCompanyId)) {
                  return (<option key={department.id} value={department.id}>{department.name}</option>)
                }
              })}
            </select>}

            {/* Department filter for people who only manage 1 company */}
            {(user.managed_companies.length === 1 && user.support_ticket_departments.length > 1) && <select
              className="mr-2 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={filterDepartmentId}
              onChange={(event) => addFilter('department_id', event.target.value)}
            >
              <option value="">All departments</option>
              {user.support_ticket_departments.map((department) => {
                return (<option key={department.id} value={department.id}>{department.name}</option>)
              })}
            </select>}
            <select
              className="mr-2 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={filterPriority}
              onChange={(event) => addFilter('priority', event.target.value)}
            >
              <option value="">All priorities</option>
              <option value="low">LOW</option>
              <option value="normal">NORMAL</option>
              <option value="urgent">URGENT</option>
            </select>

            <DateRangePickerFilterField startDate={filterFrom} endDate={filterTo} onChangeStart={(value) => addFilter('from', value)} onChangeEnd={(value) => addFilter('to', value)} />

            {filterCount > 0 && <button
              onClick={() => clearFilter()}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-0"
            >
              Clear all
              <XIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </button>}
        </div>
          {/* /Filters */}

          {/* Tabs */}              
          <div className="grandizer-crud-tabs">
            <div className="hidden sm:block">
              <div className="border-b border-gray-20 px-4">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab, tabIndex) => (
                    <button
                      onClick={() => reloadData('status', tab.status)}
                      key={tab.name}
                      className={classNames(
                        (tab.status === searchParams.get('status') || (!searchParams.get('status') && tab.status === ''))
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                      )}
                    >
                      <span className={tab.status ? statusTextColors[tab.status] : ''}>{tab.name}</span>
                      {tab.count ? (
                        <span
                          className={classNames(
                            (tab.status === searchParams.get('status') || (!searchParams.get('status') && tab.status === 'open')) ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                          )}
                        >
                          {tab.count}
                        </span>
                      ) : null}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          {/* /Tabs */} 

          {/* Data table */}
          <div className="mt-0 overflow-x-auto">
            <div className="align-middle inline-block min-w-full border-b border-gray-200">
              {isDataLoading && <SkeletonTable />}
              {!isDataLoading && <table className="min-w-full">
                <thead>
                  <tr className="border-t border-gray-200">
                    <th
                      className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      scope="col"
                    >
                      <span className="whitespace-nowrap">#</span>
                    </th>

                    <th
                      className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      scope="col"
                    >
                      <span className="whitespace-nowrap">Ticket name</span>
                    </th>

                    <th scope="col" className="md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-xs text-left font-medium text-gray-500 uppercase tracking-wider">
                      <span className="group inline-flex uppercase whitespace-nowrap">
                        Status
                      </span>
                    </th>

                    {(user.managed_companies.length > 1) && <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                      <button onClick={() => orderBy('company_id')} className="group inline-flex uppercase whitespace-nowrap">
                      Assigned to Company
                        { (currentOrderBy.key === 'company_id') ? (<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                          { (currentOrderBy.order === 'desc') ? <ChevronDownIcon className="h-4 w-4" aria-hidden="true" /> : <ChevronUpIcon className="h-4 w-4" aria-hidden="true" /> }
                        </span>) : '' }
                      </button>
                    </th>}
                    
                    <th scope="col" className="md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-xs text-left font-medium text-gray-500 uppercase tracking-wider">
                      <span className="group inline-flex uppercase whitespace-nowrap">
                        Assigned To
                      </span>
                    </th>
                    <th scope="col" className="md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-xs text-left font-medium text-gray-500 uppercase tracking-wider">
                      <button onClick={() => orderBy('priority')} className="group inline-flex uppercase whitespace-nowrap">
                        Priority
                        { (currentOrderBy.key === 'priority') ? (<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                          { (currentOrderBy.order === 'desc') ? <ChevronDownIcon className="h-4 w-4" aria-hidden="true" /> : <ChevronUpIcon className="h-4 w-4" aria-hidden="true" /> }
                        </span>) : '' }
                      </button>
                    </th>
                    <th scope="col" className="md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <button onClick={() => orderBy('created_at')} className="group inline-flex uppercase whitespace-nowrap">
                        Created At
                        { (currentOrderBy.key === 'created_at') ? (<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                          { (currentOrderBy.order === 'desc') ? <ChevronDownIcon className="h-4 w-4" aria-hidden="true" /> : <ChevronUpIcon className="h-4 w-4" aria-hidden="true" /> }
                        </span>) : '' }
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-100">
                  {items.map((item) => (
                    <tr key={item.id}>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                        {item.hid}
                      </td>
                      <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="">
                          <div>
                            <Link to={`/tickets/${item.id}`} className="truncate text-blue-700 ">
                              <span>
                                <span className="underline font-bold">{item.name}</span> <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">{item.department.name}</span>
                              </span>
                            </Link>
                          </div>

                          { item.requester ? ( <div className="flex items-center mb-1">
                            <div className="flex flex-shrink-0 mr-1">
                              <img
                                    key={'requester_'+item.requester.id}
                                    className="max-w-none h-5 w-5 rounded-full ring-2 ring-white"
                                    src={item.requester.avatar_url}
                                    alt={item.requester.name}
                                  />
                            </div>
                            <span className="flex-shrink-0 text-xs leading-5 font-medium">
                                {item.requester.name} {item.requester.company.name && <span>({item.requester.company.name})</span>}
                              </span>
                          </div>) : '' }
                        </div>
                      </td>

                      <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                        <span className={classNames(statusColors[item.status], "text-white uppercase inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium")}>{statusLabels[item.status]}</span>
                      </td>

                      {(user.managed_companies.length > 1) && <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                        {item.company.name}
                      </td>}

                      <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="">
                          { item.assignee ? ( <div className="flex items-center mb-1">
                            <div className="flex flex-shrink-0 mr-1">
                              <img
                                    key={'assignee_'+item.assignee.id}
                                    className="max-w-none h-5 w-5 rounded-full ring-2 ring-white"
                                    src={item.assignee.avatar_url}
                                    alt={item.assignee.name}
                                  />
                            </div>
                            <span className="flex-shrink-0 text-xs leading-5 font-medium">
                                {item.assignee.name}
                              </span>
                          </div> ) : (<span className="flex-shrink-0 text-xs leading-5 font-medium">Unassigned</span>) }
                        </div>
                      </td>
                      <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="flex items-center space-x-3">
                          <PriorityBadge priority={item.priority} />
                        </div>
                      </td>
                      <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                        {item.created_at}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>}
            </div>
          </div>
          {/* /Data table */}

          {/* Empty state */}
          {!items.length && isLoaded ? (
            <EmptyStates createPath="/tickets/create" />
          ) : '' }

          {/* Pagination */}
          {(items.length && isLoaded) ? (<Pagination paginationMeta={paginationMeta} reloadData={reloadData} />) : ''}
          
        </div>
      </div>
    )
  }
}
