import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-calculate";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { Switch } from "@headlessui/react";

// Components
import DropzoneField from "../../components/fields/DropzoneField";
import PrioritySelectField from "../../components/fields/PrioritySelectField";
import TextField from "../../components/fields/TextField";
import TextareaField from "../../components/fields/TextareaField";
import AlertModal from "../../components/AlertModal";
import SwitchField from "../../components/fields/SwitchField";

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

// Form Multiple Validators
const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
const required = (value) => (value ? undefined : "Required");

// Form calculator
const calculator = createDecorator({
  field: "department_id", // when department_id changes...
  updates: {
    // ...update ticket_category_id to the result of this function
    ticket_category_id: () => null,
  },
});

export default function CreateCrud(props) {
  const { user } = props;
  let params = useParams();
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [visibleToRequester, setVisibleToRequester] = useState(true);

  // Set page title
  useEffect(() => {
    document.title = `Create Support Ticket`;
  }, []);

  useEffect(() => {
    // Deparment & Categories
    axios
      .get("tickets/create")
      .then(function (response) {
        if (response.data.departments) {
          setDepartments(response.data.departments);
          setCategories(response.data.categories);
          setIsLoaded(true);
        }
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
        setIsLoaded(true);
      })
      .then(function () {
        setIsLoaded(true);
      });
  }, []);

  function closeForm() {
    setAlertModalOpen(false);
    if (params.ticketId) {
      navigate("/tickets/" + params.ticketId);
    } else {
      navigate("/tickets");
    }
  }

  function formFields(categoryId) {
    const index = categories.findIndex(
      (category) => category.id === parseInt(categoryId)
    );
    if (index >= 0) {
      return categories[index].form_fields ? categories[index].form_fields : [];
    }

    return [];
  }

  // Send data
  const sendData = (values) =>
    new Promise((resolve) => {
      setSendingData(true);
      const formData = new FormData();
      formData.append("department_id", values.department_id);
      formData.append("ticket_category_id", values.ticket_category_id);
      formData.append("priority", values.priority);
      formData.append("name", values.name);
      formData.append("content", values.content);
      formData.append("visible_to_requester", values.visible_to_requester);
      formData.append("form_data", JSON.stringify(values));
      if (params.ticketId) {
        formData.append("parent_id", params.ticketId);
      }

      if (values.attachments) {
        values.attachments.forEach((attachment) => {
          formData.append("attachments[]", attachment);
        });
      }

      axios
        .post("tickets", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          setSendingData(false);
          resolve();
          if (response.data.data.id) {
            navigate("/tickets/" + response.data.data.id);
          }
        })
        .catch(function (error) {
          setSendingData(false);
          resolve();
        });
    });

  // Submit form
  const onSubmit = async (values) => {
    await sendData(values);
  };

  return (
    <div className="h-full">
      <Form
        onSubmit={onSubmit}
        decorators={[calculator]}
        initialValues={{
          priority: "normal",
          attachments: [],
          visible_to_requester: true,
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            {/* Page title & actions */}
            <div className="bg-white border-b border-gray-200 px-4 py-4 flex items-center justify-between sm:px-6 lg:px-8 fixed top-14 lg:top-0 right-0 left-0 lg:left-56  lg:fixed lg:top-0 lg:left-56 lg:right-0 lg:z-10 bg-white">
              <div className="flex-1 min-w-0">
                <h1 className="text-lg font-medium leading-6 text-gray-900 truncate">
                  Create Support Ticket
                </h1>
              </div>
              <div className="mt-4 flex mt-0 ml-4">
                <a
                  href="#"
                  onClick={() => setAlertModalOpen(true)}
                  disabled={sendingData}
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-2 disabled:opacity-75"
                >
                  Cancel
                </a>
                <button
                  disabled={sendingData}
                  type="submit"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
                >
                  {sendingData ? "Submitting.." : "Submit"}
                </button>
              </div>
            </div>
            <main className="py-10 pt-20">
              <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8 mt-6">
                <div className="shadow sm:rounded-md sm:overflow-hidden w-full">
                  <div className="bg-white py-6 px-4 space-y-6 sm:p-6 relative">
                    {sendingData ? (
                      <div className="bg-white absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center z-50">
                        Please wait..
                      </div>
                    ) : (
                      ""
                    )}
                    <div>
                      <div>
                        <div className="space-y-8 divide-y divide-gray-200">
                          <div className="space-y-8 divide-y divide-gray-200">
                            <div>
                              <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                  Ticket information
                                </h3>
                                <p className="mt-1 text-sm text-gray-500">
                                  Some note here
                                </p>
                              </div>
                              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                {params.ticketId && (
                                  <Field name="visible_to_requester">
                                    {({ input }) => (
                                      <SwitchField
                                        label="Visible to the requester"
                                        input={input}
                                      />
                                    )}
                                  </Field>
                                )}

                                <Field name="department_id" validate={required}>
                                  {({ input, meta }) => (
                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor={input.name}
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Department{" "}
                                        <span className="text-red-500">*</span>
                                      </label>
                                      <div className="mt-1 relative rounded-md shadow-sm">
                                        <select
                                          {...input}
                                          className={
                                            meta.error && meta.touched
                                              ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                                              : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                          }
                                        >
                                          <option value="">Select</option>
                                          {departments.map((department) => (
                                            <option
                                              value={department.id}
                                              key={department.id}
                                            >
                                              {department.name}{" "}
                                              {user.managed_companies.length >
                                                1 && (
                                                <span>
                                                  ({department.company.name})
                                                </span>
                                              )}
                                            </option>
                                          ))}
                                        </select>

                                        {meta.error && meta.touched && (
                                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <ExclamationCircleIcon
                                              className="h-5 w-5 text-red-500"
                                              aria-hidden="true"
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {meta.error && meta.touched && (
                                        <p className="mt-2 text-sm text-red-600">
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>

                                <Field
                                  name="ticket_category_id"
                                  validate={required}
                                >
                                  {({ input, meta }) => (
                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor={input.name}
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Category{" "}
                                        <span className="text-red-500">*</span>
                                      </label>
                                      <div className="mt-1 relative rounded-md shadow-sm">
                                        <select
                                          {...input}
                                          className={
                                            meta.error && meta.touched
                                              ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                                              : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                          }
                                        >
                                          <option value="">Select</option>
                                          {categories.map((category) =>
                                            category.department_id ==
                                            values.department_id ? (
                                              <option
                                                value={category.id}
                                                key={category.id}
                                              >
                                                {category.name}
                                              </option>
                                            ) : (
                                              ""
                                            )
                                          )}
                                        </select>

                                        {meta.error && meta.touched && (
                                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <ExclamationCircleIcon
                                              className="h-5 w-5 text-red-500"
                                              aria-hidden="true"
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {meta.error && meta.touched && (
                                        <p className="mt-2 text-sm text-red-600">
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>

                                <Field name="name" validate={required}>
                                  {({ input, meta }) => (
                                    <TextField
                                      required={true}
                                      label="Ticket Name"
                                      input={input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>

                                <Field name="priority">
                                  {({ input, meta }) => (
                                    <PrioritySelectField
                                      label="Priority"
                                      input={input}
                                      meta={meta}
                                      values={values}
                                    />
                                  )}
                                </Field>

                                <Field name="content" validate={required}>
                                  {({ input, meta }) => (
                                    <TextareaField
                                      required={true}
                                      label="Content"
                                      input={input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>

                                <Field name="attachments">
                                  {({ input, meta }) => (
                                    <DropzoneField
                                      {...input}
                                      label="Attachments"
                                      meta={meta}
                                    />
                                  )}
                                </Field>

                                {/* <div>
                                  <pre>{JSON.stringify(values, 0, 2)}</pre>
                                </div> */}
                              </div>

                              {values.ticket_category_id &&
                                formFields(values.ticket_category_id).length >
                                  0 && (
                                  <div className="mt-10">
                                    <div className="relative">
                                      <div
                                        className="absolute inset-0 flex items-center"
                                        aria-hidden="true"
                                      >
                                        <div className="w-full border-t border-gray-300" />
                                      </div>
                                      <div className="relative flex justify-center">
                                        <span className="px-2 bg-white text-sm text-gray-500">
                                          Additional information
                                        </span>
                                      </div>
                                    </div>
                                    <div className="mt-8">
                                      {formFields(
                                        values.ticket_category_id
                                      ).map((formField) => (
                                        <div key={formField.name}>
                                          {formField.type === "paragraph" && (
                                            <div
                                              className="mb-6"
                                              dangerouslySetInnerHTML={{
                                                __html: formField.text,
                                              }}
                                            ></div>
                                          )}

                                          {formField.type === "text" && (
                                            <div className="mb-6">
                                              <Field
                                                name={formField.name}
                                                validate={
                                                  formField.required
                                                    ? required
                                                    : null
                                                }
                                              >
                                                {({ input, meta }) => (
                                                  <TextField
                                                    required={
                                                      formField.required
                                                        ? true
                                                        : false
                                                    }
                                                    label={formField.label}
                                                    input={input}
                                                    meta={meta}
                                                  />
                                                )}
                                              </Field>
                                            </div>
                                          )}

                                          {formField.type === "textarea" && (
                                            <div className="mb-6">
                                              <Field
                                                name={formField.name}
                                                validate={
                                                  formField.required
                                                    ? required
                                                    : null
                                                }
                                              >
                                                {({ input, meta }) => (
                                                  <TextareaField
                                                    required={
                                                      formField.required
                                                        ? true
                                                        : false
                                                    }
                                                    label={formField.label}
                                                    input={input}
                                                    meta={meta}
                                                  />
                                                )}
                                              </Field>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>

                                    {/* <pre>{JSON.stringify(formFields(values.ticket_category_id), 0, 2)}</pre> */}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </form>
        )}
      />

      <AlertModal
        title="Close Form"
        confirm={closeForm}
        close={() => setAlertModalOpen(false)}
        open={alertModalOpen}
      />
    </div>
  );
}
