import { ExclamationCircleIcon, XIcon } from "@heroicons/react/solid";
import { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { EyeIcon } from "@heroicons/react/solid";

import axios from "axios";

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;
const fileGetURL = process.env.REACT_APP_FILE_STARTURL;

export default function SingleFileUploadField(props) {
  const {
    label,
    input,
    meta,
    placeholder = null,
    col = 3,
    accept = "",
    required = false,
  } = props;

  const [sendingData, setSendingData] = useState({
    fileUrl: null,
    fileName: null,
    filePath: null,
    image: null,
  });
  const [fileUpdateCheck, setFileUpdateCheck] = useState(true);
  //faizi code
  const [isFileValid, setIsFileValid] = useState(false);
  const [fileError, setFileError] = useState("");
  //!

  const [isData, setIsData] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {}, [sendingData]);
  const changeHandler = (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("model", "company");

    if (file) {
      axios({
        method: "post",
        url: "/upload",
        data: formData,
        header: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          sendingData.fileUrl = response.data.fileUrl;
          sendingData.fileName = response.data.fileName;
          sendingData.filePath = response.data.filePath;
          sendingData.image = response.data.image;
          // if(response){
          setIsData(true);
          // }
          setSendingData({
            fileUrl: response.data.fileUrl,
            fileName: response.data.fileName,
            filePath: response.data.filePath,
            image: response.data.image,
          });
          //faizi code
          setIsFileValid(false);
          setFileError("");
          //!
          setFileUpdateCheck(true);
          input.onChange(sendingData);
        })
        .catch(function (error) {
          // toast.error(error.message);
          //faizi code
          setIsFileValid(true);
          setIsData(false);

          setFileError(
            error?.response?.data?.message ||
              "The file must be a file of type: jpeg, png, jpg, gif, svg, pdf."
          );
          //!
        });
    }
  };

  function clearImage() {
    setFileError("");
    setSendingData({
      // ...sendingData,
      fileUrl: null,
      fileName: null,
      filePath: null,
      image: null,
    });

    if (input) {
      input.onChange(null);
    }

    // input.onChange(null);
    inputRef.current.value = null;
  }

  return (
    <div className={"col-span-12 sm:col-span-" + col}>
      <label
        htmlFor={input.name}
        className="text-sm font-medium text-gray-700 mb-1 inline-flex"
      >
        {label} {required && <span className="text-red-500">*</span>}{" "}
        {input.value.fileUrl &&
          input?.value?.fileUrl != "null" &&
          input.value.fileUrl !== "undefined" &&
          input?.value?.fileUrl != "" &&
          input?.name != "logo" &&
          input?.name != "logo_dark" &&
          input?.name != "favicon" &&
          isData != true && (
            // input.name != "letterheadbg" &&
            // input.name != "company_stamp" &&
            // input.name != "done_stamp" &&
            // input.name != "paid_stamp" &&
            // input.name != "rejected_stamp" &&
            // sendingData.fileName == null &&
            <button
              onClick={() => clearImage()}
              type="button"
              className=" border border-transparent shadow-sm text-[12px] font-bold rounded-full bg-red-400 text-white hover:bg-red-500 focus:outline-none focus:ring-0 ml-2 align-middle h-[100%] m-auto"
            >
              <XIcon className="h-3 w-3" aria-hidden="true" />
            </button>
          )}
        {input?.value?.fileUrl &&
          input?.value?.fileUrl != "null" &&
          input?.value?.fileUrl != "undefined" &&
          input?.value?.fileUrl != "" &&
          input.name != "logo" &&
          input.name != "logo_dark" &&
          input.name != "favicon" &&
          isData != true && (
            // input.name != "letterheadbg" &&
            // input.name != "company_stamp" &&
            // input.name != "done_stamp" &&
            // input.name != "paid_stamp" &&
            // input.name != "rejected_stamp" &&
            // sendingData.fileName == null &&

            <a
              target="_blank"
              title={
                sendingData.fileName
                  ? sendingData.fileName
                  : input?.value?.fileName || input.value?.fileUrl
              }
              href={
                input?.value?.fileUrl?.includes("https")
                  ? input?.value?.fileUrl
                  : fileGetURL + input?.value?.fileUrl
              }
            >
              <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
            </a>
          )}
        {input.value.fileUrl &&
          input?.value?.fileUrl != "null" &&
          input.value.fileUrl !== "undefined" &&
          input?.value?.fileUrl != "" &&
          input?.name != "logo" &&
          input?.name != "logo_dark" &&
          input?.name != "favicon" &&
          isData != false &&
          // input.name != "letterheadbg" &&
          // input.name != "company_stamp" &&
          // input.name != "done_stamp" &&
          // input.name != "paid_stamp" &&
          // input.name != "rejected_stamp" &&
          sendingData.fileName != null && (
            <button
              onClick={() => clearImage()}
              type="button"
              className=" border border-transparent shadow-sm text-[12px] font-bold rounded-full bg-red-400 text-white hover:bg-red-500 focus:outline-none focus:ring-0 ml-2 align-middle h-[100%] m-auto"
            >
              <XIcon className="h-3 w-3" aria-hidden="true" />
            </button>
          )}
        {input?.value?.fileUrl &&
          input?.value?.fileUrl != "null" &&
          input?.value?.fileUrl != "undefined" &&
          input?.value?.fileUrl != "" &&
          input.name != "logo" &&
          input.name != "logo_dark" &&
          input.name != "favicon" &&
          isData != false &&
          // input.name != "letterheadbg" &&
          // input.name != "company_stamp" &&
          // input.name != "done_stamp" &&
          // input.name != "paid_stamp" &&
          // input.name != "rejected_stamp" &&
          sendingData.fileName != null && (
            <a
              target="_blank"
              title={
                sendingData.fileName
                  ? sendingData.fileName
                  : input?.value?.fileName || input.value?.fileUrl
              }
              href={
                input?.value?.fileUrl?.includes("https")
                  ? input?.value?.fileUrl
                  : fileGetURL + input?.value?.fileUrl
              }
            >
              <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
            </a>
          )}
        {/* for string logo dak logo  */}
        {typeof input.value === "string" &&
          input?.value &&
          input?.name == "logo" &&
          input.name != "letterheadbg" &&
          input.name != "company_stamp" &&
          input.name != "done_stamp" &&
          input.name != "paid_stamp" &&
          input.name != "rejected_stamp" && (
            // sendingData.fileName != null &&
            <button
              onClick={() => clearImage()}
              type="button"
              className=" border border-transparent shadow-sm text-[12px] font-bold rounded-full bg-red-400 text-white hover:bg-red-500 focus:outline-none focus:ring-0 ml-2 align-middle h-[100%] m-auto"
            >
              <XIcon className="h-3 w-3" aria-hidden="true" />
            </button>
          )}
        {typeof input.value === "string" &&
          input?.value != null &&
          input?.value != "" &&
          input?.value != "undefined" &&
          input.name == "logo" && (
            <a
              target="_blank"
              title={sendingData.fileName ? sendingData.fileName : input.value}
              href={
                sendingData.filePath
                  ? sendingData.filePath
                  : // : input.value && !input.value.fileUrl ?  fileGetURL + input.value  : input.value.fileUrl ? fileGetURL + input.value.fileUrl : ""
                    input.value
              }
            >
              <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
            </a>
          )}
        {typeof input.value === "string" &&
          input?.value &&
          input?.name == "logo_dark" &&
          input.name != "letterheadbg" &&
          input.name != "company_stamp" &&
          input.name != "done_stamp" &&
          input.name != "paid_stamp" &&
          input.name != "rejected_stamp" && (
            <button
              onClick={() => clearImage()}
              type="button"
              className=" border border-transparent shadow-sm text-[12px] font-bold rounded-full bg-red-400 text-white hover:bg-red-500 focus:outline-none focus:ring-0 ml-2 align-middle h-[100%] m-auto"
            >
              <XIcon className="h-3 w-3" aria-hidden="true" />
            </button>
          )}
        {typeof input.value === "string" &&
          input?.value != null &&
          input?.value != "" &&
          input?.value != "undefined" &&
          input.name == "logo_dark" && (
            <a
              target="_blank"
              title={sendingData.fileName ? sendingData.fileName : input.value}
              href={
                sendingData.filePath
                  ? sendingData.filePath
                  : // : input.value && !input.value.fileUrl ?  fileGetURL + input.value  : input.value.fileUrl ? fileGetURL + input.value.fileUrl : ""
                    input.value
              }
            >
              <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
            </a>
          )}
        {/* for string logo dak logo  */}
        {/*for object logo dark logo */}
        {typeof input.value === "object" &&
          input?.value?.fileUrl &&
          input?.name == "logo" &&
          input.name != "letterheadbg" &&
          input.name != "company_stamp" &&
          input.name != "done_stamp" &&
          input.name != "paid_stamp" &&
          input.name != "rejected_stamp" && (
            <button
              onClick={() => clearImage()}
              type="button"
              className=" border border-transparent shadow-sm text-[12px] font-bold rounded-full bg-red-400 text-white hover:bg-red-500 focus:outline-none focus:ring-0 ml-2 align-middle h-[100%] m-auto"
            >
              <XIcon className="h-3 w-3" aria-hidden="true" />
            </button>
          )}
        {typeof input.value === "object" &&
          input?.value.fileUrl != null &&
          input?.value.fileUrl != "" &&
          input?.value.fileUrl != undefined &&
          input.name == "logo" && (
            <>
              <a
                target="_blank"
                title={
                  sendingData.fileName
                    ? sendingData.fileName
                    : input.value.fileName
                }
                href={
                  sendingData.filePath
                    ? sendingData.filePath
                    : // : input.value && !input.value.fileUrl ?  fileGetURL + input.value  : input.value.fileUrl ? fileGetURL + input.value.fileUrl : ""
                      input.value.fileUrl
                }
              >
                <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
              </a>
            </>
          )}
        {typeof input.value === "object" &&
          input?.value?.fileUrl &&
          input?.name == "logo_dark" &&
          input.name != "letterheadbg" &&
          input.name != "company_stamp" &&
          input.name != "done_stamp" &&
          input.name != "paid_stamp" &&
          input.name != "rejected_stamp" && (
            <button
              onClick={() => clearImage()}
              type="button"
              className=" border border-transparent shadow-sm text-[12px] font-bold rounded-full bg-red-400 text-white hover:bg-red-500 focus:outline-none focus:ring-0 ml-2 align-middle h-[100%] m-auto"
            >
              <XIcon className="h-3 w-3" aria-hidden="true" />
            </button>
          )}
        {typeof input.value === "object" &&
          input?.value.fileUrl != null &&
          input?.value.fileUrl != "" &&
          input?.value.fileUrl != undefined &&
          input.name == "logo_dark" && (
            <a
              target="_blank"
              title={
                sendingData.fileName
                  ? sendingData.fileName
                  : input.value.fileName
              }
              href={
                sendingData.filePath
                  ? sendingData.filePath
                  : // : input.value && !input.value.fileUrl ?  fileGetURL + input.value  : input.value.fileUrl ? fileGetURL + input.value.fileUrl : ""
                    input.value.fileUrl
              }
            >
              <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
            </a>
          )}
        {/* for object logo dark logo  */}
        {input?.value &&
          input.value.fileUrl !== null &&
          input?.value.fileUrl != undefined &&
          input.value !== "[object Object]" &&
          input?.name == "favicon" &&
          input.name != "letterheadbg" &&
          input.name != "company_stamp" &&
          input.name != "done_stamp" &&
          input.name != "paid_stamp" &&
          input.name != "rejected_stamp" &&
          isData == false && (
            // sendingData.fileName != null &&
            <button
              onClick={() => clearImage()}
              type="button"
              className=" border border-transparent shadow-sm text-[12px] font-bold rounded-full bg-red-400 text-white hover:bg-red-500 focus:outline-none focus:ring-0 ml-2 align-middle h-[100%] m-auto"
            >
              <XIcon className="h-3 w-3" aria-hidden="true" />
            </button>
          )}
        {input.value &&
          input?.value != null &&
          input.value.fileUrl !== null &&
          input.value !== "[object Object]" &&
          input?.value != "" &&
          input?.value != "undefined" &&
          input?.value.fileUrl != undefined &&
          input.name == "favicon" &&
          isData == false && (
            // sendingData.fileName != null &&
            <a
              target="_blank"
              title={
                sendingData.fileName
                  ? sendingData.fileName
                  : input.value.fileName || input.value
              }
              href={
                sendingData.filePath
                  ? sendingData.filePath
                  : // : fileGetURL + input.value.fileUrl
                    input.value
              }
            >
              <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
            </a>
          )}
        {input?.value &&
          input.value.fileUrl !== null &&
          input.value !== "[object Object]" &&
          input?.value.fileUrl != undefined &&
          input?.name == "favicon" &&
          input.name != "letterheadbg" &&
          input.name != "company_stamp" &&
          input.name != "done_stamp" &&
          input.name != "paid_stamp" &&
          input.name != "rejected_stamp" &&
          isData == true &&
          sendingData.fileName != null && (
            <button
              onClick={() => clearImage()}
              type="button"
              className=" border border-transparent shadow-sm text-[12px] font-bold rounded-full bg-red-400 text-white hover:bg-red-500 focus:outline-none focus:ring-0 ml-2 align-middle h-[100%] m-auto"
            >
              <XIcon className="h-3 w-3" aria-hidden="true" />
            </button>
          )}
        {input.value &&
          input?.value != null &&
          input.value.fileUrl !== null &&
          input.value !== "[object Object]" &&
          input?.value != "" &&
          input?.value != "undefined" &&
          input?.value.fileUrl != undefined &&
          input.name == "favicon" &&
          isData == true &&
          sendingData.fileName != null && (
            <a
              target="_blank"
              title={
                sendingData.fileName
                  ? sendingData.fileName
                  : input.value.fileName || input.value
              }
              href={
                sendingData.filePath
                  ? sendingData.filePath
                  : // : fileGetURL + input.value.fileUrl
                    input.value
              }
            >
              <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
            </a>
          )}
        {/* { input.value != "" && input.value != undefined && input.value != null && input.name === "profile_pic_url" &&
            <button
            onClick={() => clearImage()}
            type="button"
            className=" border border-transparent shadow-sm text-[12px] font-bold rounded-full bg-red-400 text-white hover:bg-red-500 focus:outline-none focus:ring-0 ml-2 align-middle h-[100%] m-auto"
          >
            <XIcon className="h-3 w-3" aria-hidden="true" />
          </button>
          } */}
        {/* { input.value != "" && input.value != undefined && input.value != null && input.name === "profile_pic_url" &&
            <a
            target="_blank"
            href={
              sendingData.filePath
                ? sendingData.filePath
                : fileGetURL + input.value
            }
          >
            <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
          </a>
          } */}
      </label>
      <div className="relative">
        <input
          name={input.name}
          id={input.id}
          onChange={changeHandler}
          type="file"
          placeholder={placeholder}
          accept={
            accept
              ? accept
              : "image/png, image/jpeg, image/jpg, image/gif, image/svg+xml, application/pdf"
          }
          className={
            (meta.error && meta.touched) || isFileValid
              ? "border py-2 px-2 rounded-md block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              : "border border-slate-300 py-2 px-2 rounded-md block w-full sm:text-sm placeholder-gray-300 mb-2"
          }
          ref={inputRef}
        />
        {/* <p className="mt-2 text-sm text-gray-500">Accept: {accept}</p> */}
        {((meta.error && meta.touched) || isFileValid) && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {(isFileValid || (meta.error && meta.touched)) && (
        <p className="mt-2 text-sm text-red-600">{fileError || meta.error}</p>
      )}
    </div>
  );
}
