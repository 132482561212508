
import React, { useState, useEffect } from 'react'
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import axios from 'axios';
import {
  TrashIcon,
  PencilAltIcon,
  EyeIcon,
} from "@heroicons/react/solid";
import NavBar from 'layout/NavBar';
import Tabs from 'layout/Tabs';
import Error from 'layout/Error';
import Loader from 'layout/Loader';
import SkeletonTable from 'components/SkeletonTable';
import moment from "moment/moment";
import EmptyStates from 'components/EmptyStates';
import AlertModal from 'components/AlertModal';
import PaginationAll from 'components/pagination';
import { toast } from 'react-toastify';


// Static variables
const pageTitle = "Roles";

const statusTextColors = {
  open: "text-black",
  pending: "text-purple-500",
  cancelled: "text-gray-500",
  resolved: "text-green-500",
};


export default function ListRole(props) {

    const [itemsPerPage, setItemsPerPage] = useState(10)

    let navigate = useNavigate();

    const { user } = props;
    let [searchParams, setSearchParams] = useSearchParams();
    const [filterStatus, setFilterStatus] = useState(
      searchParams.get("status") ? searchParams.get("status") : ""
    );
    const [deleteItemModalOpen, setDeleteItemModalOpen] = useState(false);
    const [deletingData, setDeletingItem] = useState();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [addCompanyFilter, setAddCompanyFilter] = useState(null);


    
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const displayedData = items?.slice(startIndex, endIndex);
      // Set page title
  useEffect(() => {
    document.title = pageTitle;
  }, []);

    const loadData = (params = {}) => {
        setIsDataLoading(true);
    
        if (params === {}) {
          //params['status'] = ''
        }
        setSearchParams(params);
        axios
          .get("/role/all", {
            params: params,
          })
          .then(function (response) {
            // handle success
            setIsLoaded(true);
            setItems(response.data.data);
            setTimeout(function () {
              setIsDataLoading(false);
            }, 300);
          })
          .catch(function (error) {
            // handle error
            setError(error);
          });
      };

      const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

      function refreshData() {
        let params = {};
        searchParams.forEach((key, value) => {
          params[value] = key;
          setFilterCount((filterCount) => filterCount + 1);
        });
    
        loadData(params);
      }

      function addFilter(e) {
        e.preventDefault();
        setAddCompanyFilter(e.target.value);
        setIsDataLoading(true);
    
        const formData = new FormData();
    
        formData.append("name", e.target.value);
        axios({
          method: "get",
          url: "/role/all",
          data: formData,
          header: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(function (response) {
            // handle success
            setIsLoaded(true);
            setItems(response.data.data);
            setTimeout(function () {
              setIsDataLoading(false);
            }, 300);
          })
          .catch(function (error) {
            // handle error
            setError(error);
          });
      }
    
      useEffect(() => {
        refreshData();
      }, []);

      function updateItem(item) {
        navigate(`/role/edit/${item.id}`);
      }


      async function deleteItem(item) {
        setDeletingItem(item);
        setDeleteItemModalOpen(true);
     
    }

    const confirmDeleteItem = async ()  => {
     

      await  axios
      .delete("role/delete/" + deletingData?.id , {
          headers: {
              "Content-Type": "application/json",
          },
      })
      .then(function (response) {
        setDeleteItemModalOpen(false);
        refreshData();
        setIsLoaded(true);
        setTimeout(function () {
          setIsDataLoading(false);
        }, 300);
      })
      .catch(function (error) {
          toast.error(error.response.data.message);
      });
    }

  return (
    <div className="min-h-[100vh] bg-gray-50 pb-1">
         <NavBar pageTitle={pageTitle}>
        <Link
          to="/role/create"
          type="button"
          className="relative inline-flex items-center px-2 py-1 sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
        >
          Add New Role
        </Link>
      </NavBar>


      <div>
        <div className="px-4 py-3 col-span-full lg:col-span-3">
        <div className="col-span-12 lg:col-span-3">
            <div className="mt-1 relative rounded-md">
              <form className="pr-3 pt-3 pb-3">
                <input
                  type="text"
                  name="name"
                  placeholder="Search by Employee name..."
                  value={addCompanyFilter}
                  onChange={(event) => addFilter(event)}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  className="p-2 w-full lg:w-2/5 outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded"
                />
                <div className='float-right'>
                 <select className='rounded-md outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 bg-[#ffffff]' name="" id="" value={itemsPerPage} onChange={(e) => setItemsPerPage(e.target.value)}>
                
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                 </select>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

  {/* Data Table */}
  <div className="mt-0 overflow-x-auto">
          <div className="align-middle inline-block min-w-full border-b border-gray-200">
            {isDataLoading && <SkeletonTable />}
            {!isDataLoading && (
              <table className="min-w-full table-auto">
                <thead>
                 
                    <tr className="border-t border-gray-200">
                      <th
                        className="px-9 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Action
                      </th>
                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        <span className="whitespace-nowrap">Name</span>
                      </th>
                    </tr>
              
                
                </thead>
                <tbody className="bg-white divide-y divide-gray-100">
                  {
                    displayedData.map((item , index) => (
                      <tr
                        className=""
                        key={item.id}
                      >
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-left">
                        <button
                            // to="/employee/view/:employeeId"
                            onClick={() => updateItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
                          >
                            <EyeIcon
                              className="h-4 w-4 text-blue-400"
                              aria-hidden="true"
                            />
                          </button>
                       { item.id !== 1 && item.id !==2 && item.id !==3 && item.id !==4 && item.id !==5 && item.id !==6 &&item.id !==7 && item.id !==8 &&
                         item.id !==9 && item.id !==10 &&  <button
                            // onClick={() => editItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
                          >
                            <PencilAltIcon
                              className="h-4 w-4 text-green-400"
                              aria-hidden="true"
                            />
                          </button> }
                         { item.id !== 1 && item.id !==2 && item.id !==3 && item.id !==4 && item.id !==5 && item.id !==6 &&item.id !==7 && item.id !==8 &&
                         item.id !==9 && item.id !==10 &&
                          <button
                            onClick={() => deleteItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700  focus:outline-none focus:ring-0"
                          >
                            <TrashIcon
                              className="h-4 w-4 text-red-400"
                              aria-hidden="true"
                            />
                          </button>}
                        
                      
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                         {item?.name} {item.id === 1 || item.id === 2 || item.id === 3 || item.id === 4 || item.id === 5 || item.id === 6 || item.id ===7 || item.id === 8
                         || item.id === 9 || item.id === 10 ? <span className='text-sm text-gray-400'>(system role)</span> : "" } 
                        </td>
                       
                       
                      </tr>
                    ))}
                
                </tbody>
              </table>
            )}
          </div>
        </div>
        {/* /Data Table */}

        <AlertModal
        title="Delete Role"
        note={`Are you sure you want to delete "${deletingData?.name}" Role.`}
        confirm={confirmDeleteItem}
        close={() => setDeleteItemModalOpen(false)}
        open={deleteItemModalOpen}
      />

         {/* Empty state */}
         {!items.length && isLoaded ? (
          <EmptyStates createPath="/role/create" />
        ) : (
          ""
        )}


<PaginationAll
              currentPage={currentPage}
              totalPages={Math.ceil(items?.length / itemsPerPage)}
              onPageChange={handlePageChange}
          />


        </div>
  )
}