import { useState, useEffect } from 'react';
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'

export default function ArrayField(props) {
    const { label, input, meta, columns, required = false } = props
    const [ dataRows, setDataRows ] = useState(input.value ? input.value : [])

    function addDataRow() {
        let newRowData = {}
        columns.map((column) => {
            newRowData[column.key] = null
        })
        setDataRows([...dataRows, newRowData])
    }

    function handleChange(event, dataRowIndex, columnKey) {
        const value = event.target.value
        let newDataRows = dataRows.slice()
        newDataRows[dataRowIndex][columnKey] = value
        setDataRows(newDataRows)
        input.onChange(newDataRows)
    }

    function removeDataRow(dataRowIndex) {
        let newDataRows = dataRows.slice()
        newDataRows.splice(dataRowIndex, 1)
        setDataRows(newDataRows)
        input.onChange(newDataRows)
    }

    return (
        <div className="sm:col-span-6">
            <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            
            <div className="mt-1 relative">
                {dataRows?.map((dataRow, dataRowIndex) => (
                    <div className="relative pr-10" key={dataRowIndex}>
                        <div className={'mb-2 grid gap-4 grid-cols-'+columns.length}>
                            {columns.map((column) => (
                            <div key={column.key}>
                                <input
                                    type="text"
                                    onChange={(event) => handleChange(event, dataRowIndex, column.key)}
                                    placeholder={column.label}
                                    value={dataRow[column.key]}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>
                            ))}
                        </div>

                        <button
                            onClick={() => removeDataRow(dataRowIndex)}
                            type="button"
                            className="absolute right-0 top-1 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-0"
                        >
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                ))}
                
                <button
                    onClick={() => addDataRow()}
                    type="button"
                    className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0"
                >
                    Add row
                </button>             
            </div>
            {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}

            <div className="grid-cols-1 sr-only"></div>
            <div className="grid-cols-2 sr-only"></div>
            <div className="grid-cols-3 sr-only"></div>
            <div className="grid-cols-4 sr-only"></div>
            <div className="grid-cols-5 sr-only"></div>
            <div className="grid-cols-6 sr-only"></div>
            <div className="grid-cols-7 sr-only"></div>
            <div className="grid-cols-8 sr-only"></div>
            <div className="grid-cols-9 sr-only"></div>
            <div className="grid-cols-10 sr-only"></div>
            <div className="grid-cols-11 sr-only"></div>
            <div className="grid-cols-12 sr-only"></div>
        </div>
    )
}