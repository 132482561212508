import { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-calculate";
import {
  CheckIcon,
  PaperClipIcon,
  ThumbUpIcon,
  UserIcon,
  ExclamationCircleIcon,
  XIcon,
  StarIcon,
  CollectionIcon,
  SelectorIcon,
  AdjustmentsIcon,
  BellIcon,
  PencilAltIcon,
} from "@heroicons/react/solid";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import moment from "moment/moment";

import axios from "axios";

// Components
import PriorityBadge from "../../components/PriorityBadge";
import DropzoneField from "../../components/fields/DropzoneField";
import UserSelectField from "../../components/fields/UserSelectField";
import ResolveTicketModal from "../../components/ResolveTicketModal";
import AlertModal from "../../components/AlertModal";
import PrioritySelectField from "../../components/fields/PrioritySelectField";
import StarRatings from "react-star-ratings";
import RatingStars from "../../components/RatingStars";
import RateModal from "../../components/RateModal";
import MoveTicketModal from "../../components/MoveTicketModal";
// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

// Form Multiple Validators
const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
const required = (value) => (value ? undefined : "Required");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TicketShow(props) {
  const { user } = props;
  const navigate = useNavigate();
  let { vendorId } = useParams();
  const [error, setError] = useState(null);
  const [isNoPermission, setIsNoPermission] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [model, setModel] = useState("");
  const [rateModalOpen, setRateModalOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [stringy, setStringy] = useState("");

  const [address, setAdress] = useState([
    { name: "ali", adress: "qwerty" },
    { name: "aliyan", adress: "zxcvb" },
    { name: "tyuuii", adress: "asdfg" },
  ]);

  async function reloadModelData() {
    await axios
      .get(`http://127.0.0.1:8000/v2/v2vendor/show/${vendorId}`)
      .then(function (response) {
        console.log(typeof response.data.data.address, "response");
        const new_var = JSON.parse(response.data.data.address);
        console.log(typeof new_var, "asdasdsad");
        setModel(response?.data?.data);

        // handle success
        // if (response.data.status === "no_permission") {
        //   setIsNoPermission(true);
        // } else {
        //   if (response.data.data) {
        //     document.title = response.data.data.name;

        //     setModel(response.data.data);
        //   }
        // }
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
        setIsLoaded(true);
      })
      .then(function () {
        setIsLoaded(true);
      });
  }

  useEffect(() => {
    // Load Data
    reloadModelData();
  }, []);

  // console.log(typeof model?.address, "mobb");
  // setStringy(JSON?.parse(model.address));

  function canUpdateModel() {
    return true;
  }

  if (isNoPermission) {
    return (
      <div className="fullscreen-loading flex items-center justify-around">
        404 Not Found
      </div>
    );
  } else {
    return (
      <>
        <div className="min-h-full bg-gray-100 relative pt-10">
          {!isLoaded ? (
            <div className="bg-white absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center z-50">
              Loading..
            </div>
          ) : (
            ""
          )}
          {/* Page title & actions */}
          <div className="bg-white border-b border-gray-200 px-4 py-4 flex items-center justify-between sm:px-6 lg:px-8 fixed top-14 lg:top-0 right-0 left-0 lg:left-56  lg:fixedlg:right-0 lg:z-10 ">
            <div className="flex-1 min-w-0">
              <h1 className="text-lg font-medium leading-6 text-gray-900 truncate">
                vendor Profile
              </h1>
            </div>
            <div className="mt-4 flex ml-4">
              <Link
                to="/vendors"
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-2"
              >
                Close
              </Link>
            </div>
          </div>

          <div className="bg-white rounded-md sm:px-5 px-0 mb-5 sm:mx-5 mx-0 pl-2 ">
            <h4 className="font-bold text-[20px] py-5 border-b-2 my-5">
              Personal Information
            </h4>

            <div className="bg-white border-b-2 my-6 rounded-md sm:p-5 p-0 sm:mx-5 mx-0 ">
              <div className=" grid grid-cols-1">
                <div className="">
                  <div className="md:flex">
                    <div className="sm:w-[30%] pb-5">
                      <img
                        className="w-[40%] sm:w-[95%] md:w-[70%] mx-auto text-center rounded-full border-2 mt-10 sm:mt-0 bg-gray-400"
                        src="https://png.pngtree.com/png-vector/20220901/ourmid/pngtree-company-employee-avatar-icon-wearing-a-suit-png-image_6133899.png"
                        alt="profile"
                      />
                    </div>

                    <div className=" sm:w-[90%] md:w-[70%] lg:my-auto lg:align-middle sm:text-left text-center  px-1 sm:px-5  break-words">
                      <h4 className="text-[18px] font-bold my-2">
                        {model?.name ? model.name : "N/A"}
                      </h4>
                      <p className="text-[18px] font-medium my-2">
                        {model?.companies ? model?.companies : "N/A"}
                      </p>
                      <span className="border-2 px-2 py-1 rounded-2xl bg-[rgb(113,128,150)] text-white">
                        {model?.catagories ? model?.catagories : "N/A"}
                      </span>
                    </div>
                  </div>
                </div>

                {/* code */}
                <div className="sm:w-4/6 w-full md:ml-5 sm:ml-5 ml-0 sm:text-left text-center pt-10 pb-10 lg:pl-10 pl-0">
                  <h4 className="font-bold text-20px md:pb-6">
                    Contact Details
                  </h4>
                  <div className="flex flex-col items-center sm:flex-row sm:items-start sm:justify-start">
                    <div className="flex items-center">
                      <MailIcon className="h-5 w-5 mr-2" />
                      <span>{model?.email ? model?.email : "N/A"}</span>
                    </div>
                    <br className="sm:hidden" />
                    <div className="flex items-center sm:pl-3">
                      <PhoneIcon className="h-5 w-5 mr-2" />
                      <span>
                        {model?.user_profile?.cellphone_number
                          ? model?.user_profile?.cellphone_number
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid xl:grid-cols-2 pb-1">
              <div>
                <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                  <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">
                    Id:
                  </td>
                  <td className="pl-0 sm:pl-[10%] sm:w-[62%] w-[100%] 50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%] md:overflow-auto lg:w-[62%] lg:overflow-auto xl:w-[62%] xl:overflow-auto break-words">
                    # {model?.id ? model?.id : "N/A"}
                  </td>
                </tr>
                <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                  <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">
                    Created at:
                  </td>
                  <td className="pl-0 sm:pl-[10%] sm:w-[62%] w-[100%] 50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%] md:overflow-auto lg:w-[62%] lg:overflow-auto xl:w-[62%] xl:overflow-auto break-words">
                    {model?.created_at
                      ? moment(model?.created_at).format("DD/MM/YYYY")
                      : "N/A"}
                  </td>
                </tr>
                <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                  <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">
                    Vat Number:
                  </td>
                  <td className="pl-0 sm:pl-[10%] sm:w-[62%] w-[100%] 50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%] md:overflow-auto lg:w-[62%] lg:overflow-auto xl:w-[62%] xl:overflow-auto break-words">
                    {model?.vat_number ? model?.vat_number : "N/A"}
                  </td>
                </tr>
              </div>
              <div>
                <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                  <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">
                    CR Number:
                  </td>
                  <td className="pl-0 sm:pl-[10%] sm:w-[62%] w-[100%] 50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%] md:overflow-auto lg:w-[62%] lg:overflow-auto xl:w-[62%] xl:overflow-auto break-words">
                    {model?.cr_number ? model?.cr_number : "N/A"}
                  </td>
                </tr>
                <tr className="grid grid-flow-row sm:flex sm:h-[80px]">
                  <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">
                    Address:
                  </td>
                  {address.map((e) => (
                    <td className="pl-0 sm:pl-[10%] sm:w-[62%] w-[100%] 50 sm:pb-0 pb-5 break-all sm:overflow-auto md:w-[62%] md:overflow-auto lg:w-[62%] lg:overflow-auto xl:w-[62%] xl:overflow-auto break-words">
                      {e?.name ? e?.name : "N/A"}
                    </td>
                  ))}
                </tr>
              </div>
            </div>
          </div>

          {/* {isLoaded ? (
            <main className="py-10 pt-20">
              <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                  <div>
                    <h1 className="text-2xl font-bold text-gray-900">
                      {model.name} 
                    </h1>
                    <p className="text-sm font-medium text-gray-500"></p>
                  </div>
                </div>

                {canUpdateModel() && (
                  <Link
                    to={"/vendors/" + model.id + "/edit"}
                    type="button"
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
                  >
                    <PencilAltIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    Edit
                  </Link>
                )}
              </div>

              <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                  <section aria-labelledby="applicant-information-title">
                    <div className="bg-white shadow sm:rounded-lg">
                      <div className="px-4 py-5 sm:px-6">
                        <h2
                          id="applicant-information-title"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          #{model.id}
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          <span className="text-gray-900">
                            Created at{" "}
                            <time dateTime={model.created_at}>
                              {model.created_at}
                            </time>
                          </span>
                        </p>
                      </div>
                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                          <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">
                              Companies
                            </dt>
                            <dd className="mt-1 mb-5 text-sm text-gray-900">
                              {model.companies.map((company) => (
                                <span
                                  key={company.id}
                                  className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                                >
                                  {company.name}
                                </span>
                              ))}
                            </dd>
                          </div>
                        </dl>

                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Category
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {model?.name}
                            </dd>
                          </div>

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              VAT Number
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {model?.vat_number}
                            </dd>
                          </div>

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              CR Number
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {model?.cr_number}
                            </dd>
                          </div>

                          {model.general_addresses && (
                            <div className="sm:col-span-3">
                              <dt className="text-sm font-medium text-gray-500">
                                Addresses
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6 md:pl-0"
                                      >
                                        Name
                                      </th>
                                      <th
                                        scope="col"
                                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-500"
                                      >
                                        Address
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200">
                                    {model.general_addresses.map(
                                      (address, addressIndex) => (
                                        <tr key={addressIndex}>
                                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                            {address.name}
                                          </td>
                                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                            {address.address}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </dd>
                            </div>
                          )}

                          {model?.phones && (
                            <div className="sm:col-span-3">
                              <dt className="text-sm font-medium text-gray-500">
                                Phones
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6 md:pl-0"
                                      >
                                        Name
                                      </th>
                                      <th
                                        scope="col"
                                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-500"
                                      >
                                        Number
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200">
                                    {model?.phones?.map((phone, phoneIndex) => (
                                      <tr key={phoneIndex}>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                          {phone.name}
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                          {phone.number}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </dd>
                            </div>
                          )}

                          {model?.bank_acc && (
                            <div className="sm:col-span-3">
                              <dt className="text-sm font-medium text-gray-500">
                                Bank Accounts
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6 md:pl-0"
                                      >
                                        Bank Name
                                      </th>
                                      <th
                                        scope="col"
                                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-500"
                                      >
                                        Account Number
                                      </th>
                                      <th
                                        scope="col"
                                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-500"
                                      >
                                        SWIFT
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200">
                                    {model?.bank_acc.map(
                                      (bankAccount, bankAccountIndex) => (
                                        <tr key={bankAccountIndex}>
                                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                            {bankAccount.name}
                                          </td>
                                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                            {bankAccount.number}
                                          </td>
                                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                            {bankAccount.swift}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </dd>
                            </div>
                          )}
                        </dl>
                        )}

                        <dl className="mt-10 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                          {model.attachments.length ? (
                                  <div className="sm:col-span-3">
                                      <dt className="text-sm font-medium text-gray-500">Attachments</dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                      <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                          {ticket.attachments.map((attachment) => (
                                          <li
                                              key={attachment.file_name}
                                              className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                                          >
                                              <div className="w-0 flex-1 flex items-center">
                                              <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                              <span className="ml-2 flex-1 w-0 truncate">{attachment.file_name}</span>
                                              </div>
                                              <div className="ml-4 flex-shrink-0">
                                              <a href={attachment.url} target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:text-blue-500">
                                                  Download
                                              </a>
                                              </div>
                                          </li>
                                          ))}
                                      </ul>
                                      </dd>
                                  </div>
                                  ) : ''}
                        </dl>
                      </div>
                    </div>
                  </section>
                </div>

                <section
                  aria-labelledby="timeline-title"
                  className="lg:col-start-3 lg:col-span-1"
                >
                  <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                    <h2
                      id="timeline-title"
                      className="text-lg font-medium text-gray-900"
                    >
                      Reviews
                    </h2>
                  </div>
                </section>
              </div>
            </main>
          ) : (
            ""
          )} */}
        </div>
      </>
    );
  }
}
