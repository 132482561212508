import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useSearchParams } from "react-router-dom";
import {
    ChevronDownIcon,
    ChevronUpIcon,
    RefreshIcon,
    TrashIcon,
    XIcon,
} from "@heroicons/react/solid";
import { FilterIcon } from "@heroicons/react/outline";
import { Disclosure, Transition } from '@headlessui/react'

// Required components
import Loader from "layout/Loader";
import NavBar from "layout/NavBar";
import Pagination from "components/Pagination";
import EmptyStates from "components/EmptyStates";
import SkeletonTable from "components/SkeletonTable";
import Error from "layout/Error";
import Tabs from "layout/Tabs";

// Optional Components
import AlertModal from "components/AlertModal";

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

// ====================================== APP ==========================================
// Static variables
const pageTitle = "Offices";

const statusTextColors = {
    open: "text-pink-500",
    pending: "text-purple-500",
    cancelled: "text-gray-500",
    resolved: "text-green-500",
};

export default function List(props) {
    const { user } = props;
    let [searchParams, setSearchParams] = useSearchParams();
    const [deleteItemModalOpen, setDeleteItemModalOpen] = useState(false);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [items, setItems] = useState([]);
    const [paginationMeta, setPaginationMeta] = useState([]);
    const [tabs, setTabs] = useState([]);

    // Set page title
    useEffect(() => {
        document.title = pageTitle;
    }, []);

    // Order By
    let curretnOrderByValue = {
        key: "created_at",
        order: "desc",
    };
    if (searchParams.get("order_by")) {
        if (
            searchParams.get("order_by").key !== "created_at" &&
            searchParams.get("order_by").order !== "desc"
        ) {
            const currentOrderByArray = searchParams.get("order_by").split("-");
            curretnOrderByValue = {
                key: currentOrderByArray[0],
                order: currentOrderByArray[1],
            };
        }
    }
    let [currentOrderBy, setCurrentOrderBy] = useState(curretnOrderByValue);

    const loadData = (params = {}) => {
        setIsDataLoading(true);

        if (params === {}) {
            //params['status'] = ''
        }
        //console.log(params)
        setSearchParams(params);

        //console.log('Params', params)

        axios
            .get("/offices", {
                params: params,
            })
            .then(function (response) {
                // handle success
                setIsLoaded(true);
                setItems(response.data.data);
                setPaginationMeta(response.data.meta);
                setTimeout(function () {
                    setIsDataLoading(false);
                }, 300);
            })
            .catch(function (error) {
                // handle error
                setError(error);
                console.log(error);
            });
    };

    function refreshData() {
        let params = {};
        searchParams.forEach((key, value) => {
            params[value] = key;
            setFilterCount((filterCount) => filterCount + 1);
        });

        loadData(params);
    }

    useEffect(() => {
        refreshData();
    }, []);

    function reloadData(key, value) {
        let params = {};
        searchParams.forEach((key, value) => {
            params[value] = key;
        });
        params[key] = value;

        // Load tab
        if (key != "page") {
            params["page"] = 1;
        }

        loadData(params);
    }

    // Orders
    function orderBy(key) {
        if (currentOrderBy.order === "desc") {
            reloadData("order_by", key + "-asc");
            setCurrentOrderBy({
                key: key,
                order: "asc",
            });
        } else {
            reloadData("order_by", key + "-desc");
            setCurrentOrderBy({
                key: key,
                order: "desc",
            });
        }
    }

    // Filters
    const [filterCompanyId, setFilterCompanyId] = useState(
        searchParams.get("company_id") ? searchParams.get("company_id") : ""
    );

    function clearFilter() {
        let params = {};
        setFilterCompanyId("");
        setFilterCount(0);
        loadData(params);
    }

    function addFilter(key, value) {
        switch (key) {
            case "company_id":
                setFilterCompanyId(value);
                setFilterCount((filterCount) => filterCount + 1);
                break;
        }
        reloadData(key, value);
    }
    // /Filters

    // Methods
    const [deletingItem, setDeletingItem] = useState({ name: null });
    function deleteItem(item) {
        setDeletingItem(item);
        setDeleteItemModalOpen(true);
    }

    function confirmDeleteItem() {
        axios
            .post("offices/" + deletingItem.id, {
                _method: "delete",
            })
            .then(function (response) {
                setDeleteItemModalOpen(false);
                refreshData();
                setDeletingItem({ name: null });
            })
            .then(function (response) {
                setDeleteItemModalOpen(false);
                setDeletingItem({ name: null });
            })
            .catch(function (error) {
                setDeleteItemModalOpen(false);
                setDeletingItem({ name: null });
            });
    }
    // /Methods

    if (error) {
        return <Error error={error} />;
    }

    if (!isLoaded) {
        return <Loader fullscreen={false} />;
    }

    return (
        <div className="min-h-[100vh] bg-gray-50 pb-1 relative">
            {/* Page title & actions */}
            <NavBar pageTitle={pageTitle}>
                <Link
                    to="/offices/create"
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
                >
                    Create
                </Link>
            </NavBar>
            {/* /Page title & actions */}

            
            <div>
                {/* Filters */}
                <div className="px-4 py-3">
                    <Disclosure>
                        <div className="flex justify-start items-center">
                            <button
                                onClick={() => refreshData()}
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0"
                            >
                                <RefreshIcon
                                    className={
                                        (isDataLoading ? "animate-spin" : "") +
                                        " mr-2 h-5 w-5"
                                    }
                                    aria-hidden="true"
                                />
                                Refresh
                            </button>

                            <Disclosure.Button className="ml-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">
                                <FilterIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                Filters
                                <span className="ml-2 inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                                {filterCount}
                                </span>
                            </Disclosure.Button>

                            {filterCount > 0 && (
                                <button
                                    onClick={() => clearFilter()}
                                    type="button"
                                    className="ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-red-600 focus:outline-none focus:ring-0"
                                >
                                    Clear filters
                                    <XIcon
                                        className="-mr-1 ml-2 h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}
                        </div>

                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Disclosure.Panel className="text-gray-500">
                                <div className="mt-3 px-3 py-3 rounded-lg bg-white shadow">
                                    <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
                                        <div className="col-span-1">
                                        {user.managed_companies.length > 1 && (
                                            <select
                                                className="w-full text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                value={filterCompanyId}
                                                onChange={(event) =>
                                                    addFilter("company_id", event.target.value)
                                                }
                                            >
                                                <option value="">All companies</option>
                                                {user.managed_companies.map((company) => (
                                                    <option key={company.id} value={company.id}>
                                                        {company.name}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                        </div>                   
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </Transition>
                    </Disclosure>
                </div>
                {/* /Filters */}

                {/* Tabs */}
                <Tabs tabs={tabs} statusTextColors={statusTextColors} searchParams={searchParams} addFilter={addFilter}  />

                {/* Data Table */}
                <div className="mt-0 overflow-x-auto">
                    <div className="align-middle inline-block min-w-full border-b border-gray-200">
                        {isDataLoading && <SkeletonTable />}
                        {!isDataLoading && (
                            <table className="min-w-full">
                                <thead>
                                    <tr className="border-t border-gray-200">
                                        <th
                                            className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            scope="col"
                                        ></th>
                                        <th
                                            className="w-full px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            scope="col"
                                        >
                                            <span className="whitespace-nowrap">
                                                Office name
                                            </span>
                                        </th>

                                        {user.managed_companies.length >
                                            1 &&
                                            !filterCompanyId && (
                                                <th
                                                    className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    scope="col"
                                                >
                                                    <span className="whitespace-nowrap">
                                                        Company
                                                    </span>
                                                </th>
                                            )}

                                        <th
                                            scope="col"
                                            className="md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            <button
                                                onClick={() =>
                                                    orderBy("created_at")
                                                }
                                                className="group inline-flex uppercase whitespace-nowrap"
                                            >
                                                Created At
                                                {currentOrderBy.key ===
                                                "created_at" ? (
                                                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                                                        {currentOrderBy.order ===
                                                        "desc" ? (
                                                            <ChevronDownIcon
                                                                className="h-4 w-4"
                                                                aria-hidden="true"
                                                            />
                                                        ) : (
                                                            <ChevronUpIcon
                                                                className="h-4 w-4"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-100">
                                    {items.map((item) => (
                                        <tr key={item.id}>
                                            <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                                                <button
                                                    onClick={() =>
                                                        deleteItem(item)
                                                    }
                                                    className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0"
                                                >
                                                    <TrashIcon
                                                        className="h-4 w-4"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </td>
                                            <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                                <div className="">
                                                    <div>
                                                        <Link
                                                            to={`/offices/${item.id}/edit`}
                                                            className="truncate text-blue-700 "
                                                        >
                                                            <span>
                                                                <span className="underline font-bold">
                                                                    {
                                                                        item.name
                                                                    }
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>

                                                    {item.requester ? (
                                                        <div className="flex items-center mb-1">
                                                            <div className="flex flex-shrink-0 mr-1">
                                                                <img
                                                                    key={
                                                                        "requester_" +
                                                                        item
                                                                            .requester
                                                                            .id
                                                                    }
                                                                    className="max-w-none h-5 w-5 rounded-full ring-2 ring-white"
                                                                    src={
                                                                        item
                                                                            .requester
                                                                            .avatar_url
                                                                    }
                                                                    alt={
                                                                        item
                                                                            .requester
                                                                            .name
                                                                    }
                                                                />
                                                            </div>
                                                            <span className="flex-shrink-0 text-xs leading-5 font-medium">
                                                                {
                                                                    item
                                                                        .requester
                                                                        .name
                                                                }{" "}
                                                                {item
                                                                    .requester
                                                                    .company
                                                                    .name && (
                                                                    <span>
                                                                        (
                                                                        {
                                                                            item
                                                                                .requester
                                                                                .company
                                                                                .name
                                                                        }
                                                                        )
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </td>

                                            {user.managed_companies.length >
                                                1 &&
                                                !filterCompanyId && (
                                                    <td className="whitespace-nowrap px-6 py-3 w-full text-sm font-medium text-gray-900">
                                                        {item.company.name}
                                                    </td>
                                                )}

                                            <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                                                {item.created_at}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
                {/* /Data Table */}

                {/* Empty state */}
                {!items.length && isLoaded ? (
                    <EmptyStates createPath="/offices/create" />
                ) : (
                    ""
                )}

                {/* Pagination */}
                {items.length && isLoaded ? (
                    <Pagination
                        paginationMeta={paginationMeta}
                        reloadData={reloadData}
                    />
                ) : (
                    ""
                )}
            </div>

            <AlertModal
                title="Delete office"
                note={`Are you sure you want to delete "${deletingItem.name}" office. If employees are added to this office they won't be able to check in / out once office deleted`}
                confirm={confirmDeleteItem}
                close={() => setDeleteItemModalOpen(false)}
                open={deleteItemModalOpen}
            />
        </div>
    );
}
