import classNames from "helpers/classNames";

export default function Tabs(props) {
    const { tabs, statusTextColors, searchParams, addFilter } = props;

    return (<div className="grandizer-crud-tabs">
    <div className="sm:block">
        <div className="border-b border-gray-20">
            <nav
                className="-mb-px flex space-x-8 bg-[#e2e8f0]"
                aria-label="Tabs"
            >
                {tabs.map((tab, tabIndex) => (
                    <button
                        onClick={() =>
                            addFilter("status", tab.status)
                        }
                        key={tab.name}
                        className={classNames(
                            tab.status ===
                                searchParams.get(
                                    "status"
                                ) ||
                                (!searchParams.get(
                                    "status"
                                ) &&
                                    tab.status === "")
                                ? "border-indigo-500 text-black bg-white"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                            "whitespace-nowrap flex py-3 px-4 border-b-2 font-semibold text-[14px]"
                        )}
                    >
                        <span
                            className={
                                tab.status
                                    ? statusTextColors[
                                          tab.status
                                      ]
                                    : ""
                            }
                        >
                            {tab.name}
                        </span>
                        {tab.count ? (
                            <span
                                className={classNames(
                                    tab.status ===
                                        searchParams.get(
                                            "status"
                                        ) ||
                                        (!searchParams.get(
                                            "status"
                                        ) &&
                                            tab.status ===
                                                "open")
                                        ? "bg-indigo-100 text-indigo-600"
                                        : "bg-gray-100 text-gray-900",
                                    "hidden ml-3 py-0.5 px-2.5 rounded-full text-[16px] font-semibold md:inline-block"
                                )}
                            >
                                {tab.count}
                            </span>
                        ) : null}
                    </button>
                ))}
            </nav>
        </div>
    </div>
</div>)
}