import { useState, useEffect } from 'react';
import DatePicker from 'react-date-picker';
import moment from 'moment';

export default function DateRangePickerFilterField(props) {
    const { startDate, endDate, onChangeStart, onChangeEnd } = props
    const [minDate, setMinDate] = useState()
    const [maxDate, setMaxDate] = useState()
    const [startValue, setStartValue ] = useState()
    const [endValue, setEndValue ] = useState()

    useEffect(() => {
        //console.log(startDate)
        let inputStartValue = ''
        let inputEndValue = ''

        if(startDate) {
            const startDateValue = moment(startDate, 'YYYY-MM-DD');
            inputStartValue = new Date(startDateValue.year(), startDateValue.month(), startDateValue.date())
            setStartValue(inputStartValue)
        } else {
            setStartValue(null)
        }
        if(endDate) {
            const endDateValue = moment(endDate, 'YYYY-MM-DD');
            inputEndValue = new Date(endDateValue.year(), endDateValue.month(), endDateValue.date())
            setEndValue(inputEndValue)
        } else {
            setEndValue(null)
        }
      }, [startDate, endDate])

    function handleStartChange(dateValue) {
        if(dateValue) {
            setMinDate(new Date(dateValue))
            const dateString = moment(new Date(dateValue), 'D/M/YYYY').format('YYYY-MM-DD');
            onChangeStart(dateString)
            setStartValue(dateValue)
        } else {
            onChangeStart('')
            setStartValue(null)
        }
    }

    function handleEndChange(dateValue) {
        if(dateValue) {
            setMaxDate(new Date(dateValue))
            const dateString = moment(new Date(dateValue), 'D/M/YYYY').format('YYYY-MM-DD');
            onChangeEnd(dateString)
            setEndValue(dateValue)
        } else {
            onChangeEnd('')
            setEndValue(null)
        }
    }


    return (
        <div className="mr-2 inline-flex text-sm">
            <span className="px-2 py-2">From</span>
            <DatePicker format="d/M/yyyy" onChange={(value) => handleStartChange(value)} maxDate={maxDate} value={startValue} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border first-letter:border-gray-300 rounded-md" />
            <span className="px-2 py-2">to</span>
            <DatePicker format="d/M/yyyy" onChange={(value) => handleEndChange(value)} minDate={minDate} maxDate={new Date()} value={endValue} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border first-letter:border-gray-300 rounded-md" />
        </div>
    )
}