import React from 'react'

export default function LetterHeadCanvas({ screenShotUrl , setShowModal }) {
  
   
    return (
    
    <div className="off-canvas bg-gray-500" >

      <div style={{position:"absolute" , top:"10px" , right:"10px"}}>
        <button className='bg-gray-100 rounded-md px-2' onClick={() => setShowModal(false)}>x</button>
      </div>

{screenShotUrl && <img src={screenShotUrl} alt="Screenshot" className='w-full h-full' />}
  </div>

 


  )
}