import { Fragment, useState, useEffect, useReducer } from "react";
import axios from "axios";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon, XIcon } from "@heroicons/react/solid";
import { FilterIcon } from "@heroicons/react/outline";

// Components
import Pagination from "../../components/Pagination";
import VendorEmptyStates from "../../components/VendorEmptyStates";
import PriorityBadge from "../../components/PriorityBadge";
import TicketShow from "./Show";

//icons
import {
  RefreshIcon,
  TrashIcon,
  PencilAltIcon,
  EyeIcon,
  MailIcon,
} from "@heroicons/react/solid";
import PaginationAll from "components/pagination";

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

// Helpers
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Search params as a object
const useCustomSearchParams = () => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = Object.fromEntries(new URLSearchParams(search));

  return [searchAsObject, setSearch];
};

export default function List(props) {
  const { user } = props;
  const navigate = useNavigate();
  //const [search, setSearch] = useCustomSearchParams();
  let [searchParams, setSearchParams] = useSearchParams();

  // Set page title
  useEffect(() => {
    document.title = `Supplier`;
  }, []);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState([]);
  const [dataUrl, setDataUrl] = useState(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [tabs, setTabs] = useState([
    { name: "General", status: "general", count: 0, current: true },
    { name: "Cast", status: "cast", count: 0, current: false },
    { name: "Crew", status: "crew", count: 0, current: false },
  ]);

  // Order By
  let curretnOrderByValue = {
    key: "created_at",
    order: "desc",
  };
  if (searchParams.get("order_by")) {
    if (
      searchParams.get("order_by").key !== "created_at" &&
      searchParams.get("order_by").order !== "desc"
    ) {
      const currentOrderByArray = searchParams.get("order_by").split("-");
      curretnOrderByValue = {
        key: currentOrderByArray[0],
        order: currentOrderByArray[1],
      };
    }
  }
  let [currentOrderBy, setCurrentOrderBy] = useState(curretnOrderByValue);

  const apiUrl = "/suppliers";
  const loadData = (params = {}) => {
    if (params === {}) {
      params["status"] = "general";
    }
    //console.log(params)
    setSearchParams(params);

    console.log("Params", params);

    axios
      .get(apiUrl, {
        params: params,
      })
      .then(function (response) {
        // handle success
        setIsLoaded(true);
        setItems(response.data.data);
        setPaginationMeta(response.data.meta);
      })
      .catch(function (error) {
        // handle error
        setIsLoaded(true);
        setError(error);
      })
      .then(function () {
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    let params = {};
    searchParams.forEach((key, value) => {
      params[value] = key;
    });

    loadData(params);
  }, []);

  //action methods
  function updateItem(e) {
    navigate(`/supplier/view/${e.id}`);
  }

  function editItem(e) {
    navigate(`/supplier/edit`);

    // navigate(`/supplier/edit/${e.id}`);
  }

  function deleteItem(e) {
    navigate(`/supplier/view/${e.id}`);
  }

  function reloadData(key, value) {
    let params = {};
    searchParams.forEach((key, value) => {
      params[value] = key;
    });
    params[key] = value;

    // Load tab
    if (key != "page") {
      params["page"] = 1;
    }

    loadData(params);
  }

  function orderBy(key) {
    if (currentOrderBy.order === "desc") {
      reloadData("order_by", key + "-asc");
      setCurrentOrderBy({
        key: key,
        order: "asc",
      });
    } else {
      reloadData("order_by", key + "-desc");
      setCurrentOrderBy({
        key: key,
        order: "desc",
      });
    }
  }

  const x = [{ a: 1 }, { b: 2 }, { c: 3 }, { d: 4 }];

  // Filters
  const [filterCompanyId, setFilterCompanyId] = useState(
    searchParams.get("company_id") ? searchParams.get("company_id") : ""
  );
  const [filterPriority, setFilterPriority] = useState(
    searchParams.get("priority") ? searchParams.get("priority") : ""
  );

  function clearFilter() {
    let params = {};
    setFilterCompanyId("");
    setFilterPriority("");
    loadData(params);
  }

  function addFilter(key, value) {
    switch (key) {
      case "company_id":
        setFilterCompanyId(value);
        break;
      case "priority":
        setFilterPriority(value);
        break;
    }
    reloadData(key, value);
  }

  if (!isLoaded) {
    return (
      <div className="fullscreen-loading flex items-center justify-around">
        Loading..
      </div>
    );
  } else {
    return (
      <div className="min-h-[100vh] bg-white pb-1 relative">
        {/* Page title & actions */}
        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 lg:fixed lg:top-0 lg:left-56 lg:right-0 lg:z-10 bg-white">
          <div className="flex-1 min-w-0">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
              Suppliers
            </h1>
          </div>
          <div className="mt-4 flex sm:mt-0 sm:ml-4">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm  text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0">
                  <Link to="/suppliers/create" className=" px-4 py-2">
                    Create
                  </Link>
                </Menu.Button>
              </div>
            </Menu>
          </div>
        </div>

        <div className="pt-20 ">
          <div className="px-8 py-2 pb-8  inline-block justify-start w-full ">
            <span className="mr-2 inline-block py-2">
              <FilterIcon className="w-5 text-gray-500" aria-hidden="true" />
            </span>
            {user.managed_companies.length > 1 && (
              <select
                className="mr-2 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={filterCompanyId}
                onChange={(event) =>
                  addFilter("company_id", event.target.value)
                }
              >
                <option value="">All companies</option>
                {user.managed_companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </select>
            )}
            <button
              onClick={() => clearFilter()}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0"
            >
              Clear all
              <XIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </button>

            <select
              className="mr-2 inline-block pl-3 pr-10 mt-3 py-2 text-base border-gray-300 focus:border-indigo-500 sm:text-sm rounded-md float-right"
              name=""
              id=""
              // value={itemsPerPage}
              // onChange={(e) => handleItemsPerPage(e)}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
            </select>
          </div>

          {/* Projects table (small breakpoint and up) */}
          <div className="mt-0 overflow-x-auto">
            <div className="align-middle inline-block min-w-full border-b border-gray-200">
              <table className="min-w-full">
                <thead>
                  <tr className="border-t border-gray-200">
                    <th
                      className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      scope="col"
                    >
                      <button
                        onClick={() => orderBy("action")}
                        className="group inline-flex uppercase whitespace-nowrap"
                      >
                        Action
                      </button>
                    </th>
                    <th
                      className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      scope="col"
                    >
                      <button
                        onClick={() => orderBy("name")}
                        className="group inline-flex uppercase whitespace-nowrap"
                      >
                        name
                        {currentOrderBy.key === "name" ? (
                          <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                            {currentOrderBy.order === "desc" ? (
                              <ChevronDownIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                            ) : (
                              <ChevronUpIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </button>
                    </th>
                    <th
                      className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      scope="col"
                    >
                      <span className="whitespace-nowrap">email</span>
                    </th>
                    <th
                      className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      scope="col"
                    >
                      <span className="whitespace-nowrap">category</span>
                    </th>
                    <th
                      className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      scope="col"
                    >
                      <span className="whitespace-nowrap">company</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-100">
                  {x.map((item, id) => (
                    <tr key={id}>
                      <td className="px-6 py-3 w-56 whitespace-nowrap text-sm font-medium text-gray-900">
                        <button
                          onClick={() => updateItem(item)}
                          className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
                        >
                          <EyeIcon
                            className="h-4 w-4 text-blue-400"
                            aria-hidden="true"
                          />
                        </button>
                        <button
                          onClick={() => editItem(item)}
                          className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
                        >
                          <PencilAltIcon
                            className="h-4 w-4 text-green-400"
                            aria-hidden="true"
                          />
                        </button>
                        <button
                          onClick={() => deleteItem(item)}
                          className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700  focus:outline-none focus:ring-0"
                        >
                          <TrashIcon
                            className="h-4 w-4 text-red-400"
                            aria-hidden="true"
                          />
                        </button>
                      </td>
                      <td className="px-6 py-3 w-56  whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="">
                          <div>
                            <Link
                              to={`/vendors/${id}`}
                              // className="truncate text-blue-700 "
                            >
                              <span>
                                <span className="">
                                  {/* {item.name} */}Moheib Asif
                                </span>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td className="hidden md:table-cell w-60  px-6 py-3 whitespace-nowrap text-sm text-gray-500 ">
                        {item.email}xyz@gmail.com
                      </td>
                      <td className="hidden md:table-cell px-6 w-56 py-3 whitespace-nowrap text-sm text-gray-500 ">
                        {/* {item.created_at} */}xyz
                      </td>
                      <td className="hidden md:table-cell px-6 w-56 py-3  whitespace-nowrap text-sm text-gray-500 ">
                        {/* {item.created_at} */}xyz
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <PaginationAll
          currentPage={1}
          totalPages={Math.ceil(10 / 10)}
          // onPageChange={handlePageChangeSaveAsDraft}
        />
      </div>
    );
  }
}
