export const category = [
    {
        value: "Afghan",
        label: "Afghan ",
    },
    {
        value: "Albanian",
        label: "Albanian ",
    },
    {
        value: "Algerian",
        label: "Algerian ",
    },

]