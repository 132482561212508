import NavBar from "layout/NavBar";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CrudForm from "./components/CrudForm";
import axios from "axios";
import { toast } from "react-toastify";

const pageTitle = "Create Role";

export default function CreateRole() {
  const [sendingData, setSendingData] = useState(false);
  const [stateData, setStateData] = useState(true);
  const [errorHold, setErrorHold] = useState(false);
  let navigate = useNavigate();

  let initialValues = {
    role_name: "",
    role_permissions: [],
  };

  // Set page title
  useEffect(() => {
    document.title = pageTitle;
  }, []);

  // Send data
  const sendData = (values) =>
    new Promise((resolve) => {
      setSendingData(false);
      //  const formData = new FormData();

      //  if (values.role_name) {
      //    formData.append("name", values.role_name);

      //  }
      //  if (values.role_permissions) {
      //     values.role_permissions.forEach((int) => {
      //         // formData.append('myArray[]', int);
      //    formData.append( "permissions", int);

      //       });
      // //    formData.append( "permissions", values.role_permissions);

      //  }

      // name dasjdhas
      // permission [4 , 5 , 6]

      //  let data = {
      //     "name" : values.name,
      //     "permissions" : values?.role_permissions
      //  }

      const formdata = {
        name: values?.role_name,
        permissions: values?.role_permissions,
      };

      axios
        .post("role/store", formdata, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          toast.success("Record save successfully! ");
          console.log("response", response);
          setTimeout(function () {
            navigate("/roles");
          }, 2000);
        })
        .catch(function (error) {
          setSendingData(false);
          setErrorHold(false);
          toast.error(error.response.data.message);
          resolve();
        });
    });

  const onSubmit = async (values) => {
    console.log(values, "permissions");

    await sendData(values);
  };

  return (
    <div className="h-full">
      <NavBar pageTitle={pageTitle}>
        <a
          href="#"
          //   onClick={() => setAlertModalOpen(true)}
          disabled={sendingData}
          className="inline-flex items-center px-[10px] py-[5px] sm:px-3 sm:py-2 border border-gray-300 shadow-sm text-xs sm:text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-[3px] sm:mr-2 disabled:opacity-75"
        >
          Cancel
        </a>
        <button
          // disabled={stateData}
          type="submit"
          onClick={() => document.getElementById("mainSubmitRole").click()}
          className="relative inline-flex items-center px-[10px] py-[5px] sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
        >
          {sendingData ? "Submitting.." : "Save"}
        </button>
      </NavBar>

      <main className="">
        <div className="max-w-3xl mx-auto px-0 sm:px-6  md:space-x-5 lg:max-w-full lg:px-8 mt-0 relative pb-0 lg:pb-6">
          <CrudForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            // savaButtonAction={(val) => setStateData(val)}
          />
        </div>
      </main>
    </div>
  );
}
