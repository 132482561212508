import { useEffect, useState, Fragment } from "react";
import {
  ExclamationCircleIcon,
  XIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { Country } from "country-state-city";
import Select from "react-select";

export default function UpdateLocationArrayField(props) {
  const {
    label,
    input,
    meta,
    columns,
    required = false,
    companyLocationData,
  } = props;
  const [dataRows, setDataRows] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);

  useEffect(() => {
    if (companyLocationData.length !== 0) {
      setDataRows(companyLocationData);
    } else {
      setDataRows([{ name: "", country: "" }]);
    }
  }, []);
  const countries = Country.getAllCountries();
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.name,
  }));

  function addDataRow() {
    let newRowData = { name: "", country: "" };
    const newDataRows = [...dataRows, newRowData];
    const isAnyFieldEmpty = newDataRows.some(
      (item) => item.name === "" || item.country === ""
    );
    setDataRows(newDataRows);
    if (isAnyFieldEmpty) {
      input.onChange(null);
      return false;
    }
    input.onChange(newDataRows);
  }

  const handleChange = (e, idx) => {
    const clone = [...dataRows];
    let obj = clone[idx];
    obj.name = e.target.value; // Trim any leading or trailing whitespace
    clone[idx] = obj;
    setDataRows(clone);

    const isAnyFieldEmpty = clone.some(
      (item) => item.name === "" || item.country === ""
    );

    if (obj.name === "") {
      input.onChange(null);
      setErrorMsg((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[idx] = "Location is required!";
        return updatedErrors;
      });
      return false;
    } else {
      setErrorMsg((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[idx] = "";
        return updatedErrors;
      });
    }

    if (!/^(?! )(?!.* $)[\S\s]{1,95}$/.test(obj.name)) {
      input.onChange(null);
      setErrorMsg((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[idx] =
          "Location name can be 95 characters or less(No Blank Spaces allow at start and end)";
        return updatedErrors;
      });
      return false;
    }
    if (isAnyFieldEmpty) {
      input.onChange(null);
      return false;
    }
    setErrorMsg((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[idx] = "";
      return updatedErrors;
    });
    input.onChange(clone);
  };

  const handleCountryChange = (value, idx) => {
    const updatedRows = dataRows.map((row, index) => {
      if (index === idx) {
        return {
          ...row,
          country: value ? value.value : "",
        };
      }
      return row;
    });

    setDataRows(updatedRows);

    const isAnyFieldEmpty = updatedRows.some(
      (item) => item.name === "" || item.country === ""
    );

    if (isAnyFieldEmpty) {
      input.onChange(null);
    } else {
      input.onChange(updatedRows);
    }
  };

  function removeDataRow(dataRowIndex) {
    const newDataRows = dataRows.filter((row, index) => index !== dataRowIndex);
    setErrorMsg((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[dataRowIndex] = "";
      return updatedErrors;
    });
    const isAnyFieldEmpty = newDataRows.some(
      (item) => item.name === "" || item.country === ""
    );
    setDataRows(newDataRows);
    if (isAnyFieldEmpty) {
      input.onChange(null);
      return false;
    } else {
      input.onChange(newDataRows);
    }
  }

  return (
    <div className="sm:col-span-6">
      <div className="mt-1 relative">
        {dataRows.map((dataRow, dataRowIndex) => {
          return (
            <Fragment key={`container-${dataRowIndex}`}>
              <div className="relative pr-10" key={`container-${dataRowIndex}`}>
                <div
                  className="mb-2 grid gap-4 grid-cols-1 sm:grid-cols-2"
                  key={`main-${dataRowIndex}`}
                >
                  <div key={`name-${dataRowIndex}`}>
                    <label
                      htmlFor={columns.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Location Name
                      {required && <span className="text-red-500">*</span>}
                    </label>
                    <input
                      type="text"
                      onChange={(e) => handleChange(e, dataRowIndex)}
                      placeholder={columns.name}
                      value={dataRow.name}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                    />
                    {errorMsg[dataRowIndex] && (
                      <p className="mt-2 text-sm text-red-600">
                        {errorMsg[dataRowIndex]}
                      </p>
                    )}
                  </div>
                  <div key={`country-${dataRowIndex}`}>
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Country
                      {required && <span className="text-red-500">*</span>}
                    </label>
                    <Select
                      value={
                        dataRow.country !== ""
                          ? updatedCountries.find(
                              (country) => country.value === dataRow.country
                            )
                          : null
                      }
                      label="Country"
                      onChange={(value) =>
                        handleCountryChange(value, dataRowIndex)
                      }
                      options={updatedCountries}
                      placeholder="Select a country"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </div>
                </div>
                <button
                  onClick={() => removeDataRow(dataRowIndex)}
                  type="button"
                  className="my-5 absolute right-0 top-1 inline-flex items-center p-1 border border-transparent rounded-full text-white"
                  key={`button-${dataRowIndex}`}
                >
                  <TrashIcon
                    className="h-6 w-6 text-red-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </Fragment>
          );
        })}
        <div className="flex flex-nowrap justify-center flex-col items-center">
          <div>
            <p className="mt-1 text-sm text-gray-500">Add New Location</p>
          </div>
          <button
            onClick={() => addDataRow()}
            type="button"
            className="text-center relative inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-sm font-bold rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75 justify-center"
          >
            <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      {meta.error && meta.touched && (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      )}
    </div>
  );
}
