import { useState, useEffect } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import DatePicker from "react-date-picker";
import moment from "moment";

export default function UpdateDatePickerField(props) {
  const {
    label,
    input,
    meta,
    data,
    required = false,
    maxDateToday = false,
  } = props;
  let inputValue = "";
  if (data) {
    const dateValue = moment(data, "YYYY-M-D");
    inputValue = new Date(
      dateValue.year(),
      dateValue.month(),
      dateValue.date()
    );
  }

  const [value, setValue] = useState(inputValue);

  useEffect(() => {
    setValue(inputValue);
  }, [data]);

  function handleChange(dateValue) {
    // debugger
    // const dateString = new Date(dateValue).toDateString();
    // let updatedDate = "";
    // const newDate = moment(dateValue, "YYYY-M-D");
    // updatedDate = new Date(
    //   newDate.year(),
    //   newDate.month(),
    //   newDate.date()
    // );
    input.onChange(dateValue);
    setValue(dateValue);
  }
  return (
    <div className="col-span-12 sm:col-span-3">
      <label
        htmlFor={input.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <DatePicker
          //   maxDate={maxDateToday ? new Date() : null}
          format="yyyy-M-d"
          onChange={(value) => handleChange(value)}
          value={value}
          className={
            meta.error || meta.touched
              ? "border block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
              : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border first-letter:border-gray-300 rounded-md placeholder-gray-300"
          }
        />
        {(meta.error || meta.touched) && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {(meta.error || meta.touched) && (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      )}
    </div>
  );
}
