import {  useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";
import { Form, Field } from 'react-final-form'
import createDecorator from 'final-form-calculate'
import {
  CheckIcon,
  PaperClipIcon,
  ThumbUpIcon,
  UserIcon,
  ExclamationCircleIcon,
  XIcon,
  StarIcon,
  CollectionIcon,
  SelectorIcon,
  AdjustmentsIcon,
  BellIcon
} from '@heroicons/react/solid'
import axios from 'axios';

// Components
import PriorityBadge from './PriorityBadge';
import DropzoneField from './fields/DropzoneField'
import UserSelectField from './fields/UserSelectField';
import ResolveTicketModal from './ResolveTicketModal';
import AlertModal from './AlertModal';
import PrioritySelectField from './fields/PrioritySelectField';
import StarRatings from 'react-star-ratings';
import RatingStars from './RatingStars';
import RateModal from './RateModal';
import MoveTicketModal from './MoveTicketModal';
// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

// Form Multiple Validators
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
const required = value => (value ? undefined : 'Required')

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Comments(props) {
    const { user, modelId } = props
    const [comments, setComments] = useState([])
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false)
    const [sendingData, setSendingData] = useState(false)
    const [deletingCommentId, setDeletingCommentId] = useState(undefined)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    function reloadCommentsData() {
        axios.get('recruit-job-applications/'+modelId+'/notes').then(function (response) {
            if(response.data.data) {
                setComments(response.data.data)
            }
        }).catch(function (error) {
            // handle error
            //setIsLoaded(true);
            setError(error);
            setIsLoaded(true);
        })
        .then(function () {
            setIsLoaded(true);
        });
    }

    useEffect(() => {
        reloadCommentsData()
    }, [])

    // Send data
    const sendData = values => new Promise(resolve => {
        setSendingData(true)
        const formData = new FormData();
        formData.append('job_application_id', modelId);
        formData.append('note_text', values.content);
        if(values.attachments) {
            values.attachments.forEach((attachment) => {
                formData.append('attachments[]', attachment);
            });
        }

        axios.post('recruit-applicant-notes', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
        })
        .then(function (response) {
            setSendingData(false)
            resolve()
            if(response.data.data.id) {
                reloadCommentsData()
            }
        })
        .catch(function (error) {
            setSendingData(false)
            resolve()
        });
    })

    // Submit form
    const onSubmit = async values => {
        await sendData(values)
    }  

    function deleteComment(id) {
        setDeletingCommentId(id)
        setDeleteModalOpen(true)
    }

    function confirmDelete(id) {
        axios.post('recruit-applicant-notes/'+deletingCommentId, {_method: 'delete'})
          .then(function (response) {
                if(response.data.status == 'deleted') {
                    reloadCommentsData()
                    setDeleteModalOpen(false)
                }
            })
            .catch(function (error) {
                setDeleteModalOpen(false)
            });
    }

    return (
        <section aria-labelledby="notes-title">
            <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                <div className="divide-y divide-gray-200">
                    <div className="px-6 py-2 pt-5">
                        <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                            Notes
                        </h2>
                    </div>

                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start space-x-4">
                            <div className="flex-shrink-0">
                                <img
                                className="inline-block h-10 w-10 rounded-full"
                                src={user.avatar_url}
                                alt=""
                                />
                            </div>
                            <div className="min-w-0 flex-1">
                                <Form
                                    onSubmit={onSubmit}
                                    initialValues={{ attachments: [] }}
                                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                                    <form onSubmit={async (event) => {
                                            handleSubmit(event)
                                            form.reset();
                                        }}
                                        encType="multipart/form-data">

                                        <div className="relative">
                                        
                                            <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                                                <label htmlFor="comment" className="sr-only">
                                                Add your comment
                                                </label>

                                                <Field name="content" validate={required}>
                                                {({ input, meta }) => (
                                                    <div>
                                                        <div className="mt-1 relative rounded-md shadow-sm">
                                                            <textarea
                                                                {...input}
                                                                placeholder="Add your comment..."
                                                                rows={3}
                                                                className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
                                                            />
                                                        </div>
                                                        {/* <div className="px-4">{meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}</div> */}
                                                    </div>      
                                                    )}
                                                </Field>

                                                {/* Spacer element to match the height of the toolbar */}
                                                <div className="py-2" aria-hidden="true">
                                                    {/* Matches height of button in toolbar (1px border + 36px content height) */}
                                                    <div className="py-px">
                                                        <div className="h-9" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
                                                <div className="flex items-center space-x-5">
                                                <div className="flex items-center">
                                                    {/* <button
                                                    type="submit"
                                                    className="-m-2.5 w-10 h-10 rounded-full flex items-center justify-center text-gray-400 hover:text-gray-500"
                                                    >
                                                    <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                                                    <span className="sr-only">Attach a file</span>
                                                    </button> */}
                                                </div>
                                                </div>
                                                <div className="flex-shrink-0">
                                                <button
                                                    disabled={sendingData || pristine || !values.content}
                                                    type="submit"
                                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
                                                >
                                                    Post
                                                </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bg-white p-4 mt-5 border border-gray-300 rounded-lg">
                                            <Field name="attachments">
                                            {({ input, meta }) => (      
                                                <DropzoneField {...input} label="" meta={meta} compact={true} />                       
                                            )}
                                            </Field>
                                        </div>
                                        
                                    </form>
                                )} />
                            </div>
                        </div>
                    </div>

                    <div className="px-4 py-6 sm:px-6">
                        <ul role="list" className="space-y-8">
                            {comments.map((comment) => (
                            <li key={comment.id}>
                                <div className="flex space-x-3">
                                {/* <div className="flex-shrink-0">
                                    <img
                                    className="h-10 w-10 rounded-full"
                                    src={comment.creator.avatar_url}
                                    alt=""
                                    />
                                </div> */}
                                <div className="w-full">
                                    <div className="text-sm">
                                        <span className="font-medium text-gray-900">
                                            {comment.creator.name}
                                        </span>
                                    </div>
                                    <div className="mt-1 text-sm text-gray-700" dangerouslySetInnerHTML={{ __html: comment.content }}></div>
                                    {comment.attachments.length ? (
                                        <div className="mt-2 text-sm">
                                            <dd className="mt-1 text-sm text-gray-900">
                                            <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                                {comment.attachments.map((attachment) => (
                                                <li
                                                    key={attachment.file_name}
                                                    className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                                                >
                                                    <div className="w-0 flex-1 flex items-center">
                                                    <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    <span className="ml-2 flex-1 w-0 truncate">{attachment.file_name}</span>
                                                    </div>
                                                    <div className="ml-4 flex-shrink-0">
                                                    <a href={attachment.url} target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:text-blue-500">
                                                        Download
                                                    </a>
                                                    </div>
                                                </li>
                                                ))}
                                            </ul>
                                            </dd>
                                        </div>
                                        ) : ''}
                                    <div className="mt-2 text-sm space-x-2">
                                        <span className="text-gray-500 font-medium">{comment.created_at}</span> &#8228;
                                        { (user.id === comment.user_id) && <span className="text-gray-500 font-medium">&middot;</span>}
                                        {/* <button className="text-gray-900 hover:text-indigo-600 font-medium">Edit</button>
                                        <span className="text-gray-500 font-medium">&middot;</span> */}
                                        { (user.id === comment.user_id) && <button onClick={() => deleteComment(comment.id)} className="text-gray-900 hover:text-indigo-600 font-medium">Delete</button>}
                                    </div>
                                    
                                </div>
                                </div>
                            </li>
                            ))}
                        </ul>
                    </div>
                </div>
                
            </div>
            <AlertModal title="Delete Note" note="Are you sure you want to delete this note?" confirm={confirmDelete} close={() => setDeleteModalOpen(false)} open={deleteModalOpen} />
            
    </section>)
}
