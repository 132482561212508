import { useState, useEffect, useLayoutEffect, useRef } from "react";
import axios from "axios";
import {
  Link,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import {
  CalendarIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
  ReplyIcon,
  TableIcon,
  TrashIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/solid";
import { DownloadIcon, FilterIcon } from "@heroicons/react/outline";
import { Disclosure, Transition } from "@headlessui/react";
import moment from "moment";
import { toast } from "react-toastify";

// Components
import Pagination from "../../components/Pagination";
import EmptyStates from "../../components/EmptyStates";
import PriorityBadge from "../../components/PriorityBadge";
import TicketShow from "./Show";
import SkeletonTable from "../../components/SkeletonTable";
import DateRangePickerFilterField from "../../components/filterFields/DateRangePickerFilterField";
import Show from "./Show";
// import DatePickerFilterField from '../../components/filterFields/DatePickerFilterField';
import MarkAttendanceValidModal from "../../components/MarkAttendanceValidModal";
import UserSelectFilterField from "../../components/filterFields/UserSelectFilterField";
import AttendanceCalendar from "../../components/AttendanceCalendar";
import AlertModal from "../../components/AlertModal";
import NavBar from "layout/NavBar";
import Error from "layout/Error";
import Loader from "layout/Loader";

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

// Helpers
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Search params as a object
const useCustomSearchParams = () => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = Object.fromEntries(new URLSearchParams(search));

  return [searchAsObject, setSearch];
};

const pageTitle = "Attendance";

const statusColors = {
  valid: "bg-green-500",
  invalid: "bg-red-500",
  no_record: "bg-gray-500",
  revoked: "bg-orange-500",
};

const statusTextColors = {
  valid: "text-green-500",
  invalid: "text-red-500",
  no_record: "text-gray-500",
  revoked: "text-orange-500",
};

const statusLabels = {
  valid: "Valid",
  invalid: "Invalid",
  no_record: "No record",
  revoked: "Revoked",
};

export default function List(props) {
  let routeParams = useParams();
  const { user } = props;
  const navigate = useNavigate();
  //const [search, setSearch] = useCustomSearchParams();
  let [searchParams, setSearchParams] = useSearchParams();

  // Set page title
  useEffect(() => {
    document.title = pageTitle;
  }, []);

  const [backupPath, setBackupPath] = useState(
    window.location.pathname + window.location.search
  );
  const [isShowedModelFromRoute, setIsShowedModelFromRoute] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [items, setItems] = useState([]);
  const [users, setUsers] = useState([]);
  const [attendanceNotes, setAttendanceNotes] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [paginationMeta, setPaginationMeta] = useState([]);
  const [dataUrl, setDataUrl] = useState(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [markValidModalOpen, setMarkValidModalOpen] = useState(false);
  const [tabs, setTabs] = useState([
    { name: "All", color: "", status: "", count: 0, current: true },
    {
      name: "Valid",
      color: "bg-green-500",
      status: "valid",
      count: 0,
      current: false,
    },
    {
      name: "Invalid",
      color: "bg-red-500",
      status: "invalid",
      count: 0,
      current: false,
    },
    {
      name: "Revoked",
      color: "bg-orange-500",
      status: "revoked",
      count: 0,
      current: false,
    },
    {
      name: "No record",
      color: "bg-gray-500",
      status: "no_record",
      count: 0,
      current: false,
    },
  ]);

  // View type
  const [viewType, setViewType] = useState("table");

  // Bulk edit
  useLayoutEffect(() => {
    if (isLoaded) {
      const isIndeterminate =
        selectedItems.length > 0 && selectedItems.length < items.length;
      setChecked(selectedItems.length === items.length);
      setIndeterminate(isIndeterminate);
      if (checkbox.current) {
        checkbox.current.indeterminate = isIndeterminate;
      }
    }
  }, [selectedItems]);

  function toggleAll() {
    setSelectedItems(checked || indeterminate ? [] : items);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  // Order By
  let curretnOrderByValue = {
    key: "created_at",
    order: "desc",
  };
  if (searchParams.get("order_by")) {
    if (
      searchParams.get("order_by").key !== "created_at" &&
      searchParams.get("order_by").order !== "desc"
    ) {
      const currentOrderByArray = searchParams.get("order_by").split("-");
      curretnOrderByValue = {
        key: currentOrderByArray[0],
        order: currentOrderByArray[1],
      };
    }
  }
  let [currentOrderBy, setCurrentOrderBy] = useState(curretnOrderByValue);

  function loadUsers() {
    // Deparment & Categories
    axios
      .get("attendance-reports/create")
      .then(function (response) {
        // handle success
        //console.log(response.data);
        if (response.data.users) {
          setUsers(response.data.users);
          setAttendanceNotes(response.data.attendance_notes);
          setIsLoaded(true);
        }
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
        setIsLoaded(true);
      })
      .then(function () {
        setIsLoaded(true);
      });
  }

  const apiUrl = "/attendance-reports";
  const loadData = (params = {}) => {
    setIsDataLoading(true);

    if (params === {}) {
      //params['status'] = ''
    }
    //console.log(params)
    setSearchParams(params);

    //console.log('Params', params)

    axios
      .get(apiUrl, {
        params: params,
      })
      .then(function (response) {
        // handle success
        loadUsers();
        setIsLoaded(true);
        setItems(response.data.data);
        //console.log(response.data.data)
        setPaginationMeta(response.data.meta);
        setTimeout(function () {
          setIsDataLoading(false);
          if (routeParams.modelId) {
            if (!isShowedModelFromRoute) {
              showModel(routeParams.modelId);
              setIsShowedModelFromRoute(true);
            }
          }
        }, 300);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      });
  };

  useEffect(() => {
    let params = {};
    searchParams.forEach((key, value) => {
      params[value] = key;
      if (value != "page") {
        setFilterCount((filterCount) => filterCount + 1);
      }
      if (value === "mode" && key === "calendar") {
        setViewType("calendar");
      }
    });

    loadData(params);
  }, []);

  function reloadData(key, value) {
    let params = {};
    searchParams.forEach((key, value) => {
      params[value] = key;
    });
    params[key] = value;

    // Load tab
    if (key != "page") {
      params["page"] = 1;
    }

    loadData(params);
  }

  function orderBy(key) {
    if (currentOrderBy.order === "desc") {
      reloadData("order_by", key + "-asc");
      setCurrentOrderBy({
        key: key,
        order: "asc",
      });
    } else {
      reloadData("order_by", key + "-desc");
      setCurrentOrderBy({
        key: key,
        order: "desc",
      });
    }
  }

  // Filters

  const [filterCompanyId, setFilterCompanyId] = useState(
    searchParams.get("company_id") ? searchParams.get("company_id") : ""
  );
  const [filterOfficeId, setFilterOfficeId] = useState(
    searchParams.get("office_id") ? searchParams.get("office_id") : ""
  );
  const [filterEmployeeName, setFilterEmployeeName] = useState(
    searchParams.get("employee_name") ? searchParams.get("employee_name") : ""
  );
  const [filterEmployeeId, setFilterEmployeeId] = useState(
    searchParams.get("employee_id") ? searchParams.get("employee_id") : ""
  );
  const [filterStatus, setFilterStatus] = useState(
    searchParams.get("status") ? searchParams.get("status") : ""
  );
  const [filterType, setFilterType] = useState(
    searchParams.get("type") ? searchParams.get("type") : ""
  );
  const [filterNote, setFilterNote] = useState(
    searchParams.get("note") ? searchParams.get("note") : ""
  );

  const dateString = moment().format("YYYY-MM-DD");
  const dateStringBefore1Week = moment()
    .subtract(7, "days")
    .format("YYYY-MM-DD");
  const [filterFrom, setFilterFrom] = useState(
    searchParams.get("from") ? searchParams.get("from") : dateStringBefore1Week
  );
  const [filterTo, setFilterTo] = useState(
    searchParams.get("to") ? searchParams.get("to") : dateString
  );

  function clearFilter(params = {}) {
    if (params["mode"] === "calendar") {
      params["calendar_startdate"] = searchParams.get("calendar_startdate");
      params["calendar_enddate"] = searchParams.get("calendar_enddate");
    }

    setFilterCompanyId("");
    setFilterOfficeId("");
    setFilterEmployeeName("");
    setFilterEmployeeId("");
    setFilterStatus("");
    setFilterType("");
    setFilterNote("");
    setFilterFrom(dateString);
    setFilterTo(dateString);
    setFilterCount(0);
    setSelectedItems([]);
    setChecked(false);

    loadData(params);
  }

  function addFilter(key, value) {
    setSelectedItems([]);
    setChecked(false);
    switch (key) {
      case "company_id":
        setFilterCompanyId(value);
        setFilterOfficeId("");
        setFilterEmployeeName("");
        setFilterEmployeeId("");
        setFilterNote("");
        setFilterCount((filterCount) => filterCount + 1);
        break;
      case "office_id":
        setFilterOfficeId(value);
        setFilterCount((filterCount) => filterCount + 1);
        break;
      case "employee_name":
        setFilterEmployeeName(value);
        setFilterCount((filterCount) => filterCount + 1);
        break;
      case "employee_id":
        setFilterEmployeeId(value);
        setFilterCount((filterCount) => filterCount + 1);
        break;
      case "status":
        setFilterStatus(value);
        setFilterCount((filterCount) => filterCount + 1);
        break;
      case "type":
        setFilterType(value);
        setFilterCount((filterCount) => filterCount + 1);
        break;
      case "note":
        setFilterNote(value);
        setFilterCount((filterCount) => filterCount + 1);
        break;
      case "from":
        setFilterFrom(value);
        setFilterCount((filterCount) => filterCount + 1);
        break;
      case "to":
        setFilterTo(value);
        setFilterCount((filterCount) => filterCount + 1);
        break;
    }
    reloadData(key, value);
  }

  const [showModelSlideOpen, setShowModelSlideOpen] = useState(false);
  const [showModelId, setShowModelId] = useState(null);

  function showModel(modelId) {
    setShowModelSlideOpen(true);
    setShowModelId(modelId);
    setBackupPath(window.location.pathname + window.location.search);
    window.history.replaceState(null, "", "/attendances/" + modelId);
  }

  function closeShowSlide() {
    setShowModelSlideOpen(false);
    setShowModelId(null);
    window.history.replaceState(null, "", backupPath);
  }

  function onMarkValidModalDone() {
    setMarkValidModalOpen(false);
    setSelectedItems([]);
    setChecked(false);
    let params = {};
    searchParams.forEach((key, value) => {
      params[value] = key;
    });
    loadData(params);
    closeShowSlide();
  }

  function markValid(itemId) {
    const item = items.find((item) => item.id === itemId);
    setSelectedItems([item]);
    setMarkValidModalOpen(true);
  }

  function switchToMode(mode) {
    clearFilter({ mode: mode });
    setViewType(mode);
  }

  function onCalendarMonthChange(payload) {
    let params = {};
    searchParams.forEach((key, value) => {
      params[value] = key;
    });
    params["calendar_startdate"] = payload.startStr;
    params["calendar_enddate"] = payload.endStr;

    loadData(params);
  }

  const [revokeModalOpen, setRevokeModalOpen] = useState(false);
  const [restoreModalOpen, setRestoreModalOpen] = useState(false);
  const [revokingItem, setRevokingItem] = useState(null);
  const [restoringItem, setRestoringItem] = useState(null);

  function revokeItem(item) {
    setRevokingItem(item);
    setRevokeModalOpen(true);
  }

  function confirmRevokeItem() {
    console.log(revokingItem);
    axios
      .post("/attendance-reports/" + revokingItem.id, {
        _method: "delete",
      })
      .then(function (response) {
        // handle success
        setRevokingItem(null);
        setRevokeModalOpen(false);
        clearFilter();
      })
      .catch(function (error) {
        // handle error
      });
  }

  function restoreItem(item) {
    setRestoringItem(item);
    setRestoreModalOpen(true);
  }

  function confirmRestoreItem() {
    axios
      .post("/attendance-reports/" + restoringItem.id + "/restore", {
        _method: "put",
      })
      .then(function (response) {
        // handle success
        if (response.data.status === "error") {
          toast.error(response.data.message);
        } else {
          clearFilter();
        }

        setRestoringItem(null);
        setRestoreModalOpen(false);
      })
      .catch(function (error) {
        // handle error
      });
  }

  function canViewInMultipleCompanies() {
    return (
      user.permissions.includes(
        "manage attendances in departments supervised"
      ) ||
      user.permissions.includes("view attendances in departments supervised") ||
      user.permissions.includes("manage attendances in their managed companies")
    );
  }

  function canManangeInMultipleCompanies() {
    return (
      user.permissions.includes(
        "manage attendances in departments supervised"
      ) ||
      user.permissions.includes("manage attendances in their managed companies")
    );
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!isLoaded) {
    return <Loader fullscreen={false} />;
  }

  return (
    <div className="min-h-[100vh] bg-gray-50 pb-1 relative">
      {/* Page title & actions */}
      <NavBar pageTitle={pageTitle}>
        {canManangeInMultipleCompanies() && (
          <Link
            to="/attendances/create"
            type="button"
            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
          >
            Create
          </Link>
        )}
      </NavBar>

      <div>
        {/* Filters */}
        <div className="px-4 py-3">
          <Disclosure>
            <div className="flex justify-start items-center">
              <Disclosure.Button className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">
                <FilterIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Filters
                <span className="ml-2 inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                  {filterCount}
                </span>
              </Disclosure.Button>

              {viewType === "table" && (
                <a
                  href={
                    process.env.REACT_APP_API_ENDPOINT +
                    "/attendance-reports?export=1&" +
                    searchParams.toString()
                  }
                  target="_blank"
                  rel="nofollow"
                  type="button"
                  className="ml-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
                >
                  <DownloadIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Export
                </a>
              )}

              {filterCount > 0 && (
                <button
                  onClick={() => clearFilter()}
                  type="button"
                  className="ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-red-600 focus:outline-none focus:ring-0"
                >
                  Clear filters
                  <XIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </button>
              )}
            </div>

            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="text-gray-500">
                <div className="mt-3 px-3 py-3 rounded-lg bg-white shadow">
                  <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
                    {(canViewInMultipleCompanies() || user.is_ceo) &&
                      viewType === "table" && (
                        <div className="col-span-1">
                          <input
                            type="text"
                            onChange={(event) =>
                              addFilter("employee_name", event.target.value)
                            }
                            value={filterEmployeeName}
                            className="w-full mr-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="Name"
                          />
                        </div>
                      )}

                    {canViewInMultipleCompanies() && (
                      <div className="col-span-1">
                        <select
                          className="mr-2 w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          value={filterCompanyId}
                          onChange={(event) =>
                            addFilter("company_id", event.target.value)
                          }
                        >
                          <option value="">All companies</option>
                          {user.managed_companies.map((company) => (
                            <option key={company.id} value={company.id}>
                              {company.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    {(canViewInMultipleCompanies() || user.is_ceo) && (
                      <div className="col-span-1">
                        <UserSelectFilterField
                          onChange={(value) =>
                            addFilter("employee_id", value.id)
                          }
                          people={
                            filterCompanyId
                              ? users.filter((user) => {
                                  return (
                                    parseInt(user.company_id) ===
                                    parseInt(filterCompanyId)
                                  );
                                })
                              : users
                          }
                          showCompany={true}
                          value={
                            filterEmployeeId
                              ? users.find(
                                  (user) =>
                                    parseInt(user.id) ==
                                    parseInt(filterEmployeeId)
                                )
                              : null
                          }
                        />
                      </div>
                    )}

                    {(canViewInMultipleCompanies() || user.is_ceo) &&
                      viewType === "table" && (
                        <div className="col-span-1">
                          <select
                            className="w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            value={filterType}
                            onChange={(event) =>
                              addFilter("type", event.target.value)
                            }
                          >
                            <option value="">All types</option>
                            <option value="remote">Remote</option>
                            <option value="physical">Physical</option>
                          </select>
                        </div>
                      )}

                    {(canViewInMultipleCompanies() || user.is_ceo) &&
                      viewType === "table" &&
                      filterStatus != "no_record" && (
                        <div className="col-span-1">
                          <select
                            className="w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            value={filterNote}
                            onChange={(event) =>
                              addFilter("note", event.target.value)
                            }
                          >
                            <option value="">All reasons</option>
                            {attendanceNotes.map((attendanceNote) => {
                              if (canViewInMultipleCompanies()) {
                                if (
                                  parseInt(attendanceNote.company_id) ===
                                    parseInt(filterCompanyId) ||
                                  !filterCompanyId
                                ) {
                                  return (
                                    <option
                                      key={attendanceNote.id}
                                      value={attendanceNote.label}
                                    >
                                      {attendanceNote.label}
                                    </option>
                                  );
                                }
                              } else {
                                if (
                                  parseInt(attendanceNote.company_id) ===
                                  parseInt(user.company_id)
                                ) {
                                  return (
                                    <option
                                      key={attendanceNote.id}
                                      value={attendanceNote.label}
                                    >
                                      {attendanceNote.label}
                                    </option>
                                  );
                                }
                              }
                            })}
                          </select>
                        </div>
                      )}

                    {/* <DatePickerFilterField clearAble={false} date={filterDate} onChange={(value) => addFilter('date', value)} /> */}
                    {viewType === "table" && (
                      <div className="col-span-3">
                        <DateRangePickerFilterField
                          startDate={filterFrom}
                          endDate={filterTo}
                          onChangeStart={(value) => addFilter("from", value)}
                          onChangeEnd={(value) => addFilter("to", value)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Disclosure.Panel>
            </Transition>
          </Disclosure>
        </div>
        {/* /Filters */}

        <div className="flex items-center justify-between">
          {/* Tabs */}
          {viewType === "table" ? (
            <div className="px-4 sm:px-4 lg:px-4 overflow-x-auto">
              <div className="hidden sm:block">
                <div className="border-b border-gray-20 px-4">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {tabs.map((tab, tabIndex) => (
                      <button
                        onClick={() => addFilter("status", tab.status)}
                        key={tab.name}
                        className={classNames(
                          tab.status === searchParams.get("status") ||
                            (!searchParams.get("status") && tab.status === "")
                            ? "border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                          "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                        )}
                      >
                        <span
                          className={
                            tab.status ? statusTextColors[tab.status] : ""
                          }
                        >
                          {tab.name}
                        </span>
                        {tab.count ? (
                          <span
                            className={classNames(
                              tab.status === searchParams.get("status") ||
                                (!searchParams.get("status") &&
                                  tab.status === "open")
                                ? "bg-indigo-100 text-indigo-600"
                                : "bg-gray-100 text-gray-900",
                              "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                            )}
                          >
                            {tab.count}
                          </span>
                        ) : null}
                      </button>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div className="pr-3 flex items-center">
            <span className="mr-2 text-sm whitespace-nowrap pl-3">
              View type: {viewType}
            </span>
            <span className="relative z-0 inline-flex shadow-sm rounded-md">
              <button
                type="button"
                onClick={() => switchToMode("table")}
                className={classNames(
                  viewType === "table" ? "bg-gray-200" : "bg-white",
                  "relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300  text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-0"
                )}
              >
                <TableIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                onClick={() => switchToMode("calendar")}
                className={classNames(
                  viewType === "calendar" ? "bg-gray-200" : "bg-white",
                  "-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-0"
                )}
              >
                <CalendarIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </span>
          </div>
        </div>

        {/* Projects table (small breakpoint and up) */}
        {viewType === "table" && (
          <div className="border-t mt-0 overflow-x-auto">
            <div className="align-middle  min-w-full border-b border-gray-200">
              {isDataLoading && <SkeletonTable />}
              {!isDataLoading && (
                <table className="min-w-full">
                  <thead>
                    <tr className="border-b border-gray-200 bg-gray-50">
                      {canManangeInMultipleCompanies() &&
                        filterStatus != "no_record" && (
                          <th
                            scope="col"
                            className="relative w-12 px-4 sm:w-16 sm:px-5"
                          >
                            <input
                              type="checkbox"
                              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                              ref={checkbox}
                              checked={checked}
                              onChange={toggleAll}
                            />

                            {selectedItems.length > 0 && (
                              <div className="whitespace-nowrap absolute top-0 left-12 flex h-10 items-center space-x-3 bg-gray-50 sm:left-18">
                                <button
                                  onClick={() => setMarkValidModalOpen(true)}
                                  type="button"
                                  className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-30"
                                >
                                  Mark ({selectedItems.length}) selected as
                                  valid
                                </button>
                              </div>
                            )}
                          </th>
                        )}

                      {canManangeInMultipleCompanies() &&
                        filterStatus != "no_record" &&
                        filterStatus != "revoked" && (
                          <th
                            className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            scope="col"
                          >
                            Revoke
                          </th>
                        )}

                      {canManangeInMultipleCompanies() &&
                        filterStatus === "revoked" && (
                          <th
                            className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            scope="col"
                          >
                            Restore
                          </th>
                        )}

                      <th
                        scope="col"
                        className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-xs text-left font-medium text-gray-500 uppercase tracking-wider"
                      >
                        <span className="group inline-flex uppercase whitespace-nowrap">
                          Date
                        </span>
                      </th>

                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        <span className="whitespace-nowrap">Employee</span>
                      </th>

                      <th
                        scope="col"
                        className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-xs text-left font-medium text-gray-500 uppercase tracking-wider"
                      >
                        <span className="group inline-flex uppercase whitespace-nowrap">
                          Status
                        </span>
                      </th>

                      {!filterOfficeId && (
                        <th
                          className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          scope="col"
                        >
                          <button
                            onClick={() => orderBy("company_id")}
                            className="group inline-flex uppercase whitespace-nowrap"
                          >
                            Office
                            {currentOrderBy.key === "company_id" ? (
                              <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                                {currentOrderBy.order === "desc" ? (
                                  <ChevronDownIcon
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ChevronUpIcon
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            ) : (
                              ""
                            )}
                          </button>
                        </th>
                      )}

                      {filterStatus != "no_record" && (
                        <th
                          scope="col"
                          className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-xs text-left font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span className="group inline-flex uppercase whitespace-nowrap">
                            Check-in
                          </span>
                        </th>
                      )}
                      {filterStatus != "no_record" && (
                        <th
                          scope="col"
                          className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-xs text-left font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <button
                            onClick={() => orderBy("priority")}
                            className="group inline-flex uppercase whitespace-nowrap"
                          >
                            Check-out
                            {currentOrderBy.key === "priority" ? (
                              <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                                {currentOrderBy.order === "desc" ? (
                                  <ChevronDownIcon
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ChevronUpIcon
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            ) : (
                              ""
                            )}
                          </button>
                        </th>
                      )}
                      {filterStatus != "no_record" && (
                        <th
                          scope="col"
                          className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-xs text-left font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span className="group inline-flex uppercase whitespace-nowrap">
                            Hours Worked
                          </span>
                        </th>
                      )}
                    </tr>
                  </thead>
                  {filterStatus != "no_record" && (
                    <tbody className="bg-white divide-y divide-gray-100">
                      {items.map((item) => (
                        <tr
                          key={item.id}
                          className={
                            selectedItems.includes(item)
                              ? "bg-gray-50"
                              : undefined
                          }
                        >
                          {canManangeInMultipleCompanies() &&
                            filterStatus != "no_record" && (
                              <td className="relative w-12 px-4 sm:w-16 sm:px-5">
                                {selectedItems.includes(item) && (
                                  <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                )}
                                <input
                                  type="checkbox"
                                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                                  value={item.id}
                                  checked={selectedItems.includes(item)}
                                  onChange={(e) =>
                                    setSelectedItems(
                                      e.target.checked
                                        ? [...selectedItems, item]
                                        : selectedItems.filter(
                                            (p) => p !== item
                                          )
                                    )
                                  }
                                />
                              </td>
                            )}

                          {canManangeInMultipleCompanies() &&
                            filterStatus != "no_record" &&
                            filterStatus != "revoked" && (
                              <td className="px-6 py-3 relative whitespace-nowrap text-center">
                                <button
                                  onClick={() => revokeItem(item)}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  <TrashIcon
                                    className="h-5 w-5 text-red-400"
                                    aria-hidden="true"
                                  />
                                </button>
                              </td>
                            )}

                          {canManangeInMultipleCompanies() &&
                            filterStatus === "revoked" && (
                              <td className="px-6 py-3 relative whitespace-nowrap">
                                <button
                                  onClick={() => restoreItem(item)}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  <ReplyIcon
                                    className="h-5 w-5 text-indigo-400"
                                    aria-hidden="true"
                                  />
                                </button>
                              </td>
                            )}

                          {filterStatus != "no_record" && (
                            <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                              {item.date}
                            </td>
                          )}
                          {filterStatus === "no_record" && (
                            <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                              {filterFrom
                                ? filterFrom.substr(8, 2) +
                                  "/" +
                                  filterFrom.substr(5, 2) +
                                  "/" +
                                  filterFrom.substr(0, 4)
                                : ""}
                            </td>
                          )}

                          {filterStatus != "no_record" && (
                            <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                              <div className="">
                                <div>
                                  <button
                                    onClick={() => {
                                      showModel(item.id);
                                    }}
                                    className="truncate  "
                                  >
                                    {item.user ? (
                                      <div className="flex items-center mb-1">
                                        <div className="flex flex-shrink-0 mr-1">
                                          <img
                                            key={"user_" + item.user.id}
                                            className="max-w-none h-5 w-5 rounded-full ring-2 ring-white"
                                            src={item.user.avatar_url}
                                            alt={item.user.name}
                                          />
                                        </div>
                                        <span className="flex-shrink-0 leading-5 flex underline font-bold text-blue-700">
                                          {item.user.name}{" "}
                                          <EyeIcon
                                            className="ml-2 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </button>
                                  <div>
                                    {canViewInMultipleCompanies() &&
                                      canManangeInMultipleCompanies() &&
                                      item.company && (
                                        <div>
                                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                            {item.company.name}
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </td>
                          )}
                          {filterStatus === "no_record" && (
                            <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                              <div className="">
                                <div>
                                  <div className="truncate">
                                    {item.user ? (
                                      <div className="flex items-center mb-1">
                                        <div className="flex flex-shrink-0 mr-1">
                                          <img
                                            key={"user_" + item.user.id}
                                            className="max-w-none h-5 w-5 rounded-full ring-2 ring-white"
                                            src={item.user.avatar_url}
                                            alt={item.user.name}
                                          />
                                        </div>
                                        <span className="flex-shrink-0 leading-5 flex font-bold text-black">
                                          {item.user.name}
                                        </span>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div>
                                    {canViewInMultipleCompanies() &&
                                      canManangeInMultipleCompanies() && (
                                        <div>
                                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                            {item.company.name}
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </td>
                          )}

                          {filterStatus != "no_record" &&
                            filterStatus != "revoked" && (
                              <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                                <span
                                  className={classNames(
                                    statusColors[item.status],
                                    "text-white uppercase inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                                  )}
                                >
                                  {statusLabels[item.status]}
                                </span>
                                {item.marked_valid_by && (
                                  <div className="mt-1 text-xs text-gray-500">
                                    Remarked valid <br />
                                    by {item.marked_valid_by.name}
                                    <br />
                                    on {item.marked_valid_at}
                                  </div>
                                )}
                              </td>
                            )}

                          {filterStatus === "no_record" && (
                            <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                              <span className="text-white bg-gray-600 uppercase inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium">
                                No record
                              </span>
                            </td>
                          )}

                          {filterStatus === "revoked" && (
                            <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                              <span
                                className={classNames(
                                  statusColors["revoked"],
                                  "text-white uppercase inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                                )}
                              >
                                {statusLabels["revoked"]}
                              </span>
                              {item.deleted_by && (
                                <div className="mt-1 text-xs text-gray-500">
                                  By {item.deleted_by.name}
                                  <br />
                                  {item.deleted_at}
                                </div>
                              )}
                            </td>
                          )}

                          {!filterOfficeId && (
                            <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                              <div>{item.office.name}</div>
                              <div>
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                  {item.timezone}
                                </span>
                              </div>
                            </td>
                          )}

                          {filterStatus != "no_record" && (
                            <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                              <div className="">
                                <div className="flex items-center space-x-1">
                                  {item.is_checkin_time_valid && (
                                    <CheckCircleIcon
                                      className="h-5 w-5 text-green-400"
                                      aria-hidden="true"
                                    />
                                  )}
                                  {!item.is_checkin_time_valid && (
                                    <XCircleIcon
                                      className="h-5 w-5 text-red-400"
                                      aria-hidden="true"
                                    />
                                  )}
                                  <span className="text-sm font-medium text-gray-500">
                                    {item.checkin_at}
                                  </span>
                                </div>

                                {item.office.id !== 1 && (
                                  <div>
                                    {item.is_checkin_location_valid && (
                                      <div className="flex items-center space-x-1">
                                        <CheckCircleIcon
                                          className="h-5 w-5 text-green-400"
                                          aria-hidden="true"
                                        />
                                        <span className="text-sm font-medium text-gray-500">
                                          In office
                                        </span>
                                      </div>
                                    )}

                                    {!item.is_checkin_location_valid && (
                                      <div className="flex items-center space-x-1">
                                        <XCircleIcon
                                          className="h-5 w-5 text-red-400"
                                          aria-hidden="true"
                                        />
                                        <span className="text-sm font-medium text-gray-500">
                                          Not in office
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                              {item.marked_valid_by &&
                                item.checkin_at != item.checkin_at_original && (
                                  <div className="mt-1 text-xs text-gray-500">
                                    Time updated <br />
                                    by {item.marked_valid_by.name}
                                    <br />
                                    on {item.marked_valid_at}
                                  </div>
                                )}
                            </td>
                          )}

                          {filterStatus != "no_record" && (
                            <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                              {item.checkout_at && (
                                <div className="">
                                  <div className="flex items-center space-x-1">
                                    {item.is_checkout_time_valid && (
                                      <CheckCircleIcon
                                        className="h-5 w-5 text-green-400"
                                        aria-hidden="true"
                                      />
                                    )}
                                    {!item.is_checkout_time_valid && (
                                      <XCircleIcon
                                        className="h-5 w-5 text-red-400"
                                        aria-hidden="true"
                                      />
                                    )}
                                    <span className="text-sm font-medium text-gray-500">
                                      {item.checkout_at}
                                    </span>
                                  </div>

                                  {item.office.id !== 1 && (
                                    <div>
                                      {item.is_checkout_location_valid && (
                                        <div className="flex items-center space-x-1">
                                          <CheckCircleIcon
                                            className="h-5 w-5 text-green-400"
                                            aria-hidden="true"
                                          />
                                          <span className="text-sm font-medium text-gray-500">
                                            In office
                                          </span>
                                        </div>
                                      )}

                                      {!item.is_checkout_location_valid && (
                                        <div className="flex items-center space-x-1">
                                          <XCircleIcon
                                            className="h-5 w-5 text-red-400"
                                            aria-hidden="true"
                                          />
                                          <span className="text-sm font-medium text-gray-500">
                                            Not in office
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                              {!item.checkout_at && <div>N/A</div>}

                              {item.marked_valid_by &&
                                item.checkout_at !=
                                  item.checkout_at_original && (
                                  <div className="mt-1 text-xs text-gray-500">
                                    Time updated <br />
                                    by {item.marked_valid_by.name}
                                    <br />
                                    on {item.marked_valid_at}
                                  </div>
                                )}
                            </td>
                          )}
                          {filterStatus != "no_record" && (
                            <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                              {item.checkout_at && (
                                <div>
                                  {item.is_worked_valid && (
                                    <div className="">
                                      <div className="flex items-center space-x-1">
                                        <CheckCircleIcon
                                          className="h-5 w-5 text-green-400"
                                          aria-hidden="true"
                                        />
                                        <span className="text-sm font-medium text-green-500">
                                          {item.hours_worked}
                                        </span>
                                      </div>
                                      <div className="flex items-center space-x-1">
                                        <span className="text-sm font-medium text-gray-500">
                                          {item.valid_working_hours}
                                        </span>
                                        {item.worked_diff_in_seconds && (
                                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-green-600">
                                            +{item.worked_diff_in_hours}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {!item.is_worked_valid && (
                                    <div className="">
                                      <div className="flex items-center space-x-1">
                                        <XCircleIcon
                                          className="h-5 w-5 text-red-400"
                                          aria-hidden="true"
                                        />
                                        <span className="text-sm font-medium text-gray-500">
                                          {item.hours_worked}
                                        </span>
                                      </div>
                                      <div className="flex items-center space-x-1">
                                        <span className="text-sm font-medium text-gray-500">
                                          {item.valid_working_hours}
                                        </span>
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-orange-600">
                                          -{item.worked_diff_in_hours}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              {!item.checkout_at && <div>N/A</div>}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  )}
                  {filterStatus === "no_record" && (
                    <>
                      {items.map((itemDate) => (
                        <tbody
                          key={itemDate.date}
                          className="bg-white divide-y divide-gray-100"
                        >
                          {itemDate.items.map((item) => (
                            <tr
                              key={item.id}
                              className={
                                selectedItems.includes(item)
                                  ? "bg-gray-50"
                                  : undefined
                              }
                            >
                              {canManangeInMultipleCompanies() &&
                                filterStatus != "no_record" && (
                                  <td className="relative w-12 px-4 sm:w-16 sm:px-5">
                                    {selectedItems.includes(item) && (
                                      <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                    )}
                                    <input
                                      type="checkbox"
                                      className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                                      value={item.id}
                                      checked={selectedItems.includes(item)}
                                      onChange={(e) =>
                                        setSelectedItems(
                                          e.target.checked
                                            ? [...selectedItems, item]
                                            : selectedItems.filter(
                                                (p) => p !== item
                                              )
                                        )
                                      }
                                    />
                                  </td>
                                )}

                              {canManangeInMultipleCompanies() &&
                                filterStatus != "no_record" &&
                                filterStatus != "revoked" && (
                                  <td className="px-6 py-3 relative whitespace-nowrap text-center">
                                    <button
                                      onClick={() => revokeItem(item)}
                                      className="text-indigo-600 hover:text-indigo-900"
                                    >
                                      <TrashIcon
                                        className="h-5 w-5 text-red-400"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </td>
                                )}

                              {canManangeInMultipleCompanies() &&
                                filterStatus === "revoked" && (
                                  <td className="px-6 py-3 relative whitespace-nowrap">
                                    <button
                                      onClick={() => restoreItem(item)}
                                      className="text-indigo-600 hover:text-indigo-900"
                                    >
                                      <ReplyIcon
                                        className="h-5 w-5 text-indigo-400"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </td>
                                )}

                              {filterStatus != "no_record" && (
                                <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                                  {item.date}
                                </td>
                              )}
                              {filterStatus === "no_record" && (
                                <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                                  {itemDate.date}
                                </td>
                              )}

                              {filterStatus != "no_record" && (
                                <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                                  <div className="">
                                    <div>
                                      <button
                                        onClick={() => {
                                          showModel(item.id);
                                        }}
                                        className="truncate  "
                                      >
                                        {item.user ? (
                                          <div className="flex items-center mb-1">
                                            <div className="flex flex-shrink-0 mr-1">
                                              <img
                                                key={"user_" + item.user.id}
                                                className="max-w-none h-5 w-5 rounded-full ring-2 ring-white"
                                                src={item.user.avatar_url}
                                                alt={item.user.name}
                                              />
                                            </div>
                                            <span className="flex-shrink-0 leading-5 flex underline font-bold text-blue-700">
                                              {item.user.name}{" "}
                                              <EyeIcon
                                                className="ml-2 h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </button>
                                      <div>
                                        {canViewInMultipleCompanies() &&
                                          canManangeInMultipleCompanies() && (
                                            <div>
                                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                                {item.company.name}
                                              </span>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              )}
                              {filterStatus === "no_record" && (
                                <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                                  <div className="">
                                    <div>
                                      <div className="truncate">
                                        {item.user ? (
                                          <div className="flex items-center mb-1">
                                            <div className="flex flex-shrink-0 mr-1">
                                              <img
                                                key={"user_" + item.user.id}
                                                className="max-w-none h-5 w-5 rounded-full ring-2 ring-white"
                                                src={item.user.avatar_url}
                                                alt={item.user.name}
                                              />
                                            </div>
                                            <span className="flex-shrink-0 leading-5 flex font-bold text-black">
                                              {item.user.name}
                                            </span>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div>
                                        {canViewInMultipleCompanies() &&
                                          canManangeInMultipleCompanies() && (
                                            <div>
                                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                                {item.company.name}
                                              </span>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              )}

                              {filterStatus != "no_record" &&
                                filterStatus != "revoked" && (
                                  <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                                    <span
                                      className={classNames(
                                        statusColors[item.status],
                                        "text-white uppercase inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                                      )}
                                    >
                                      {statusLabels[item.status]}
                                    </span>
                                    {item.marked_valid_by && (
                                      <div className="mt-1 text-xs text-gray-500">
                                        Remarked valid <br />
                                        by {item.marked_valid_by.name}
                                        <br />
                                        on {item.marked_valid_at}
                                      </div>
                                    )}
                                  </td>
                                )}

                              {filterStatus === "no_record" && (
                                <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                                  <span className="text-white bg-gray-600 uppercase inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium">
                                    No record
                                  </span>
                                </td>
                              )}

                              {filterStatus === "revoked" && (
                                <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                                  <span
                                    className={classNames(
                                      statusColors["revoked"],
                                      "text-white uppercase inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                                    )}
                                  >
                                    {statusLabels["revoked"]}
                                  </span>
                                  {item.deleted_by && (
                                    <div className="mt-1 text-xs text-gray-500">
                                      By {item.deleted_by.name}
                                      <br />
                                      {item.deleted_at}
                                    </div>
                                  )}
                                </td>
                              )}

                              {!filterOfficeId && (
                                <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                  <div>{item.office.name}</div>
                                  <div>
                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                      {item.timezone}
                                    </span>
                                  </div>
                                </td>
                              )}

                              {filterStatus != "no_record" && (
                                <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                                  <div className="">
                                    <div className="flex items-center space-x-1">
                                      {item.is_checkin_time_valid && (
                                        <CheckCircleIcon
                                          className="h-5 w-5 text-green-400"
                                          aria-hidden="true"
                                        />
                                      )}
                                      {!item.is_checkin_time_valid && (
                                        <XCircleIcon
                                          className="h-5 w-5 text-red-400"
                                          aria-hidden="true"
                                        />
                                      )}
                                      <span className="text-sm font-medium text-gray-500">
                                        {item.checkin_at}
                                      </span>
                                    </div>

                                    {item.office.id !== 1 && (
                                      <div>
                                        {item.is_checkin_location_valid && (
                                          <div className="flex items-center space-x-1">
                                            <CheckCircleIcon
                                              className="h-5 w-5 text-green-400"
                                              aria-hidden="true"
                                            />
                                            <span className="text-sm font-medium text-gray-500">
                                              In office
                                            </span>
                                          </div>
                                        )}

                                        {!item.is_checkin_location_valid && (
                                          <div className="flex items-center space-x-1">
                                            <XCircleIcon
                                              className="h-5 w-5 text-red-400"
                                              aria-hidden="true"
                                            />
                                            <span className="text-sm font-medium text-gray-500">
                                              Not in office
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  {item.marked_valid_by &&
                                    item.checkin_at !=
                                      item.checkin_at_original && (
                                      <div className="mt-1 text-xs text-gray-500">
                                        Time updated <br />
                                        by {item.marked_valid_by.name}
                                        <br />
                                        on {item.marked_valid_at}
                                      </div>
                                    )}
                                </td>
                              )}

                              {filterStatus != "no_record" && (
                                <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                                  {item.checkout_at && (
                                    <div className="">
                                      <div className="flex items-center space-x-1">
                                        {item.is_checkout_time_valid && (
                                          <CheckCircleIcon
                                            className="h-5 w-5 text-green-400"
                                            aria-hidden="true"
                                          />
                                        )}
                                        {!item.is_checkout_time_valid && (
                                          <XCircleIcon
                                            className="h-5 w-5 text-red-400"
                                            aria-hidden="true"
                                          />
                                        )}
                                        <span className="text-sm font-medium text-gray-500">
                                          {item.checkout_at}
                                        </span>
                                      </div>

                                      {item.office.id !== 1 && (
                                        <div>
                                          {item.is_checkout_location_valid && (
                                            <div className="flex items-center space-x-1">
                                              <CheckCircleIcon
                                                className="h-5 w-5 text-green-400"
                                                aria-hidden="true"
                                              />
                                              <span className="text-sm font-medium text-gray-500">
                                                In office
                                              </span>
                                            </div>
                                          )}

                                          {!item.is_checkout_location_valid && (
                                            <div className="flex items-center space-x-1">
                                              <XCircleIcon
                                                className="h-5 w-5 text-red-400"
                                                aria-hidden="true"
                                              />
                                              <span className="text-sm font-medium text-gray-500">
                                                Not in office
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {!item.checkout_at && <div>N/A</div>}

                                  {item.marked_valid_by &&
                                    item.checkout_at !=
                                      item.checkout_at_original && (
                                      <div className="mt-1 text-xs text-gray-500">
                                        Time updated <br />
                                        by {item.marked_valid_by.name}
                                        <br />
                                        on {item.marked_valid_at}
                                      </div>
                                    )}
                                </td>
                              )}
                              {filterStatus != "no_record" && (
                                <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                                  {item.checkout_at && (
                                    <div>
                                      {item.is_worked_valid && (
                                        <div className="">
                                          <div className="flex items-center space-x-1">
                                            <CheckCircleIcon
                                              className="h-5 w-5 text-green-400"
                                              aria-hidden="true"
                                            />
                                            <span className="text-sm font-medium text-green-500">
                                              {item.hours_worked}
                                            </span>
                                          </div>
                                          <div className="flex items-center space-x-1">
                                            <span className="text-sm font-medium text-gray-500">
                                              {item.valid_working_hours}
                                            </span>
                                            {item.worked_diff_in_seconds && (
                                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-green-600">
                                                +{item.worked_diff_in_hours}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      )}

                                      {!item.is_worked_valid && (
                                        <div className="">
                                          <div className="flex items-center space-x-1">
                                            <XCircleIcon
                                              className="h-5 w-5 text-red-400"
                                              aria-hidden="true"
                                            />
                                            <span className="text-sm font-medium text-gray-500">
                                              {item.hours_worked}
                                            </span>
                                          </div>
                                          <div className="flex items-center space-x-1">
                                            <span className="text-sm font-medium text-gray-500">
                                              {item.valid_working_hours}
                                            </span>
                                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-orange-600">
                                              -{item.worked_diff_in_hours}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {!item.checkout_at && <div>N/A</div>}
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      ))}
                    </>
                  )}
                </table>
              )}
            </div>
          </div>
        )}

        {viewType === "calendar" && (
          <div className="mt-0 p-5">
            <AttendanceCalendar
              items={items}
              onMonthChange={onCalendarMonthChange}
            />
          </div>
        )}

        {/* Empty state */}
        {!items.length && isLoaded && viewType === "table" ? (
          <EmptyStates
            createPath={
              canManangeInMultipleCompanies() ? "/attendances/create" : null
            }
          />
        ) : (
          ""
        )}

        {/* Pagination */}
        {items.length && isLoaded && viewType === "table" ? (
          <Pagination paginationMeta={paginationMeta} reloadData={reloadData} />
        ) : (
          ""
        )}
      </div>

      <Show
        open={showModelSlideOpen}
        close={closeShowSlide}
        modelId={showModelId}
        user={user}
        reloadData={reloadData}
        markValid={() => markValid(showModelId)}
      />

      <MarkAttendanceValidModal
        title={
          selectedItems.length > 1
            ? "Mark selected (" +
              selectedItems.length +
              ") records as valid attendance"
            : "Mark record as valid attendance"
        }
        close={() => setMarkValidModalOpen(false)}
        open={markValidModalOpen}
        selectedItems={selectedItems}
        onDone={() => onMarkValidModalDone()}
      />

      <AlertModal
        title="Revoke attendance"
        note="Are you sure you want to revoke this attendance?"
        confirm={confirmRevokeItem}
        close={() => setRevokeModalOpen(false)}
        open={revokeModalOpen}
      />
      <AlertModal
        title="Restore attendance"
        note="Are you sure you want to restore this attendance?"
        confirm={confirmRestoreItem}
        close={() => setRestoreModalOpen(false)}
        open={restoreModalOpen}
      />
    </div>
  );
}
