export default function Error(props) {
    const { error } = props;
    const url = window.location.href;
const index = url.indexOf('#');
const modifiedUrl = index !== -1 ? url.substring(0, index) : url;

    return (
        <div className="fullscreen-loading flex items-center justify-around text-gray-600 p-20">
            <div className="text-center">
                <p>Oops! We're Sorry for the Inconvenience.</p>
                <p>Error Message: {error.message}</p>
                <p><a href={modifiedUrl} className="font-medium underline text-indigo-500">Click here to try again</a></p>
            </div>
        </div>
    );
}