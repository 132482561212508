import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { ExclamationCircleIcon, CheckIcon, SelectorIcon, ChevronDownIcon, ChevronDoubleUpIcon, MenuAlt4Icon } from '@heroicons/react/solid'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
  
export default function PrioritySelectField(props) {
    const { label, input, meta, required = false } = props
 
    return (
        <div className="sm:col-span-3">
            <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
                
                <Listbox value={input.value} onChange={input.onChange}>
                {({ open }) => (
                    <>
                    <div className="mt-1 relative min-w-[140px]">
                        <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        <span className="flex items-center">
                            {(input.value === 'urgent') && <ChevronDoubleUpIcon className="h-5 w-5 text-red-500" aria-hidden="true" /> }
                            {(input.value === 'normal') && <MenuAlt4Icon className="h-5 w-5 text-yellow-500" aria-hidden="true" /> }
                            {(input.value === 'low') && <ChevronDownIcon className="h-5 w-5 text-blue-500" aria-hidden="true" /> }
                            <span className="ml-3 block truncate">{input.value.toUpperCase()}</span>
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                        </Listbox.Button>

                        <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            <Listbox.Option
                            key={'low'}
                            className={({ active }) =>
                                classNames(
                                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                'cursor-default select-none relative py-2 pl-3 pr-9'
                                )
                            }
                            value={'low'}
                            >
                            {({ selected, active }) => (
                                <>
                                <div className="flex items-center">
                                    <ChevronDownIcon className="h-5 w-5 text-blue-500" aria-hidden="true" />
                                    <span
                                    className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                    >
                                    LOW
                                    </span>
                                </div>

                                {selected ? (
                                    <span
                                    className={classNames(
                                        active ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                    >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                ) : null}
                                </>
                            )}
                            </Listbox.Option>
                            <Listbox.Option
                            key={'normal'}
                            className={({ active }) =>
                                classNames(
                                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                'cursor-default select-none relative py-2 pl-3 pr-9'
                                )
                            }
                            value={'normal'}
                            >
                            {({ selected, active }) => (
                                <>
                                <div className="flex items-center">
                                    <MenuAlt4Icon className="h-5 w-5 text-yellow-500" aria-hidden="true" />
                                    <span
                                    className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                    >
                                    NORMAL
                                    </span>
                                </div>

                                {selected ? (
                                    <span
                                    className={classNames(
                                        active ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                    >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                ) : null}
                                </>
                            )}
                            </Listbox.Option>
                            <Listbox.Option
                            key={'urgent'}
                            className={({ active }) =>
                                classNames(
                                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                'cursor-default select-none relative py-2 pl-3 pr-9'
                                )
                            }
                            value={'urgent'}
                            >
                            {({ selected, active }) => (
                                <>
                                <div className="flex items-center">
                                    <ChevronDoubleUpIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                    <span
                                    className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                    >
                                    URGENT
                                    </span>
                                </div>

                                {selected ? (
                                    <span
                                    className={classNames(
                                        active ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                    >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                ) : null}
                                </>
                            )}
                            </Listbox.Option>
                        </Listbox.Options>
                        </Transition>
                    </div>
                    </>
                )}
                </Listbox>

                {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>}
            </div>
            {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
        </div>
    )
}
