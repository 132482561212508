import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Form, Field } from 'react-final-form'
import createDecorator from 'final-form-calculate'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { Switch } from '@headlessui/react'

// Components
import DropzoneField from '../../components/fields/DropzoneField'
import PrioritySelectField from '../../components/fields/PrioritySelectField';
import TextField from '../../components/fields/TextField';
import EmailField from '../../components/fields/EmailField';
import TextareaField from '../../components/fields/TextareaField';
import AlertModal from '../../components/AlertModal';
import SwitchField from '../../components/fields/SwitchField';
import CompanyMultiselectField from '../../components/fields/CompanyMultiselectField';
import ArrayField from '../../components/fields/ArrayField';
import DatePickerField from '../../components/fields/DatePickerField';
import CountrySelectField from '../../components/fields/CountrySelectField';
import PhoneNumberField from '../../components/fields/PhoneNumberField';
import ToggleField from '../../components/fields/ToggleField';
import RadioGroupField from '../../components/fields/RadioGroupField';
import AddressField from '../../components/fields/AddressField';
import NumberField from '../../components/fields/NumberField';
import TagsField from '../../components/fields/TagsField';
import { current } from '@reduxjs/toolkit';
import CheckboxesField from '../../components/fields/CheckboxesField';
import SelectField from '../../components/fields/SelectField';
import SingleFileUploadField from '../../components/fields/GrandizerSingleFileUploadField';
import CountryStateCityField from '../../components/fields/CountryStateCityField';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

const tabs = [
  { name: 'Information', key: 'information' },
  { name: 'Attributes', key: 'attributes' },
  { name: 'Media', key: 'media' },
]

// Form Multiple Validators
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
const required = value => (value ? undefined : 'Required')

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CreateCrud(props) {
  //const { user } = props
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [jobs, setJobs] = useState([])
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [sendingData, setSendingData] = useState(false)

  // Set page title
  useEffect(() => {
    document.title = `Create Job Application`;
  }, [])
  
  useEffect(() => {
    // Creation form data
    axios.get('/recruit-job-applications/create')
      .then(function (response) {
        // handle success
        setJobs(response.data.jobs)
        setIsLoaded(true);
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
        setIsLoaded(true);
      })
      .then(function () {
        setIsLoaded(true);
      });
  }, [])

  function closeForm() {
    setAlertModalOpen(false)
    navigate("/recruit-job-applications");
  }

  // Send data
  const sendData = values => new Promise(resolve => {
    setSendingData(true)
    const formData = new FormData();

    if(values.full_name) { formData.append('full_name', values.full_name); }
    formData.append('job_id', values.job.id);
    if(values.email) { formData.append('email', values.email); }
    if(values.phone) { formData.append('phone', values.phone); }
    if(values.cover_letter) { formData.append('cover_letter', values.cover_letter); }
    if(values.gender) { formData.append('gender', values.gender); }
    if(values.dob) { formData.append('dob', values.dob); }
    if(values.country.country) { formData.append('country', values.country.country); }
    if(values.country.state) { formData.append('state', values.country.state); }
    if(values.country.city) { formData.append('city', values.country.city); }
    if(values.photo) { formData.append('photo', values.photo); }
    if(values.resume) { formData.append('resume', values.resume); }

    values.job.fields.forEach((field) => {
      if(values[field.name]) { formData.append(field.name, values[field.name]); }
    })

    axios.post('recruit-job-applications', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    .then(function (response) {
        setSendingData(false)
        resolve()
        if(response.data.data.id) {
          navigate('/recruit-job-applications')
        }
      })
      .catch(function (error) {
        setSendingData(false)
        resolve()
      });
  })

  // Submit form
  const onSubmit = async values => {
    await sendData(values)
  }

  if(!isLoaded) {
    return <div className="fullscreen-loading flex items-center justify-around">Loading..</div>
  } else {
    return (
      <div className="h-full">
        <Form
          keepDirtyOnReinitialize
          onSubmit={onSubmit}
          initialValues={{ country: {country: 'Saudi Arabia', state: null, city: null}, companies: [] }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              {/* Page title & actions */}
              <div className="bg-white border-b border-gray-200 px-4 py-4 flex items-center justify-between sm:px-6 lg:px-8 fixed top-14 lg:top-0 right-0 left-0 lg:left-56  lg:fixed lg:top-0 lg:left-56 lg:right-0 z-50 bg-white">
                <div className="flex-1 min-w-0">
                  <h1 className="text-lg font-medium leading-6 text-gray-900 truncate">Create Job Application</h1>
                </div>
                <div className="mt-4 flex mt-0 ml-4"> 
                  <a href="#" onClick={() => setAlertModalOpen(true)} disabled={sendingData} className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-2 disabled:opacity-75">
                    Cancel
                  </a>
                  <button
                    disabled={sendingData}
                    type="submit"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
                  >
                    {sendingData ? 'Submitting..' : 'Submit'}
                  </button>
                </div>
              </div>
              <main className="py-10 pt-20">
                <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8 mt-4">
                  <div className="shadow sm:rounded-md sm:overflow-hidden w-full">
                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6 relative">
                      {sendingData ? (<div className="bg-white absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center z-50">
                        Please wait..
                      </div>) : ''}
                      <div>
                        <div> 
                          <div className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200">
                              <div>
                                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                  <Field name="job" validate={required}>
                                    {({ input, meta }) => (            
                                      <SelectField required={true} label="Select job" col="6" input={input} meta={meta} options={jobs} />
                                    )}
                                  </Field>
                                
                                  <Field name="full_name" validate={required}>
                                    {({ input, meta }) => (            
                                      <TextField required={true} label="Full Name" col="6" input={input} meta={meta} />
                                    )}
                                  </Field>

                                  <Field name="email" validate={required}>
                                    {({ input, meta }) => (            
                                      <EmailField label="Email" input={input} meta={meta} />
                                    )}
                                  </Field>

                                  <Field name="phone" validate={required}>
                                    {({ input, meta }) => (            
                                      <PhoneNumberField label="Phone" input={input} meta={meta} />
                                    )}
                                  </Field>

                                  {values.job?.required_columns?.gender && <Field name="gender" validate={required}>
                                    {({ input, meta }) => (            
                                      <RadioGroupField label="Gender" input={input} meta={meta} col="3" options={[
                                        { label: 'Male', value: 'male' },
                                        { label: 'Female', value: 'female' },
                                      ]} />
                                    )}
                                  </Field>}

                                  {values.job?.required_columns?.dob && <Field name="dob" validate={required}>
                                    {({ input, meta }) => (            
                                      <DatePickerField label="Birthday Date" input={input} meta={meta} />
                                    )}
                                  </Field>}

                                  {values.job?.required_columns?.country && <Field name="country" validate={value => ((value.country && value.state && value.city) ? undefined : 'Required')}>
                                    {({ input, meta }) => (            
                                      <CountryStateCityField label="Country" input={input} meta={meta} />
                                    )}
                                  </Field>}

                                  {values.job?.section_visibility?.profile_image && <Field name="photo" validate={required}>
                                    {({ input, meta }) => (            
                                      <SingleFileUploadField label="Profile Image" accept=".png,.jpg,.jpeg" input={input} meta={meta} />
                                    )}
                                  </Field>}

                                  {values.job?.section_visibility?.resume && <Field name="resume" validate={required}>
                                    {({ input, meta }) => (            
                                      <SingleFileUploadField label="Resume" input={input} meta={meta} accept=".png,.jpg,.jpeg,.pdf,.doc,.docx,.xls,.xlsx,.rtf" />
                                    )}
                                  </Field>}

                                  {values.job?.section_visibility?.cover_letter && <Field name="cover_letter" validate={required}>
                                    {({ input, meta }) => (            
                                      <TextareaField label="Cover Letter" input={input} meta={meta} />
                                    )}
                                  </Field>}
                                </div>

                                <div className="mt-8">
                                  {values.job?.fields.map((formField) => (<div key={formField.name}>
                                    {formField.type === 'paragraph' && <div className="mb-6" dangerouslySetInnerHTML={{__html: formField.text}}></div>}

                                    {formField.type === 'text' && 
                                      <div className="mb-6"><Field name={formField.name} validate={formField.required ? required : null}>
                                        {({ input, meta }) => (            
                                          <TextField required={formField.required ? true : false} label={formField.label} input={input} meta={meta} />
                                        )}
                                      </Field></div>
                                    }
                                    
                                    {formField.type === 'textarea' && 
                                      <div className="mb-6"><Field name={formField.name} validate={formField.required ? required : null}>
                                        {({ input, meta }) => (            
                                          <TextareaField label={formField.label} input={input} meta={meta} />
                                        )}
                                      </Field></div>
                                    }

                                    {formField.type === 'boolean' && 
                                      <div className="mb-6"><Field name={formField.name} validate={formField.required ? required : null}>
                                        {({ input, meta }) => (       
                                          <RadioGroupField label={formField.label} input={input} meta={meta} col="3" options={[
                                            { label: 'Yes', value: 'yes' },
                                            { label: 'No', value: 'no' },
                                          ]} />
                                        )}
                                      </Field></div>
                                    }
                                  </div>))}
                                </div>

                                {/* <div>
                                  <pre>{JSON.stringify(values, 0, 2)}</pre>
                                </div> */}
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </form>
            
          )}  
        />
        
        <AlertModal title="Close Form" confirm={closeForm} close={() => setAlertModalOpen(false)} open={alertModalOpen} />
      </div>
    )
  }
}
