import { useEffect, useState } from "react";
import classNames from "helpers/classNames";

export default function FormTabs({
  tabs,
  setCurrentTab,
  defaultTab,
  values,
  initialRequiredField,
  formSelect,
  errors,
  submitted,
  setSubmitted,
  nonRegisterederror,
}) {
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  useEffect(() => {
    setSelectedTab(defaultTab);
  }, [defaultTab]);

  function changeTab(tabKey) {
    setSelectedTab(tabKey);
    setCurrentTab(tabKey);
  }
  if (
    submitted &&
    JSON.stringify(errors) === "{}" &&
    JSON.stringify(nonRegisterederror) === "{}"
  ) {
    setSubmitted(false);
  }
  if (
    submitted &&
    ((errors && Object.keys(errors).length > 0) ||
      (nonRegisterederror && Object.keys(nonRegisterederror).length > 0))
  ) {
    tabs.forEach((tab) => {
      let hasError = false;

      if (tab.fields && Array.isArray(tab.fields)) {
        // Check for errors in regular fields
        hasError = tab.fields.some((field) => {
          return (
            (errors && errors[field.name]) ||
            (nonRegisterederror && nonRegisterederror[field.name])
          );
        });

        if (!hasError && tab.key === "bank_detail") {
          // Check for errors in columns
          tab.fields.forEach((field) => {
            if (field.columns && Array.isArray(field.columns)) {
              field.columns.forEach((column) => {
                if (nonRegisterederror && nonRegisterederror[column.key]) {
                  hasError = true;
                }
              });
            }
          });
        }
      }

      if (hasError) {
        tab.bgColor = "bg-red-200";
      } else {
        tab.bgColor = null;
      }
    });
  } else {
    tabs.forEach((tab) => {
      tab.bgColor = null;
    });
  }

  if (formSelect === "company") {
    // tabs.forEach((tab) => {
    //   let tabKey = tab.key;

    //   if (initialRequiredField[tabKey] !== undefined) {
    //     for (const [key, value] of Object.entries(
    //       initialRequiredField[tabKey]
    //     )) {
    //       if (
    //         key in values === true &&
    //         values[key] &&
    //         values[key] !== null &&
    //         values[key] !== undefined
    //       ) {
    //         let getvalue = values[key];

    //         if (Array.isArray(getvalue)) {
    //           if (getvalue.length === 0) {
    //             tab.bgColor = "bg-red-200";
    //             break;
    //           } else {
    //             tab.bgColor = null;
    //           }
    //         } else if (tab.key === "styles") {
    //           if (
    //             values?.logo?.fileUrl === undefined &&
    //             typeof values?.logo === "object"
    //           ) {
    //             tab.bgColor = "bg-red-200";
    //             break;
    //           }
    //           if (
    //             values?.logo_dark?.fileUrl === undefined &&
    //             typeof values?.logo_dark === "object"
    //           ) {
    //             tab.bgColor = "bg-red-200";
    //             break;
    //           } else {
    //             tab.bgColor = null;
    //           }
    //         } else if (typeof getvalue === "boolean") {
    //           tab.bgColor = "bg-red-200";
    //           break;
    //         } else {
    //           tab.bgColor = null;
    //         }
    //       } else {
    //         tab.bgColor = "bg-red-200";
    //         break;
    //       }
    //     }
    //   }
    // });

    // Check for errors and update tab colors
    // if (submitted) {
    for (const [key, value] of Object.entries(errors)) {
      if (!value?.includes("Required")) {
        const tabToUpdate = tabs.find((tab) => {
          const fieldToUpdate = tab.fields.find((field) => field.name === key);
          return fieldToUpdate !== undefined;
        });
        if (tabToUpdate) {
          tabToUpdate.bgColor = "bg-red-200";
        }
      }
    }
    // }
  }

  if (formSelect === "employee") {
    for (const [key, value] of Object.entries(errors)) {
      if (!value?.includes("Required")) {
        const tabToUpdate = tabs.find((tab) => {
          const fieldToUpdate = tab.fields.find((field) => field.name === key);
          return fieldToUpdate !== undefined;
        });
        if (tabToUpdate) {
          tabToUpdate.bgColor = "bg-red-200";
        }
      }
    }
    // tabs.forEach((tab) => {
    //   let tabKey = tab.key;

    //   if (initialRequiredField[tabKey] !== undefined) {
    //     for (const [key, value] of Object.entries(
    //       initialRequiredField[tabKey]
    //     )) {
    //       if (
    //         key in values === true &&
    //         values[key] &&
    //         values[key] !== null &&
    //         values[key] !== undefined
    //       ) {
    //         let getvalue = values[key];

    //         if (Array.isArray(getvalue)) {
    //           if (getvalue.length === 0) {
    //             tab.bgColor = "bg-red-200";
    //             break;
    //           } else {
    //             tab.bgColor = null;
    //           }
    //         } else if (typeof getvalue === "boolean") {
    //           tab.bgColor = "bg-red-200";
    //           break;
    //         } else {
    //           tab.bgColor = null;
    //         }
    //       } else {
    //         tab.bgColor = "bg-red-200";
    //         break;
    //       }
    //     }
    //   }
    // });
  }
  return (
    <div className="mb-3">
      <nav className="flex flex-col xl:flex-row" aria-label="Tabs">
        {tabs.map((tab, index) => (
          <a
            onClick={() => changeTab(tab.key)}
            href={"#" + tab.key}
            key={tab.key} // Use a unique identifier for the key
            className={classNames(
              tab.bgColor !== null
                ? tab.bgColor + " text-gray-800 mr-2"
                : tab.key == selectedTab
                ? "bg-gray-200 text-gray-800 mr-2"
                : "text-gray-600 hover:text-gray-800 mr-2",
              "px-3 py-2 font-medium text-sm rounded-md mr-2"
            )}
            aria-current={tab.key === selectedTab ? "page" : undefined}
          >
            {tab.name}
          </a>
        ))}
      </nav>
    </div>
  );
}
