import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Form, Field } from 'react-final-form'
import createDecorator from 'final-form-calculate'

// Components
import AutoSave from '../../components/fields/AutoSave';
import NumberField from '../../components/fields/NumberField';
import CompanySelectField from '../../components/fields/CompanySelectField';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

// Form Multiple Validators
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
const required = value => (value ? undefined : 'Required')


export default function Calculator2(props) {
    let params = useParams()
    const { user } = props
    const [error, setError] = useState(null);
    const [isNoPermission, setIsNoPermission] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false);
    const [sendingData, setSendingData] = useState(false)
    const [result, setResult] = useState({
        aboveMinTargetCommission: null,
        commission: null,
    })
    const [calculator, setCalculator] = useState({})

    function reloadData() {
        axios.get('calculators/2')
        .then(function (response) {
            // handle success
            //console.log(response.data.data);
            if(response.data.status === 'no_permission') {
                setIsNoPermission(true)
            } else {
                if(response.data.data) {
                    document.title = response.data.data.name;
                    setCalculator(response.data.data)
                } 
            }
            setIsLoaded(true);
        })
        .catch(function (error) {
            // handle error
            //setIsLoaded(true);
            setError(error);
            setIsLoaded(true);
        })
        .then(function () {
            setIsLoaded(true);
        });
      }


    // Set page title
    useEffect(() => {
        reloadData()
    }, [])

    // Send data
    const sendData = values => new Promise(resolve => {
        setSendingData(true)
        const formData = new FormData();
        if(values.company.id) {
            formData.append('company_id', values.company.id);
        }
        if(values.min_yearly_revenue_target) {
            formData.append('min_yearly_revenue_target', values.min_yearly_revenue_target);
        }
        if(values.actual_yearly_target) {
            formData.append('actual_yearly_target', values.actual_yearly_target);
        }

        axios.post('calculator-hander/2', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
        .then(function (response) {
            setResult(response.data)
            setSendingData(false)
            resolve()
        })
        .catch(function (error) {
            setSendingData(false)
            resolve()
        });
    })

    // Submit form
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    const onSubmit = async values => {
        await sleep(1000)
        await sendData(values)
    }

    if(!isLoaded) {
        return <div className="fullscreen-loading flex items-center justify-around">Loading..</div>
      } else {
        if(isNoPermission) {
            return <div className="fullscreen-loading flex items-center justify-around">404 Not Found</div>
        } else {
            return (
                <div className="p-10">

                    <div className="pb-5">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">{calculator.name}</h3>
                        <p className="mt-2 max-w-4xl text-sm text-gray-500">
                        </p>
                    </div>
                    
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        
                        <div className="mt-5 md:mt-0 md:col-span-1">
                        <Form
                            subscription={{} /* No need to subscribe to anything */}
                            onSubmit={onSubmit}
                            initialValues={{ company: user.company, actual_avg_project_profit: ''  }}
                            render={({ handleSubmit, form, submitting, pristine, values }) => (
                                <div className="form">
 
                                    <div className="shadow sm:rounded-md">
                                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                           
                                            {(user.managed_companies.length > 1) ? (<Field name="company">
                                                {({ input, meta }) => (            
                                                    <CompanySelectField label="Company & Min Avg Project Profit" input={input} meta={meta} />
                                                )}
                                            </Field>) : ((<div className="text-sm">
                                                <label className="block text-sm font-medium text-gray-700">Company & Min Avg Project Profit</label>
                                                <p className="mt-1 max-w-2xl text-sm text-gray-500">{user.company.name} ({user.company.min_avg_project_profit}%)</p>
                                            </div>))}
                                        
                                            <Field name="min_yearly_revenue_target" validate={required}>
                                                {({ input, meta }) => (            
                                                <NumberField required={true} label="Min Yearly Revenue Target (SAR)" input={input} meta={meta} />
                                                )}
                                            </Field>
                                            
                                            <Field name="actual_yearly_target">
                                                {({ input, meta }) => (            
                                                <NumberField label="Actual Yearly Target (SAR)" input={input} meta={meta} />
                                                )}
                                            </Field>
                                        </div>

                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <AutoSave debounce={500} save={onSubmit} title="Calculate" />
                                        </div>
                                        
                                        <div className="hidden px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="submit"
                                                disabled={sendingData}
                                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Calculate
                                            </button>
                                        </div>  
                                    </div>                                         

                                    {/* <pre>{JSON.stringify(formFields(values.ticket_category_id), 0, 2)}</pre> */}                                    
                                </div> 
                            )}  
                            />
                        </div>

                        <div className="md:col-span-2">
                            <div className="shadow sm:rounded-md sm:overflow-hidden">
                                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Results</h3>
                                <div className="mt-1 text-sm text-gray-600">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                                            >
                                                Name
                                            </th>
                                            <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                Value
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {result.aboveMinTargetCommission && <tr>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">Above Min Target Commission %</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 font-medium">{result.aboveMinTargetCommission}</td>
                                            </tr>}
                                            {result.commission && <tr>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">Commission</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 font-medium">{result.commission}</td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
        