import { useEffect } from "react";

const ErrorModification = ({ setCustomError, errors }) => {
  useEffect(() => {
    setCustomError({ ...errors });
  }, [errors]);

  return <div></div>;
};

export default ErrorModification;
