import NavBar from "layout/NavBar";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UpdateCrudFrom from "./components/UpdateCrudForm";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Loader from "layout/Loader";
import AlertModal from "components/AlertModal";

const pageTitle = "Create Employee";

export default function DraftEdit(props) {
  // employee draft edit
  const { user } = props;
  let { draftEmployeeId } = useParams();
  const [customError, setCustomError] = useState({});
  const [isDisableDraft, setIsDisableDraft] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [nonRegisterederror, setNonRegisterederror] = useState({});

  const [sendingData, setSendingData] = useState(false);
  const [stateData, setStateData] = useState(true);
  const [errorHold, setErrorHold] = useState(false);
  const [recevingData, setRecevingData] = useState(false);
  let navigate = useNavigate();

  const [name, setName] = useState();
  const [jobType, setJobType] = useState();
  const [bankAccountData, setBankAccountData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [tabSelect, setTabSelect] = useState("employee");

  const [email, setEmail] = useState();
  const [isRemote, setIsRemote] = useState();
  const [company, setCompany] = useState();
  const [officeId, setOfficeId] = useState();
  const [timezone, setTimezone] = useState();
  const [departmentToggle, setDepartmentToggle] = useState();

  const [closePayRoll, setClosePayRoll] = useState();
  const [companyLocation, setCompanyLocation] = useState();
  const [lineManager, setLineManager] = useState();
  const [arabicName, setArabicName] = useState();
  const [sallaryOne, setSallaryOne] = useState();
  const [sallaryTwo, setSallaryTwo] = useState();
  const [sallaryThree, setSallaryThree] = useState();
  const [businessHours, setBusinessHours] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [alertModalOpen, setAlertModalOpen] = useState(false);

  const [sunday, setSunday] = useState({
    open: "",
    close: "",
  });

  const [passportFile, setPassportFile] = useState({
    fileUrl: null,
    fileName: null,
  });

  const [ibanFile, setIbanFile] = useState({
    fileUrl: null,
    fileName: null,
  });

  const [profilePic, setProfilePic] = useState({
    fileUrl: null,
    fileName: null,
  });

  const [iqamaFile, setIqamaFile] = useState({
    fileUrl: null,
    fileName: null,
  });

  const [contractsFile, setContractsFile] = useState({
    fileUrl: null,
    fileName: null,
  });

  const [model, setModel] = useState({});

  // Set page title
  useEffect(() => {
    document.title = pageTitle;
  }, []);

  const [errorMsg, setErrorMsg] = useState("employee");

  function closeForm() {
    setAlertModalOpen(false);
    navigate("/employees");
  }

  useEffect(() => {
    getEmployeeDraftInfo();
  }, []);

  async function getEmployeeDraftInfo() {
    setIsLoaded(false);
    await axios
      .get("draft/edit/" + draftEmployeeId, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        //   sunday.open =
        //   response.data.data?.business_hours?.sunday[0]?.open;
        // sunday.close =
        //   response.data.data?.business_hours?.sunday[0]?.close;

        //   let banckAccountData = response.data?.data?.user_profile?.bank_accounts
        //   ? response.data.data.user_profile.bank_accounts
        //   : [];

        let rolesData = response.data?.data?.draft?.roles
          ? response.data?.data?.draft?.roles
          : [];

        passportFile.fileUrl =
          response?.data?.data?.draft?.passport_file || null;
        passportFile.fileName =
          response?.data?.data?.draft?.passport_filename || null;

        ibanFile.fileUrl = response?.data?.data?.draft?.iban_file
          ? response?.data?.data?.draft?.iban_file
          : null;
        ibanFile.fileName = response?.data?.data?.draft?.iban_filename
          ? response?.data?.data?.draft?.iban_filename
          : null;

        profilePic.fileUrl = response?.data?.data?.draft?.profile_pic_url
          ? response?.data?.data?.draft?.profile_pic_url
          : null;
        profilePic.fileName = response?.data?.data?.draft?.profilepic_filename
          ? response?.data?.data?.draft?.profilepic_filename
          : null;

        iqamaFile.fileUrl =
          response?.data?.data?.draft?.national_iqama_id_fileurl || null;
        iqamaFile.fileName =
          response?.data?.data?.draft?.national_iqama_id_filename || null;

        contractsFile.fileUrl = response?.data?.data?.draft?.contracts_file
          ? response?.data?.data?.draft?.contracts_file
          : null;
        contractsFile.fileName = response?.data?.data?.draft?.contracts_fileName
          ? response?.data?.data?.draft?.contracts_fileName
          : null;

        let bank_accounts = [];
        if (response?.data.data?.draft?.bank_accounts?.length > 0) {
          bank_accounts = response?.data.data?.draft?.bank_accounts?.map(
            (account) => {
              return {
                name: account.bank_name,
                number: account.iban,
              };
            }
          );
        }
        let bankAccountData = bank_accounts?.length > 0 ? bank_accounts : [];

        //     setSunday(sunday);
        setIqamaFile(iqamaFile.fileUrl === null ? null : iqamaFile);
        setProfilePic(profilePic);
        setPassportFile(passportFile.fileUrl === null ? null : passportFile);
        setBusinessHours(response?.data?.data?.draft?.business_hours);
        setOfficeId(response.data?.data?.draft?.Working_at_office?.id);
        //     setIsRemote(response.data?.data?.is_remote);
        //     setSallaryOne(response.data?.data?.user_profile?.salary_details[0]);
        //     setSallaryTwo(response.data?.data?.user_profile?.salary_details[1]);
        //     setSallaryThree(response.data?.data?.user_profile?.salary_details[2]);
        setBankAccountData([...bankAccountData]);
        setRoles([...rolesData]);
        setIbanFile(ibanFile);
        setTimezone(response.data?.data?.draft?.timezone);
        setClosePayRoll(response.data?.data?.draft?.close_payroll_day);
        setDepartmentToggle(response.data.data?.draft?.group_department_toggle);

        //     setName(response.data?.data?.name);
        //     setJobType(response.data?.data?.job_type);
        setCompany(response.data?.data?.draft?.company_id);
        setCompanyLocation(response.data?.data?.draft?.company_location_id);
        //     setLineManager(response.data?.data?.line_manager_id);
        //     setEmail(response.data?.data?.email);
        setModel(response.data?.data?.draft);
        setRecevingData(true);
        setContractsFile(contractsFile);

        setTimeout(function () {
          setIsLoaded(true);
        }, 1000);
      })
      .catch(function (error) {
        toast.error(error);
      });
  }

  let initialRequiredField = {
    personal_information: {
      name: "",
      nationality: "",
      res_country: "",
      res_country_ar: "",
      hometown_address: "",
      local_address: "",
    },

    contact_information: {
      email: "",
      cellphone_number: "",
    },
    bank_passport_information: {
      passport_no: "",
      passport_expire_date: "",
      passport_file: null,
    },
    company_information: {
      joining_date: "",
      company_id: "",
      company_location_id: "",
      job_title: "",
      job_title_ar: "",
      department_id: "",
      position_id: "",
    },
    iqama_medical_information: {
      national_iqama_id: "",
      national_iqama_id_expire_date: "",
      iqama_id_file: "",
    },
    salary_information: {
      basic_salary: "",
      housing_salary: "",
      transpiration_salary: "",
    },
    working_hours: {},
    job_type: {
      job_type: "",
      // contracts: "",
    },
  };

  let initialValues = {
    name: "",
    nationality: "",
    res_country: "",
    res_country_ar: "",
    hometown_address: "",
    local_address: "",
    email: "",
    cellphone_number: "",
    passport_no: "",
    passport_expire_date: "",
    passport_file: null,
    joining_date: "",
    company_id: "",
    company_location_id: "",
    job_title: "",
    job_title_ar: "",
    department_id: "",
    position_id: "",
    national_iqama_id: "",
    national_iqama_id_expire_date: "",
    iqama_id_file: "",
    basic_salary: "",
    housing_salary: "",
    transpiration_salary: "",
    job_type: "",
    Working_at_office: "",
  };

  if (model) {
    if (model?.nationality === "Saudi") {
      delete initialValues?.hometown_address;
      delete initialRequiredField.personal_information?.hometown_address;
      delete initialValues?.passport_no;
      delete initialRequiredField.bank_passport_information?.passport_no;
      delete initialValues?.passport_expire_date;
      delete initialRequiredField.bank_passport_information
        ?.passport_expire_date;
      delete initialValues?.passport_file;
      delete initialRequiredField.bank_passport_information?.passport_file;
    } else {
      initialValues["hometown_address"] = "";
      initialRequiredField.personal_information["hometown_address"] = "";
      initialValues["passport_no"] = "";
      initialRequiredField.bank_passport_information["passport_no"] = "";
      initialValues["passport_expire_date"] = "";
      initialRequiredField.bank_passport_information["passport_expire_date"] =
        "";
      initialValues["passport_file"] = null;
      initialRequiredField.bank_passport_information["passport_file"] = null;
    }
  }
  // Send data
  const sendData = (values) =>
    new Promise((resolve) => {
      setSendingData(false);
      setIsDisableSubmit(true);
      // draft sa create
      const formData = new FormData();
      //  debugger
      const roleIds = values.system_roles?.map((item) => parseInt(item.id));
      if (values.name) {
        formData.append("name", values.name);
      }
      if (values.name_arabic) {
        formData.append("name_arabic", values.name_arabic);
      }
      if (values.profile_pic_url) {
        formData.append("profile_pic_url", values.profile_pic_url?.fileUrl);
      }
      if (values.gender) {
        formData.append("gender", values.gender);
      }
      if (values.dob) {
        formData.append("dob", moment(values.dob).format("YYYY-MM-DD"));
      }
      if (values.nationality) {
        formData.append("nationality", values.nationality);
      }
      if (values.nationality_ar) {
        formData.append("nationality_ar", values.nationality_ar);
      }
      if (values.res_country) {
        formData.append("resident_country", values.res_country);
      }
      if (values.res_country_ar) {
        formData.append("resident_country_arabic", values.res_country_ar);
      }

      if (values.hometown_address) {
        formData.append("hometown_address", values.hometown_address);
      }

      if (values.local_address) {
        formData.append("local_address", values.local_address);
      }

      // if (values.marital_status) {
      //   formData.append("marital_status", values.marital_status);
      // }

      if (values.marital_status) {
        if (values.marital_status === "Single") {
          formData.append("marital_status", values.marital_status);
        }
        if (values.marital_status === "Married") {
          formData.append("marital_status", values.marital_status);
          formData.append("number_of_wife", values?.number_of_wife);
          formData.append("number_of_children", values?.number_of_children);
        }
        if (values.marital_status === "Divorced") {
          formData.append("marital_status", values.marital_status);
          formData.append("number_of_children", values?.number_of_children);
        }
      }

      // contact information

      if (values.email) {
        formData.append("email", values.email);
      }

      if (values.personal_Email) {
        formData.append("personal_email", values.personal_Email);
      }
      if (values.cellphone_number) {
        formData.append("cell_phone_number", values.cellphone_number);
      }
      if (values.alternative_mobile) {
        formData.append("alternative_mobile", values.alternative_mobile);
      }
      if (values.emergency_contact_relationship) {
        formData.append(
          "emergency_contact_relationship",
          values.emergency_contact_relationship
        );
      }
      if (values.emergency_contact_name) {
        formData.append(
          "emergency_contact_name",
          values.emergency_contact_name
        );
      }
      if (values.emergency_contact_mobile_number) {
        formData.append(
          "emergency_contact_mobile_number",
          values.emergency_contact_mobile_number
        );
      }

      // bank & passport Information

      if (values.passport_no) {
        formData.append("passport_no", values.passport_no);
      }
      if (values.passport_expire_date) {
        formData.append(
          "passport_expire_date",
          moment(values.passport_expire_date).format("YYYY-MM-DD h:mm:ss")
        );
      }
      if (values.passport_file) {
        formData.append("passport_file", values.passport_file.fileUrl);
        formData.append("passport_filename", values.passport_file.fileName);
      }

      if (values.iban_fileurl) {
        formData.append("iban_file", values.iban_fileurl.fileUrl);
        formData.append("iban_filename", values.iban_fileurl.fileName);
      }

      if (values.bank_accounts?.length > 0) {
        const bank_accounts = values.bank_accounts?.map((account) => {
          return {
            bank_name: account.name,
            iban: account.number,
          };
        });
        formData.append("bank_accounts", JSON.stringify(bank_accounts));
      }

      // const keyboard = [{id:1,value:"esc",name:"esc"}]

      // Company Information

      if (values.joining_date) {
        formData.append(
          "joining_date",
          moment(values.joining_date).format("YYYY-MM-DD h:mm:ss")
        );
      }
      if (values.leaving_date) {
        formData.append(
          "leaving_date",
          moment(values.leaving_date).format("YYYY-MM-DD h:mm:ss")
        );
      }

      if (values.position_id) {
        formData.append("position_id", values.position_id);
      }

      if (values.system_roles) {
        formData.append("roles", JSON.stringify(roleIds));
      }
      if (values.company_id) {
        formData.append("company_id", values.company_id);
        formData.append("sponsorship", values.sponsorship?.value);
      }
      if (values.company_location_id) {
        formData.append("company_location_id", values.company_location_id);
      }
      if (values.department_id) {
        formData.append("department_id", values.department_id);
      }

      if (values.division_id) {
        formData.append("division_id", values?.division_id);
      }
      if (values.job_title) {
        formData.append("job_title", values.job_title);
      }
      if (values.job_title_ar) {
        formData.append("job_title_arabic", values.job_title_ar);
      }

      if (values.line_manager_id) {
        formData.append("line_manager_id", values.line_manager_id);
      }

      // iqama & medical information

      if (values.national_iqama_id) {
        formData.append("iqama_id", values.national_iqama_id);
      }

      if (values.national_iqama_id_expire_date) {
        formData.append(
          "iqama_id_expire_date",
          moment(values.national_iqama_id_expire_date).format(
            "YYYY-MM-DD h:mm:ss"
          )
        );
      }
      if (values.iqama_id_file) {
        formData.append("iqama_id_file", values.iqama_id_file.fileUrl);
        formData.append("iqama_id_filename", values.iqama_id_file.fileName);
      }
      if (values.medical_membership_no) {
        formData.append("medical_membership_no", values.medical_membership_no);
      }

      // sallary Information

      if (values.basic_salary) {
        formData.append("basic_salary", values.basic_salary);
      }
      if (values.housing_salary) {
        formData.append("housing_salary", values.housing_salary);
      }
      if (values.transpiration_salary) {
        formData.append("transpiration_salary", values.transpiration_salary);
      }
      // if (values.salary_total) {
      //   formData.append("salary_total", values.salary_total);
      // }

      // working hours

      const businessHours = {
        friday: [
          values.friday_timing
            ? values.friday_timing
            : { open: null, close: null },
        ],
        saturday: [
          values.saturday_timing
            ? values?.saturday_timing
            : { open: null, close: null },
        ],
        sunday: [
          values.sunday_timing
            ? values.sunday_timing
            : { open: null, close: null },
        ],
        monday: [
          values.monday_timing
            ? values.monday_timing
            : { open: null, close: null },
        ],
        tuesday: [
          values.tuesday_timing
            ? values.tuesday_timing
            : { open: null, close: null },
        ],
        wednesday: [
          values.wednesday_timing
            ? values.wednesday_timing
            : { open: null, close: null },
        ],
        thursday: [
          values.thursday_timing
            ? values.thursday_timing
            : { open: null, close: null },
        ],
      };

      if (values.Working_at_office) {
        formData.append("office_id", values.Working_at_office);

        formData.append("Working_at_office", values.Working_at_office);
      }

      // if (businessHours) {
      formData.append("business_hours", JSON.stringify(businessHours));
      // }

      if (values.timezone) {
        formData.append("timezone", values.timezone);
      }
      if (values.close_payroll_day) {
        formData.append(
          "close_payroll_day",
          parseInt(values.close_payroll_day)
        );
      }
      //group_department_toggle
      if (values.group_department_toggle) {
        formData.append(
          "group_department_toggle",
          values?.group_department_toggle
        );
      }

      if (values.job_type) {
        formData.append("job_type", values.job_type);
      }

      // if (values.is_remote) {
      //   // formData.append("is_remote", values.is_remote);
      //   if (values.is_remote === true) {
      //     formData.append("is_remote", 1);
      //   }
      //   else {
      //     formData.append("is_remote", 0);
      //   }
      // }

      if (values.is_remote === true) {
        formData.append("is_remote", 1);
      } else {
        formData.append("is_remote", 0);
      }

      if (values.contracts) {
        formData.append("contracts", values.contracts);
      }
      if (values.contracts_file) {
        formData.append("contracts_file", values?.contracts_file?.fileUrl);
      }

      formData.append("working_days", 1);
      formData.append("draft_id", draftEmployeeId);

      axios
        .post("employees/store", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          toast.success(response.data.message);
          setTimeout(function () {
            navigate("/employees");
            setIsDisableSubmit(false);
          }, 2000);
        })
        .catch(function (error) {
          setSendingData(false);
          setTimeout(function () {
            setIsDisableSubmit(false);
          }, 2000);
          const emailError = JSON.parse(error.response.data.message);
          if (emailError.email) {
            if (emailError.email[0] === "The email has already been taken.") {
              setErrorHold(true);
              toast.error(emailError.email[0]);
            }
          } else {
            toast.error(error.message);
            setErrorHold(false);
          }
          resolve();
        });
    });

  const onSubmit = async (values) => {
    await sendData(values);
  };
  const errorShowingHanlder = () => {
    setIsDisableSubmit(true);
    if (JSON.stringify(customError) !== "{}") {
      setSubmitted(true);
      toast.error("Please validate your highlighted Tabs!");
      setIsDisableSubmit(false);
    }
  };

  if (!isLoaded) {
    return <Loader fullscreen={false} />;
  }
  return (
    <div className="h-full">
      {/* Page title & actions */}
      <NavBar pageTitle={pageTitle}>
        <a
          href="#"
          onClick={() => setAlertModalOpen(true)}
          disabled={sendingData}
          className="inline-flex items-center px-[10px] py-[5px] sm:px-3 sm:py-2 border border-gray-300 shadow-sm text-xs sm:text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-[3px] sm:mr-2 disabled:opacity-75"
        >
          Cancel
        </a>
        <button
          disabled={isDisableDraft}
          type="button"
          // onClick={() => saveAsDraft(getState().values)}
          onClick={() =>
            document.getElementById("draftmainFormSubmitEmployee").click()
          }
          className="mr-[3px] sm:mr-2 relative inline-flex items-center px-[7px] py-[3px] xs:px-[10px] xs:py-[5px] sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-0 disabled:opacity-75"
        >
          {isDisableDraft ? "Submitting.." : "Save and Continue Later"}
        </button>

        {JSON.stringify(customError) !== "{}" ? (
          <button
            disabled={stateData}
            type="submit"
            onClick={errorShowingHanlder}
            className="relative inline-flex items-center px-[10px] py-[5px] sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
          >
            {isDisableSubmit ? "Submitting..." : "Create"}
          </button>
        ) : (
          <button
            disabled={stateData}
            type="submit"
            onClick={() =>
              document.getElementById("mainFormSubmitEmployee").click()
            }
            className="relative inline-flex items-center px-[10px] py-[5px] sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
          >
            {isDisableSubmit ? "Submitting..." : "Create"}
          </button>
        )}
      </NavBar>
      {/* /Page title & actions */}

      <main className="">
        <div className="max-w-3xl mx-auto px-0 sm:px-6  md:space-x-5 lg:max-w-full lg:px-8 mt-0 relative pb-0 lg:pb-6">
          {sendingData && <Loader fullscreen={false} />}
          {recevingData && (
            <UpdateCrudFrom
              onSubmit={onSubmit}
              initialValues={{
                ...model,
                profile_pic_url: profilePic,
                passport_file: passportFile,
                iban_fileurl: ibanFile,
                iqama_id_file: iqamaFile,
                Working_at_office: officeId,
                timezone: timezone,
                close_payroll_day: closePayRoll,
                group_department_toggle: departmentToggle,
                contracts_file: contractsFile,
                system_roles: [...roles],
                bank_accounts: [...bankAccountData],
                sunday_timing: businessHours?.sunday?.[0],
                monday_timing: businessHours?.monday?.[0],
                tuesday_timing: businessHours?.tuesday?.[0],
                wednesday_timing: businessHours?.wednesday?.[0],
                thursday_timing: businessHours?.thursday?.[0],
                friday_timing: businessHours?.friday?.[0],
                saturday_timing: businessHours?.saturday?.[0],
              }}
              draftEmployeeId={draftEmployeeId}
              setButtonActive={initialValues}
              updateValues={initialValues}
              user={user}
              savaButtonAction={(val) => setStateData(val)}
              initialRequiredField={initialRequiredField}
              errorMsg={errorMsg}
              tabSelect={tabSelect}
              isDisableDraft={isDisableDraft}
              setCustomError={setCustomError}
              customError={customError}
              setIsDisableDraft={setIsDisableDraft}
              setErrorHold={setErrorHold}
              errorHold={errorHold}
              setSubmitted={setSubmitted}
              submitted={submitted}
              nonRegisterederror={nonRegisterederror}
              setNonRegisterederror={setNonRegisterederror}
              isDisableSubmit={isDisableSubmit}
            />
          )}
        </div>
      </main>

      <AlertModal
        title="Close Form"
        confirm={closeForm}
        close={() => setAlertModalOpen(false)}
        open={alertModalOpen}
      />
    </div>
  );
}
