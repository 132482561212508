import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import NavBar from "layout/NavBar";


export default function ViewDepartment() {

  let {departmentId} = useParams();
  const [companyInfo, setCompanyInfo] = useState({});


  const pageTitle = "Department Profile";


  let navigate = useNavigate();
  useEffect(() => {
    getDepartmentInfo();
  }, []);

  async function getDepartmentInfo() {
    await axios
      .get("departments/show/" + departmentId, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // setSendingData(false);
        // resolve()
        setCompanyInfo(response.data.data);
        // navigate("/companies");
      })
      // .catch(function (error) {
      //   toast.error(error.response.data.message);
      // });
  }

  function backButton() {
    navigate("/Departments");
  }

  console.log("departmentInfo" , companyInfo)


  return (
    <div>
         <NavBar pageTitle={pageTitle}>
        <button
          className="flex float-right px-[15px] py-[7px] rounded-md bg-[#1C3FAA] text-white font-medium"
          // onClick={backButton}
        >
          Back
        </button>
      </NavBar>
      <div className="grid grid-cols-1 sm:m-10 m-0 ">
        <div className="col-span-1 bg-white rounded-lg sm:p-5 p-0 mb-5 px-2 ">
          <div>
            <div className="border-b-2 mt-4 sm:mt-0">
              <h3 className="text-lg leading-6 font-bold text-[20px]">
                {" "}
                {companyInfo?.company ? companyInfo.company : "N/A"}
              </h3>
              <p className="mt-1 max-w-2xl text-sm pb-5">
                {" "}
                {companyInfo?.name ? companyInfo.name : "N/A"}
              </p>
            </div>
            <div className="grid xl:grid-cols-2 pt-8">
              <div>
                <tr className="grid grid-flow-row sm:flex">
                  <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">
                  Manager Name: 
                  </td>
                  <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                    {companyInfo?.manager_name ? companyInfo?.manager_name : "N/A"}
                  </td>
                </tr>
                <tr className="grid grid-flow-row sm:flex">
                  <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">
                  Manager's Corporate Email:
                  </td>
                  <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                    
                    {companyInfo?.manager_email ? companyInfo?.manager_email : "N/A"}
                  </td>
                </tr>
              </div>
              <div>
                <tr className="grid grid-flow-row sm:flex">
                  <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">
                  Supervisors:
                  </td>
                  <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                  {companyInfo?.supervisors ? companyInfo?.supervisors : "N/A"}
                  </td>
                </tr>
                <tr className="grid grid-flow-row sm:flex">
                  <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">
                  Addditional roles to the manager:
                  </td>
                  <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                  {companyInfo?.roles ? companyInfo?.roles : "N/A"}
                  </td>
                </tr>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  )
}