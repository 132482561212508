import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

export default function DepartmentSelectField(props) {
    const {label, input, required = false} = props
    const [companies, setCompanies] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState(input.value.companies ? input.value.companies : [])
    const [selectedDepartments, setSelectedDepartments] = useState(input.value.departments ? input.value.departments : [])

    useEffect(() => {
        // Get user detail
        axios.get('/companies-with-departments')
            .then(function (response) {
                //console.log(response.data)
                setCompanies(response.data)
            })
            .catch(function (error) {
            // handle error
            //setIsLoaded(true);
            //setError(error);
            })
            .then(function () {
            //setIsLoaded(true);
            });    
    }, [])

    function onCompanyCheck(event) {
        const companyId = parseInt(event.target.value)
        //console.log(companyId, event.target.checked)
        if(event.target.checked) {
            let newArray = [...selectedCompanies, companyId];
            let newCleanArray = [...new Set(newArray)];
            setSelectedCompanies(newCleanArray)
            //console.log('add',newCleanArray)
            selectAllDepartments(companyId)
        } else {
            let newArray = selectedCompanies.slice();
            const findIndex = newArray.indexOf(companyId)
            //console.log(findIndex)
            if(findIndex >= 0) {
                newArray.splice(findIndex, 1)
                setSelectedCompanies(newArray)
                //console.log('remove',newArray)
                removeAllDepartments(companyId)
            }
        }
    }

    function onDepartmentCheck(event, companyId) {
        const departmentId = parseInt(event.target.value)
        //console.log(departmentId, event.target.checked)
        if(event.target.checked) {
            let newArray = [...selectedDepartments, departmentId];
            let newCleanArray = [...new Set(newArray)];
            setSelectedDepartments(newCleanArray)
            //console.log('add',newCleanArray)
        } else {
            let newArray = selectedDepartments.slice();
            const findIndex = newArray.indexOf(departmentId)
            //console.log(findIndex)
            if(findIndex >= 0) {
                newArray.splice(findIndex, 1)
                setSelectedDepartments(newArray)
                //console.log('remove',newArray)
            }

            // Uncheck company
            let newCompanyArray = selectedCompanies.slice();
            const findCompanyIndex = newCompanyArray.indexOf(companyId)
            if(findCompanyIndex >= 0) {
                newCompanyArray.splice(findCompanyIndex, 1)
                setSelectedCompanies(newCompanyArray)
            }
        }
        
    }

    function selectAllDepartments(companyId) {
        var index = companies.findIndex(company => company.id == companyId)
        if(index >= 0) {
            let newArray = [...selectedDepartments]
            companies[index].departments.forEach((department) => {
                newArray.push(department.id)
            })
            let newCleanArray = [...new Set(newArray)]
            setSelectedDepartments(newCleanArray)
        }
    }

    function removeAllDepartments(companyId) {
        var index = companies.findIndex(company => company.id == companyId)
        if(index >= 0) {
            let newArray = selectedDepartments.slice();
            companies[index].departments.forEach((department) => {
                const findIndex = newArray.indexOf(department.id)
                if(findIndex >= 0) {
                    newArray.splice(findIndex, 1)
                }
            })
            setSelectedDepartments(newArray)
        }
    }

    const permissions = function() {
        return {
            companies: selectedCompanies,
            departments: selectedDepartments,
        }
    }

    useEffect(() => {
        input.onChange(permissions())
    }, [selectedCompanies, selectedDepartments]);

    return (
    <>
        <div className="sm:col-span-4">
            <label className="block text-sm font-medium text-gray-700 mb-5">
                {label} {required && <span className="text-red-500">*</span>}
            </label>

            <div className="max-h-96 overflow-y-scroll">
                { companies.map((company) => (<fieldset key={company.id} className="space-y-2 mb-2">
                    <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                            <input
                                id={'company_'+company.id}
                                aria-describedby={'company_'+company.id+'_description'}
                                name={'company_'+company.id}
                                type="checkbox"
                                className="focus:ring-0 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                value={company.id}
                                onChange={(event) => onCompanyCheck(event)}
                                checked={selectedCompanies.indexOf(company.id) >= 0}
                            />
                        </div>
                        <div className="ml-3 text-sm">
                            <label htmlFor={'company_'+company.id} className="font-medium text-gray-700">
                            {company.name}
                            </label>
                        </div>
                    </div>

                    {company.departments.map((department) => (<div key={department.id} className="ml-10 relative flex items-start">
                        <div className="flex items-center h-5">
                            <input
                                id={'department_'+department.id}
                                aria-describedby={'department_'+company.id+'_description'}
                                name={'department_'+department.id}
                                type="checkbox"
                                value={department.id}
                                className="focus:ring-0 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                onChange={(event) => onDepartmentCheck(event, company.id)}
                                checked={selectedDepartments.indexOf(department.id) >= 0}
                            />
                        </div>
                        <div className="ml-3 text-sm">
                            <label htmlFor={'department_'+department.id} className="font-medium text-gray-700">
                            {department.name}
                            </label>
                        </div>
                    </div>))}

                </fieldset>))}
            </div>
        </div>

        {/* <div>
            <pre>{JSON.stringify(input.value, 0, 2)}</pre>
        </div> */}
    </>
    )
  }
  