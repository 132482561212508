import { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import { Dialog, Menu, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { ChatAltIcon, CheckCircleIcon, DocumentIcon, XCircleIcon } from '@heroicons/react/solid'
import { Form } from 'react-final-form'

// Components
import AlertModal from '../../components/AlertModal';
import Map from '../../components/Map';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const statusColors = {
    valid: 'bg-green-500',
    invalid: 'bg-red-500',
    revoked: 'bg-orange-500',
}

const statusTextColors = {
    valid: 'text-green-500',
    invalid: 'text-red-500',
    revoked: 'text-orange-500',
}

const statusLabels = {
    valid: 'Valid',
    invalid: 'Invalid',
    revoked: 'Revoked',
}

export default function Show(props) {
    const { user, open, close, modelId, reloadData, markValid } = props
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [model, setModel] = useState();
    const [archiveModalOpen, setArchiveModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [statuses, setStatuses] = useState([])

    function loadData() {
        setIsLoaded(false)
        axios.get('/attendance-reports/'+modelId)
        .then(function (response) {
            // handle success
            setIsLoaded(true);
            setModel(response.data.data)
        })
        .catch(function (error) {
            // handle error
            setIsLoaded(true);
            setError(error);
        })
        .then(function () {
            setIsLoaded(true);
        });
    }

    useEffect(() => {
        if(open && modelId) {
            loadData() 
        }
    }, [open])

    function archiveModel() {
        setIsDeleting(true)
        axios.post('attendance-reports/'+model.id, {_method: 'delete'})
          .then(function (response) {
                if(response.data.status == 'deleted') {
                    window.location.reload()
                }
                setIsDeleting(false)
            })
            .catch(function (error) {
                setIsDeleting(false)
            });
    }

    function deleteModel() {
        setIsDeleting(true)
        axios.post('attendance-reports/'+model.id+'/force', {_method: 'delete'})
          .then(function (response) {
                if(response.data.status == 'deleted') {
                    window.location.reload()
                }
                setIsDeleting(false)
            })
            .catch(function (error) {
                setIsDeleting(false)
            });
    }

    // Send data
  const sendData = values => new Promise(resolve => {
    //setSendingData(true)
    const formData = new FormData();

    formData.append('_method', 'put');
    formData.append('status_id', values.status.id);

    axios.post('recruit-job-applications-update-status/'+model.id, formData)
    .then(function (response) {
        //setSendingData(false)
        resolve()
        if(response.data.data.id) {
          //setSavedNotificationShow(true)
        }
      })
      .catch(function (error) {
        //setSendingData(false)
        resolve()
      });
  })

    // Submit form
    const onSubmit = async values => {
        await sendData(values)
        reloadData()
    }

    return (
        <div>
        <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="px-4 py-3 sm:px-6">
                            <div className="flex items-start justify-between">
                                <Dialog.Title className="text-gray-900">
                                    { isLoaded && <Form
                                        keepDirtyOnReinitialize
                                        onSubmit={onSubmit}
                                        initialValues={{ ...model }}
                                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                                            <div></div>)}  
                                        />}
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-0"
                                    onClick={() => close()}
                                >
                                    <span className="sr-only">Close panel</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                            </div>
                            </div>
                            {/* Main */}
                            { isLoaded && <div className="divide-y divide-gray-200">
                            <div className="pb-6">
                                <div className="h-24 bg-indigo-700 sm:h-20 lg:h-20" />
                                <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                                <div>
                                    <div className="-m-1 flex">
                                    <div className=" overflow-hidden rounded-full border-4 border-white bg-white aspect-square">
                                        <img className="w-24 sm:w-40 first-letter:lg:w-48 rounded-full"
                                        src={model.user.avatar_url}
                                        alt=""
                                        />
                                    </div>
                                    </div>
                                </div>
                                <div className="mt-6 sm:ml-6 sm:flex-1">
                                    <div>
                                    <div className="flex items-center">
                                        <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">{model.user.name}</h3>
                                    </div>
                                    
                                    <p className="text-sm text-gray-500 flex items-center">
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">{model.timezone}</span>
                                    </p>
                                    
                                    </div>
                                    <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                                        {model.deleted_at && <span className={classNames(statusColors['revoked'], "text-white uppercase inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium")}>{statusLabels['revoked']}</span>}
                                        {!model.deleted_at && <span className={classNames(statusColors[model.status], "text-white uppercase inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium")}>{statusLabels[model.status]}</span>}
                                        
                                        { (user.permissions.manage_attendances && !model.is_valid) && <button
                                            onClick={() => markValid()}
                                            type="button"
                                            className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0 sm:flex-1"
                                        >
                                            Mark valid
                                        </button>}
                                    
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 sm:px-0 sm:py-0">
                                <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                                    {model.deleted_by && <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Revoked by
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 capitalize">
                                            {model.deleted_by.name}<br />On {model.deleted_at}
                                        </dd>
                                    </div> }
                                    {model.creator && <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Creator
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 capitalize">
                                            {model.creator.name}<br />On {model.created_at}
                                            {model.creator_note && <div className="flex justify-start"><ChatAltIcon className="mr-1 h-5 w-5 text-gray-400" aria-hidden="true" /> {model.creator_note}</div>}
                                        </dd>
                                    </div> }

                                    {model.marked_valid_by && <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Remarked valid
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 capitalize">
                                            By {model.marked_valid_by.name}<br />On {model.marked_valid_at}
                                            <div className="flex justify-start"><ChatAltIcon className="mr-1 h-5 w-5 text-gray-400" aria-hidden="true" /> {model.remarked_note}</div>
                                        </dd>
                                    </div> }
                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Date
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 capitalize">
                                        {model.date}
                                        </dd>
                                    </div> 
                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Office
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 capitalize">
                                        {model.office.name} 
                                        {(user.managed_companies.length > 1 && user.permissions.manage_attendances) && <span>({model.company.name})</span>}
                                        </dd>
                                    </div> 
                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Buffer 15 mins allowed for employee?
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 capitalize">
                                            {model.is_allow_buffer ? 'Yes' : 'No'}
                                        </dd>
                                    </div> 
                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Check-in
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                            <div className="">
                                                <div className="flex items-center space-x-1">
                                                { model.is_checkin_time_valid && <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />}
                                                { !model.is_checkin_time_valid && <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
                                                <span className="text-sm font-medium text-gray-500">{model.checkin_at}</span>
                                                </div>

                                                {model.office.id !== 1 && <div>
                                                    { model.is_checkin_location_valid && <div className="flex items-center space-x-1">
                                                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                                    <span className="text-sm font-medium text-gray-500">In office</span>
                                                    </div>}

                                                    { !model.is_checkin_location_valid && <div className="flex items-center space-x-1">
                                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                    <span className="text-sm font-medium text-gray-500">Not in office</span>
                                                    </div>}
                                                </div>}
                                            </div>
                                            {model.checkin_note && <div className="flex justify-start"><ChatAltIcon className="mr-1 h-5 w-5 text-gray-400" aria-hidden="true" /> {model.checkin_note}</div>}
                                        </dd>
                                    </div>

                                    {!model.creator && <div className="sm:px-6 sm:py-2">            
                                        <div className="h-[250px]">
                                            {(model.office_id === 1) && <Map lat={model.checkin_lat} lng={model.checkin_lng} userAvatarUrl={model.user.avatar_url} />}
                                            {(model.office_id != 1) && <Map lat={model.checkin_lat} lng={model.checkin_lng} userAvatarUrl={model.user.avatar_url} officeLat={model.office_lat} officeLng={model.office_lng} />}
                                        </div>       
                                    </div>}

                                    <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Check-out
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                            {model.checkout_at && <div className="">
                                                <div className="flex items-center space-x-1">
                                                { model.is_checkout_time_valid && <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />}
                                                { !model.is_checkout_time_valid && <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
                                                <span className="text-sm font-medium text-gray-500">{model.checkout_at}</span>
                                                </div>

                                                {model.office.id !== 1 && <div>
                                                    { model.is_checkout_location_valid && <div className="flex items-center space-x-1">
                                                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                                    <span className="text-sm font-medium text-gray-500">In office</span>
                                                    </div>}

                                                    { !model.is_checkout_location_valid && <div className="flex items-center space-x-1">
                                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                    <span className="text-sm font-medium text-gray-500">Not in office</span>
                                                    </div>}
                                                </div>}
                                            </div>}

                                            {model.checkout_note && <div className="flex justify-start"><ChatAltIcon className="mr-1 h-5 w-5 text-gray-400" aria-hidden="true" /> {model.checkout_note}</div>}

                                            {!model.checkout_at && <div>N/A</div>}

                                            {(model.marked_valid_by && model.checkout_at != model.checkout_at_original) && <div className="mt-1 text-xs text-gray-500">
                                                Updated by {model.marked_valid_by.name}
                                            </div>}
                                        </dd>
                                    </div>

                                    {(model.checkout_at && !model.creator) && <div className="sm:px-6 sm:py-2">            
                                        <div className="h-[250px]">
                                            {(model.office_id === 1) && <Map lat={model.checkout_lat} lng={model.checkout_lng} userAvatarUrl={model.user.avatar_url} />}
                                            {(model.office_id != 1) && <Map lat={model.checkout_lat} lng={model.checkout_lng} userAvatarUrl={model.user.avatar_url} officeLat={model.office_lat} officeLng={model.office_lng} />}
                                        </div>       
                                    </div>}

                                    {model.checkout_at && <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Hours Worked
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 capitalize">
                                            {model.checkout_at && <div>
                                                { model.is_worked_valid && <div className="">
                                                    <div className="flex items-center space-x-1">
                                                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                                        <span className="text-sm font-medium text-green-500">
                                                        {model.hours_worked} 
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center space-x-1">
                                                        <span className="text-sm font-medium text-gray-500">
                                                        {model.valid_working_hours} 
                                                        </span>
                                                        { model.worked_diff_in_seconds && <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-green-600">+{model.worked_diff_in_hours}</span>}
                                                    </div>
                                                    </div>}

                                                    { !model.is_worked_valid && <div className="">
                                                    <div className="flex items-center space-x-1">
                                                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                        <span className="text-sm font-medium text-gray-500">
                                                        {model.hours_worked} 
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center space-x-1">
                                                        <span className="text-sm font-medium text-gray-500">
                                                        {model.valid_working_hours} 
                                                        </span>
                                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-orange-600">-{model.worked_diff_in_hours}</span>
                                                    </div>
                                                    </div>}
                                                </div>}
                                        </dd>
                                    </div>}
                                    {/* <div className="sm:flex sm:px-6 sm:py-2">
                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                        Hours Worked
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 capitalize">
                                            {model.gender}
                                        </dd>
                                    </div> */}
                                    
                                    
                                </dl>
                            </div>                         

                        </div>}
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
        
        <AlertModal title="Archive Application" note="Are you sure you want to archive this application?" confirm={archiveModel} close={() => setArchiveModalOpen(false)} open={archiveModalOpen} />
        <AlertModal title="Delete Application" note="Are you sure you want to delete this application?" confirm={deleteModel} close={() => setDeleteModalOpen(false)} open={deleteModalOpen} />
        </div>
    )
}
